<template>
  <div>
    <ul class="menu-header">
      <li><span class="h2">Configurações dos eventos</span></li>
    </ul>

    <el-form
      ref="formEvents"
      status-icon
      label-position="right"
      style="max-width: 70%;"
    >
      <!-- Configurações de eventos gerais -->
      <h2 style="border-bottom: 0px; margin: 20px 0px;">
        Configurações do formulário do cadastro de eventos gerais
      </h2>

      <switch-item
        :swinput.sync="settings.events.evtGeneralNeedCompPhoto"
        label="Habilita cadastro da foto do comprovante"
      />
      <switch-item
        :swinput.sync="settings.events.evtGeneralNeedCompPhotoForce"
        label="Foto do comprovante obrigatório"
      />
      <switch-item
        :swinput.sync="settings.events.evtGeneralNeedOdomPhoto"
        label="Habilita cadastro da foto do odômetro"
      />
      <switch-item
        :swinput.sync="settings.events.evtGeneralNeedOdomPhotoForce"
        label="Foto do odômetro obrigatório"
      />
      <switch-item
        :swinput.sync="settings.events.evtGeneralNeedExtrasPhoto"
        label="Habilita cadastro de fotos extras"
      />
      <switch-item
        :swinput.sync="settings.events.evtGeneralNeedExtrasPhotoForce"
        label="Cadastro de fotos extras obrigatório"
      />
      <switch-item
        :input.sync="settings.events.evtGeneralExtrasPhotoMax"
        label="Quantidade máxima de fotos extras"
      />
      <switch-item
        :input.sync="settings.events.evtGeneralTypes"
        label="Tipos de eventos disponíveis"
      />

      <!-- Configurações de eventos de abastecimetno -->
      <el-divider></el-divider>
      <h2 style="border-bottom: 0px; margin: 20px 0px;">
        Configurações do formulário do cadastro de abastecimento
      </h2>

      <switch-item
        :swinput.sync="settings.events.evtPayFuelNeedCompPhoto"
        label="Habilita cadastro da foto do comprovante"
      />
      <switch-item
        :swinput.sync="settings.events.evtPayFuelNeedCompPhotoForce"
        label="Foto do comprovante obrigatório"
      />
      <switch-item
        :swinput.sync="settings.events.evtPayFuelNeedOdomPhoto"
        label="Habilita cadastro da foto do odômetro"
      />
      <switch-item
        :swinput.sync="settings.events.evtPayFuelNeedOdomPhotoForce"
        label="Foto do odômetro obrigatório"
      />

      <!-- Configurações de eventos de pedágio -->
      <el-divider></el-divider>
      <h2 style="border-bottom: 0px; margin: 20px 0px;">
        Configurações do formulário do cadastro de pedágios
      </h2>

      <switch-item
        :swinput.sync="settings.events.evtPayTaxNeedCompPhoto"
        label="Habilita cadastro da foto do comprovante"
      />
      <switch-item
        :swinput.sync="settings.events.evtPayTaxNeedCompPhotoForce"
        label="Foto do comprovante obrigatório"
      />
      <switch-item
        :swinput.sync="settings.events.evtPayTaxNeedOdomPhoto"
        label="Habilita cadastro da foto do odômetro"
      />
      <switch-item
        :swinput.sync="settings.events.evtPayTaxNeedOdomPhotoForce"
        label="Foto do odômetro obrigatório"
      />
    </el-form>

    <div class="actions-buttons actions-buttons">
      <el-button type="success" @click="saveForm">Salvar</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import switchItem from '../components/formSwitchItem'

let evtGeneralTypesDefault = [
  'Entrega fora da carga',
  'Retirada fora da carga',
  'Manutenção do Caminhão',
]

export default {
  name: 'tabCompanieFormEvents',
  components: { switchItem },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    companie: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        events: {
          evtGeneralExtrasPhotoMax: this._.get(
            this,
            'companie.settings.events.evtGeneralExtrasPhotoMax',
            4
          ),
          evtGeneralNeedCompPhoto: this._.get(
            this,
            'companie.settings.events.evtGeneralNeedCompPhoto',
            false
          ),
          evtGeneralNeedCompPhotoForce: this._.get(
            this,
            'companie.settings.events.evtGeneralNeedCompPhotoForce',
            false
          ),
          evtGeneralNeedExtrasPhoto: this._.get(
            this,
            'companie.settings.events.evtGeneralNeedExtrasPhoto',
            true
          ),
          evtGeneralNeedExtrasPhotoForce: this._.get(
            this,
            'companie.settings.events.evtGeneralNeedExtrasPhotoForce',
            true
          ),
          evtGeneralNeedOdomPhoto: this._.get(
            this,
            'companie.settings.events.evtGeneralNeedOdomPhoto',
            false
          ),
          evtGeneralNeedOdomPhotoForce: this._.get(
            this,
            'companie.settings.events.evtGeneralNeedOdomPhotoForce',
            false
          ),
          evtGeneralTypes: this._.get(
            this,
            'companie.settings.events.evtGeneralTypes',
            evtGeneralTypesDefault
          ),

          evtPayFuelNeedCompPhoto: this._.get(
            this,
            'companie.settings.events.evtPayFuelNeedCompPhoto',
            true
          ),
          evtPayFuelNeedCompPhotoForce: this._.get(
            this,
            'companie.settings.events.evtPayFuelNeedCompPhotoForce',
            true
          ),
          evtPayFuelNeedOdomPhoto: this._.get(
            this,
            'companie.settings.events.evtPayFuelNeedOdomPhoto',
            false
          ),
          evtPayFuelNeedOdomPhotoForce: this._.get(
            this,
            'companie.settings.events.evtPayFuelNeedOdomPhotoForce',
            false
          ),
          evtPayTaxNeedCompPhoto: this._.get(
            this,
            'companie.settings.events.evtPayTaxNeedCompPhoto',
            true
          ),
          evtPayTaxNeedCompPhotoForce: this._.get(
            this,
            'companie.settings.events.evtPayTaxNeedCompPhotoForce',
            false
          ),
          evtPayTaxNeedOdomPhoto: this._.get(
            this,
            'companie.settings.events.evtPayTaxNeedOdomPhoto',
            true
          ),
          evtPayTaxNeedOdomPhotoForce: this._.get(
            this,
            'companie.settings.events.evtPayTaxNeedOdomPhotoForce',
            false
          ),
        },
      },
    }
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions('User', ['updateCompanieSettings']),
    saveForm() {
      this.updateCompanieSettings({ settings: this.settings })
    },
  },
}
</script>
