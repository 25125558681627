<template>
  <div class="SelectChecker">
    <el-dialog
      title="Selecione um conferente"
      :visible.sync="openModal"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item>
          <el-select
            v-model="checker"
            placeholder="Selecione um conferente"
            @change="onChange"
          >
            <el-option
              v-for="(checker, index) in checkersBranch"
              :key="index"
              :label="checker"
              :value="checker"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">Fechar</el-button>
        <el-button type="primary" @click="confirm">Confirma</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: ['openModal', 'truckChecker'],
  data() {
    return {
      checker: null,
    }
  },
  computed: {
    ...mapState('Checkers', ['allCheckers']),
    ...mapState('Invoices', ['filters']),
    checkersBranch() {
      return this.allCheckers
        .filter((checker) => this.filters.branch === checker.branch_id)
        .map((checker) => checker.name)
    },
  },
  created() {
    this.getCheckers()
  },
  methods: {
    ...mapActions('Checkers', ['getCheckers']),
    cancel() {
      this.$emit('closeModal', this.truckChecker, null)
    },
    confirm() {
      this.$emit('closeModal', this.truckChecker, this.checker)
    },
    onChange() {
      this.$emit('closeModal', this.truckChecker, this.checker)
    },
  },
}
</script>

<style>
.SelectChecker {
}
</style>
