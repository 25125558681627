<template>
  <div class="company-settings">
    <ul class="menu-header">
      <li>
        <span class="h1">{{ title }}</span>
      </li>
    </ul>
    <div>
      <el-tabs ref="companieTabs" type="border-card" @tab-click="tabClicked">
        <el-tab-pane name="tabGeneral" label="Geral">
          <form-general :profile="profile" :companie="companie" />
        </el-tab-pane>

        <el-tab-pane name="tabBranches" label="Endereços" lazy>
          <tab-branches />
        </el-tab-pane>

        <el-tab-pane name="tabConfigurations" label="Configurações do APP" lazy>
          <form-settings :profile="profile" :companie="companie" />
        </el-tab-pane>

        <el-tab-pane name="tabEvents" label="Eventos" lazy>
          <form-events :profile="profile" :companie="companie" />
        </el-tab-pane>

        <el-tab-pane name="tabNotifications" label="Notificações" lazy v-if="false">
          <form-notifications :profile="profile" :companie="companie" />
        </el-tab-pane>

        <el-tab-pane
          name="tabRaw"
          label="Raw"
          v-if="env === 'development'"
          lazy
        >
          <raw-data />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import formGeneral from './forms/formGeneral'
import formNotifications from './forms/formNotifications'
import formSettings from './forms/formSettings'
import formEvents from './forms/formEvents'
import tabBranches from './tabs/tabBranches'
import RawData from './raw.vue'
import env from '@/config/environment'

export default {
  name: 'companieIndex',
  components: {
    formGeneral,
    formNotifications,
    formSettings,
    formEvents,
    RawData,
    tabBranches,
  },
  props: {},
  data() {
    return {
      title: this.$route.meta.title || 'Configurações gerais da Empresa',
      env: env.build.nodeEnv,
    }
  },
  computed: {
    ...mapState('User', ['profile', 'companie']),
  },
  watch: {},
  mounted() {
    this.$refs['companieTabs'].setCurrentName(
      this.$route.meta.tabName || 'tabGeneral'
    )

    this.getUserProfile()
  },
  methods: {
    ...mapActions('User', ['getUserProfile']),
    tabClicked(component, mouseevent) {
      // console.log(
      //   `Tab clicked`,
      //   component.name,
      //   component.index,
      //   component.label,
      //   this.tabName
      // )
      if (component.name === 'tabGeneral')
        this.$router.push({ name: 'CompanieTabGeneral' })
      if (component.name === 'tabBranches')
        this.$router.push({ name: 'CompanieTabBranches' })
      if (component.name === 'tabConfigurations')
        this.$router.push({ name: 'CompanieTabGeneralSettings' })
      if (component.name === 'tabEvents')
        this.$router.push({ name: 'CompanieTabEventsSettings' })
      if (component.name === 'tabNotifications')
        this.$router.push({ name: 'CompanieTabNotificationsSettings' })
    },
  },
}
</script>
