import { store } from '@/store'

export default async (rule, value, callback) => {
  const regxLogin = new RegExp('^[a-zA-Z0-9_.]*$')
  const exists = await store.dispatch('User/checkLoginExists', value)
  if (exists) callback(new Error('Este login já está sendo utilizado!'))
  else if (!regxLogin.test(value))
    callback(
      new Error(
        'Utilize apenas letras, números, ponto (.) e underline (_) no campo login!'
      )
    )
  callback()
}
