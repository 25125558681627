<template>
  <el-dialog
    :title="title"
    :visible.sync="showThisDialog"
    @close="onCloseDialog"
    center
    custom-class="dialog-custom-picture"
  >
  <center>
    <div v-viewer="{movable: true, navbar: false, title: false}" @click="onCloseDialog">
      <img v-for="src in urlPicture" :src="src" :key="src" class="img">
    </div>

    <div>Clique na imagem para ampliar</div>
  </center>
  </el-dialog>
</template>

<script>
  import 'viewerjs/dist/viewer.css'
  import { directive as viewer } from "v-viewer"

export default {
  name: 'picture-dialog',
  props: {
    title: {
      default: 'Foto',
      type: String,
    },
  },
  directives: {
    viewer: viewer({
      debug: false,
      zIndex: 9999
    }),
  },
  data() {
    return {
      showThisDialog: false,
      urlPicture: null,
    }
  },
  computed: {},
  created() {
    // console.log('Picture Dialog Created')
  },
  methods: {
    showDialog(url) {
      this.urlPicture = [ url ]
      this.showThisDialog = true
    },
    onCloseDialog() {
      // Run when the dialods close
      this.urlPicture = null
      this.showThisDialog = false
    },
    exampleViewerAPI () {
        const viewer = this.$el.querySelector('.images').$viewer
        viewer.show()
      }
  },
}
</script>

<style>
.dialog-custom-picture .el-dialog__body {
  margin: auto;
  width: 50%;
}

.dialog-custom-picture .el-dialog__body .img {
  max-width: 100%;
  /* height: auto; */
  max-height: 75vh;
}
</style>
