<template>
  <div class="cargos-data-table">
    <el-row :gutter="10">
      <el-col :span="19">
        <el-input
          v-model="searchCargo"
          size="mini"
          :placeholder="findPlaceholder[findTarget]"
        />
      </el-col>
      <el-col :span="5">
        <el-radio-group v-model="findTarget" size="mini">
          <el-radio-button label="Cargas"></el-radio-button>
          <el-radio-button label="Documentos"></el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <br />

    <div class="cargos-data-table-results">
      <el-row>
        <el-col :span="24">
          <el-table
            :data="cargosTablePaginated"
            style="width: 100%;"
            size="mini"
            :row-class-name="rowClass"
            ref="cargosDataTable"
            @sort-change="sortChange"
          >
            <!-- Coluna Data -->
            <el-table-column
              resizable
              prop="date"
              label="Data"
              width="80"
              fixed
              sortable
              sort-by="date"
            />

            <!-- Coluna Cargo ID -->
            <el-table-column
              width="100"
              label="Carga"
              fixed
              sortable
              sort-by="id"
            >
              <template slot-scope="scope">
                <i
                  class="el-icon-warning"
                  style="color: red;"
                  v-if="cargaTemObs(scope.row)"
                />
                <el-button type="text" @click="cargoIdClick(scope.row)">{{
                  scope.row.id
                }}</el-button>
              </template>
            </el-table-column>

            <!-- Coluna Status -->
            <el-table-column
              prop="status"
              label="Status"
              width="100"
              :filters="statusLabelsFormated"
              :filter-method="filterMethod"
              filter-placement="bottom-end"
              align="center"
              sortable
              sort-by="status"
            >
              <template slot-scope="scope">
                <el-row>
                  <el-tag
                    :type="statusColor(scope.row.cargo_status)"
                    disable-transitions
                    size="mini"
                    style="width: 97px;"
                    @click="statusClick(scope.row)"
                    >{{ scope.row.status }}</el-tag
                  >
                  <i
                    @click="
                      editCargoClick(scope.row.branch_id + scope.row.cargo_id)
                    "
                    class="el-icon-edit"
                    v-if="
                      scope.row.status === 'Bloqueada' ||
                      scope.row.status === 'Disponível'
                    "
                  ></i>
                </el-row>
              </template>
            </el-table-column>

            <!-- Coluna Filial -->
            <el-table-column
              prop="branch"
              label="Filial"
              align="center"
              width="100"
              :filters="branchs"
              :filter-method="filterMethod"
              sortable
              sort-by="branch"
            />

            <!-- <el-table-column label="L/E/N/P/C" width="100" align="center">
              <template slot-scope="scope">
                {{ scope.row.customers }}/{{ scope.row.deliveredCustomers }}/{{
                  scope.row.invoices
                }}/{{ scope.row.invoicesPartial }}/{{
                  scope.row.invoicesCanceled
                }}
              </template>
            </el-table-column> -->

            <el-table-column
              label="Cientes"
              width="80"
              align="center"
              sortable
              sort-by="customers"
              prop="customers"
            />
            <el-table-column
              label="Entregues"
              width="80"
              align="center"
              sortable
              sort-by="deliveredCustomers"
              prop="deliveredCustomers"
            />
            <el-table-column
              label="Reentrega"
              width="80"
              align="center"
              sortable
              sort-by="totalReavailable"
              prop="totalReavailable"
            />
            <el-table-column
              label="Notas"
              width="80"
              align="center"
              sortable
              sort-by="invoices"
              prop="invoices"
            />
            <el-table-column
              label="Parciais"
              width="80"
              align="center"
              sortable
              sort-by="invoicesPartial"
              prop="invoicesPartial"
            />
            <el-table-column
              label="Canceladas"
              width="80"
              align="center"
              sortable
              sort-by="invoicesCanceled"
              prop="invoicesCanceled"
            />
            <el-table-column
              sortable
              sort-by="amount"
              prop="amount"
              label="Valor"
              width="100"
              align="center"
            />
            <el-table-column
              sortable
              sort-by="averageTicket"
              prop="averageTicket"
              label="Tkt Méd"
              width="100"
              align="center"
            />
            <el-table-column
              sortable="custom"
              prop="weight"
              label="Peso"
              width="100"
              align="center"
            />
            <el-table-column
              sortable="custom"
              prop="vehicle"
              label="Placa"
              width="90"
              align="center"
              :filters="trucks"
              :filter-method="filterMethod"
            />
            <el-table-column
              sortable="custom"
              prop="driver"
              label="Motorista"
              width="250"
              align="left"
              :filters="drivers"
              :filter-method="filterMethod"
            />

            <el-table-column label="Km Saída" width="100" align="center">
              <template slot-scope="scope">
                <el-button
                  v-if="
                    scope.row._database.hasOwnProperty('started_km_photo_url')
                  "
                  type="text"
                  @click="kmClick(scope.row, 'started_km_photo_url')"
                >
                  {{ scope.row.startedKm }}
                </el-button>
                <span v-else>{{ scope.row.startedKm }}</span>
                <i
                  class="el-icon-edit"
                  @click="kmEditClick(scope.row, 'started_km')"
                  :class="{ redcolor: scope.row.startedKm_why }"
                />
              </template>
            </el-table-column>

            <el-table-column label="Km Retorno" width="100" align="center">
              <template slot-scope="scope">
                <el-button
                  v-if="
                    scope.row._database.hasOwnProperty('finished_km_photo_url')
                  "
                  type="text"
                  @click="kmClick(scope.row, 'finished_km_photo_url')"
                >
                  {{ scope.row.finishedKm }}
                </el-button>
                <span v-else>{{ scope.row.finishedKm }}</span>
                <i
                  class="el-icon-edit"
                  @click="kmEditClick(scope.row, 'finished_km')"
                  :class="{ redcolor: scope.row.finishedKm_why }"
                ></i>
              </template>
            </el-table-column>

            <el-table-column
              sortable="custom"
              prop="traveledKm"
              label="Distância"
              width="120"
              align="center"
            />
            <el-table-column
              sortable="custom"
              prop="startTime"
              label="HrSaída"
              width="100"
              align="center"
            />
            <el-table-column
              sortable="custom"
              prop="finishedTime"
              label="HrRet"
              width="100"
              align="center"
            />
            <el-table-column
              sortable="custom"
              prop="duration"
              label="Duração"
              width="100"
              align="center"
            />
          </el-table>
        </el-col>
      </el-row>
    </div>

    <div class="cargos-data-table-paginator">
      <el-divider />
      <el-row justify="center" type="flex">
        <el-col :span="14">
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="[15, 30, 45, 60]"
            :page-size.sync="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="cargosFilteredByTitleSearch.length"
          >
          </el-pagination>
        </el-col>

        <el-col :span="2">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-download"
            @click="$emit('downloadXLS')"
            >XLS</el-button
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import exportJson from '@/helpers/exportXLSX'
import cargosDataTable from './_cargoDataTablePrepare'

export default {
  name: 'cargos-data-table',
  props: {},
  data() {
    return {
      findTarget: 'Cargas',
      findPlaceholder: {
        Cargas:
          'Filtrar pelo número da carga, motorista, filial ou placa do veículo',
        Documentos:
          'Filtrar pelo número do documento, nome do cliente ou número da nsu',
      },
      branchs: [],
      trucks: [],
      drivers: [],
      dateFilter: null,
      currentPage: 1,
      pageSize: 15,
      columnSort: 'date',
      columnOrder: 'desc',
      searchCargo: '',
      exportFields: {
        Carga: 'id',
        Filial: 'branch',
        Data: 'date',
        Status: 'status',
        Clientes: 'customers',
        'Clientes Entregues': 'deliveredCustomers',
        Notas: 'invoices',
        'Notas Canceladas': 'invoicesPartial',
        'Notas Parciais': 'invoicesCanceled',
        Total: 'amount',
        'Ticket Medio': 'averageTicket',
        Peso: 'weight',
        Veículo: 'vehicle',
        'KM Inicial': 'startedKm',
        'KM Final': 'finishedKm',
        'KM Percorrido': 'traveledKm',
        'Data Inicial': 'startDate',
        'Hora Inicial': 'startTime',
        'Hora Final': 'finishedTime',
        Duração: 'duration',
        Motorista: 'driver',
      },
    }
  },
  computed: {
    ...mapState('Cargos', ['allCargos', 'statusLabels']),
    ...mapState('Trucks', ['allTrucks']),
    ...mapState('Drivers', ['allDrivers']),
    ...mapState('User', ['companie']),
    ...mapGetters('Cargos', ['getAllCargosFiltered']),
    cargosTable() {
      return cargosDataTable(this.getAllCargosFiltered)
    },
    statusLabelsFormated() {
      return this.statusLabels.map((label) => {
        return { value: label.value, text: label.value }
      })
    },
    cargosTableSorted() {
      return this._.orderBy(
        this.cargosFilteredByTitleSearch,
        [this.columnSort],
        [this.columnOrder]
      )
    },
    cargosTablePaginated() {
      let initialPos = this.currentPage * this.pageSize - this.pageSize
      let finalPos = this.currentPage * this.pageSize - 1
      return this.cargosTableSorted.slice(initialPos, finalPos)
    },
    cargosFilteredByTitleSearch() {
      if (this.findTarget === 'Documentos' && this.searchCargo) {
        const fil = this.cargosTable.filter((cargo) => {
          return (
            cargo.customers_list.reduce((tot, c) => {
              const nameFound = this.searchCargo
                .toLowerCase()
                .split(' ')
                .every((v) => `${c.customer}`.toLowerCase().includes(v))

              const docFound =
                cargo.customers_list.filter((customer) => {
                  return (
                    Object.values(customer.invoices).filter(
                      (invoice) =>
                        invoice.invoice.includes(this.searchCargo.trim()) ||
                        invoice.order_number.includes(
                          this.searchCargo.trim()
                        ) ||
                        (invoice.payment &&
                          Object.values(invoice.payment).some(
                            (pay) =>
                              pay.nsuNumber &&
                              pay.nsuNumber.includes(this.searchCargo.trim())
                          )) ||
                        (invoice.paymentEdited &&
                          Object.values(invoice.paymentEdited).some(
                            (pay) =>
                              pay.nsu &&
                              pay.nsu.includes(this.searchCargo.trim())
                          ))
                    ).length > 0
                  )
                }).length > 0

              if (nameFound || docFound) {
                tot++
              }
              return tot
            }, 0) > 0
          )
        })
        return fil
      }

      // retorna somente cargas filtradas pela barra de filtros
      return this.cargosTable.filter((d) => {
        // Separo o campo busca em array, e aplico a funcão every que verificar se todos os valores satifaz a função
        // Na função procuro pela palavra dentro do grande campo concatenado
        return (
          !this.searchCargo ||
          this.searchCargo
            .toLowerCase()
            .split(' ')
            .every((v) =>
              `${d.id}${d.driver}${d.vehicle}${d.branch}`
                .toLowerCase()
                .includes(v)
            )
        )
      })
    },
  },
  created() {
    this.branchs = this.companie.branchs.map((branch) => {
      return { value: branch.branch_name, text: branch.branch_name }
    })
    this.trucks = this.allTrucks.map((truck) => {
      return { value: truck.id, text: truck.id }
    })
    this.drivers = this.allDrivers.map((driver) => {
      return { value: driver.name, text: driver.name }
    })
  },
  methods: {
    ...mapGetters('Cargos', ['statusColor']),
    cargoIdClick(row) {
      this.$emit('cargo-id-click', { branchId: row.branch_id, cargoId: row.id })
    },
    editCargoClick(cargoId) {
      // console.log(cargoId)
      this.$emit('cargo-edit-click', cargoId)
    },
    kmEditClick(row, field) {
      this.$emit('km-edit-click', { row, field })
    },
    kmClick(row, field) {
      this.$emit('km-click', { row, field })
    },
    statusClick(row) {
      this.$emit('status-click', { row })
    },
    filterMethod(value, row, column) {
      return row[column.property] === value
    },
    statusColor(status) {
      let color = ''
      // disponivel
      if (status === 'A') {
        color = 'primary'

        // Error
      } else if (status === 'E') {
        color = 'danger'

        // tranferida
      } else if (status === 'T') {
        color = 'info'

        // conferida
      } else if (status === 'V') {
        color = 'success'

        // em conferencia
      } else if (status === 'U') {
        color = 'warning'

        // em entrega
      } else if (status === 'D') {
        color = 'info'

        // carga encerrada
      } else if (status === 'F') {
        color = 'success'

        // bloqueada
      } else if (status === 'B') {
        color = 'danger'

        // cancelada
      } else if (status === 'C' || status === 'Erro') {
        color = 'info'

        // outros
      } else {
        color = 'info'
      }
      return color
    },
    cargaTemObs(row) {
      return row.invoicesPartial > 0 || row.invoicesCanceled > 0
    },
    rowClass({ row, rowIndex }) {
      if (row.invoicesPartial >= 1 || row.invoicesCanceled >= 1) {
        return 'mr-data-table-row warning-row'
      } else {
        return 'mr-data-table-row'
      }
    },
    DownloadXLS() {
      exportJson.DownloadXLS(
        this.cargosFilteredByTitleSearch,
        this.exportFields
      )
    },
    DownloadCSV() {
      exportJson.DownloadCSV(
        this.cargosFilteredByTitleSearch,
        this.exportFields
      )
    },
    sortChange(param) {
      this.columnSort = param.prop
      this.columnOrder = param.order === 'descending' ? 'desc' : 'asc'
    },
  },
}
</script>

<style>
.cargos-data-table-results {
  min-height: 600px;
}

.cargos-data-table-paginator {
  padding-top: 10px;
  border-top: 1px;
}

.row-class-cargos-data-table .el-tag {
    cursor: pointer;
  }

</style>
