<template>
  <el-dialog
    :title="`Alterar ${kmShowText}`"
    :visible.sync="showThisDialog"
    center
    :before-close="onClose"
  >
    <center>
      <span
        >Trocar KM de {{ formKm.kmEditOldValue }} para
        {{ formKm.kmEditNewValue }}</span
      >
    </center>
    <center v-if="lastUpdateMsg">
      <span style="color: red; font-size: 10px;">{{ lastUpdateMsg }}</span>
    </center>
    <br />

    <el-form :model="formKm" :rules="formKmRules" ref="formKm">
      <el-form-item
        label="Breve descrição do motivo desta alteração"
        prop="kmEditWhy"
      >
        <el-input
          v-model="formKm.kmEditWhy"
          placeholder="Insira uma breve descrição do motivo da alteração"
        ></el-input>
      </el-form-item>

      <el-form-item label="Novo km para o veículo " prop="kmEditNewValue">
        <el-input
          v-model="formKm.kmEditNewValue"
          placeholder="Insita novo KM"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitKmForm('formKm')"
        >Alterar</el-button
      >
      <el-button @click="onClose">Cancelar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import format from '@/helpers/format'
import t from 'typy'

export default {
  name: 'edit-km-dialog',
  props: {},
  data() {
    return {
      showThisDialog: false,
      lastUpdateMsg: null,
      formKm: {
        kmEditIdCargo: null,
        kmEditNewValue: 0,
        kmEditOldValue: 0,
        kmEditField: null,
        kmEditWhy: '',
      },
      kmFiledsDisplayTexts: [
        { value: 'finished_km', text: 'KM de fim de carga' },
        { value: 'started_km', text: 'KM de início de carga' },
      ],
      formKmRules: {
        kmEditWhy: [
          {
            required: true,
            message: 'O motivo da alteração é obrigatório',
            trigger: 'blur',
          },
        ],
        kmEditNewValue: [
          {
            required: true,
            validator: this.validateKmValue,
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    kmShowText() {
      if (this.formKm.kmEditField && !this.formKm.fieldArray) {
        let findName = this.kmFiledsDisplayTexts.find(
          (item) => this.formKm.kmEditField === item.value
        )
        return findName.text || ''
      } else {
        return 'KM'
      }
    },
  },
  methods: {
    ...mapActions('Cargos', ['updateCargoKm','getCargos']),
    submitKmForm() {
      this.$refs['formKm'].validate(async (valid) => {
        if (valid) {
          if (this.formKm.kmEditNewValue === this.formKm.kmEditOldValue) {
            this.showThisDialog = false
          } else {
            let payload = {
              newKm: format(this.formKm.kmEditNewValue, 'NR'),
              why: `Em ${this.$moment(new Date()).format(
                'YYYY-MM-DD hh:mm'
              )} km foi atualizado de ${this.formKm.kmEditOldValue} para ${
                this.formKm.kmEditNewValue
              } com obs ${this.formKm.kmEditWhy}`,
              cargoid: this.formKm.kmEditIdCargo,
              field: this.formKm.kmEditField,
              fieldArray: this.formKm.fieldArray,
              fieldCompare: this.formKm.fieldCompare,
              valueCompare: this.formKm.valueCompare,
            }
            await this.updateCargoKm(payload)
            this.getCargos()
            this.resetForm()
            this.showThisDialog = false
          }
        }
      })
    },
    onClose() {
      this.resetForm()
      this.showThisDialog = false
    },
    resetForm() {
      this.formKm = {
        kmEditIdCargo: null,
        kmEditNewValue: 0,
        kmEditOldValue: 0,
        kmEditField: null,
        kmEditWhy: '',
      }
    },
    showDialog(km, firestoreDocId, KmFirestoreField, row = {}, fieldArray, fieldCompare, valueCompare) {
      this.lastUpdateMsg =
        t(row, `_database.${KmFirestoreField}_whyUpdate`).safeObject || null
      this.formKm.kmEditWhy = ''
      this.formKm.kmEditIdCargo = firestoreDocId
      this.formKm.kmEditOldValue = format(km, 'N')
      this.formKm.kmEditNewValue = format(km, 'N')
      this.formKm.fieldArray = fieldArray
      this.formKm.fieldCompare = fieldCompare
      this.formKm.valueCompare = valueCompare
      this.formKm.kmEditField = KmFirestoreField
      this.showThisDialog = true
    },
    validateKmValue(rule, value, callback) {
      if (/^\d{1,3}(?:\.\d{3})*,\d{2}$/.test(value)) callback()
      else callback(new Error('Novo KM deve seguir o formato xx.xxx,xx'))
    },
  },
}
</script>
