import moment from 'moment'
import { store } from '@/store'
import format from '@/helpers/format'
import { toInteger,get } from 'lodash'

// Função utilizada em algumas rotinas dos componentes de cargo para agregação
// import cargosDataTable from '@/components/Admin/Cargos/components/_cargoDataTablePrepare'
// return cargosDataTable(this.allCargos)
// return cargosDataTable(this.allCargos, this.cargoid)[0]

// Função utilizada em algumas rotinas dos componentes de cargo para agregação
// import detailsTablePrepare from '@/components/Admin/Cargos/components/_customerDetailsTablePrepare'
// return detailsTablePrepare(this.allCargos, this.cargoid, this.branchid)
// return detailsTablePrepare(this.allCargos, this.cargoid, this.branchid, this.events).details

export default {
  getAllCargosForReportExtended:
    (state, getters, rootState, rootGetters) =>
    () => {
      let returnObj = []
      const newDataFiltered = getters.getAllCargosFiltered.filter((item) => {
        return true
        // const allowedStatus = ['F', 'V', 'U']
        // if (allowedStatus.includes(item.cargo_status)) return true
        // else return false
      })

      newDataFiltered.forEach(async (cargo) => {
        // const dataSomated = cargosDataTable(newDataFiltered, cargo.cargo_id)[0]

        Object.keys(cargo.customers).forEach(async (indexCustumer) => {
          let customer = cargo.customers[indexCustumer]

          Object.keys(customer.invoices).forEach((indexInvoice) => {
            let invoice = customer.invoices[indexInvoice]

            let concatPayments = ''
            let concatNSU = ''
            Object.keys(invoice.payment).forEach((indexPayment) => {
              let payment = invoice.payment[indexPayment]
              let nsu = payment.nsuNumber ? payment.nsuNumber : false

              if (concatNSU) { concatNSU = nsu ? `${nsu} | ${concatNSU}` : `${concatNSU}` }
              else { concatNSU = nsu ? `${nsu}` : `${concatNSU}` }

              if (indexPayment == 0) {
                concatPayments = `${rootGetters['User/getPaymentName'](payment)} (${format(payment.ammount, 'C')})`
              } else {
                concatPayments = `${concatPayments} | ${rootGetters['User/getPaymentName'](payment)} (${format(payment.ammount, 'C')})`
              }
            })

            let concatPaymentsEdited = '---'
            if (invoice.paymentEdited) {
              Object.keys(invoice.paymentEdited).forEach((indexPayment) => {
                let payment = invoice.paymentEdited[indexPayment]
                let nsu = payment.nsu ? payment.nsu : false

                if (concatNSU) { concatNSU = nsu ? `${nsu} | ${concatNSU}` : `${concatNSU}` }
                else { concatNSU = nsu ? `${nsu}` : `${concatNSU}` }

                if (indexPayment == 0) {
                  concatPaymentsEdited = `${rootGetters['User/getPaymentName'](payment)} (${format(payment.ammount, 'C')})`
                } else {
                  concatPaymentsEdited = `${concatPayments} | ${rootGetters['User/getPaymentName'](payment)} (${format(payment.ammount, 'C')})`
                }
              })
            }

            let toPush = {
              branch_id: cargo.branch_id,
              branch_name: cargo.branch_id ? rootGetters['User/getCompanieBranchName'](cargo.branch_id) : '',

              cargo_id: cargo.cargo_id,
              cargo_status_id: cargo.cargo_status,
              cargo_status: cargo.cargo_status ? rootGetters['Cargos/getCargoStatusLabel'](cargo.cargo_status) : '',
              cargo_created_at: cargo.created_at ? moment.unix(cargo.created_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              cargo_started_at: cargo.started_at ? moment.unix(cargo.started_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              cargo_finished_at: cargo.finished_at ? moment.unix(cargo.finished_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              cargo_traveled_time_s: cargo.finished_at ? Math.round((get(cargo, 'finished_at.seconds', 0) - get(cargo, 'started_at.seconds', 0))) : 0,
              cargo_created_by: cargo.created_by ? cargo.created_by.split(' ')[0] : '',
              cargo_started_by: cargo.started_by ? rootGetters['User/getDriverName'](cargo.started_by) : '',
              cargo_finished_by: cargo.finished_by ? rootGetters['User/getDriverName'](cargo.finished_by) : '',
              cargo_total_customers: Object.values(cargo.customers).length,

              driver_name: cargo.started_by ? rootGetters['User/getDriverName'](cargo.started_by) : '',
              checker_name: cargo.checker ? cargo.checker : '',
              truck_id: cargo.truck_id ? cargo.truck_id : '',
              truck_started_km: cargo.started_km ? cargo.started_km : '',
              truck_finished_km: cargo.finished_km ? cargo.finished_km : '',
              truck_traveled_km: cargo.finished_km ? Math.round((cargo.finished_km - cargo.started_km)) : 0,

              customer_order: (toInteger(indexCustumer)+1),
              customer_id: customer.customer_id ? customer.customer_id : '',
              customer: customer.customer ? customer.customer : '',
              customer_address: customer.address1 ? `${customer.address1} ${customer.address2}` : '',
              customer_city: customer.city ? customer.city : '',
              customer_neighborhood: customer.neighborhood ? customer.neighborhood : '',
              customer_state: customer.state ? customer.state : '',
              customer_postal_code: customer.postal_code ? customer.postal_code : '',
              customer_phone: customer.phone ? customer.phone : '',
              customer_route: customer.route ? customer.route : '',
              customer_sector: customer.sector ? customer.sector : '',
              customer_zone: customer.zone ? customer.zone : '',
              customer_status: customer.status ? rootGetters['Cargos/getCargoDeliveryLabels'](customer.status) : '',
              canceled_reason: customer.canceled_reason ? rootGetters['User/cancellationReasonsDesc'](customer.canceled_reason) : '',
              canceled_notice: customer.canceled_notice ? customer.canceled_notice : '',

              delivered_doc: customer.delivered_doc ? customer.delivered_doc : '',
              delivered_who: customer.delivered_who ? customer.delivered_who : '',
              delivered_km: customer.delivered_km ? customer.delivered_km : '',
              delivered_info: customer.delivered_info ? customer.delivered_info : '',

              delivering_at: customer.delivering_at ? moment.unix(customer.delivering_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              delivering_checkin_at: customer.delivering_checkin_at ? moment.unix(customer.delivering_checkin_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              delivered_at: customer.delivered_at ? moment.unix(customer.delivered_at.seconds).format('DD/MM/YYYY HH:mm') : '',

              order_number: invoice.order_number ? invoice.order_number : '',
              invoice: invoice.invoice ? invoice.invoice : '',
              invoice_ammount: invoice.total ? format(invoice.total, 'C') : format(0, 'C'),
              invoice_weight: invoice.weight ? format(invoice.weight, 'N') : format(0, 'N'),
              invoice_volumes: invoice.volumes ? invoice.volumes : '',
              invoice_items:  invoice.items ? Object.keys(invoice.items).length : 0,
              invoice_status: invoice.status ? rootGetters['Cargos/getCargoDeliveryLabels'](invoice.status) : '',
              invoice_status_reason: invoice.cancel_reason ? rootGetters['User/cancellationReasonsDesc'](invoice.cancel_reason) : '',
              invoice_status_notice: invoice.cancel_notice ? invoice.cancel_notice : '',
              invoice_delivered_at: invoice.delivered_at ? moment.unix(invoice.delivered_at.seconds).format('DD/MM/YYYY HH:mm') : '',

              payments: concatPayments,
              paymentsEdited: invoice.paymentEdited ? 'Sim' : 'Não',
              paymentsEditedTo: concatPaymentsEdited,
              nsu: concatNSU,

              checked_log: customer.checked_log ? 'Sim' : 'Não',
              checked_log_by: customer.checked_log_at ? moment.unix(customer.checked_log_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              checked_log_at: customer.checked_log_by ? customer.checked_log_by.split(' ')[0] : '',
              checked_fin: customer.checked_fin ? 'Sim' : 'Não',
              checked_fin_by: customer.checked_fin_at ? moment.unix(customer.checked_fin_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              checked_fin_at: customer.checked_fin_by ? customer.checked_fin_by.split(' ')[0] : '',
             }

            toPush['truck_traveled_avg'] = Math.round((toPush.truck_traveled_km / toPush.cargo_total_customers))
            toPush['cargo_traveled_time_avg_s'] = Math.round((toPush.cargo_traveled_time_s / toPush.cargo_total_customers))

            returnObj.push(toPush)

          })
        })
      })
      return returnObj
    },
}
