<template>
  <el-dialog
    :title="`Localizar documento`"
    :visible.sync="showThisDialog"
    center
    :before-close="onClose"
  >
    <el-form :model="formFindDoc" :rules="formFindDocRules" ref="formFindDoc">
      <el-form-item label="Número do documento" prop="docnumber">
        <el-input
          v-model="formFindDoc.docnumber"
          placeholder="Numero do documento"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submiFindDocForm('formFindDoc')">
        Localizar
      </el-button>
      <el-button @click="onClose">Voltar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'find-invoice-dialog',
  props: {},
  data() {
    return {
      showThisDialog: false,
      formFindDoc: {
        docnumber: '',
      },
      formFindDocRules: {
        docnumber: [
          {
            required: true,
            message: 'Obrigatório preencher este campo',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {},
  methods: {
    ...mapActions('Cargos', ['updateCargoKm']),
    submiFindDocForm() {
      this.$refs['formKm'].validate((valid) => {
        if (valid) {
          this.resetForm()
          this.showThisDialog = false
        }
      })
    },
    onClose() {
      this.resetForm()
      this.showThisDialog = false
    },
    resetForm() {
      this.formFindDoc = { docnumber: '' }
    },
    showDialog() {
      this.showThisDialog = true
    },
  },
}
</script>
