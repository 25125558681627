import env from '@/config/environment'
import axios from 'axios'

export default async (address, postalCode) => {
  const url = `https://geocode.search.hereapi.com/v1/geocode?q=${escape(
    address
  )}&qq=country=Brazil;postalCode=${postalCode}&apikey=${env.here.apikey_rest}`
  axios.defaults.validateStatus = () => true
  let position = {}
  try {
    const res = await axios.get(url)
    position = res.data.items[0].position
  } catch (error) {}
  return position
}
