<template>
  <div></div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'Reload',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('Shared', ['swNeedUpdate']),
  },
  created() {
    this.setLoadingMessage('Atualizando...')
  },
  mounted() {
    setTimeout(() => {
      if (this.swNeedUpdate) {
        this.setSwNeedUpdate(false)
        window.location.reload(true)
      } else {
        this.$router.push('/')
      }
    }, 1000)
  },
  methods: {
    ...mapMutations('Shared', ['setSwNeedUpdate', 'setLoadingMessage']),
  },
}
</script>
