import { getDefaultLogsState } from './state'

export default {
  resetState(state) {
    // console.log(`Logs state reseted`)
    Object.assign(state, getDefaultLogsState())
  },
  changeAllLogs(state, payload) {
    state.allLogs = payload
  },
  shiftLogs(state) {
    state.allLogs.shift()
  },
  updateLogs(state, payload) {
    state.allLogs.unshift(payload)
    state.allLogs.pop()
  },
}
