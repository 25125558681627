export default {
  getinvoicesStatusCount(state) {
    // Formated for use with Google Charts
    return [
      ['Status', 'Total'],
      [
        'Bloqueado',
        state.allInvoices.filter((invoice) => invoice.invoice_status === 'B')
          .length,
      ],
      [
        'Disponível',
        state.allInvoices.filter((invoice) => invoice.invoice_status === 'A')
          .length,
      ],
      [
        'Montado',
        state.allInvoices.filter((invoice) => invoice.invoice_status === 'M')
          .length,
      ],
      [
        'Error',
        state.allInvoices.filter((invoice) => invoice.invoice_status === 'E')
          .length,
      ],
      [
        'Cancelado',
        state.allInvoices.filter((invoice) => invoice.invoice_status === 'C')
          .length,
      ],
    ]
  },
}
