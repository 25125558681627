<template>
  <div class="form-user">
    <el-form
      ref="addEditUserForm"
      status-icon
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item label="Nome do usuário" prop="name">
        <el-input v-model="form.name" :disabled="form.is_disabled" />
      </el-form-item>

      <el-form-item label="Login de acesso" prop="driver_login">
        <el-input v-model="form.driver_login" :disabled="isEdition" />
        <div class="form-input-extra-option form-input-extra-option-left">
          Apenas letras, números, ponto (.) e underline (_)
        </div>
      </el-form-item>

      <el-form-item label="Senha" prop="password" v-if="!isEdition">
        <el-input type="password" v-model="form.password" />
        <div class="form-input-extra-option form-input-extra-option-left">
          Mínimo de 6 digitos
        </div>
      </el-form-item>

      <el-form-item
        label="Confirmar senha"
        prop="passwordconfirm"
        v-if="!isEdition"
      >
        <el-input type="password" v-model="form.passwordconfirm" />
      </el-form-item>

      <el-form-item label="E-mail" prop="email">
        <el-input v-model="form.email" />
      </el-form-item>

      <el-form-item label="Cargo" prop="title">
        <el-input v-model="form.title" :disabled="form.is_disabled" />
      </el-form-item>

      <el-form-item label="Filial" prop="branch_id">
        <el-select
          v-model="form.branch_id"
          placeholder="Selecione uma filial"
          :disabled="form.is_disabled"
        >
          <el-option
            v-for="item in branchs"
            :key="item.branch_id"
            :label="item.branch_name"
            :value="item.branch_id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Grupo de de acesso" prop="roles">
        <el-select
          v-model="form.roles"
          multiple
          placeholder="Select o grupo de acesso"
        >
          <el-option
            v-for="group in groups"
            :key="group.value"
            :label="group.label"
            :value="group.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        prop="has_user_manager_right"
        label="Permissões adicionais"
        v-if="isAdmin"
      >
        <el-checkbox
          v-model="form.has_user_manager_right"
          label="Pode gerenciar usuários?"
          :disabled="form.is_disabled"
          border
        />
      </el-form-item>

      <el-form-item prop="is_branch_locked" v-if="isAdmin">
        <el-checkbox
          v-model="form.is_branch_locked"
          label="Restringir a própria filial?"
          :disabled="form.is_disabled"
          border
        />
      </el-form-item>

      <el-form-item prop="is_disabled" v-if="isEdition" label="Status">
        <el-checkbox
          v-model="form.is_disabled"
          label="Usuário desativado"
          border
        />
      </el-form-item>

      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="success" @click="saveForm('addEditUserForm')">{{
          saveButtonText
        }}</el-button>
        <el-button type="info" @click="showPasswordDialog" v-if="isEdition"
          >Trocar senha</el-button
        >
        <el-button type="info" @click="$router.go(-1)">Cancelar</el-button>
      </div>
    </el-form>

    <reset-password-dialog ref="resetPasswordDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import resetPasswordDialog from './resetPasswordDialog'
import validateLogin from '@/helpers/validateLogin'
import validatePass from '@/helpers/validatePassword'

export default {
  name: 'user-form',
  components: {
    resetPasswordDialog,
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      form: {},
      branchs: [],
      groups: [
        { value: 'admin', label: 'Administrador' },
        { value: 'driver', label: 'Motorista' },
        { value: 'checker', label: 'Conferente' },
        { value: 'finance', label: 'Financeiro' },
        { value: 'logistics', label: 'Logistica' },
      ],
      rules: {
        name: [{ required: true, message: 'Campo obrigatório' }],
        email: [
          { required: false },
          {
            type: 'email',
            message: 'Por favor insira um endereço de e-mail válido',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          { required: true, message: 'Campo obrigatório' },
          { validator: this.validatePass, trigger: ['blur', 'change'] },
        ],
        passwordconfirm: [
          { required: true, message: 'Campo obrigatório, confirme a senha' },
          { validator: this.confirmPass, trigger: ['blur', 'change'] },
        ],
        driver_login: [
          { required: true, message: 'Campo obrigatório' },
          { validator: this.validateLoginEdition, trigger: 'blur' },
        ],
        branch_id: [{ required: true, message: 'Campo obrigatório' }],
        roles: [
          {
            type: 'array',
            required: true,
            message: 'Selecione pelo menos um grupo de acesso.',
            trigger: 'change',
          },
        ],
      },
      saveButtonText: this.user.login ? 'Atualizar' : 'Adicionar',
      isEdition: Boolean(this.user.login),
    }
  },
  computed: {
    ...mapGetters('User', ['getCompanieBranches']),
    isAdmin() {
      return this.form.roles.includes('admin')
    },
  },
  created() {
    this.branchs = this.getCompanieBranches

    // Form object somente com os campos necesários para update
    this.form = {
      roles: this.user.roles || [],
      id: this.user.id || null,
      name: this.user.name || '',
      email: this.user.email || '',
      title: this.user.title || '',
      driver_login: this.user.driver_login || '',
      password: this.user.password || '',
      branch_id: this.user.branch_id || '01',
      is_branch_locked: this.user.is_branch_locked || false,
      has_user_manager_right: this.user.has_user_manager_right || false,
      is_disabled: this.user.is_disabled || false,
    }
  },
  methods: {
    ...mapActions('User', ['addUser', 'updateUser']),
    saveForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.isEdition) this.addUser(this.form)
          else await this.updateUser(this.form)
          this.$router.push({
            name: `${this.isAdmin ? 'AdminsList' : 'DriversList'}`,
          })
        } else {
          return false
        }
      })
    },
    showPasswordDialog() {
      this.$refs.resetPasswordDialog.showDialog(this.user.login, this.user.id)
    },
    validatePass(rule, value, callback) {
      return validatePass.validatePass(
        rule,
        value,
        callback,
        this.form.passwordconfirm,
        'addEditUserForm',
        'passwordconfirm',
        this
      )
    },
    validateLoginEdition(rule, value, callback) {
      if (this.isEdition) callback()
      else return validateLogin(rule, value, callback)
    },
    confirmPass(rule, value, callback) {
      return validatePass.confirmPass(rule, value, callback, this.form.password)
    },
  },
}
</script>
