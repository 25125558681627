/**
 * @class exportXLSX
 * @description A class to export jsons to excel, csv etc
 * @param configOptions - a config object with options set
 * @dependencie https://github.com/SheetJS/sheetjs
 * @codesIdia https://github.com/jecovier/vue-json-excel
 */

import XLSX from 'xlsx'

class ExportXLSX {
  constructor() {
    this.defaultValue = ''
    this.type = 'xlsx'
    this.headers = false
    this.title = 'Minha Rota Export'
    this.author = 'Minha Rota Panel'
    this.company = 'App Solutio'
    this.sheet = 'Sheet'
    this.filename = 'data-export'
    this.debug = false
  }

  setConfig(options = {}) {
    if (options.title) this.title = options.title
    if (options.author) this.author = options.author
    if (options.company) this.company = options.company
    if (options.sheet) this.sheet = options.sheet
    if (options.filename) this.filename = options.filename
    if (options.debug) this.debug = options.debug
  }

  DownloadXLS(json = false, headers = false, options = false) {
    if (!json) return new Error('Invalid json')
    if (options) this.setConfig(options)
    if (headers) this.headers = headers
    XLSX.writeFile(this._prepareDownload(json), `${this.filename}.xlsx`, {
      bookType: 'xlsx',
    })
  }

  DownloadCSV(json, headers = false, options = false) {
    if (!json) return new Error('Invalid json')
    if (options) this.setConfig(options)
    if (headers) this.headers = headers
    XLSX.writeFile(this._prepareDownload(json), `${this.filename}.csv`, {
      bookType: 'csv',
    })
  }

  // Metodo Download permite mudar o tipo de download enviado no option
  Download(json, headers = false, options = false) {
    if (!json) return new Error('Invalid json')
    if (options) this.setConfig(options)
    if (headers) this.headers = headers
    XLSX.writeFile(
      this._prepareDownload(json),
      `${this.filename}.${this.type}`,
      { bookType: this.type }
    )
  }

  _prepareDownload(json = {}) {
    // Se enviado json com mapeando nome x chave, o json será filtrado para somente as chaves enviadas e as chaves alteradas para o nome recebido
    // Aceita chaves dentro de chaves ver em https://github.com/abelmferreira/vue-json-excel
    // Exemplo
    // headers = {
    //   'nome_para_download': 'chave_original_json'
    // }
    //
    if (this.headers) {
      if (this.debug) console.log(`Data before processing headers: `, json)
      json = this._getProcessedJson(json, this.headers)
      if (this.debug) console.log(`Data after processing headers: `, json)
    }

    if (this.debug) console.log(`Data to export: `, json)

    // Preparo header do documento com base nas chaves recebidas
    let header = Object.keys(json[0]).map((header) => header)
    if (this.debug) console.log(`Headers: `, header)

    // Crio Worksheet
    let ws = XLSX.utils.json_to_sheet(json, { header: header })

    // Crio Workbook
    let wb = XLSX.utils.book_new()
    if (!wb.Props) {
      wb.Props = {
        Title: this.title,
        Author: this.author,
        Company: this.company,
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, this.sheet)
    return wb
  }

  _getValueFromNestedItem(item, indexes) {
    let nestedItem = item
    for (let index of indexes) {
      if (nestedItem) nestedItem = nestedItem[index]
    }
    return this._parseValue(nestedItem)
  }

  _getValueFromCallback(item, callback) {
    if (typeof callback !== 'function') return this.defaultValue
    const value = callback(item)
    return this._parseValue(value)
  }

  _parseValue(value) {
    return value || value === 0 || typeof value === 'boolean'
      ? value
      : this.defaultValue
  }

  _getValue(key, item) {
    const field = typeof key !== 'object' ? key : key.field
    let indexes = typeof field !== 'string' ? [] : field.split('.')
    let value = this.defaultValue

    if (!field) value = item
    else if (indexes.length > 1)
      value = this._getValueFromNestedItem(item, indexes)
    else value = this._parseValue(item[field])

    if (key.hasOwnProperty('callback'))
      value = this._getValueFromCallback(value, key.callback)

    return value
  }

  _getProcessedJson(data, header) {
    let keys = this._getKeys(data, header)
    let newData = []
    let _self = this
    data.map(function (item, index) {
      let newItem = {}
      for (let label in keys) {
        let property = keys[label]
        newItem[label] = _self._getValue(property, item)
      }
      newData.push(newItem)
    })

    return newData
  }

  _getKeys(data, header) {
    if (header) {
      return header
    }

    let keys = {}
    for (let key in data[0]) {
      keys[key] = key
    }
    return keys
  }
}

export default new ExportXLSX()
