import {
  getDefaultSharedState,
  defaultUploadingStatus,
  defaultSearchState,
} from './state'
import dates from '@/helpers/getDateRange'
import notifyErrors from '@/services/notifyErrors'

export default {
  resetState(state) {
    // console.log(`Shared state reseted`)
    Object.assign(state, getDefaultSharedState())
  },

  setLocation(state, location) {
    state.lastLocation = location
    state.lastLocationUpdate = Math.round(new Date().getTime() / 1000) // Unix Seconds timestamp
  },

  setLastUpdateLocationDB(state, location) {
    state.lastLocationUpdateDB = Math.round(new Date().getTime() / 1000) // Unix Seconds timestamp
  },

  setLoadingMessage(state, payload) {
    state.loadingMessage = payload
  },

  setUploadingStatus(state, payload) {
    state.uploadingStatus = payload
  },

  setUploadingFiles(state, payload) {
    state.uploadingFiles = payload
  },

  setSwNeedUpdate(state, payload) {
    state.swNeedUpdate = payload
  },

  setShowMenuCollapse(state, payload = '') {
    if (payload === '') state.showNavMenuCollapse = !state.showNavMenuCollapse
    else state.showNavMenuCollapse = payload
  },

  setShowItems(state, payload) {
    // Define valores para o showNavMenuBar, show...
    if (!payload.stateName) return
    state[payload.stateName] = payload.value
  },

  updateUploadingFiles(state, payload) {
    // If new upload... push to array, else update it
    let uploadIndex = state.uploadingFiles.findIndex(
      (uploadTask) => uploadTask.uid === payload.uid
    )
    if (uploadIndex === -1) {
      state.uploadingFiles.push({
        uid: payload.uid,
        progress: payload.progress,
        status: payload.status,
      })
    } else {
      state.uploadingFiles[uploadIndex].uid = payload.uid
        ? payload.uid
        : state.uploadingFiles[uploadIndex].uid
      state.uploadingFiles[uploadIndex].status = payload.status
        ? payload.status
        : state.uploadingFiles[uploadIndex].status
      state.uploadingFiles[uploadIndex].progress = payload.progress
        ? payload.progress
        : state.uploadingFiles[uploadIndex].progress
    }
  },

  clearUploadingMessage(state) {
    state.uploadingStatus = defaultUploadingStatus()
  },

  // Alert Messages Begin
  setError(state, payload) {
    // console.log('setError: ', JSON.stringify(payload))

    state.loadingMessage = ''
    // Se error message é string
    if (typeof payload === 'string') {
      state.errorMessage.push(payload)

      // Se error message é array de mensagens
    } else if (Array.isArray(payload)) {
      state.errorMessage.push(payload)

      // Se error message é um objeto de Error
      // Que devera ser enviado para o sistema de notificação de mensagens
    } else if (
      typeof payload === 'object' &&
      payload.err &&
      payload.that &&
      payload.err instanceof Error
    ) {
      state.errorMessage.push(payload.err.message)
      notifyErrors.error(payload.err, payload.that, 'setError catch')

      // Se error message é um objeto de Error Comum
    } else if (typeof payload === 'object' && payload instanceof Error) {
      // console.log('setError: ', payload.message)
      state.errorMessage.push(payload.message)

      // Se deve usar construtor de mensagem vnode
      // Ver metodo x em App.vue para mais detalhes
    } else if (typeof payload === 'object' && payload.vnode) {
      state.errorMessage.push(payload)

      // Se Qualquer outra opção
    } else {
      state.errorMessage.push(JSON.stringify(payload))
    }
  },

  setInfoMessage(state, payload) {
    // console.log('setInfoMessage: ', JSON.stringify(payload))
    state.loadingMessage = ''
    state.infoMessage.push(payload)
  },

  setSuccessMessage(state, payload) {
    // console.log('setSuccessMessage: ', JSON.stringify(payload))
    state.loadingMessage = ''
    state.successMessage.push(payload)
  },

  clearError(state) {
    state.errorMessage = []
  },

  clearSuccessMessage(state) {
    state.successMessage = []
  },

  clearInfoMessage(state) {
    state.infoMessage = []
  },
  // Alert Messages Ends

  setOnlineState(state) {
    state.onlineState = navigator.onLine
  },

  setSyncingState(state, payload) {
    state.isSyncing = payload || false
  },

  setSyncingPending(state, payload) {
    state.isSyncingPending = payload || 0
  },

  setConnectionType(state, bypass = false) {
    // navigator.connection.type
    // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
    // https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
    if (!bypass) {
      let connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection
      state.connectionEffectiveType = connection.effectiveType
      state.connectionType = connection.type
    } else {
      state.connectionType = bypass
    }
  },

  setConnectionTypeBypass(state, payload) {
    state.connectionTypeBypass = payload || false
  },

  setGetGPSFail(state, payload) {
    state.isGPSError = payload || false
  },

  setDeviceInfo(state, payload) {
    state.deviceInfo = payload
  },

  setAppVersions(state, payload) {
    state.appVersions = payload
  },

  setSearchDateFilter(state, payload) {
    dates.update()
    if (payload && payload.start) state.searchStartDateFilter = payload.start
    else state.searchStartDateFilter = dates.d.startDateYesterdayFull

    if (payload && payload.end) state.searchEndDateFilter = payload.end
    else state.searchEndDateFilter = dates.d.endDateFull
  },

  setSearchBranchFilter(state, payload) {
    if (Array.isArray(payload)) state.searchBranchsFilter = payload
    else state.searchBranchsFilter = ['*']
  },

  setSearchDefaults(state) {
    Object.assign(state, defaultSearchState())
  },
}
