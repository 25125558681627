import { store } from '@/store'
// import { geopoint } from '@/config/firebase'

const options = {
  enableHighAccuracy: true,
  timeout: 6000,
  maximumAge: 0,
}

// function that gets the location and returns it
function getLocation() {
  if (navigator.geolocation) {
    const now = Math.round(new Date().getTime() / 1000)
    const lastUpdateInSeconds = now - store.state.Shared.lastLocationUpdate

    // Solicito a localização se a última consulta realizada for a mais que x segundos
    if (lastUpdateInSeconds >= 30) {
      return new Promise((resolve, reject) => {
        // navigator.geolocation.getCurrentPosition(success, error, options)
        navigator.geolocation.getCurrentPosition(
          (position) => {
            var location = {
              longitude: position.coords.longitude, // The latitude as a decimal number (always returned)
              latitude: position.coords.latitude, // The longitude as a decimal number (always returned)
              accuracy: position.coords.accuracy, // The accuracy of position (always returned)
              timestamp: position.timestamp, // The altitude in meters above the mean sea level (returned if available)
              altitude: position.coords.altitude, // The altitude accuracy of position (returned if available)
              altitudeAccuracy: position.coords.altitudeAccuracy, // The heading as degrees clockwise from North (returned if available)
              heading: position.coords.heading, // The speed in meters per second (returned if available)
              speed: position.coords.speed, // The date/time of the response (returned if available)
            }
            // console.info(
            //   `Atualizando store location -> Long: ${location.longitude} Lat: ${location.latitude}`
            // )
            store.commit('Shared/setGetGPSFail', false, { root: true })
            store.commit('Shared/setLocation', location, { root: true })

            // Return full location data
            resolve(location)

            // Return firebase geopoint format
            // resolve(geopoint(location.latitude, location.longitude))
          },
          (err) => {
            console.warn('ERROR(' + err.code + '): ' + err.message)
            console.warn(locationError(err))
            store.commit('Shared/setGetGPSFail', true, { root: true })
            resolve(null)
          },
          options
        )
      })
    } else {
      // console.log('Retornando location do store')
      return Promise.resolve(store.state.Shared.lastLocation)
      // resolve(geopoint(location.latitude, location.longitude))
    }
  } else {
    console.warn('Geo Location not supported by browser')
    store.commit('Shared/setGetGPSFail', true, { root: true })
    return Promise.resolve(null)
  }
}

function locationError(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return 'User denied the request for Geolocation.'
    case error.POSITION_UNAVAILABLE:
      return 'Location information is unavailable.'
    case error.TIMEOUT:
      return 'The request to get user location timed out.'
    case error.UNKNOWN_ERROR:
      return 'An unknown error occurred.'
  }
}

export default getLocation
