<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Logout',
  created() {
    // console.log('logout')
    this.userLogout()
  },
  methods: {
    ...mapActions('User', ['userLogout']),
  },
}
</script>
