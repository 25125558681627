<!--
    <cargos-filter-bar
      :filteredInvoices.sync="varInvoicesFiltered"    - aceita sync, recebe e atualiza o array de invocies
      :showResumeStats=true                           - exibe status no final
      readerMode                                      - Campos reduzidos para modo reader

      @onApplyClick="function"                        - Evento executado ao clicar em apply
      @onResetClick="function"                        - Evento executado ao clicar em reset
      @onUpdate="function"                            - Evento executado ao atualizar qualquer campo dos filtros
    />
-->

<template>
  <div class="invoice-filter-bar">
    <el-row type="flex" justify="center" :gutter="5" v-if="readerMode">
      <el-col :xs="24" :sm="14">
        <el-select
          v-model="filtersLocal.branch"
          filterable
          size="mini"
          @change="onUpdatedFields(filtersLocal.branch, 'branches')"
          placeholder="Selecione a filial"
        >
          <el-option
            v-for="branch in getCompanieBranches"
            :key="branch.branch_id"
            :label="branch.branch_name"
            :value="branch.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center" :gutter="5" v-if="readerMode">
      <el-col :xs="24" :sm="14">
        <el-input
          id="searchNfeKey"
          size="mini"
          clearable
          placeholder="Leia o código de barras da NF-e ou o QrCode da NFC-e"
          v-model="nfeKey"
          @keyup.enter.native="onSearchNFeKey(nfeKey)"
        >
        </el-input>
      </el-col>
    </el-row>

    <!-- 1 Linha -->
    <el-row type="flex" justify="center" :gutter="5" v-if="!readerMode">
      <!-- Range de datas -->
      <el-col :xs="24" :sm="4">
        <date-picker
          disabled
          :dateRange.sync="filtersLocal.dates"
          @change="onUpdatedFields('dates')"
        />
      </el-col>

      <!-- Filiais -->
      <!-- Não permitir seleção múltipla das filiais -->
      <!-- Devido a condição de só permitir notas e caminhões de uma única filial -->
      <el-col :xs="24" :sm="4">
        <el-select
          v-model="filtersLocal.branch"
          filterable
          size="mini"
          @change="onUpdatedFields(filtersLocal.branch, 'branches')"
          placeholder="Filiais"
        >
          <el-option
            v-for="branch in getCompanieBranches"
            :key="branch.branch_id"
            :label="branch.branch_name"
            :value="branch.id"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Bairro -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.neighborhoods"
          multiple
          filterable
          collapse-tags
          size="mini"
          @change="onUpdatedFields"
          placeholder="Bairros"
        >
          <el-option
            v-for="neighborhood in getNeighborhoodsFromInvoices('branch')"
            :key="neighborhood"
            :label="neighborhood"
            :value="neighborhood"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Cidades -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.cities"
          multiple
          filterable
          collapse-tags
          size="mini"
          @change="onUpdatedFields"
          placeholder="Cidades"
        >
          <el-option
            v-for="city in getCitiesFromInvoices('branch')"
            :key="city"
            :label="city"
            :value="city"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Estados -->
      <el-col :xs="24" :sm="4">
        <el-select
          v-model="filtersLocal.states"
          multiple
          filterable
          collapse-tags
          size="mini"
          @change="onUpdatedFields"
          placeholder="Estados"
        >
          <el-option
            v-for="state in getStatesFromInvoices('branch')"
            :key="state"
            :label="state"
            :value="state"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>

    <!-- 2 Linha -->
    <el-row type="flex" justify="center" :gutter="5" v-if="!readerMode">
      <!-- Rotas -->
      <el-col :xs="24" :sm="8">
        <el-select
          v-model="filtersLocal.routes"
          multiple
          collapse-tags
          filterable
          size="mini"
          @change="onUpdatedFields"
          placeholder="Rotas"
        >
          <el-option
            v-for="route in getRoutesFromInvoices('branch')"
            :key="route"
            :label="route"
            :value="route"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Zonas -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.zones"
          multiple
          collapse-tags
          filterable
          size="mini"
          @change="onUpdatedFields"
          placeholder="Zonas"
        >
          <el-option
            v-for="zone in getZonesFromInvoices('branch')"
            :key="zone"
            :label="zone"
            :value="zone"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Setores -->
      <el-col :xs="24" :sm="9">
        <el-select
          v-model="filtersLocal.sectors"
          multiple
          collapse-tags
          filterable
          size="mini"
          placeholder="Setores"
          @change="onUpdatedFields"
        >
          <el-option
            v-for="sector in getSectorsFromInvoices('branch')"
            :key="sector"
            :label="sector"
            :value="sector"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>

    <!-- 3 Linha-->
    <el-row type="flex" justify="center" :gutter="5" v-if="!readerMode">
      <!-- Botão reset -->
      <el-col :xs="24" :sm="4">
        <el-button
          size="mini"
          type="info"
          icon="el-icon-remove-outline"
          plain
          @click="resetFilter"
          style="width: 100%;"
          >Reset filtros</el-button
        >
      </el-col>

      <!-- Campo de pesquisa livre -->
      <el-col :xs="24" :sm="14">
        <el-input
          size="mini"
          placeholder="Pesquisa livre pelo documento, pedido, nome do cliente, bairro ou chave NFE"
          v-model="filtersLocal.searchString"
          class="input-with-select"
          @input="onUpdatedFields(filtersLocal.searchString, 'searchString')"
        />
      </el-col>

      <!-- Botão aplicar -->
      <el-col :xs="24" :sm="4">
        <el-button
          size="mini"
          type="info"
          icon="el-icon-search"
          plain
          @click="applyFilter"
          style="width: 100%;"
          >Atualiza / Aplicar</el-button
        >
      </el-col>
    </el-row>

    <!-- Resumo dos resultados -->
    <el-row type="flex" justify="center" v-if="showResumeStats">
      <el-col :xs="24" :sm="24" class="textoQuantidade">
        <span>
          Exibindo {{ filteredInvoicesInternal.length }} de
          {{ allInvoices.length }} documentos disponíveis
        </span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import datePicker from '@/components/Shared/DatePicker'

export default {
  name: 'invoicesFilterBar',
  components: {
    datePicker,
  },
  props: {
    filteredInvoices: {
      type: Array,
      default: () => {
        return []
      },
    },
    showResumeStats: {
      type: Boolean,
      default: false,
    },
    readerMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstload: true,
      lazyOnUpdate: null,
      filtersLocal: {},
      nfeKey: null,
      filteredInvoicesInternal: [],
    }
  },
  watch: {
    readerMode(value) {
      if (value) this.resetFilter()
    },
  },
  computed: {
    ...mapState('Invoices', ['allInvoices', 'filters']),
    ...mapGetters('User', ['getCompanieBranches']),
    ...mapGetters('Invoices', [
      'gettersInvoicesFiltered',
      'getNeighborhoodsFromInvoices',
      'getCitiesFromInvoices',
      'getStatesFromInvoices',
      'getBranchesFromInvoices',
      'getBranchesFromInvoicesWithName',
      'getRoutesFromInvoices',
      'getSectorsFromInvoices',
      'getZonesFromInvoices',
      'getAllInvoicesFiltered',
    ]),
    lazyLoadTimeout() {
      return this.readerMode ? 50 : 500
    },
  },
  created() {
    // Completa o filtersLocal com os valores default do state
    this.filtersLocal = { ...this.filters }
  },

  mounted() {
    // Define obrigatoriamente uma filial selecionada
    if (this && this.filtersLocal.branch === '') {
      this.filtersLocal.branch = this.getCompanieBranches[0].branch_id
      this.setSearchInvoiceFilter(this._.toPlainObject(this.filtersLocal))
    }

    // Atualizo lista de documentos filtrados no primeiro carregamento
    // aguardando o carregamento de allInvoices
    // Se for reeader mode apenas reseto os filtros
    if (this.readerMode) this.resetFilter()
    else this.reloadDocs()

    // Para não disparar os eventos de update na abertura do componente
    setTimeout(() => {
      this.firstload = false
    }, 500)
  },
  methods: {
    ...mapActions('Invoices', ['getAndMonitorInvoices']),
    ...mapMutations('Invoices', [
      'setSearchInvoiceFilter',
      'resetSearchInvoiceFilter',
    ]),

    onSearchNFeKey(nfeKey) {
      const posQ = nfeKey.indexOf('?p=')
      if (posQ >= 0) {
        nfeKey = nfeKey.substr(posQ + 3, 44)
      }
      this.$emit('onFetchNFeKey', nfeKey)
      this.nfeKey = null
    },

    reloadDocs(i = 0) {
      if (this.allInvoices.length > 0) {
        this.filteredInvoicesInternal = this.getAllInvoicesFiltered
        this.$emit('update:filteredInvoices', this.filteredInvoicesInternal)
      } else if (i <= 10) {
        i++
        let that = this
        setTimeout(() => {
          that.reloadDocs(i)
        }, this.lazyLoadTimeout)
      }
    },

    onUpdatedFields(value, fieldUpdated) {
      if (fieldUpdated === 'branches') {
        this.resetFilterWithouUpdate(value)
      }

      this.$emit('onUpdate', this._.toPlainObject(this.filtersLocal))
      if (!this.firstload) {
        // Simula o lazy load, para disparar o update a cada 0.5 segundo após a última alteração
        clearTimeout(this.lazyOnUpdate)
        this.lazyOnUpdate = setTimeout(() => {
          this.setSearchInvoiceFilter(this._.toPlainObject(this.filtersLocal))
          this.filteredInvoicesInternal = this.getAllInvoicesFiltered
          this.$emit('update:filteredInvoices', this.filteredInvoicesInternal)
        }, 500)
      }
    },

    resetFilterWithouUpdate(branch = null) {
      this.resetSearchInvoiceFilter()
      this.filtersLocal = { ...this.filters }
      this.filtersLocal.branch = branch || this.getCompanieBranches[0].branch_id
    },

    resetFilter() {
      this.resetSearchInvoiceFilter()
      this.filtersLocal = { ...this.filters }
      this.filtersLocal.branch = this.getCompanieBranches[0].branch_id
      this.setSearchInvoiceFilter(this._.toPlainObject(this.filtersLocal))
      this.$emit('onResetClick', this._.toPlainObject(this.filtersLocal))
      this.$emit('update:filteredInvoices', this.getAllInvoicesFiltered)
    },

    applyFilter() {
      this.$emit('onApplyClick', this._.toPlainObject(this.filtersLocal))
      this.setSearchInvoiceFilter(this._.toPlainObject(this.filtersLocal))
      this.getAndMonitorInvoices({
        useDateFilters: false,
        filterStatus: 'A',
        force: true,
      }).then(() => {
        this.$emit('update:filteredInvoices', this.getAllInvoicesFiltered)
      })
    },

    onSearchStringEnter(value) {
      this.$emit('onSearchStringEnter', value)
    },

    clearSearchString(value) {
      this.filtersLocal.searchString = ''
      this.filteredInvoicesInternal = this.getAllInvoicesFiltered
      this.$emit('update:filteredInvoices', this.filteredInvoicesInternal)
    },
  },
}
</script>

<style>

.invoice-filter-bar .input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.invoice-filter-bar .input-with-select .el-input-group__prepend .invoice-filter-bar .el-select .el-input {
  width: 110px;
}

.invoice-filter-bar .textoQuantidade {
    font-style: italic;
    font-size: 10px;
    text-align: center;
    margin-top: 4px;
  }
</style>
