import { store } from '@/store'
import { gcs, db, timestamp } from '@/config/firebase'
import createIds from '@/helpers/createIds'
import moment from 'moment'

import openStore from '@/services/localForageService'
const indexDbStore = openStore('files')

function buildFullFileName(fileName, extension) {
  const now = new Date()
  const companyId = store.state.User.companie.id
  function add0(n) {
    return n < 10 ? '0' + n : '' + n
  }
  const year = now.getFullYear()
  const month = add0(now.getMonth() + 1)
  const fullDestinationName = `images/${companyId}/${year}/${month}/${fileName}.${extension}`
  console.log(`function buildFullFileName: ${fullDestinationName}`)
  return fullDestinationName
}

function buildMetadata(metadata = {}, dateType = 'string') {
  metadata.company_id =
    metadata.company_id || store.state.User.companie.id || false
  metadata.auth_uid =
    metadata.auth_uid || store.state.User.profile.auth_uid || false
  metadata.auth_login =
    metadata.auth_login || store.state.User.profile.login || false
  metadata.truck_id =
    metadata.truck_id || store.state.Trucks.selectedTruck.truck_id || null
  metadata.truck_km =
    metadata.truck_km || store.state.Trucks.selectedTruck.km || null
  metadata.branch_id =
    metadata.branch_id || store.state.Cargos.cargo.branch_id || null
  metadata.cargo_id =
    metadata.cargo_id || store.state.Cargos.cargo.cargo_id || null

  if (dateType === 'firestore') metadata.created_at = timestamp || null
  else metadata.created_at = moment().format('YYYY-MM-DD hh:mm:ss Z')

  // console.log(`returned metadata: ${JSON.stringify(metadata)}`)
  return metadata
}

function updateStates(payload) {
  // Update or set upload files
  store.commit('Shared/updateUploadingFiles', payload, { root: true })

  let uploadingFiles = store.state.Shared.uploadingFiles.slice(0)

  // If want remove completed uploads
  // let uploadingFiles = store.state.Shared.uploadingFiles.filter(uploading => (uploading.status !== 'success'))

  // Calculate the overall progress
  let filesBeingUploaded = uploadingFiles.length
  let totalCompleted = uploadingFiles.reduce(
    (prevVal, elem) => prevVal + elem.progress,
    0
  )
  let overallProgress = Math.round(totalCompleted / filesBeingUploaded)
  let hasFailed = uploadingFiles.some(
    (uploadTask) => uploadTask.status === 'failed'
  )
  let hasSending = uploadingFiles.some(
    (uploadTask) =>
      uploadTask.status === 'sending' || uploadTask.status === 'starting'
  )

  // Update state with overall status
  if (filesBeingUploaded === 0) {
    store.commit(
      'Shared/setUploadingStatus',
      {
        filesBeingUploaded,
        overallProgress: 0,
        overallStatus: 'not_uploading',
      },
      { root: true }
    )
  } else if (hasFailed && !hasSending) {
    // If has a failed task and no other active sending files... set failed overall status and clear the uploading files list array
    store.commit(
      'Shared/setUploadingStatus',
      { filesBeingUploaded, overallProgress, overallStatus: 'failed' },
      { root: true }
    )
    store.commit('Shared/setUploadingFiles', [], { root: true })
  } else if (hasFailed && hasSending) {
    // If has sending and a failed task... just update the new overall progress status with failed mark
    store.commit(
      'Shared/setUploadingStatus',
      { filesBeingUploaded, overallProgress, overallStatus: 'failed' },
      { root: true }
    )
  } else if (hasSending) {
    // If has sending... just update the new overall progress status
    store.commit(
      'Shared/setUploadingStatus',
      { filesBeingUploaded, overallProgress, overallStatus: 'sending' },
      { root: true }
    )
  } else {
    // If has no failed task and any other sending files... set success overall status and clear the uploading files list array
    store.commit(
      'Shared/setUploadingStatus',
      { filesBeingUploaded, overallProgress: 100, overallStatus: 'completed' },
      { root: true }
    )
    store.commit('Shared/setUploadingFiles', [], { root: true })
  }
}

function uploadBlob(blob, imageDescGroup, metadata = {}, type) {
  const uid = createIds.firebaseDoc()
  const extension = type === 'imagem' ? 'jpeg' : 'webm'
  const destinationFileName = buildFullFileName(uid, extension)
  const fileRef = gcs.child(destinationFileName)

  metadata = buildMetadata(metadata, 'string')
  metadata.type = `blob`

  return new Promise((resolve, reject) => {
    if (blob) {
      // Start sending file to Google Storage
      let fileUploadTask = fileRef.put(blob, {
        contentType: type === 'imagem' ? 'image/jpeg' : 'audio/webm',
        customMetadata: metadata,
      })

      // Update States with new task status
      updateStates({ uid, progress: 0, status: 'starting' })

      fileUploadTask.on(
        'state_changed',
        (snapshot) => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

          updateStates({ uid, progress, status: 'sending' })

          // https://firebase.google.com/docs/storage/web/upload-files?hl=pt-br
          // Pause the upload   -> uploadTask.pause()
          // Resume the upload  -> uploadTask.resume()
          // Cancel the upload  -> uploadTask.cancel()

          // switch (snapshot.state) {
          //   case firebase.storage.TaskState.PAUSED: // or 'paused'
          //     console.log('Upload is paused');
          //     break;
          //   case firebase.storage.TaskState.RUNNING: // or 'running'
          //     console.log('Upload is running');
          //     break;
          // }
        },
        (err) => {
          console.log(`Fail to upload file ${err}`)
          updateStates({ uid, status: 'failed' })
          reject(err)
        },
        () => {
          fileUploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            updateStates({ uid, status: 'success' })
            resolve(downloadURL)
          })
        }
      )
    } else {
      resolve(-1)
    }
  })
}

function uploadBase64(base64, metadata = {}, type) {
  return new Promise((resolve, reject) => {
    if (base64) {
      metadata = buildMetadata(metadata, 'firestore')

      let collection = ''
      if (type === 'imagem') {
        metadata.image = base64
        collection = 'images'
      } else {
        metadata.audio = base64
        collection = 'audios'
      }

      // console.log('Uploading base64 to firestore', metadata)

      if (!metadata.company_id) reject(new Error(`Invalid metadata.company_id`))
      if (!metadata.collection_name)
        reject(new Error(`Invalid metadata.collection_name`))
      if (!metadata.doc_id) reject(new Error(`Invalid metadata.collection_id`))

      const docId = createIds.firebaseDoc()

      const imageDocRef = db
        .collection('companies')
        .doc(metadata.company_id)
        .collection(collection)
        .doc(docId)

      updateStates({ uid: docId, progress: 0, status: 'starting' })
      imageDocRef
        .set(metadata)
        .then(() => {
          updateStates({ uid: docId, progress: 100, status: 'success' })
          resolve(docId)
        })
        .catch((err) => {
          console.error(err)
          updateStates({ uid: docId, status: 'failed' })
          reject(err)
        })
    } else {
      resolve(-1)
    }
  })
}

function uploadRoute(fileData, metadata, type) {
  if (fileData instanceof Blob) return uploadBlob(fileData, '', metadata, type)
  else return uploadBase64(fileData, metadata, type)
}

async function queueImageUpload() {
  let total = await indexDbStore.length()
  store.commit('Shared/setSyncingPending', total)
  store.commit('Shared/setConnectionType') // Force check wifi status

  let isLoggedIn = store.getters['User/isLoggedIn']
  let isConnectionFast = store.getters['Shared/isConnectionFast']
  let uploadOnlyWifi =
    store.getters['User/companieImagesSettings'].uploadOnlyWifi

  if (total > 0 && isLoggedIn && (isConnectionFast || !uploadOnlyWifi)) {
    // console.log(
    //   'Uploading from queue',
    //   total,
    //   isLoggedIn,
    //   isConnectionFast,
    //   uploadOnlyWifi
    // )

    store.commit('Shared/setSyncingState', true)

    let key = await indexDbStore.key(0)
    let keyValue = await indexDbStore.getItem(key)

    if (keyValue.status === 'queued') {
      keyValue.status = 'uploading'
      indexDbStore.setItem(key, keyValue)

      uploadRoute(keyValue.fileData, keyValue.metadata, keyValue.type)
        .then((urlOrId) => console.log('Finished', urlOrId))
        .then(() => indexDbStore.removeItem(key))
        .then(() => store.commit('Shared/setSyncingState', false))
        .then(() => indexDbStore.length())
        .then((total) => store.commit('Shared/setSyncingPending', total))
        .then(() => queueImageUpload()) // Auto invoke for fast upload
        .catch((err) => console.error('Upload Failed', err))
    } else {
      // console.log('Uploading already in process...')
    }
  } else {
    store.commit('Shared/setSyncingState', false)
  }
}

function upload(fileData, metadata, type = 'imagem') {
  return new Promise((resolve, reject) => {
    if (!metadata.collection_name)
      reject(new Error('Metadata coll_name cant be empty'))
    if (!metadata.doc_id)
      reject(new Error('Metadata coll_doc_id cant be empty'))
    if (!metadata.doc_ref) reject(new Error('Metadata doc_ref cant be empty'))
    if (!metadata.image_tag && !metadata.tag)
      reject(new Error('Metadata taags cant be empty'))

    if (fileData) {
      // IndexDB not support object reference
      // onCreate Image on firebase functions just need path
      metadata.doc_ref = { path: metadata.doc_ref.path }

      // image_tag is depreciated to allow fileUpload any file type
      metadata.tag = metadata.tag || metadata.image_tag

      const keyname = `${metadata.collection_name}_${metadata.doc_id}_${
        metadata.tag
      }_${createIds.firebaseDoc()}`
      const keyvalue = { metadata, fileData, status: 'queued', type }

      indexDbStore
        .setItem(keyname, keyvalue)
        .then((keyValueReturned) => {
          console.info(`fileData queued with ${type}`)

          // Update state
          indexDbStore
            .length()
            .then((total) => store.commit('Shared/setSyncingPending', total))
            .then(() => queueImageUpload())
            .catch((err) => reject(err))

          resolve(true)
        })
        .catch((err) => console.error('storeImage Set item Error', err))
    } else {
      reject(new Error(`Invalid data: ${metadata.image_tag}`))
    }
  })
}

export default {
  queueImageUpload,
  upload,
}
