export default {
  // retorna contagem de cada carga e seu status
  getCargosStatusCount(state, getters) {
    // Formated for use with Google Charts
    // ['Bloqueado', (getters.getAllCargosFiltered.filter(cargo => cargo.cargo_status === 'B')).length],
    let dataForGChart = [['Status', 'Total']]
    state.statusLabels.forEach((status) => {
      dataForGChart.push([
        status.value,
        getters.getAllCargosFiltered.filter(
          (cargo) => cargo.cargo_status === status.content
        ).length,
      ])
    })
    return dataForGChart
  },

  // retorna contagem de customers/notas em cada status
  getDeliveredCustomersCount(state, getters) {
    // Formated for use with Google Charts
    let totalDelivered = 0
    getters.getAllCargosFiltered.map((cargo) => {
      let tempCustomer = cargo.customers || cargo.costumers
      return Object.values(tempCustomer).map((customer) =>
        customer.status === 'delivered' ? totalDelivered++ : null
      )
    })

    let totalCanceled = 0
    getters.getAllCargosFiltered.map((cargo) => {
      let tempCustomer = cargo.customers || cargo.costumers
      return Object.values(tempCustomer).map((customer) =>
        customer.status === 'canceled' ? totalCanceled++ : null
      )
    })

    let totalDelivering = 0
    getters.getAllCargosFiltered.map((cargo) => {
      let tempCustomer = cargo.customers || cargo.costumers
      return Object.values(tempCustomer).map((customer) =>
        customer.status === 'delivering' ? totalDelivering++ : null
      )
    })

    let totalCheckin = 0
    getters.getAllCargosFiltered.map((cargo) => {
      let tempCustomer = cargo.customers || cargo.costumers
      return Object.values(tempCustomer).map((customer) =>
        customer.status === 'delivering_checkin' ? totalCheckin++ : null
      )
    })

    let totalWaiting = 0
    getters.getAllCargosFiltered.map((cargo) => {
      let tempCustomer = cargo.customers || cargo.costumers
      return Object.values(tempCustomer).map((customer) =>
        customer.status === undefined ? totalWaiting++ : null
      )
    })

    return [
      ['Status', 'Total'],
      ['Entregue', totalDelivered],
      ['Cancelado', totalCanceled],
      ['Em entrega', totalDelivering],
      ['Checkin', totalCheckin],
      ['Aguardando', totalWaiting],
    ]
  },

  // Retorna total de customers/notas
  getTotalCustomersCount(state, getters) {
    return getters.getAllCargosFiltered.reduce((prevVal, cargo) => {
      let tempCustomers = cargo.customers || cargo.costumers
      return prevVal + Object.keys(tempCustomers).length
    }, 0)
  },

  // retorna total de cargas apos filtro
  getTotalCargos(state, getters) {
    return Object.values(getters.getAllCargosFiltered).length
  },

  // total de cargas com status em entrega
  getTotalCargosInDeliveringState(state, getters) {
    return Object.values(getters.getAllCargosFiltered).filter(
      (cargo) => cargo.cargo_status === 'D'
    ).length
  },
}
