<template>
  <div class="imagem-detail">
    <h1>Detalhes do Evento</h1>
    <div>
      <el-col :xs="20" :sm="12">
        <p>Data: {{ eventDetail.created_at }}</p>
        <p>Tipo: {{ eventDetail.type }}</p>
        <p>Veiculo: {{ eventDetail.truck }} </p>
      </el-col>
      <p>Observação: {{ eventDetail.desc }}</p>
      <p>Valor: {{ eventDetail.value }}</p>
      <p>km: {{ eventDetail.km }} </p>
      <div>
        <el-col :xs="20">
          <div class="event-detail-imagem">
            <p>Imagens</p>
            <el-image :src="eventDetail.images[0]" :preview-src-list="eventDetail.images" :initial-index="0"
              fit="cover"
              style="width: 50%; height: 50%"/>
          </div>
        </el-col>
      </div>
      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="info" @click="$router.go(-1)">
          Voltar
        </el-button>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: 'detail',
  props: {
    eventDetail: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {}
  },
  components: {},
  methods: {
    cellClicked(row) {
      // console.log(row)
    },
  },
}
</script>
<style scope>
.event-detail {
  width: 100%;
  text-align: center;
  align-content: center;
  margin-top: 10px;
  padding-top: 10px;
}
.event-detail-imagem {
  width: 100%;
  text-align: center;
  align-content: center;
  margin-top: 10px;
  padding-top: 10px;
}
</style>
