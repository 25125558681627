<template>
  <div class="drivers">
    <list-users :users="allDrivers" type="drivers" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ListUsers from '@/components/Admin/Users/components/list'

export default {
  name: 'DriversList',
  components: {
    ListUsers,
  },
  computed: {
    ...mapState('Drivers', ['allDrivers']),
  },
}
</script>

<style scoped>
.drivers {
  width: 100%;
}
</style>
