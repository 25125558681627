export default {
  selectedTruckSavedKm(state) {
    if (state.selectedTruck.km) return state.selectedTruck.km
    else return false
  },
  getTruckLastLocation: (state) => (id) => {
    // console.log(state.allTrucks.find((truck) => truck.id === id))
    const truck = state.allTrucks.find((truck) => truck.id === id)
    const location = truck.location || {}
    if (truck && truck.location) location.updated_at = truck.updated_at_location
    return location
  },
  getTruckBranch: (state) => (id) => {
    const truck = state.allTrucks.find((truck) => truck.id === id)
    const branch = truck.branch_id || null
    return branch
  },

  getTruckCost: (state) => (id) => {
    const truck = state.allTrucks.find((truck) => truck.id === id)
    let cost = 0
    cost = (cost + (truck.cost_security || 0))
    cost = (cost + (truck.cost_ipva || 0))
    cost = (cost + (truck.cost_tracker || 0))
    cost = (cost + (truck.cost_depreciation || 0))
    cost = (cost + (truck.cost_others || 0))
    return cost
  },
}
