import { getDefaultCheckerState } from './state'

export default {
  resetState(state) {
    // console.log(`Checker state reseted`)
    Object.assign(state, getDefaultCheckerState())
  },
  changeCheckers(state, payload) {
    state.allCheckers = payload
  },
  addChecker(state, payload) {
    state.allCheckers.push(payload)
  },
  removeChecker(state, payload) {
    state.allCheckers = state.allCheckers.filter(
      (checker) => checker.driver_login !== payload
    )
  },
  updateChecker(state, payload) {
    const checkerIndex = state.allCheckers.findIndex(
      (checker) => checker.driver_login === payload.driver_login
    )
    if (checkerIndex >= 0) {
      Object.assign(state.allCheckers[checkerIndex], payload)
    } else {
      state.allCheckers.push(payload)
    }
  },
}
