export default {
  isLoggedIn(state) {
    if (state.profile && state.profile.auth_uid && state.profile.login)
      return true
    else return false
  },
  cancellationReasonsPartial(state) {
    if (
      state.companie.settings &&
      state.companie.settings.cancellationReasonsPartial
    )
      return state.companie.settings.cancellationReasonsPartial
    else return null
  },
  cancellationReasonsPartialDesc: (state) => (id) => {
    // Retorna descricao da razao ou próprio id
    let reasons = state.companie.settings.cancellationReasonsPartial
    let desc = id ? reasons.find((reason) => reason.id === id) : undefined
    return desc ? desc.description : id
  },
  cancellationReasons(state) {
    if (state.companie.settings && state.companie.settings.cancellationReasons)
      return state.companie.settings.cancellationReasons
    else return null
  },
  cancellationReasonsDesc: (state) => (id) => {
    // Retorna descricao da razao ou próprio id
    if (id === 'system') return 'Cancelamento automático'
    let reasons = state.companie.settings.cancellationReasons
    let desc = id ? reasons.find((reason) => reason.id === id) : undefined
    return desc ? desc.description : id
  },
  companieEventsSettings(state) {
    if (state.companie.settings && state.companie.settings.events)
      return state.companie.settings.events
    else return {}
  },
  companieImagesSettings(state) {
    if (state.companie.settings && state.companie.settings.images)
      return state.companie.settings.images
    else return {}
  },
  getCompanieBranches(state) {
    if (state.companie.branchs) return state.companie.branchs
    return null
  },
  getCompanieBranchName: (state) => (id) => {
    // Retorna nome ou undefined
    let branch = id
      ? state.companie.branchs.find((branch) => branch.branch_id === id)
      : undefined
    return branch ? branch.branch_name : undefined
  },
  getPaymentName: (state) => (payment) => {
    // Retorna nome ou string -
    if (payment.name) return payment.name

    let pay = payment
      ? Object.values(state.companie.settings.payments).find(
          (paybd) => paybd.type === payment.type || paybd.code === payment.code
        )
      : undefined
    return pay ? pay.name : '-'
  },
  getCompanieBranchLocation: (state) => (id) => {
    const branch = state.companie.branchs.find(
      (branch) => branch.branch_id === id
    )
    if (branch) {
      return { lat: branch.latitude, lng: branch.longitude }
    }
    return null
  },
  getDriverName: (state, getters, rootState) => (id) => {
    // Retorna nome ou undefined
    if (id) {
      const driver = rootState.Drivers.allDrivers.find(
        (driverlist) => driverlist.auth_uid === id
      )
      return driver ? driver.name : ''
    }
    return undefined
  },
}
