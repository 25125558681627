import { db } from '@/config/firebase'
import dbFilter from '@/helpers/firestoreQueryFilter'

export default {
  async getEvents({ commit, rootState }, limit = 50) {
    commit('Shared/setLoadingMessage', 'Procurando evendos...', { root: true })

    let eventRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('events')
      .orderBy('created_at', 'desc')
      .limit(limit)
    let events = await dbFilter.query(eventRef)
    commit('changeEvents', events)
    commit('Shared/setLoadingMessage', '', { root: true })
    return events
  },

  async getEventsOfCargo({ commit, rootState }, { cargoId, branchId }) {
    let logsRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('events')
    let events = []
    await logsRef
      .where('cargo_id', '==', cargoId)
      .where('branch_id', '==', branchId)
      .limit(200)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) =>
          events.push(Object.assign({ id: doc.id }, doc.data()))
        )
      })
      .catch((err) => {
        throw new Error(err)
      })

    return events
  },
}
