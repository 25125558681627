const paymenTypes = [
  { value: '01', label: 'Dinheiro' },
  { value: '02', label: 'Cheque' },
  { value: '03', label: 'Cartão de Crédito' },
  { value: '04', label: 'Cartão de Débito' },
  { value: '05', label: 'Crédito Loja' },
  { value: '10', label: 'Vale Alimentação' },
  { value: '11', label: 'Vale Refeição' },
  { value: '12', label: 'Vale Presente' },
  { value: '13', label: 'Vale Combustível' },
  { value: '15', label: 'Boleto Bancário' },
  { value: '90', label: 'Sem Pagamento' },
  { value: '99', label: 'Outros' },
]

const getPaymentTypeLabel = (value) => {
  const pt = paymenTypes.find((p) => p.value === value)
  if (pt) {
    return pt.label
  }
  return ''
}

export { paymenTypes, getPaymentTypeLabel }
