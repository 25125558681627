<template>
  <div class="cargo-conference-component-events">
    <el-card class="box-card-conference-history" shadow="never">
      <div slot="header" class="box-card-conference-header">
        Eventos
      </div>

      <div
        class="historyline"
        v-for="(event, index) in eventsSorted"
        :key="`logs_${index}`"
      >
        <b>{{
          $moment.unix(event.created_at.seconds).format('DD/MM/YYYY HH:mm')
        }}</b
        ><br />
        {{ eventText(event) }}
      </div>
    </el-card>
  </div>
</template>

<script>
import format from '@/helpers/format'

export default {
  name: 'cargos-conference-events',
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    eventsSorted() {
      let sorted = this.events
      return sorted.sort((a, b) => {
        if (a.created_at.seconds > b.created_at.seconds) return -1
        if (a.created_at.seconds < b.created_at.seconds) return 1
        return 0
      })
    },
  },
  methods: {
    eventText(event) {
      let tipo = ''
      if (event.event_type === 'paytax') tipo = 'Pedágio'
      else if (event.event_type === 'payfuel') tipo = 'Abastecimento'
      else if (event.event_type === 'general') tipo = 'Outros'
      else tipo = event.event_type

      return `${tipo}: ${format(event.event.value, 'C')}`
    },
  },
  mounted() {},
}
</script>

<style scoped>
.historyline {
  line-height: 14px;
  font-size: 12px;
  margin-bottom: 10px;
}
.box-card-conference-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 60px;
}
</style>
