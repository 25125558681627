<template>
  <div class="invoiceCollapse">
    <el-collapse style="font-size: x-small;">
      <el-collapse-item :name="index">
        <template slot="title">
          <i
            v-if="showRemove"
            class="el-icon-delete delete-doc"
            @click="remove"
          ></i>
          <div class="padding: 10px;">
            <el-tag
              v-if="showSequence"
              effect="dark"
              type="warning"
              size="mini"
              style="margin-right: 5px; font-size: xx-small;"
              >{{ invoice.sequence }}</el-tag
            >
            <span class="text-header">{{
              `${
                invoice.invoice.length > 0
                  ? invoice.invoice
                  : invoice.order_number
              } - ${invoice.customer}`
            }}</span>
          </div>
        </template>
        <div>
          <span class="text-title">Documento: </span>
          <el-tooltip class="item" effect="dark" placement="bottom-start">
            <span class="text-content">{{
              invoice.invoice.length > 0
                ? invoice.invoice
                : invoice.order_number
            }}</span>
            <div slot="content">
              <el-table
                :data="invoice.items"
                style="width: 100%; font-size: xx-small;"
              >
                <el-table-column prop="product" label="Produto" width="300">
                </el-table-column>
                <el-table-column prop="quantity" label="Qtde." width="50">
                </el-table-column>
                <el-table-column prop="um" label="UM" width="50">
                </el-table-column>
              </el-table>
            </div>
          </el-tooltip>
        </div>
        <div>
          <span class="text-title">Dt. Entrega: </span>
          <span class="text-content">{{
            this.$moment(invoice.delivery_date, 'YYYYMMDD').format('DD/MM/YYYY')
          }}</span>
        </div>
        <div>
          <span class="text-title">Peso: </span>
          <span class="text-content">{{
            new Intl.NumberFormat('pt-BR', {
              style: 'decimal',
              minimumFractionDigits: 2,
            }).format(invoice.weight)
          }}</span>
        </div>
        <div>
          <span class="text-title">Valor: </span>
          <span class="text-content">{{
            new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(invoice.invoice_total)
          }}</span>
        </div>
        <div>
          <span class="text-title">End.: </span>
          <span v-if="enableChangeLatLng" class="text-content btn-content" @click="openMap(invoice)">{{
            `${invoice.address1}, ${invoice.address2}, ${invoice.neighborhood} - ${invoice.city}-${invoice.state} - ${invoice.postal_code}`
          }}</span>
          <span v-else class="text-content">{{
            `${invoice.address1}, ${invoice.address2}, ${invoice.neighborhood} - ${invoice.city}-${invoice.state} - ${invoice.postal_code}`
          }}</span>
        </div>
        <div>
          <span class="text-title">Fone: </span>
          <span class="text-content">{{
            `(${invoice.phone_code_area}) ${invoice.phone}`
          }}</span>
        </div>
        <div>
          <span class="text-title">Observação: </span>
          <span class="text-content">{{
            `(${invoice.order_observations}) ${invoice.phone}`
          }}</span>
        </div>
      </el-collapse-item>
    </el-collapse>
    <map-dialog ref="mapDialog" title="Confirme a localização do ponto de entrega" subtitle="Use o botão esquerdo do mouse para clicar na nova localização e o direito para arrastar o mapa" @map-click="mapClick" :enableClick="true"/>
  </div>
</template>

<script>
// import mapDialog from './components/mapDialog'
import mapDialog from '../components/mapDialog.vue'
export default {
  name: 'invoiceCollapse',
    components: {
    mapDialog,
  },
  props: {
    invoice: Object,
    index: Number,
    showSequence: {
      type: Boolean,
      default: false,
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
    enableChangeLatLng: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    remove() {
      this.$emit('remove', this.invoice)
    },
    openMap(invoice) {
      this.$refs.mapDialog.showDialog([{coords:{...invoice.latlng}}])
      this.$emit('open-map', invoice)
    },
    mapClick(latLng) {
      this.invoice.latlng = {...latLng}
      this.$emit('changeLatLng', this.invoice)
    },
  },
}
</script>

<style>
.invoiceCollapse .el-collapse-item__header {
  font-size: xx-small;
}
 .invoiceCollapse .el-collapse-item__content {
  font-size: xx-small;
  text-align: start;
}
 .invoiceCollapse .el-collapse-item__content .text-title {
  font-weight: bold;
}
 .invoiceCollapse .text-header {
  max-width: 2vw;
}
 .invoiceCollapse .el-collapse-item__header {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
 .invoiceCollapse .el-collapse-item__wrap {
  background-color: #eeeded;
}
 .invoiceCollapse .delete-doc {
  position: absolute;
}
 .invoiceCollapse .btn-content {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
 
</style>
