// Valores retornado com base nos filtros aplicados na barra de filtros
// ou no array global allInvoices conforme parametro informado
// argumento fromFilter aceita valores como 'none', 'branch', 'full'
//
// none - nenhum filtro é aplicado
// branch - apelas filtro por filial é aplicado
// full - todos os filtros são aplicados

function getSource(state, getters, filter) {
  if (filter === 'none') return state.allInvoices
  else if (filter === 'branch') return getters.getAllInvoicesFilteredOnyByBranch
  else return state.getAllInvoicesFiltered
}

export default {
  getBranchesFromInvoices(state, getters) {
    return state.allInvoices
      .reduce((results, invoice) => {
        if (
          typeof invoice.branch_id === 'string' &&
          invoice.branch_id !== '' &&
          results.indexOf(invoice.branch_id) < 0
        )
          results.push(invoice.branch_id)
        return results
      }, [])
      .sort()
  },
  getBranchesFromInvoicesWithName(state, getters, rootState, rootGetters) {
    return state.allInvoices
      .reduce(
        (results, invoice) => {
          if (
            typeof invoice.branch_id === 'string' &&
            invoice.branch_id !== '' &&
            results[0].ids.indexOf(invoice.branch_id) < 0
          ) {
            results[0].ids.push(invoice.branch_id)
            results[0].names.push({
              id: invoice.branch_id,
              name: rootGetters['User/getCompanieBranchName'](
                invoice.branch_id
              ),
            })
          }
          return results
        },
        [{ ids: [], names: [] }]
      )
      .reduce((results, lasResult) => {
        return lasResult.names
      }, [])
      .sort(function (a, b) {
        var nameA = a.name.toUpperCase() // ignore lowercase
        var nameB = b.name.toUpperCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0 // when names equal
      })
  },
  getNeighborhoodsFromInvoices: (state, getters) => (filter = 'none') => {
    const source = getSource(state, getters, filter)

    return source
      .reduce((results, invoice) => {
        if (
          typeof invoice.neighborhood === 'string' &&
          invoice.neighborhood !== '' &&
          results.indexOf(invoice.neighborhood) < 0
        )
          results.push(invoice.neighborhood)
        return results
      }, [])
      .sort()
  },
  getCitiesFromInvoices: (state, getters) => (filter = 'none') => {
    const source = getSource(state, getters, filter)

    return source
      .reduce((results, invoice) => {
        if (
          typeof invoice.city === 'string' &&
          invoice.city !== '' &&
          results.indexOf(invoice.city) < 0
        )
          results.push(invoice.city)
        return results
      }, [])
      .sort()
  },
  getStatesFromInvoices: (state, getters) => (filter = 'none') => {
    const source = getSource(state, getters, filter)

    return source
      .reduce((results, invoice) => {
        if (
          typeof invoice.state === 'string' &&
          invoice.state !== '' &&
          results.indexOf(invoice.state) < 0
        )
          results.push(invoice.state)
        return results
      }, [])
      .sort()
  },
  getRoutesFromInvoices: (state, getters) => (filter = 'none') => {
    const source = getSource(state, getters, filter)

    return source
      .reduce((results, invoice) => {
        if (
          typeof invoice.route === 'string' &&
          invoice.route !== '' &&
          results.indexOf(invoice.route) < 0
        )
          results.push(invoice.route)
        return results
      }, [])
      .sort()
  },
  getSectorsFromInvoices: (state, getters) => (filter = 'none') => {
    const source = getSource(state, getters, filter)

    return source
      .reduce((results, invoice) => {
        if (
          typeof invoice.sector === 'string' &&
          invoice.sector !== '' &&
          results.indexOf(invoice.sector) < 0
        )
          results.push(invoice.sector)
        return results
      }, [])
      .sort()
  },
  getZonesFromInvoices: (state, getters) => (filter = 'none') => {
    const source = getSource(state, getters, filter)

    return source
      .reduce((results, invoice) => {
        if (
          typeof invoice.zone === 'string' &&
          invoice.zone !== '' &&
          results.indexOf(invoice.zone) < 0
        )
          results.push(invoice.zone)
        return results
      }, [])
      .sort()
  },
}
