<template>
  <div class="invoices-data-table">
    <el-row :gutter="10">
      <el-col :span="19">
        <el-input
          v-model="searchInvoice"
          size="mini"
          placeholder="Filtrar pelo número do documento, número da carga, nome, filial ou bairro"
        />
      </el-col>

      <el-col :span="4">
        <el-switch
          v-model="showOnlyAvaible"
          active-text="Listar somente disponíveis"
        >
        </el-switch>
      </el-col>
    </el-row>

    <br />
    <br />
    <div class="invoices-data-table-results">
      <el-table
        :data="invoiceTablePaginated"
        style="width: 100%;"
        size="mini"
        row-class-name="mr-data-table-row"
        class="table"
        @cell-click="cellClicked"
      >
        <el-table-column sortable label="Pedido" align="center" width="130">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="`ID ${scope.row.id}`"
              placement="top-start"
            >
              <el-button type="text">
                {{ scope.row.order_number || '--' }}
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          label="Nota Fiscal"
          align="center"
          width="130"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="`ID ${scope.row.id}`"
              placement="top-start"
            >
              <el-button type="text">{{ scope.row.invoice }}</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="branch"
          label="Filial"
          width="150"
          align="center"
        />
        <el-table-column sortable prop="customer" label="Nome" />
        <el-table-column sortable label="Bairro" align="center">
          <template slot-scope="scope"
            >{{ scope.row.neighborhood }}/{{ scope.row.state }}</template
          >
        </el-table-column>

        <el-table-column
          sortable
          width="100"
          prop="status"
          label="Status"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              :type="statusColor(scope.row.status)"
              disable-transitions
              size="mini"
              style="width: 97px;"
              @click="changeInvoiceStatus(scope.row)"
              >{{ scope.row.status }}</el-tag
            >
          </template>
        </el-table-column>

        <el-table-column sortable label="Carga" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.cargo_id !== '--'"
              @click="
                cargoIdClick({
                  branchId: scope.row.branch_id,
                  cargoId: scope.row.cargo_id,
                })
              "
            >
              {{ scope.row.cargo_id }}
            </el-button>
            <span v-else>
              {{ scope.row.cargo_id }}
            </span>
          </template>
        </el-table-column>

        <el-table-column>
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              plain
              @click="handleDeleteInvoice(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="cargos-data-table-paginator">
      <el-divider />
      <el-row justify="center" type="flex">
        <el-col :span="14">
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="[15, 30, 45, 60]"
            :page-size.sync="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="invoicesFilteredByTitleSearch.length"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import dbFilter from '@/helpers/firestoreQueryFilter'
export default {
  name: 'invoicesList',
  data() {
    return {
      searchInvoice: '',
      showOnlyAvaible: true,
      currentPage: 1,
      pageSize: 15,
    }
  },
  computed: {
    ...mapState('Invoices', ['allInvoices', 'statusLabels']),
    ...mapState('User', ['companie']),
    invoicesTable() {
      let dataToReturn = []

      this.allInvoices.forEach((invoice) => {
        let statusLabel = this.statusLabels.find(
          (status) => status.content === invoice.invoice_status
        )
        statusLabel = statusLabel ? statusLabel.value : invoice.invoice_status

        let branch = this.companie.branchs.find(
          (branch) => branch.branch_id === invoice.branch_id
        )
        if (branch) {
          branch = branch.branch_name
        } else {
          branch = ''
        }
        let dataObject = {
          ...invoice,
          id: invoice.id,
          cargo_id: invoice.cargo_id || '--',
          branch,
          status: statusLabel,
        }
        dataToReturn.push(dataObject)
      })
      return dataToReturn
    },
    invoicesFilteredByTitleSearch() {
      return this.invoicesTable.filter((d) => {
        return (
          !this.searchInvoice ||
          this.searchInvoice
            .toLowerCase()
            .split(' ')
            .every((v) =>
              `${d.id}${d.cargo_id}${d.customer}${d.branch}${d.neighborhood}`
                .toLowerCase()
                .includes(v)
            )
        )
      })
    },
    invoiceTablePaginated() {
      let initialPos = this.currentPage * this.pageSize - this.pageSize
      let finalPos = this.currentPage * this.pageSize - 1
      return this.invoicesFilteredByTitleSearch.slice(initialPos, finalPos)
    },
  },
  methods: {
    ...mapActions('Invoices', ['getAndMonitorInvoices']),
    ...mapMutations('Shared', [
      'setError',
      'setSuccessMessage',
      'setInfoMessage',
      'setLoadingMessage',
    ]),
    cargoIdClick({ cargoId, branchId }) {
      this.$router.push({
        name: 'CargosDetails',
        params: { branchId, cargoId },
      })
    },
    changeInvoiceStatus(invoice) {
      if (invoice.status !== 'Cancelada') {
        return
      }
      this.$confirm(
        'Deseja alterar o status do documento para disponível?',
        'Atenção',
        {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            this.setLoadingMessage('Aguarde...')
            const res = await this.$$http.updateInvoiceStatus(invoice.id, 'A')
            this.setLoadingMessage(null)
            if (res.hasOwnProperty('status') && res.status === 200) {
              await this.getAndMonitorInvoices({
                force: true,
                useDateFilters: true,
                showMsg: true,
              })

              this.setSuccessMessage(
                'O status do documento foi atualizado com sucesso.'
              )
            } else {
              this.setError('Não foi possivel atualizar o status do documento')
              console.error('Error changeInvoiceStatus', res)
            }
          } catch (error) {
            this.setLoadingMessage(null)
            this.setError('Não foi possivel atualizar o status do documento')
          }
        })
        .catch(() => {})
    },
    statusColor(status) {
      const statusFinded = this.statusLabels.find((st) => st.value === status)
      if (statusFinded) {
        return statusFinded.color
      }
      return 'primary'
    },
    cellClicked(row, column) {
      if (column.property) {
        // console.log(row)
      }
    },
    async handleDeleteInvoice(invoice) {
      this.$confirm(`Confirma a exclusão do documento ${invoice.id}?`, {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning',
      })
        .then(async () => {
          try {
            this.setLoadingMessage(`Excluindo documento ${invoice.id}`)
            await this.$elastic.deleteInvoice(invoice.id)
            setTimeout(async () => {
              await this.getAndMonitorInvoices({
                useDateFilters: true,
                force: true,
                showMsg: true,
                filterStatus: 'A',
              })
              this.setLoadingMessage('')
              this.setSuccessMessage('Documento excluido.')
            }, 500)
          } catch (error) {
            this.setError(
              `Houve algum problema na exclusão do documento.  (${error.message})`
            )
          }
        })
        .catch(() => {})
    },
  },
  beforeDestroy() {
    dbFilter.queryUnsubscribe('Invoices/changeAllInvoices')
  },
  watch: {
    showOnlyAvaible(newValue) {
      const params = {
        useDateFilters: true,
        force: true,
        showMsg: true,
      }
      if (newValue) {
        params['filterStatus'] = 'A'
      }
      this.getAndMonitorInvoices(params)
    },
  },
}
</script>

<style>
tr.mr-data-table-row {
  cursor: pointer;
}
</style>
