import { db } from '@/config/firebase'
import dates from '@/helpers/getDateRange'

const defaultOption = {
  limit: 200,
  usersFilter: ['*'],
  startDateFilter: dates.d.startDateTodayFull,
  endDateFilter: dates.d.endDateFull,
}

export default {
  async getLogsOfCargo({ commit, rootState }, { cargoId, branchId }) {
    let logsRef = db.collection('logs')
    console.log('Procurando logs para ids', cargoId, branchId)
    let logs = []
    await logsRef
      .where('company_id', '==', rootState.User.profile.company_id)
      .where('extraData.cargo_id', '>=', cargoId)
      .where('extraData.branch_id', '==', branchId)
      .limit(200)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) =>
          logs.push(Object.assign({ id: doc.id }, doc.data()))
        )
      })
      .catch((err) => {
        throw new Error(err)
      })

    return logs
  },

  async getLogs({ commit, rootState }, options = {}) {
    let limit = options.limit || defaultOption.limit
    let usersFilter = options.usersFilter || defaultOption.usersFilter
    let startDateFilter = new Date(
      options.startDateFilter || defaultOption.startDateFilter
    )
    let endDateFilter = new Date(
      options.endDateFilter || defaultOption.endDateFilter
    )

    let logs = []
    let logsSearchJobs = []
    let logsRef = db.collection('logs')

    commit('Shared/setLoadingMessage', 'Procurando logs...', { root: true })

    // Se branchFilter === *, realiza a busca sem filtro por filial
    if (usersFilter[0] === '*') {
      console.log('getLogs looking for logs without user filter *')
      console.log(
        startDateFilter,
        endDateFilter,
        rootState.User.profile.company_id,
        limit
      )
      logsSearchJobs[0] = logsRef
        .orderBy('created_at', 'desc')
        .where('company_id', '==', rootState.User.profile.company_id)
        .where('created_at', '>=', startDateFilter)
        .where('created_at', '<=', endDateFilter)
        .limit(limit)
        .get()
        .then((querySnapshot) =>
          querySnapshot.forEach((doc) =>
            logs.push(Object.assign({ id: doc.id }, doc.data()))
          )
        )
        .catch((err) => {
          throw new Error(err)
        })
    } else {
      console.log('getLogs looking for logs with user filter')
      logsSearchJobs = usersFilter.map(async (userUid) => {
        console.log(`getLogs looking for logs for user ${userUid}`)
        return logsRef
          .orderBy('created_at', 'desc')
          .where('company_id', '==', rootState.User.profile.company_id)
          .where('created_at', '>=', startDateFilter)
          .where('created_at', '<=', endDateFilter)
          .where('user_id', '==', userUid)
          .limit(limit)
          .get()
          .then((querySnapshot) =>
            querySnapshot.forEach((doc) =>
              logs.push(Object.assign({ id: doc.id }, doc.data()))
            )
          )
          .catch((err) => {
            throw new Error(err)
          })
      })
    }

    // Aguardo todas as promisses acima terminarem para atualizar o state
    await Promise.all(logsSearchJobs)
      .then(() => console.log('logsSearchJobs finished', logsSearchJobs, logs))
      .catch((err) => {
        throw new Error(err)
      })

    commit('changeAllLogs', logs)
    commit('Shared/setLoadingMessage', '', { root: true })
    return logs
  },

  listenLogs({ commit, rootState }, options = {}) {
    let limit = (options.limit || defaultOption.limit) / 4
    let usersFilter = options.usersFilter || defaultOption.usersFilter // Para o snapshot, por enquanto apenas o primeiro user será filtrado
    let startDateFilter = new Date(
      options.startDateFilter || defaultOption.startDateFilter
    )
    let logsRef = db.collection('logs')

    if (usersFilter[0] === '*') {
      // console.log('listenLogs monitor for logs updates without user filter *')
      return logsRef
        .orderBy('created_at', 'desc')
        .where('company_id', '==', rootState.User.profile.company_id)
        .where('created_at', '>=', startDateFilter)
        .limit(limit)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((snapshot) => {
            let source = snapshot.metadata.hasPendingWrites ? 'Local' : 'Server'
            // console.log(
            //   `Novo log recebido from ${source} -> ${
            //     snapshot.data().created_at.seconds
            //   } ${snapshot.data().msg}`
            // )
            commit('updateLogs', snapshot.data())
          })
        })
    } else {
      // console.log(
      //   `listenLogs start monitor for logs updates with user filter ${usersFilter[0]}`
      // )
      return logsRef
        .orderBy('created_at', 'desc')
        .where('company_id', '==', rootState.User.profile.company_id)
        .where('created_at', '>=', startDateFilter)
        .where('user_id', '==', usersFilter[0])
        .limit(limit)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((snapshot) => {
            let source = snapshot.metadata.hasPendingWrites ? 'Local' : 'Server'
            // console.log(
            //   `Novo log recebido from ${source} -> ${
            //     snapshot.data().created_at.seconds
            //   } ${snapshot.data().msg}`
            // )
            commit('updateLogs', snapshot.data())
          })
        })
    }
  },

  removeListenLogs({ commit }, listener) {
    if (listener) {
      // console.log('Listener destroy')
      listener()
    } else {
      console.log('Invalid listener to unsubscribe')
    }
  },
}
