import { db, timestamp } from '@/config/firebase'
import { getInvoicesSQL } from '@/services/elastic'

function prepareLatLngObj(InvoiceData) {
  return {
    lat:
      InvoiceData.latlng && InvoiceData.latlng.lat ? InvoiceData.latlng.lat : 0,
    lng:
      InvoiceData.latlng && InvoiceData.latlng.lng ? InvoiceData.latlng.lng : 0,
  }
}

function prepareInvoiceObj(invoice) {
  return {
    id: invoice.data.id || invoice.id || '',
    invoice: invoice.data.invoice || '',
    order_number: invoice.data.order_number || '',
    nfe_key: invoice.data.nfe_key || '',
    WEIGHT: invoice.data.weight,
    TOTAL: invoice.data.invoice_total,
    amountreceivable: invoice.data.payment_ammount,
    VOLUMES: invoice.data.volumes,
    payment: [
      {
        ammount: invoice.data.payment_ammount,
        type: invoice.data.payment_type,
        code: invoice.data.payment_code || '',
      },
    ],
    // Se não existir items ou este for vazio
    // Na montagem manual do documento com elastic tivemos alguns casos assim
    ITEMS:
      invoice.data.items && invoice.data.items.length > 0
        ? invoice.data.items.map((i) => {
            return {
              PRODUCT: i.product,
              QUANTITY: i.quantity,
              UM: i.um,
              STEP: i.step,
            }
          })
        : [],
  }
}

export default {
  async getInvoiceByNFeKey({ commit, rootState }, nfeKey) {
    const objSql = []

    objSql.push({
      operation: 'EQUAL',
      field: 'invoice_status',
      value: 'A',
    })

    objSql.push({
      operation: 'EQUAL',
      field: 'nfe_key',
      value: nfeKey,
    })

    try {
      const response = await getInvoicesSQL(objSql)
      const hits = response.data.hits
      if (hits.total.value > 0) {
        return hits.hits[0]._source
      } else {
        return null
      }
    } catch (error) {
      console.error('getInvoiceByNFeKey', error)
      return null
    }
  },

  async getAndMonitorInvoices({ commit, rootState }, params = {}) {
    const activeLoading = params.hasOwnProperty('showMsg')
      ? params.showMsg
      : true

    const onlyWithAddress = params.hasOwnProperty('onlyWithAddress')
      ? params.onlyWithAddress
      : false

    const useDateFilters = params.hasOwnProperty('useDateFilters')
      ? params.useDateFilters
      : true

    if (activeLoading)
      commit('Shared/setLoadingMessage', 'Atualizando documentos...', {
        root: true,
      })

    const objSql = []

    if (params.hasOwnProperty('filterStatus')) {
      objSql.push({
        operation: 'EQUAL',
        field: 'invoice_status',
        value: params.filterStatus,
      })
    }

    if (onlyWithAddress) {
      objSql.push({
        operation: 'NOEQUAL',
        field: 'address1',
        value: '',
      })
    }

    if (useDateFilters) {
      const startDate = rootState.Shared.searchStartDateFilter
        .substring(0, 10)
        .replace(/-/g, '')
      const endDate = rootState.Shared.searchEndDateFilter
        .substring(0, 10)
        .replace(/-/g, '')

      objSql.push({
        operation: 'BETWEEN',
        field: 'invoice_date',
        value: { start: startDate, end: endDate },
      })
    }

    if (rootState.Shared.searchBranchsFilter[0] !== '*') {
      objSql.push({
        operation: 'IN',
        field: 'branch_id',
        value: rootState.Shared.searchBranchsFilter,
      })
    }

    objSql.push({
      operation: 'ORDERBY',
      value: ['invoice_date DESC', 'created_at DESC'],
    })

    const branches = Object.values(rootState.User.companie.branchs).map(
      (companie) => {
        return {
          cnpj: companie.cnpj.replace(/[^\d]+/g, ''),
          id: companie.id,
        }
      }
    )
    const response = await getInvoicesSQL(objSql)
      .then((res) => {
        const invoices = res.data.hits.hits
          .map((h) => {
            return { ...h._source, id: h._id }
          })
          .map((invoice) => {
            if (!('branch_id' in invoice) && 'company_document' in invoice) {
              const branch = branches.find(
                (branch) => branch.cnpj === invoice.company_document
              )
              if (branch) {
                invoice.branch_id = branch.id
              }
            }
            return invoice
          })
        commit('changeAllInvoices', invoices)
        commit('Shared/setLoadingMessage', '', { root: true })
        return invoices
      })
      .catch((error) => {
        console.warn('getInvoices', error)
      })
    return response
  },

  updateStatus({ commit, rootState }, payload) {
    const newStatus = payload.new_status || null
    const invoiceid = payload.invoice_id || null
    const companyId = rootState.User.profile.company_id || null
    const userId = rootState.User.profile.id || null

    if (invoiceid && companyId && newStatus) {
      const invoiceRef = db
        .collection('companies')
        .doc(companyId)
        .collection('invoices')
        .doc(invoiceid)

      // Commit chages to state
      commit('updateStatus', payload)

      let dataToUpdate = {
        updated_at: timestamp,
        updated_by: userId,
        invoice_status: newStatus,
      }

      // Save to database new Km and location
      return invoiceRef
        .update(dataToUpdate)
        .then(() =>
          console.log(
            `Doc invoice/${invoiceid}/invoice_status successfully updated with ${newStatus}`
          )
        )
    } else {
      throw new Error('updateStatus: invalid data')
    }
  },

  async getInvoicesInCargo({ commit, rootState }, payload) {
    commit('Shared/setLoadingMessage', 'Buscando documentos da carga...', {
      root: true,
    })
    const objSql = []

    objSql.push({
      operation: 'EQUAL',
      field: 'cargo_id',
      value: payload.cargo,
    })

    objSql.push({
      operation: 'EQUAL',
      field: 'branch_id',
      value: payload.branch,
    })

    try {
      const response = await getInvoicesSQL(objSql)
      const hits = response.data.hits
      let invoices = []
      if (hits.total.value > 0) {
        hits.hits.forEach((hit) => invoices.push(hit._source))
      }
      return invoices
    } catch (error) {
      console.error('getInvoicesInCargo', error)
      return null
    }
  },

  doCargoInvoicesAvailable({ commit, rootState }, payload) {
    const companyId = rootState.User.profile.company_id || null
    const userId = rootState.User.profile.id || null
    const branchId = payload.branch || null
    const cargoId = payload.cargo || null

    if (branchId && companyId && cargoId) {
      const invoiceRef = db
        .collection('companies')
        .doc(companyId)
        .collection('invoices')
        .where('branch_id', '==', payload.branch)
        .where('cargo_id', '==', payload.cargo)

      // Commit chages to state
      commit('updateStatus', payload)

      let dataToUpdate = {
        updated_at: timestamp,
        updated_by: userId,
        invoice_status: 'A',
        cargo_id: '',
      }

      let docsUpdated = []

      return invoiceRef
        .get()
        .then((response) => {
          let batch = db.batch()

          response.docs.forEach((doc) => {
            docsUpdated.push(doc.data())
            const docRef = db
              .collection('companies')
              .doc(companyId)
              .collection('invoices')
              .doc(doc.id)
            batch.update(docRef, dataToUpdate)
          })

          return batch.commit().then(() => {
            return docsUpdated
          })
        })
        .catch((e) => {
          throw new Error(e)
        })
    }
  },

  async buildCargosPOSTBody({ commit, dispatch, rootState }, payload) {
    let loadedVehicles = payload.loadedVehicles || []
    let editCargo = payload.editCargo || false
    let originalCargo = {}
    if (editCargo) {
      originalCargo = rootState.Cargos.allCargos.find(
        (cargo) =>
          `${cargo.branch_id}${cargo.cargo_id}` ===
          `${editCargo.branch_id}${editCargo.cargo_id}`
      )
    }

    console.log({ loadedVehicles })

    let cargos = loadedVehicles
      .filter((lV) => lV.hasOwnProperty('invoices') && lV.invoices.length > 0)
      .map((c) => {
        let tempCargo = {
          TRUCK_ID: c.truck_id,
          BRANCH_ID: c.branch_id,
          CHECKER: c.checker || '',
          CUSTOMERS: c.invoices.reduce((results, invoice) => {
            let index = results.findIndex(
              (r) => r.CUSTOMER_ID === invoice.data.customer_id
            )
            if (index >= 0) {
              results[index].INVOICES.push(prepareInvoiceObj(invoice))
            } else {
              results.push({
                NEIGHBORHOOD: invoice.data.neighborhood,
                ZONE: invoice.data.neighborhood,
                SECTOR: invoice.data.sector,
                ROUTE: invoice.data.route,
                order_observations: invoice.data.order_observations,
                CITY: invoice.data.city,
                CUSTOMER_ID: invoice.data.customer_id,
                CUSTOMER: invoice.data.customer,
                FANTASY_NAME: invoice.data.fantasy_name,
                ADDRESS2: invoice.data.address2,
                ADDRESS1: invoice.data.address1,
                STATE: invoice.data.state,
                postal_code: invoice.data.postal_code,
                latlng: prepareLatLngObj(invoice.data),
                INVOICES: [prepareInvoiceObj(invoice)],
                SEQUENCEOFDELIVERY: invoice.data.sequence,
                PHONE: invoice.data.phone,
              })
            }
            return results
          }, []),
        }
        // Se edição de carga... defino cargo_id e outros campos para serem mantidos pela API
        if (editCargo) {
          tempCargo['cargo_id'] = editCargo.cargo_id
          tempCargo['created_at'] = originalCargo.created_at
          tempCargo['created_by'] = originalCargo.created_by
        }
        return tempCargo
      })

    cargos = { CARGOS: cargos }
    return cargos
  },
}
