// https://br.vuejs.org/v2/guide/plugins.html
// https://alligator.io/vuejs/creating-custom-plugins/
// https://www.codementor.io/chidieberennadi558/how-to-create-a-plugin-in-vue-js-ehdmg125e

// Remove caracteres especiais, espaços e concatena um array de strings recebidos
const trimCleanConcat = {
  install(Vue, options) {
    Vue.prototype.$$trimCleanConcat = (...string) =>
      string.join('').replace(/[/\\@#!$%ˆ&*() ]/g, '')
  },
}
export default trimCleanConcat
