import env from '@/config/environment'
import axios from 'axios'
/* eslint-disable no-undef */
const platform = new H.service.Platform({
  apikey: env.here.apikey_js,
})

const defaultLayers = platform.createDefaultLayers()

export default async (
  invoices,
  optimize = false,
  elementMapTarget,
  startPoint,
  map
) => {
  if (!startPoint || !startPoint.latlng) {
    return
  }
  const wayPoints = []
  const routingParameters = {
    transportMode: 'truck',
    origin: `${startPoint.latlng.lat},${startPoint.latlng.lng}`,
    destination: `${startPoint.latlng.lat},${startPoint.latlng.lng}`,
    return: 'polyline,summary,typicalDuration',
  }
  if (optimize) {
    invoices = await optimizeRoute(
      invoices,
      startPoint,
      routingParameters,
      wayPoints
    )
  } else {
    invoices = [
      ...makeSequence(invoices, routingParameters, startPoint, wayPoints),
    ]
  }
  if (!map) {
    map = new H.Map(elementMapTarget, defaultLayers.raster.normal.map, {
      zoom: 8,
      center: {
        lat: startPoint.latlng.lat,
        lng: startPoint.latlng.lng,
      },
    })
    const mapEvents = new H.mapevents.MapEvents(map)
    new H.mapevents.Behavior(mapEvents)
    H.ui.UI.createDefault(map, defaultLayers, 'pt-BR')
  } else {
    map.getObjects().forEach((obj) => {
      map.removeObject(obj)
    })
  }
  let truckDuration = 0
  let truckDistance = 0
  const router = platform.getRoutingService(null, 8)
  routingParameters['via'] = new H.service.Url.MultiValueQueryParameter(
    wayPoints.map((wayPoint) => `${wayPoint.latlng.lat},${wayPoint.latlng.lng}`)
  )
  await router.calculateRoute(
    routingParameters,
    (result) => {
      const sections = result.routes[0].sections
      const lineStrings = []
      truckDuration = sections.reduce(
        (tot, section) => (tot += section.summary.duration),
        0
      )
      truckDistance = sections.reduce(
        (tot, section) => (tot += section.summary.length),
        0
      )
      sections.forEach((section) => {
        lineStrings.push(
          H.geo.LineString.fromFlexiblePolyline(section.polyline)
        )
      })
      const multiLineString = new H.geo.MultiLineString(lineStrings)
      const bounds = multiLineString.getBoundingBox()
      const makers = []
      makers.push(createMarker(startPoint.latlng, '!'))
      wayPoints.forEach((point) => {
        makers.push(createMarker(point.latlng, point.sequence))
      })
      var routeLine = new H.map.Polyline(multiLineString, {
        style: {
          strokeColor: 'blue',
          lineWidth: 2,
        },
      })
      map.addObjects([routeLine, ...makers])
      map.getViewModel().setLookAtData({ bounds })
    },
    alert
  )
  return { truckDuration, truckDistance, invoices, map }
}

const createMarker = (latlng, sequence) => {
  let image = `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red${sequence}.png`
  if (sequence === '!') {
    image = `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blue${sequence}.png`
  }
  let icon = new H.map.Icon(image)
  const maker = new H.map.Marker(
    {
      lat: latlng.lat,
      lng: latlng.lng,
    },
    { icon: icon }
  )
  return maker
}

const makeSequence = (invoices, routingParameters, startPoint, wayPoints) => {
  let sequence = 0
  let lastInvoice = null
  invoices.map((i) => {
    if (
      lastInvoice === null ||
      i.data.customer_id !== lastInvoice.data.customer_id ||
      i.data.address1 !== lastInvoice.data.address1
    ) {
      lastInvoice = i
      sequence += 1
      const coord = `geo!${i.data.latlng.lat},${i.data.latlng.lng}`
      routingParameters[`waypoint${sequence}`] = coord
      wayPoints.push({
        sequence: sequence,
        latlng: { lat: i.data.latlng.lat, lng: i.data.latlng.lng },
      })
    }
    i.data['sequence'] = sequence
    return i
  })
  sequence += 1
  const coord = `geo!${startPoint.latlng.lat},${startPoint.latlng.lng}`
  routingParameters[`waypoint${sequence}`] = coord

  return invoices
}

const optimizeRoute = async (
  invoices,
  startPoint,
  routingParameters,
  wayPoints
) => {
  const customers = invoices.reduce((acc, it) => {
    const address = it.data.postal_code
    if (
      !acc.find((customer) => customer.id === it.data.customer_id + address)
    ) {
      acc.push({ id: it.data.customer_id + address, latlng: it.data.latlng })
    }
    return acc
  }, [])

  let URL = `https://wse.ls.hereapi.com/2/findsequence.json?start=BeginRoute;${startPoint.latlng.lat},${startPoint.latlng.lng}`
  let index = 0
  customers.forEach((customer) => {
    index++
    let waypoint = `&destination${index}=${customer.id};${customer.latlng.lat},${customer.latlng.lng}`
    URL += waypoint
  })
  URL += `&end=EndRoute;${startPoint.latlng.lat},${startPoint.latlng.lng}&mode=fastest;car&apiKey=${env.here.apikey_rest}`

  axios.defaults.validateStatus = () => true
  const res = await axios.get(URL)
  if (res.data.errors.length > 0) {
    throw new Error(res.data.errors[0])
  }
  const hereWaypoints = res.data.results[0].waypoints
    .filter((w) => w.id !== 'BeginRoute')
    .filter((w) => w.id !== 'EndRoute')

  let invoicesTemp = []
  hereWaypoints.forEach((s) => {
    routingParameters[`waypoint${s.sequence}`] = `geo!${s.lat},${s.lng}`
    wayPoints.push({
      sequence: s.sequence,
      latlng: { lat: s.lat, lng: s.lng },
    })
    invoices.forEach((i) => {
      const address = i.data.postal_code
      if (s.id === i.data.customer_id + address) {
        i.data['sequence'] = s.sequence
        invoicesTemp.push(i)
      }
    })
  })
  invoices = [...invoicesTemp]

  const coord = `geo!${startPoint.latlng.lat},${startPoint.latlng.lng}`
  routingParameters[`waypoint${hereWaypoints.length + 1}`] = coord

  return invoices
}
