import moment from 'moment'

function t(data) {
  return data.toString().padStart(2, '0')
}

const format = (value, type) => {
  if ((value !== 0 && !value) || !type) {
    return value
  }
  if (type === 'D') {
    if (value.trim()) {
      return moment(value, 'YYYYMMDD').format('L')
    }
  }

  if (type === 'C') {
    if (value === 0) return 'R$ 0,00'
    let numero = parseFloat(value).toFixed(2).split('.')
    numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.')
    return numero.join(',')
  }

  // Convertendo número padrão americano (123456.00) para brasileiro (123.456,00)
  if (type === 'N') {
    // console.log(' ', value)
    let numero = parseFloat(value).toFixed(2).split('.')
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join('.')
    return numero.join(',')
  }

  // Convertendo número padrão brasileiro (123.456,00) para americano  (123456.00)
  if (type === 'NR') {
    let numero = value.split(',')
    numero[0] = numero[0].replace('.', '')
    let numeroJoin = numero.join('.')
    return parseFloat(numeroJoin)
  }

  if (type === 'AM') {
    return value.substring(4, 7) + '/' + value.substring(0, 4)
  }

  // Formata para YYYY-MM-DD hh:mm:ss
  if (type === 'DTSQL') {
    return `${value.getFullYear()}-${t(value.getMonth() + 1)}-${t(
      value.getDate()
    )} ${t(value.getHours())}:${t(value.getMinutes())}:${t(value.getSeconds())}`
  }

  // Converte String para Nunerico
  if (type === 'CR') {
    return parseFloat(
      value.replace('R$ ', '').replace('.', '').replace(',', '.')
    )
  }

  return value
}

export default format
