<template>
  <div>
    <center>
      <b>Minha Rota Admin Panel</b><br />
      <i
        >www.minharota.com.br<br />
        contato@minharota.com.br</i
      >
    </center>
    <br /><br />

    <h2>Sessão</h2>
    <el-table
      :data="tableData3"
      style="width: 95%; margin-bottom: 40px;"
      size="mini"
      :show-header="false"
    >
      <el-table-column prop="field" label="Conf" width="120"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <h2>Status</h2>
    <el-table
      :data="tableData2"
      style="width: 95%; margin-bottom: 40px;"
      size="mini"
      :show-header="false"
    >
      <el-table-column prop="field" label="Conf" width="100"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <h2>Build</h2>
    <el-table
      :data="tableData1"
      style="width: 95%; margin-bottom: 40px;"
      size="mini"
      :show-header="false"
    >
      <el-table-column prop="field" label="Conf" width="100"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <h2>Conexão</h2>
    <el-table
      :data="tableData4"
      style="width: 95%; margin-bottom: 40px;"
      size="mini"
      :show-header="false"
    >
      <el-table-column prop="field" label="Conf" width="100"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <el-button @click="buttonclick1('Teste 1')">1</el-button>
    <el-button @click="buttonclick2('Teste 2')">2</el-button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import env from '@/config/environment'

export default {
  name: 'About',
  data() {
    return {
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
    }
  },
  computed: {
    ...mapState('Shared', [
      'onlineState',
      'isSyncing',
      'isSyncingPending',
      'isGPSError',
    ]),
    ...mapState('User', ['profile']),
    ...mapGetters('Shared', ['isConnectionFast']),
  },
  mounted() {
    this.tableData1 = [
      { field: 'Versão', value: env.build.version },
      { field: 'Build Date', value: env.build.buildDate },
      { field: 'Ambiente', value: env.build.nodeEnv },
    ]

    this.tableData2 = [
      { field: 'Online', value: this.onlineState ? 'Sim' : 'Não' },
      { field: 'Wifi', value: this.isConnectionFast ? 'Sim' : 'Não' },
      { field: 'Atualizando', value: this.isSyncing ? 'Sim' : 'Não' },
      { field: 'Fila de envio', value: this.isSyncingPending },
      { field: 'Falha no GPS', value: this.isGPSError ? 'Sim' : 'Não' },
    ]

    this.tableData3 = [
      { field: 'User', value: this.profile ? this.profile.name : '---' },
    ]

    this.tableData4 = [
      {
        field: 'param1',
        value: `...${env.firebase.apiKey.substr(
          env.firebase.apiKey.length - 4
        )}`,
      },
      { field: 'param2', value: env.firebase.authDomain.split('.')[0] },
      {
        field: 'param3',
        value: env.firebase.databaseUrl.split('//')[1].split('.')[0],
      },
      { field: 'param4', value: env.firebase.projectId },
      {
        field: 'param5',
        value: `...${env.firebase.messagingSenderId.substr(
          env.firebase.messagingSenderId.length - 4
        )}`,
      },
      { field: 'param6', value: env.firebase.storageBucket.split('.')[0] },
      {
        field: 'param7',
        value: env.firebase.workBucket.split('//')[1].split('.')[0],
      },
    ]
  },
  methods: {
    ...mapMutations('Shared', ['setError']),
    buttonclick1(msg) {
      this.setError({ err: new Error(msg), that: this })
    },
    buttonclick2(msg) {
      // console.log(this.tableData1.naoexiste.subobjeto)
    },
  },
}
</script>
