<template>
  <el-dialog
    :title="`Alterar senha de ${form.login.split('@')[0]}`"
    :visible.sync="showThisDialog"
    center
    :before-close="onClose"
  >
    <el-form :model="form" :rules="rules" ref="resetPasswordForm">
      <el-form-item label="Senha" prop="password">
        <el-input type="password" v-model="form.password" />
        <div class="form-input-extra-option form-input-extra-option-left">
          Mínimo de 6 digitos
        </div>
      </el-form-item>

      <el-form-item label="Confirmar senha" prop="passwordconfirm">
        <el-input type="password" v-model="form.passwordconfirm" />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">Salvar</el-button>
      <el-button @click="onClose">Cancelar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import validatePasss from '@/helpers/validatePassword'

export default {
  name: 'user-form-reset-password',
  props: {},
  data() {
    return {
      showThisDialog: false,
      form: {
        uid: '',
        login: '',
        password: '',
        confirmpassword: '',
      },
      rules: {
        password: [
          { required: true, message: 'Campo obrigatório' },
          { validator: this.validatePass, trigger: ['blur', 'change'] },
        ],
        passwordconfirm: [
          { required: true, message: 'Campo obrigatório, confirme a senha' },
          { validator: this.confirmPass, trigger: ['blur', 'change'] },
        ],
      },
    }
  },
  computed: {},
  methods: {
    ...mapActions('User', ['updateUserPassword']),
    submitForm() {
      this.$refs['resetPasswordForm'].validate((valid) => {
        if (valid) {
          this.updateUserPassword(this.form)
          this.onClose()
        }
      })
    },
    onClose() {
      // Run when the dialods close
      this.resetForm()
      this.showThisDialog = false
    },
    resetForm() {
      this.form = {
        uid: '',
        login: '',
        password: '',
        confirmpassword: '',
      }
    },
    showDialog(login, uid) {
      this.form.login = login
      this.form.uid = uid
      this.showThisDialog = true
    },
    validatePass(rule, value, callback) {
      validatePasss.validatePass(
        rule,
        value,
        callback,
        this.form.passwordconfirm,
        'resetPasswordForm',
        'passwordconfirm',
        this
      )
    },
    confirmPass(rule, value, callback) {
      validatePasss.confirmPass(rule, value, callback, this.form.password)
    },
  },
}
</script>
