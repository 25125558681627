export default {
  getCargoStatusLabel: (state) => (cargo_status) => {
    // Retorna nome ou o próprio valor do cargo_status
    let labelState = state.statusLabels.find((item) => item.content === cargo_status) // retorna objeto ou undefined
    let label = labelState
      ? labelState.value
      : cargo_status
    return label
  },
  getCargoDeliveryLabels: (state) => (cargo_status) => {
    // Retorna nome ou o próprio valor do cargo_status
    let labelState = state.deliveryLabels.find((item) => item.content === cargo_status) // retorna objeto ou undefined
    let label = labelState
      ? labelState.value
      : cargo_status
    return label
  },
}
