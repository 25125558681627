import Vue from 'vue'
import App from './App'
import router from './router'

// Duas versões diferentes de tratar o state utilizando o indexDB
import { store, initializeStore, populateStatesFromIndexDB } from './store'
// import store from './store'

import { sync } from 'vuex-router-sync'
import { fbapp } from '@/config/firebase'

// import './registerServiceWorker'

// https://fontawesome.com/icons/
// Ver em cargo/index exemplos
import 'vue-awesome/icons/microphone'
import 'vue-awesome/icons/wifi'
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/camera'
import 'vue-awesome/icons/truck-moving'
import 'vue-awesome/icons/exclamation-triangle'
import 'vue-awesome/icons/id-card'
import 'vue-awesome/icons/route'
import 'vue-awesome/icons/building'
import 'vue-awesome/icons/filter'
import Icon from 'vue-awesome/components/Icon'

import './plugins/element.js'
import env from '@/config/environment'
import trimCleanConcat from '@/helpers/trimCleanConcatStrings.js'
import { uaParser, uaParserInstall } from '@/helpers/uaParser.js'
import logging from '@/services/logging.js'
import ImageUploader from 'vue-image-upload-resize'
import { installAxios } from '@/services/axiosHttp'
import { elasticInstall } from '@/services/elastic'
import moment from 'moment'
import VueMoment from 'vue-moment'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import notifyErrors from '@/services/notifyErrors'

import * as VueGoogleMaps from 'vue2-google-maps'

moment.locale('pt-br')

Vue.component('icon', Icon)

Vue.use(installAxios)
Vue.use(elasticInstall)
Vue.use(VueMoment, moment)
Vue.use(trimCleanConcat)
Vue.use(uaParserInstall)
Vue.use(logging)
Vue.use(ImageUploader)
Vue.use(VueGoogleMaps, {
  load: {
    region: 'BR',
    language: 'pt-br',
    key: env.google.gmapsapikey,
    libraries: 'places,drawing,visualization',
  },
})
Vue.use(VueLodash, { lodash: lodash })

Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.config.devtools = !(process.env.NODE_ENV === 'production')
Vue.config.debug = !(process.env.NODE_ENV === 'production')
Vue.config.silent = process.env.NODE_ENV === 'production'
console.log(`Env: ${process.env.NODE_ENV}`)

// ERROR HANDLER
// Vue possui um hook para erros próprios que cobrem praticamente todos os erros não esperados do Vue
// window.onerror é nativo do javascript, mas nesse caso só funciona fora do app vue
// window.onerror = function (message, source, line, column, error) {
//   console.error('ONE ERROR HANDLER TO RULE THEM ALL:', message)
// }
// Funcoes recebe (err, vm, info) objeto de erro, vm/instˆncia do app, info de texto qualquer
if (env.build.nodeEnv === 'production' || env.build.nodeEnv === 'staging') {
  Vue.config.errorHandler = notifyErrors.error
  Vue.config.warnHandler = notifyErrors.warn
}

/* eslint-disable no-new */
let app = null

// Versão do vuex persistent com auxilio do link sem plugin de terceiros
// https://rauvetech.com/blog/offline-persistence-vuex-state-with-localforage

async function initializeApp() {
  await populateStatesFromIndexDB()
  await initializeStore()
  await sync(store, router)

  // Save to state the device Info
  store.commit('Shared/setDeviceInfo', uaParser(), { root: true })

  // Wait firebase auth be fully initialized and start a observer fired every time auth changed
  // fbapp.auth().currentUser will be null when auth is not fully initialized and some router validation fails
  fbapp.auth().onAuthStateChanged((user) => {
    if (!app) {
      // console.log('Firebase auth() success initialized... starting App')
      app = new Vue({
        router,
        store,
        render: (h) => h(App),
      }).$mount('#app')
    } else {
      // console.log('Firebase auth() changed...')
    }
  })
}

initializeApp()

// Versão do vuex persistent com plugin
// https://github.com/championswimmer/vuex-persist
// https://github.com/championswimmer/vuex-persist/issues/15

// store._vm.$root.$on('storageReady', () => {
//   // self.$store.dispatch('somethingThatDependsOnTheStateBeingFilled')
//   console.log('storageReady ------------ >>>>>>>>')

//   // Save to state the device Info
//   store.commit('Shared/setDeviceInfo', uaParser(), { root: true })

//   sync(store, router)

//   // Wait firebase auth be fully initialized and start a observer fired every time auth changed
//   // fbapp.auth().currentUser will be null when auth is not fully initialized and some router validation fails
//   fbapp.auth().onAuthStateChanged(user => {
//     if (!app) {
//       console.log('Firebase auth() success initialized... starting App')
//       app = new Vue({
//         router,
//         store,
//         render: h => h(App)
//       }).$mount('#app')
//     } else {
//       console.log('Firebase auth() changed...')
//     }
//   })
// })
