<template>
  <div class="mountCargo">
    <global-title-with-filter-bar
      :title="
        editCargo ? `Editar carga ${editCargo.cargo_id}` : $route.meta.title
      "
      hidedate
      :buttons="dynamicButtons"
    >
      <!-- Component Slot -->
      <invoices-filter-bar
        :filteredInvoices.sync="filteredInvoices"
        :readerMode="readerMode"
        @onSearchStringEnter="onSearchStringEnter"
        @onFetchNFeKey="fetchNFeKey"
        ref="invoicesFilterBar"
      />
    </global-title-with-filter-bar>

    <el-row
      :gutter="20"
      style="margin-left: 0px; margin-right: 0px;"
      type="flex"
      justify="center"
    >
      <el-col v-if="!readerMode" :xs="24" :sm="readerMode ? 11 : 11">
        <DocumentsVue
          :invoices="documentsWithoutCargo"
          :editCargo="editCargo"
        />
      </el-col>

      <el-col :xs="24" :sm="13">
        <vehicles-vue
          :AllVehicles="allTrucks"
          :editCargo="editCargo"
          :readerMode="readerMode"
          @setItems="setItems"
          ref="vehiclesVue"
        />
      </el-col>
    </el-row>

    <GetAddress
      :openGetAddress="openGetAddress"
      :docAddressModal="docAddressModal"
      @onCloseModal="closeAddressModal"
    />
    <!-- Dialog de confirmação de criação de carga -->
    <cargo-http-response ref="statusDialog" />
  </div>
</template>

<script>
import DocumentsVue from './Documents.vue'
import VehiclesVue from './Vehicles.vue'
import GetAddress from './GetAddress.vue'
import globalTitleWithFilterBar from '@/components/Menu/globalTitleWithFilterBar'
import invoicesFilterBar from '@/components/Menu/invoicesFilterBar'
import cargoHttpResponse from '../components/cargoHttpResponseDialog'

import dbFilter from '@/helpers/firestoreQueryFilter'

// eslint-disable-next-line
import { sendCargos } from '@/services/axiosHttp'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'Events',
  components: {
    DocumentsVue,
    VehiclesVue,
    GetAddress,
    globalTitleWithFilterBar,
    invoicesFilterBar,
    cargoHttpResponse,
  },
  data() {
    return {
      filteredInvoices: [],
      filtersFromState: {},
      invoices: null,
      invoicesInCargo: {},
      invoicesInCargoIds: [],
      loadedVehicles: [],
      openGetAddress: false,
      docAddressModal: {},
    }
  },
  computed: {
    ...mapState('Trucks', ['allTrucks']),
    ...mapState('Cargos', ['allCargos']),
    ...mapState('Invoices', ['allInvoices', 'filters']),
    ...mapState('User', ['companie', 'profile']),
    ...mapGetters('Invoices', ['gettersInvoicesFiltered']),
    editCargo() {
      if (this.$route.params.cargoId) {
        return {
          cargo_id: this.$route.params.cargoId,
          branch_id: this.$route.params.branchId,
        }
      } else {
        return null
      }
    },
    readerMode() {
      return this.$route.path === '/cargos/mount/reader'
    },
    dynamicButtons() {
      let buttons = []
      let that = this
      buttons.push({ text: 'Voltar', click: () => that.$router.go(-1) })

      if (!this.readerMode)
        buttons.push({
          text: 'Com leitor',
          click: () => that.changeToReaderMode(),
        })

      buttons.push({
        text: 'Confirmar',
        click: () => that.confirm(),
        type: 'success',
      })

      return buttons
    },
    documentsWithoutCargo() {
      // Retorna lista de documentos filtrados não montados em algum veículo
      return this.filteredInvoices.filter((invoice) => {
        let invoiceTempID =
          `${invoice.branch_id}` +
          `${invoice.invoice.split('/')[0]}` +
          `${invoice.order_number}`

        return !this.invoicesInCargoIds.includes(invoiceTempID)
      })
    },
    mandatoryStockist() {
      return this._.get(
            this,
            'companie.settings.general.mandatoryStockist',
            true
          )
    }
  },
  watch: {},
  created() {
    // Se editando carga, verifica se carga pode ser editada
    if (this.editCargo) {
      let cargo = this.allCargos.find((cargo) => {
        return (
          cargo.cargo_id === this.editCargo.cargo_id &&
          cargo.branch_id === this.editCargo.branch_id
        )
      })

      // Carga nao localizada
      if (!cargo) {
        this.setError(`Carga ${this.editCargo.cargo_id} não encontrada`)
        this.$router.go(-1)

        // Status não permitidos
      } else if (cargo.cargo_status !== 'B' && cargo.cargo_status !== 'A') {
        this.setError(`Status da carga ${cargo.cargo_id} não permite alteração`)
        this.$router.go(-1)

        // OK para seguir
      } else {
        console.log(`Editando carga ${cargo.branch_id}/${cargo.cargo_id}`)
      }
    }
  },
  async mounted() {
    // console.log('Mount cargo mounted')
    if (!this.readerMode) {
      await this.getAndMonitorInvoices({
        useDateFilters: false,
        filterStatus: 'A',
        force: true,
        onlyWithAddress: true,
      })
    }
  },
  methods: {
    ...mapActions('Invoices', [
      'getAndMonitorInvoices',
      'doCargoInvoicesAvailable',
      'buildCargosPOSTBody',
      'getInvoiceByNFeKey',
    ]),
    ...mapMutations('Shared', [
      'setError',
      'setSuccessMessage',
      'setInfoMessage',
      'setLoadingMessage',
    ]),
    // http://www4.fazenda.rj.gov.br/consultaNFCe/QRCode?p=33200313594751000397550000001535631100053547|2|1|3|838841e4a05e9d2a5a60e663e406192ae39c5e71
    // 33200313594751000397550000001535721100263060
    // 33200313594751000397550000001535101100004178
    // 33200313594751000397652200000823081000799834 - PADRAO
    async fetchNFeKey(nfeKey) {
      const invoiceGroupRef = this.$refs['vehiclesVue'].$refs['TrucksInvoices']
        .$refs['invoiceGroup']
      if (!invoiceGroupRef || invoiceGroupRef === 'undefined') {
        this.setInfoMessage('Informe um veículo.')
        return
      }
      let doc = await this.getInvoiceByNFeKey(nfeKey)
      Object.keys(this.invoicesInCargo).forEach((i) => {
        if (
          doc &&
          doc.nfe_key === this.invoicesInCargo[i].invoice.data.nfe_key
        ) {
          doc = null
        }
      })
      if (doc) {
        if (doc.delivery_date.trim().length <= 0) {
          doc.delivery_date = this.$moment().format('YYYYMMDD')
        }
        if (doc.address1.trim().length <= 0) {
          this.getAddress(doc)
        } else {
          invoiceGroupRef[0].onDrop({
            removedIndex: null,
            addedIndex: 0,
            payload: { data: doc },
          })
        }
      }
    },

    getAddress(doc) {
      this.openGetAddress = true
      this.docAddressModal = doc
    },

    closeAddressModal(form) {
      const invoiceGroupRef = this.$refs['vehiclesVue'].$refs['TrucksInvoices']
        .$refs['invoiceGroup']
      this.openGetAddress = false
      document.getElementById('searchNfeKey').focus()
      if (form) {
        this.docAddressModal = { ...this.docAddressModal, ...form }
        invoiceGroupRef[0].onDrop({
          removedIndex: null,
          addedIndex: 0,
          payload: { data: this.docAddressModal },
        })
      }
      this.docAddressModal = {}
    },

    // Ações durante a troca para readerMde
    changeToReaderMode() {
      this.$refs.vehiclesVue.leaveOnlyOneVehicles()
      this.$router.push({ name: 'CargosMountWithReader' })
    },

    // Essa função é chamada de dentro do InvoiceGroup.vue
    setItems(trucks) {
      this.loadedVehicles = trucks
      this.invoicesInCargoIds = []
      this.invoicesInCargo = {}
      if (trucks && trucks.length > 0) {
        trucks.forEach((truck) => {
          if (truck.invoices && truck.invoices.length > 0) {
            truck.invoices.forEach((invoice) => {
              let invoiceTempID =
                `${invoice.data.branch_id}` +
                `${invoice.data.invoice.split('/')[0]}` +
                `${invoice.data.order_number}`

              // Ativa os quantitativso na movimentação de cargas
              let indexId = this.invoicesInCargoIds.indexOf(invoiceTempID)
              if (indexId < 0) {
                this.invoicesInCargo[invoiceTempID] = { truck, invoice }
                this.invoicesInCargoIds.push(invoiceTempID)
              }
            })
          }
        })
      }
    },

    // Confirmar e criar/atualizar carga
    async confirm() {
      // Validação se existe carga para montar, com 1 veiculo e 1 nota
      let validTrucks = this.loadedVehicles.filter(
        (lV) => lV.hasOwnProperty('invoices') && lV.invoices.length > 0
      )
      if (validTrucks.length === 0) {
        this.setError(`Monte pelo menos 01 documento em 01 veículo`)
        return
      }

      // Validação se conferente esta preenchido
      // Somente dos veículos com pelo menos 1 nota adicionada
      let noChecker = this.loadedVehicles
        .filter((lV) => lV.invoices.length >= 1)
        .filter((lV) => !lV.checker || lV.checker === '')

      if (noChecker.length >= 1 && this.mandatoryStockist) {
        this.setError(
          `Conferente não pode ser vazio: ${noChecker
            .map((v) => v.truck_id)
            .join(', ')}`
        )
        return
      }

      // Preparo o titulo da pergunta de confirmação
      // Diferente entre nova carga e editar carga
      let question = ''
      let describe = ''
      if (!this.editCargo) {
        question = 'Montar nova carga(s)?'
        describe =
          `Deseja montar ${validTrucks.length} carga(s)` +
          ` para o(s) veiculo(s)` +
          ` ${validTrucks.map((v) => v.truck_id).join(', ')} ?`
      } else {
        question = `Editar carga ${this.editCargo.cargo_id}?`
        describe =
          `Deseja editar carga ${this.editCargo.cargo_id}` +
          ` com o veiculo` +
          ` ${validTrucks.map((v) => v.truck_id).join(', ')} ?`
      }

      // Confirmação para criar/editar carga
      return this.$confirm(describe, question, {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning',
      })
        .then(async () => {
          this.setLoadingMessage('Montando nova(s) carga(s)')

          let postBody = await this.buildCargosPOSTBody({
            loadedVehicles: this.loadedVehicles,
            editCargo: this.editCargo,
          })
          console.log(postBody)
          let httpresponse = await this.$$http.postCargos(postBody)

          this.setLoadingMessage('')
          this.$refs.statusDialog.show(httpresponse, this.editCargo)
          this.$refs.vehiclesVue.clearVehicles()
          this.loadedVehicles = []
          this.invoicesInCargo = {}
          this.$refs.vehiclesVue.autoFillTrucks()
          // this.setSuccess('Sucesso na montagem das cargas')
          // this.setError('Ocorreu algum erro na montagem da carga.')
        })
        .catch((e) => {})
    },

    // Metodo disparado de entro do invoiceFilterBar ao pressionar enter no campo de pesquisa livre
    onSearchStringEnter(value) {
      // prettier-ignore
      let invoiceGroupRef = this.$refs['vehiclesVue'].$refs['TrucksInvoices'].$refs['invoiceGroup']
      let trucksToMount = invoiceGroupRef ? invoiceGroupRef.length : 0

      if (!trucksToMount)
        return this.setError('Selecione pelo menos um veículo')

      setTimeout(() => {
        if (this.documentsWithoutCargo.length === 1) {
          let invoice = this.documentsWithoutCargo[0]
          invoiceGroupRef[0].onDrop({
            removedIndex: null,
            addedIndex: 0,
            payload: { data: invoice },
          })
          this.$refs['invoicesFilterBar'].clearSearchString()
        } else if (this.documentsWithoutCargo.length > 1) {
          this.setInfoMessage(
            'Mais de um documento localizado, escolha e adicione o documento desejado manualmente.'
          )
        } else {
          this.setError('Documento não encontrado')
        }
      }, 600)
    },
  },
  beforeDestroy() {
    dbFilter.queryUnsubscribe('Invoices/changeAllInvoices')
  },
}
</script>

<style>
.mountCargo .invoice-filter-bar {
    margin-top: 5px;
  }
.mountCargo .el-row {
    margin-bottom: 2px;
  }
.mountCargo .el-col {
    border-radius: 4px;
  }
.mountCargo .bg-purple-dark {
    background: #99a9bf;
  }
.mountCargo .bg-purple {
    background: #d3dce6;
  }
.mountCargo .bg-purple-light {
    background: #e5e9f2;
  }
.mountCargo .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
.mountCargo .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
.mountCargo .el-card__body {
    overflow-y: auto;
    max-height: 60vh;
  }
</style>
