<template>
  <user-form />
</template>

<script>
import Form from '@/components/Admin/Users/components/form'

export default {
  name: 'UsersAdd',
  components: {
    UserForm: Form,
  },
}
</script>
