export default {
  // retorna lista de bairros de todas as cargas em memória
  getNeighborhoodsFromCargos(state, getters) {
    return getters.getAllCargosFiltered
      .map((cargo) => cargo.customers || cargo.costomers)
      .reduce((inLineCustomers, arrOfcustomers) => {
        // Transforma/Flatten um grande array de objetos array like em um único array com todos os objetos no mesmo nível
        // Todos os blocos de customers de cada carga, nivelados no mesmo nível
        return inLineCustomers.concat(Object.values(arrOfcustomers))
      }, [])
      .reduce((results, customer) => {
        if (
          customer.neighborhood !== '' &&
          results.indexOf(customer.neighborhood) < 0
        )
          results.push(customer.neighborhood)
        return results
      }, [])
      .sort()
  },

  // retorna lista de cidades de todas as cargas em memória
  getCitiesFromCargos(state, getters) {
    return getters.getAllCargosFiltered
      .map((cargo) => cargo.customers || cargo.costomers)
      .reduce((inLineCustomers, arrOfcustomers) => {
        // Transforma/Flatten um grande array de objetos array like em um único array com todos os objetos no mesmo nível
        // Todos os blocos de customers de cada carga, nivelados no mesmo nível
        return inLineCustomers.concat(Object.values(arrOfcustomers))
      }, [])
      .reduce((results, customer) => {
        if (customer.city !== '' && results.indexOf(customer.city) < 0)
          results.push(customer.city)
        return results
      }, [])
      .sort()
  },

  // retorna lista de estados de todas as cargas em memória
  getStatesFromCargos(state, getters) {
    return getters.getAllCargosFiltered
      .map((cargo) => cargo.customers || cargo.costomers)
      .reduce((inLineCustomers, arrOfcustomers) => {
        // Transforma/Flatten um grande array de objetos array like em um único array com todos os objetos no mesmo nível
        // Todos os blocos de customers de cada carga, nivelados no mesmo nível
        return inLineCustomers.concat(Object.values(arrOfcustomers))
      }, [])
      .reduce((results, customer) => {
        if (
          customer.state !== '' &&
          results.indexOf(customer.state.toUpperCase()) < 0
        )
          results.push(customer.state.toUpperCase())
        return results
      }, [])
      .sort()
  },

  // retorna lista de rotas de todas as cargas em memória
  getRoutesFromCargos(state, getters) {
    return getters.getAllCargosFiltered
      .map((cargo) => cargo.customers || cargo.costomers)
      .reduce((inLineCustomers, arrOfcustomers) => {
        return inLineCustomers.concat(Object.values(arrOfcustomers))
      }, [])
      .reduce((results, customer) => {
        if (
          customer.route &&
          customer.route !== '' &&
          results.indexOf(customer.route) < 0
        )
          results.push(customer.route)
        return results
      }, [])
      .sort()
  },

  // retorna lista de zonas de todas as cargas em memória
  getZonesFromCargos(state, getters) {
    return getters.getAllCargosFiltered
      .map((cargo) => cargo.customers || cargo.costomers)
      .reduce((inLineCustomers, arrOfcustomers) => {
        return inLineCustomers.concat(Object.values(arrOfcustomers))
      }, [])
      .reduce((results, customer) => {
        if (
          customer.zone &&
          customer.zone !== '' &&
          results.indexOf(customer.zone) < 0
        )
          results.push(customer.zone)
        return results
      }, [])
      .sort()
  },

  // retorna lista de setores de todas as cargas em memória
  getSectorsFromCargos(state, getters) {
    return getters.getAllCargosFiltered
      .map((cargo) => cargo.customers || cargo.costomers)
      .reduce((inLineCustomers, arrOfcustomers) => {
        return inLineCustomers.concat(Object.values(arrOfcustomers))
      }, [])
      .reduce((results, customer) => {
        if (
          customer.sector &&
          customer.sector !== '' &&
          results.indexOf(customer.sector) < 0
        )
          results.push(customer.sector)
        return results
      }, [])
      .sort()
  },
}
