<template>
  <div>
    <ul class="menu-header">
      <li><span class="h2">Dados cadastrais</span></li>
    </ul>
    <el-form :model="form" style="max-width: 70%;">
      <switch-item
        :input.sync="form.companie.name"
        label="Razão Social"
        disabled
      />
      <switch-item :input.sync="form.companie.cnpj" label="CNPJ" disabled />
      <switch-item
        :input.sync="form.companie.dnsname"
        label="DNS Name"
        disabled
      />

      <el-form-item label="Logo">
        <br />
        <center>
          <img :src="form.companie.logo_url" />
        </center>
      </el-form-item>
    </el-form>
    <div class="actions-buttons actions-buttons">
      <el-button type="success" disabled>Salvar</el-button>
    </div>
  </div>
</template>

<script>
import switchItem from '../components/formSwitchItem'

export default {
  name: 'tabCompanieFormGeneral',
  components: { switchItem },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    companie: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        profile: {},
        companie: {},
      },
    }
  },
  computed: {},
  created() {
    this.form.companie = { ...this.companie }
    this.form.profile = { ...this.profile }
  },
}
</script>
