<template>
  <el-row :gutter="10" class="VehicleInvoice">
    <el-col
      :span="readerMode ? 24 : 12"
      v-for="truck in trucks"
      :style="readerMode ? 'max-width: 100%;' : 'max-width: 50%;'"
      :key="truck.id"
    >
      <el-card
        class="box-card-vehicle-invoice"
        shadow="never"
        :body-style="
          truck.showDetails
            ? { 'background-color': 'rgba(131, 127, 127, 0.692)' }
            : { padding: 0 }
        "
        style="margin-bottom: 10px;"
      >
        <div slot="header">
          <el-row type="flex" class="header-title">
            <div class="first-row">
              <div>{{ `${truck.truck_model}` }}</div>
              <div>{{ `${truck.truck_id}` }}</div>
            </div>

            <el-button-group>
              <el-tooltip content="Sequenciar rota" placement="top">
                <el-button
                  size="mini"
                  type="primary"
                  :disabled="!(truck.invoices && truck.invoices.length > 0)"
                  @click="route(truck)"
                  icon="el-icon-map-location"
                />
              </el-tooltip>

              <el-tooltip
                content="Remover veículo / cancelar carga"
                placement="top"
              >
                <el-button
                  v-if="canRemove"
                  type="danger"
                  icon="el-icon-close"
                  size="mini"
                  @click="remove(truck)"
                />
              </el-tooltip>
            </el-button-group>
          </el-row>

          <el-row :gutter="20" class="header-lines">
            <el-col>
              <span>
                {{
                  `Peso Carga: ${new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                  }).format(truck.weight_cargo || 0)}`
                }}
              </span>
            </el-col>
          </el-row>

          <el-row class="header-lines">
            <el-col>
              <span>
                {{
                  `Valor Carga: ${new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(truck.amount_cargo || 0)}`
                }}
              </span>
            </el-col>
          </el-row>

          <el-row class="header-lines">
            <el-col>
              <span v-if="truck.special_products && truck.special_products > 0">
                {{
                  `Produtos Especiais: ${new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                  }).format(truck.special_products || 0)}`
                }}
              </span>
            </el-col>
          </el-row>

          <el-row class="header-lines">
            <el-col>
              <span>
                {{ `Itens: ${truck.itemsInInvoices || 0}` }}
              </span>
            </el-col>
          </el-row>

          <el-row class="header-lines">
            <el-col>
              <span>
                {{ `Qtde. Docs.: ${truck.quantity_invoices || 0}` }}
              </span>
            </el-col>
          </el-row>

          <el-row class="header-lines">
            <el-col>
              <span>
                {{ `Qtde. Clientes: ${truck.quantity_customers || 0}` }}
              </span>
            </el-col>
          </el-row>

          <el-row
            v-if="truck.distance && truck.duration"
            :gutter="20"
            style="margin-bottom: 0; margin-top: 5px;"
          >
            <el-col :span="24" style="font-size: x-small;">
              <span>
                {{
                  `Distancia: ${new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                  }).format(
                    truck.distance / 1000
                  )} Km - Duração Est.: ${duration(truck.duration)}`
                }}
              </span>
            </el-col>
            <el-col :span="2" style="text-align: right;">
              <el-button
                size="mini"
                type="text"
                :icon="
                  truck.showDetails ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
                @click="showHide(truck)"
              ></el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-button size="mini" type="text" @click="getChecker(truck)">
                {{ `Conferente: ${truck.checker || ''}` }}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <invoice-group
          v-show="truck.showDetails"
          @setItems="setItems"
          :truck="truck"
          ref="invoiceGroup"
        ></invoice-group>
      </el-card>
    </el-col>

    <route
      :dialogRouterVisible="dialogRouterVisible"
      @closeDialog="closeRouterDialog"
      :truckRoute="truckRoute"
    />

    <select-checker
      :openModal="openSelectChecker"
      :truckChecker="truckChecker"
      @closeModal="selectChecker"
    />
  </el-row>
</template>

<script>
import InvoiceGroup from './InvoiceGroup.vue'
import Route from './Route.vue'
import SelectChecker from './SelectChecker'
import '@/assets/demos.css'

export default {
  components: {
    InvoiceGroup,
    Route,
    SelectChecker,
  },
  props: {
    Vehicles: {
      type: Array,
      default: () => [],
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
    readerMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      trucks: this._.cloneDeep(this.Vehicles),
      truckRoute: null,
      truckChecker: null,
      dialogRouterVisible: false,
      openSelectChecker: false,
    }
  },
  watch: {
    Vehicles: function (newVal) {
      this.trucks = newVal
      this.trucks.map((t) => {
        if (!t.hasOwnProperty('showDetails')) {
          t['showDetails'] = true
        }
        return t
      })
      if (this.$refs.invoiceGroup && this.$refs.invoiceGroup.length > 0) {
        this.$refs.invoiceGroup.forEach((i) => {
          i.updateTruck()
        })
      }
    },
  },

  methods: {
    duration(time) {
      return this.$moment().startOf('day').seconds(time).format('H:mm:ss')
    },
    remove(truck) {
      // Pergunt doveromente se contiver invoices
      if (truck.invoices && truck.invoices.length >= 1) {
        this.$confirm('Confirma a exclusão deste veículo?', '', {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          type: 'error',
        }).then(() => {
          this.$emit('changeView', truck, 'DEL')
        })
      } else {
        this.$emit('changeView', truck, 'DEL')
      }
    },
    setItems(items) {
      this.trucks.map((t) => {
        if (t.id === items.truck_id) {
          let weightCargo = 0
          let amountCargo = 0
          let itemsInInvoices = 0
          let specialProducts = 0
          let quantityInvoices = 0
          let quantityCustomers = 0
          let tempInvoices = this._.cloneDeep(items.invoices)
          if (items.invoices && items.invoices.length > 0) {
            weightCargo = items.invoices.reduce(
              (total, item) => total + item.data.weight,
              0
            )

            amountCargo = items.invoices.reduce(
              (total, item) => total + item.data.invoice_total,
              0
            )

            items.invoices.forEach((invoice) => {
              if (
                invoice.data &&
                invoice.data.items &&
                invoice.data.items.length > 0
              ) {
                specialProducts += invoice.data.items.reduce(
                  (totalItem, item) => totalItem + (item.quantity_special || 0),
                  0
                )
                itemsInInvoices += invoice.data.items.reduce(
                  (totalItem, item) => {
                    return totalItem + (item.quantity ? 1 : 0)
                  },
                  0
                )
              } else {
                specialProducts = 0
                itemsInInvoices = 0
              }
            })

            quantityInvoices = items.invoices.length

            quantityCustomers = items.invoices.reduce((results, invoice) => {
              if (results.indexOf(invoice.data.customer_id) < 0) {
                results.push(invoice.data.customer_id)
              }
              return results
            }, []).length

            // Refaz a sequencia
            let sequence = 0
            let lastInvoice = null
            tempInvoices.map((invoice) => {
              if (
                lastInvoice === null ||
                invoice.data.customer_id !== lastInvoice.data.customer_id ||
                invoice.data.address1 !== lastInvoice.data.address1
              ) {
                lastInvoice = invoice
                sequence += 1
              }
              // debugger
              // console.log('this.$store', this.$store)
              invoice.data['sequence'] = sequence
              return invoice
            })
          }
          t['weight_cargo'] = weightCargo
          t['amount_cargo'] = amountCargo
          t['itemsInInvoices'] = itemsInInvoices
          t['special_products'] = specialProducts
          t['quantity_invoices'] = quantityInvoices
          t['quantity_customers'] = quantityCustomers
          t['invoices'] = tempInvoices
          return t
        }
      })
      // console.log('TrucksInvoices - setItems', this.trucks)
      this.$forceUpdate()
      this.$emit('setItems', this.trucks)
    },
    closeRouterDialog(confirm, truck) {
      this.dialogRouterVisible = false
      this.truckRoute = null
      if (confirm) {
        this.trucks.map((v) => {
          if (v.id === truck.id) {
            return truck
          }
          return v
        })
        this.$emit('changeView', truck, 'UPD')
      }
    },
    route(truck) {
      // console.log(truck)
      this.truckRoute = Object.assign({}, truck)
      this.dialogRouterVisible = true
    },
    showHide(truck) {
      truck.showDetails = !truck.showDetails
      this.$forceUpdate()
    },
    getChecker(truck) {
      this.truckChecker = truck
      this.openSelectChecker = true
    },
    selectChecker(truck, checker) {
      this.openSelectChecker = false
      truck['checker'] = checker
      this.trucks.map((v) => {
        if (v.id === truck.id) {
          return truck
        }
        return v
      })
      this.$emit('changeView', truck, 'UPD')
    },
  },
}
</script>

<style>
.box-card-vehicle-invoice .el-card__header {
  padding: 5px;
}
 .box-card-vehicle-invoice .header-title {
  margin-bottom: 0;
  justify-content: space-between;
  align-items: center;
}
 .box-card-vehicle-invoice .header-title .first-row {
  font-size: x-small;
  flex-direction: column;
  font-weight: bold;
}
 .box-card-vehicle-invoice .header-lines {
  margin-bottom: 0;
  font-size: x-small;
}
 
</style>
