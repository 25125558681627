<template>
  <div>
    <h1>Registro de logs</h1>

    <!-- Menu de opções de filtros -->
    <filter-bar
      @searchClick="getLogs"
      @refreshClick="startListen"
      @stopClick="stopListen"
    />

    <el-table :data="formatedData" style="width: 100%;">
      <el-table-column sortable prop="data" width="160" label="Data" />
      <el-table-column sortable prop="login" width="160" label="Login" />
      <el-table-column sortable prop="zone" width="120" label="Área" />
      <el-table-column sortable prop="operation" width="120" label="Operação" />
      <el-table-column sortable prop="msg" label="Mensagem" />
      <el-table-column sortable prop="queue" label="Fila" width="70" />
      <el-table-column sortable label="Localização" width="200" align="center"
        ><template slot-scope="scope"
          >{{ `${scope.row.locationLat}`.substring(0, 6) }}
          {{ `${scope.row.locationLong}`.substring(0, 6) }}</template
        ></el-table-column
      >
    </el-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import t from 'typy'
import filterBar from '@/components/Menu/logsFilterBar'

export default {
  name: 'logs',
  components: {
    filterBar,
  },
  data() {
    return {
      listener: null,
    }
  },
  computed: {
    ...mapState('Logs', ['allLogs']),
    formatedData() {
      let dataToReturn = []
      this.allLogs.forEach((log) => {
        let dataObject = {
          data: this.$moment
            .unix(log.created_at.seconds)
            .format('DD/MM/YY HH:mm:ss'),
          login: log.login.split('@')[0],
          zone: log.zone,
          operation: log.operation,
          msg: log.msg,
          queue: t(log, 'extraData.queue_size').safeObject || '--',
          locationLat: t(log, 'location.latitude').safeObject || '--',
          locationLong: t(log, 'location.longitude').safeObject || '--',
        }
        dataToReturn.push(dataObject)
      })
      return dataToReturn
    },
  },
  async mounted() {
    await this.getLogs().then((logs) => console.log(logs))
    // this.shiftLogs()
  },
  beforeDestroy() {
    this.stopListen()
  },
  methods: {
    ...mapActions('Logs', ['getLogs', 'listenLogs', 'removeListenLogs']),
    ...mapMutations('Logs', ['resetState', 'shiftLogs']),
    async startListen(options) {
      this.listener = await this.listenLogs(options)
    },
    stopListen() {
      if (this.listener) this.removeListenLogs(this.listener)
    },
  },
}
</script>

<style scoped>
.el-main > div {
    width: 100%;
}

.el-main > div .header {
      width: 100%;
      display: flex;
      position: relative;
      padding-top: 10px;
}

.el-main > div .header h1 {
  width: 100%;
  padding-top: 5px;
}

.el-main > div .header .menu {
  position: absolute;
  right: 0;
}
</style>
