import { uaParser } from '@/helpers/uaParser.js'
import { store } from '@/store'
import axios from '@/services/axiosHttp'
import lodash from 'lodash'

function buildBodyError(err, vm, info, errortype = 'Vue Error') {
  let returnJson = JSON.parse(
    JSON.stringify({
      data: {
        type: errortype,
        error: {
          message: err.message,
          code: err.code,
          type: err.name,
          stack: err.stack,
        },
        info: info,
        user: {
          profile: lodash.get(store, 'state.User.profile', {}),
          companie: lodash.get(store, 'state.User.companie', {}),
          branchsLocked: lodash.get(store, 'state.User.branchsLocked', []),
        },
        states: {
          shared: lodash.get(store, 'state.Shared', {}),
        },
        route: {
          fullPath: lodash.get(store, 'state.route.fullPath', ''),
          meta: lodash.get(store, 'state.route.meta', {}),
          params: lodash.get(store, 'state.route.params', {}),
        },
      },
    })
  )

  returnJson.data.states.shared.deviceinfo = uaParser() // force browser parser
  return JSON.parse(JSON.stringify(returnJson))
}

function buildBodyWarning(msg, vm, trace) {
  let returnJson = JSON.parse(
    JSON.stringify({
      data: {
        type: 'Vue Warning',
        message: msg,
        trace: trace,
        user: {
          profile: lodash.get(store, 'state.User.profile', {}),
          companie: lodash.get(store, 'state.User.companie', {}),
          branchsLocked: lodash.get(store, 'state.User.branchsLocked', []),
        },
        states: {
          shared: lodash.get(store, 'state.Shared', {}),
        },
        route: {
          fullPath: lodash.get(store, 'state.route.fullPath', ''),
          meta: lodash.get(store, 'state.route.meta', {}),
          params: lodash.get(store, 'state.route.params', {}),
        },
      },
    })
  )

  returnJson.data.states.shared.deviceinfo = uaParser() // force browser parser
  return JSON.parse(JSON.stringify(returnJson))
}

function sendViaAxios(vm, data) {
  console.warn(`Posting to error api data details to support team!`)
  axios.logError(data).then((response) => {
    if (response.error) console.error(`Post to error api failed!`)
    else console.warn(`Post to error api finished success!`)
    // console.log(response.data)
  })
}

function handleError(err, vm, info) {
  let files = err.stack
    ? err.stack.match(/(?<=\/)[^/?#)]+(?=[^/]*$)/gim)
    : ['0', '0', '0']
  let lines = err.stack ? err.stack.match(/(:\d+:\d+)/gim) : ['0', '0', '0']

  console.error(
    `Vue Error!!!\n Message: ${err.toString()}\n Info: ${info}\n Type: ${
      err.name
    }\n Error Code: ${err.code}\n Files: ${files[0]}${lines[0]}, ${files[1]}, ${
      files[2]
    } \n Stack: ${err.stack}`
  )
  // Atenção para não enviar o objeto erro aqui, porque coloca a aplicação em loop
  store.commit('Shared/setError', `Error: ${err.message}`)

  if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'staging' ||
    process.env.VUE_APP_ERROR_NOTIFY === 'true'
  ) {
    sendViaAxios(vm, buildBodyError(err, vm, info))
  }
}

function handleWarn(msg, vm, trace) {
  console.warn(`Vue Warning!!!\n Message: ${msg}\n Trace: ${trace}`)
  if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'staging' ||
    process.env.VUE_APP_ERROR_NOTIFY === 'true'
  ) {
    sendViaAxios(vm, buildBodyWarning(msg, vm, trace))
  }
}

const methods = {
  error: handleError,
  warn: handleWarn,
}

export default methods
export { methods as notifyErrors }
