<template>
  <div class="route">
    <el-dialog
      title="Roteirização‎"
      width="95vw"
      :visible.sync="dialogRouterVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      top="10vh"
    >
      <!-- <el-row :gutter="10">
          <el-col :xs="12" :md="3">
            <span>Data/Hora saída:</span>
          </el-col>
          <el-col :xs="12" :md="5" style="overflow: hidden;">
            <el-date-picker
              size="mini"
              format="dd-MM-yyyy HH:mm:ss"
              time-arrow-control
              v-model="dateAndTimeOfDeparture"
              @change="changeDateAndTimeOfDeparture"
              type="datetime">
            </el-date-picker>
          </el-col>
      </el-row>-->
      <el-row :gutter="20">
        <el-col :xs="24" :md="8">
          <el-card
            v-if="truck"
            class="box-card"
            shadow="never"
            :body-style="{ padding: 0 }"
            style="margin-bottom: 10px;"
          >
            <div slot="header">
              <el-row :gutter="20" style="margin-bottom: 0;">
                <el-col :span="15" style="font-size: xx-small;">
                  <span>{{ `${truck.truck_id} - ${truck.truck_model}` }}</span>
                </el-col>
                <el-col :span="9" style="text-align: right;">
                  <el-button size="mini" type="text" @click="router(true)"
                    >Otimizar Rota</el-button
                  >
                </el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 0;">
                <el-col :span="13" style="font-size: x-small;">
                  <span>
                    {{
                      `Peso Carga: ${new Intl.NumberFormat('pt-BR', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                      }).format(truck.weight_cargo || 0)}`
                    }}
                  </span>
                </el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 0;">
                <el-col :span="13" style="font-size: x-small;">
                  <span>
                    {{
                      `Valor Carga: ${new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(truck.amount_cargo || 0)}`
                    }}
                  </span>
                </el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 0;">
                <el-col :span="22" style="font-size: x-small;">
                  <span>
                    {{
                      `Qtde. Docs.: ${
                        truck.quantity_invoices || 0
                      } - Qtde. Clientes: ${truck.quantity_customers || 0}`
                    }}
                  </span>
                </el-col>
              </el-row>
              <el-row
                v-if="truck.distance && truck.duration"
                :gutter="20"
                style="margin-bottom: 0; margin-top: 5px;"
              >
                <el-col :span="24" style="font-size: x-small;">
                  <span>
                    {{
                      `Distancia: ${new Intl.NumberFormat('pt-BR', {
                        style: 'decimal',
                        maximumFractionDigits: 2,
                      }).format(
                        truck.distance / 1000
                      )} Km - Duração Est.: ${duration(truck.duration)}`
                    }}
                  </span>
                </el-col>
              </el-row>
            </div>
            <container group-name="column" @drop="onDrop">
              <draggable v-for="(item, index) in invoices" :key="index">
                <div class="draggable-item">
                  <invoice-collapse
                    :invoice="item.data"
                    :index="index"
                    :showSequence="true"
                  />
                </div>
              </draggable>
            </container>
          </el-card>
        </el-col>
        <el-col :xs="24" :md="16">
          <div id="map"></div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog(false)">Fechar</el-button>
        <el-button @click="closeDialog(true)" type="primary"
          >Confirmar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag } from '@/helpers/drag.js'
import invoiceCollapse from './invoiceCollapse.vue'
import { mapState } from 'vuex'
import routeMaker from '@/services/routeMaker'

export default {
  name: 'Route',
  components: {
    Container,
    Draggable,
    'invoice-collapse': invoiceCollapse,
  },
  props: {
    truckRoute: Object,
    dialogRouterVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      truck: null,
      invoices: null,
      map: null,
      dateAndTimeOfDeparture: new Date(),
      basePoints: null,
      newRouterOrder: [],
      maxWayPoints: 25,
      truckDuration: 0,
      truckDistance: 0,
    }
  },
  computed: {
    ...mapState('User', ['companie']),
    branchAddress() {
      if (!this.truckRoute) {
        return null
      }
      let branchId = this.truckRoute['branch_id']
      let branch = this.companie.branchs.filter(
        (b) => b.branch_id === branchId
      )[0]
      let address = `${branch.address1} - ${branch.neighborhood}, ${branch.city} - ${branch.state}, ${branch.postal_code}`
      let latlng = { lat: branch.latitude, lng: branch.longitude }
      return {
        address,
        latlng,
      }
    },
  },
  watch: {
    truckRoute(newValue) {
      if (newValue) {
        this.truck = newValue
        this.invoices = this.truck.invoices
        this.$nextTick(() => {
          this.router(false)
        })
      }
    },
  },
  methods: {
    duration(time) {
      return this.$moment().startOf('day').seconds(time).format('H:mm:ss')
    },
    onDrop(dropResult) {
      this.invoices = applyDrag(this.invoices, dropResult)
      this.router(false)
    },
    closeDialog(confirm) {
      this.truck.invoices = this.invoices
      this.$emit('closeDialog', confirm, this.truck)
    },
    changeDateAndTimeOfDeparture(value) {
      this.router()
    },
    router(optimize) {
      const elementMapTarget = document.getElementById('map')
      routeMaker(
        this.invoices,
        optimize,
        elementMapTarget,
        this.branchAddress,
        this.map
      )
        .then((param) => {
          const { truckDuration, truckDistance, invoices, map } = param
          this.truckDuration = truckDuration
          this.truckDistance = truckDistance
          this.invoices = invoices
          this.map = map

          this.truck['duration'] = 0
          this.truck['distance'] = 0
          this.$set(this.truck, 'duration', this.truckDuration)
          this.$set(this.truck, 'distance', this.truckDistance)
        })
        .catch((err) => {
          console.error('routeMaker ERROR', err)
        })
    },
  },
}
</script>

<style>
.route .el-dialog {
  max-height: 85vh;
}
 .route .el-col {
  overflow-y: auto;
  max-height: 60vh;
}
 .route #map {
  background-color: lightcyan;
  height: 60vh;
}
 .route .map-icon-label .map-icon {
  font-size: 24px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  white-space: nowrap;
}
</style>
