<!--
    <cargos-filter-bar
      @applyFilterClick="updateDashboard"
      @resetFilterClick="updateDashboard"
      @onUpdate="after lazy functions"
      @onUpdateStart="initUpdateFunctions" />
-->

<template>
  <div class="cargos-filter-bar">
    <el-row :gutter="5" type="flex" justify="center">
      <el-col :xs="24" :sm="5">
        <date-picker
          :dateRange.sync="filtersLocal.datepicker"
          @change="onUpdatedFields"
          cargosValuesUpdate
        />
      </el-col>

      <!-- Filiais -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.branches"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Filial"
        >
          <el-option
            v-for="item in getCompanieBranchesFiltered"
            :key="item.branch_id"
            :label="item.branch_name"
            :value="item.branch_id"
          >
          </el-option>
        </el-select>
      </el-col>

      <!-- Veículos -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.trucks"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Veículo"
        >
          <el-option
            v-for="item in trucksFilteredByBranch"
            :key="item.truck_id"
            :label="`${item.truck_id} (${getCompanieBranchName(
              item.branch_id
            )} | ${item.truck_model})`"
            :value="item.truck_id"
          >
          </el-option>
        </el-select>
      </el-col>

      <!-- Motoristas -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.drivers"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Motorista"
        >
          <el-option
            v-for="(item, index) in driversFilteredByBranch"
            :key="item.login + '-' + index"
            :label="`${item.name} (${getCompanieBranchName(item.branch_id)})`"
            :value="item.auth_uid"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <!-- Linha 2 -->
    <el-row :gutter="5" type="flex" justify="center">
      <!-- Estados -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.states"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Estados"
        >
          <el-option
            v-for="(item, index) in getStatesFromCargos"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Cidades -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.cities"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Cidades"
        >
          <el-option
            v-for="(item, index) in getCitiesFromCargos"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Bairros -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.neighborhoods"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Bairros"
        >
          <el-option
            v-for="(item, index) in getNeighborhoodsFromCargos"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Status -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.status"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Status"
        >
          <el-option
            v-for="item in statusLabels"
            :key="item.content"
            :label="item.value"
            :value="item.content"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <!-- Linha 3 -->
    <el-row :gutter="5" type="flex" justify="center">
      <!-- Zones -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.zones"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Zona"
        >
          <el-option
            v-for="(item, index) in getZonesFromCargos"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Routes -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.routes"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Rotas"
        >
          <el-option
            v-for="(item, index) in getRoutesFromCargos"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Sectors -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.sectors"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Setores"
        >
          <el-option
            v-for="(item, index) in getSectorsFromCargos"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>

      <!-- Conferente -->
      <el-col :xs="24" :sm="5">
        <el-select
          v-model="filtersLocal.checkers"
          size="mini"
          multiple
          filterable
          collapse-tags
          clearable
          @change="onUpdatedFields"
          placeholder="Conferente"
        >
          <el-option
            v-for="(item, index) in checkersFilteredByBranch"
            :key="item.login + '-' + index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <!-- Linha 4 -->
    <el-row :gutter="5" type="flex" justify="center">
      <el-col :xs="24" :sm="5" v-if="$listeners.resetFilterClick">
        <el-button
          size="mini"
          type="info"
          icon="el-icon-remove-outline"
          plain
          @click="resetFilter"
          style="width: 100%;"
          >Reset filtros</el-button
        >
      </el-col>

      <!-- Carga de -->
      <el-col :xs="24" :sm="5">
        <el-input
          v-model="filtersLocal.cargostart"
          size="mini"
          auto-complete="off"
          clearable
          @change="onUpdatedFields"
          placeholder="Numero da carga de"
        ></el-input>
      </el-col>

      <!-- Carga até -->
      <el-col :xs="24" :sm="5">
        <el-input
          v-model="filtersLocal.cargoend"
          size="mini"
          auto-complete="off"
          clearable
          @change="onUpdatedFields"
          placeholder="Numero da carga até"
        ></el-input>
      </el-col>

      <el-col :xs="24" :sm="5" v-if="$listeners.applyFilterClick">
        <el-button
          size="mini"
          type="info"
          icon="el-icon-search"
          plain
          @click="applyFilter"
          style="width: 100%;"
          >Aplicar filtros de exibição</el-button
        >
      </el-col>
    </el-row>

    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="24" class="textoQuantidade">
        <span
          >Exibindo {{ getTotalCargos }} de {{ allCargos.length }} cargas na
          memória</span
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import datePicker from '@/components/Shared/DatePicker'

export default {
  name: 'cargosFilterBar',
  components: {
    datePicker,
  },
  props: {},
  data() {
    // o simples instanciamento dos componentes select acima cria as chaves do tipo array drivers, trucks, etc dentro de filtersLocal
    return {
      firstload: true,
      lazyOnUpdate: null,
      filtersLocal: {},
    }
  },
  computed: {
    ...mapState('Cargos', ['allCargos', 'statusLabels', 'filters']),
    ...mapState('Drivers', ['allDrivers']),
    ...mapState('Checkers', ['allCheckers']),
    ...mapState('Trucks', ['allTrucks']),
    ...mapGetters('User', ['getCompanieBranches', 'getCompanieBranchName']),
    ...mapGetters('Cargos', [
      'getNeighborhoodsFromCargos',
      'getCitiesFromCargos',
      'getStatesFromCargos',
      'getTotalCargos',
      'getRoutesFromCargos',
      'getZonesFromCargos',
      'getSectorsFromCargos',
      'getAllCargosFiltered',
    ]),
    trucksFilteredByBranch() {
      // Exibe apenas a lista de veículos da filial filtrada, ou se nenhuma selecionada, retorna apeans das filiais retornadas na lista de cargas.
      if (this.filtersLocal.branches && this.filtersLocal.branches.length > 0)
        return this.allTrucks.filter((truck) =>
          this.filtersLocal.branches.includes(truck.branch_id)
        )
      else if (
        this.filtersLocal.branches &&
        this.filtersLocal.branches.length === 0
      )
        return this.allTrucks.filter((truck) =>
          this.getAllCargosFiltered.some(
            (cargo) => cargo.truck_id === truck.truck_id
          )
        )
      else return this.allTrucks
    },
    driversFilteredByBranch() {
      if (this.filtersLocal.branches && this.filtersLocal.branches.length > 0)
        return this.allDrivers.filter((driver) =>
          this.filtersLocal.branches.includes(driver.branch_id)
        )
      else if (
        this.filtersLocal.branches &&
        this.filtersLocal.branches.length === 0
      )
        return this.allDrivers.filter((driver) =>
          this.getAllCargosFiltered.some(
            (cargo) => cargo.branch_id === driver.branch_id
          )
        )
      else return this.allDrivers
    },
    checkersFilteredByBranch() {
      if (this.filtersLocal.branches && this.filtersLocal.branches.length > 0)
        return this.allCheckers.filter((checker) =>
          this.filtersLocal.branches.includes(checker.branch_id)
        )
      else if (
        this.filtersLocal.branches &&
        this.filtersLocal.branches.length === 0
      )
        return this.allDrivers.filter((driver) =>
          this.getAllCargosFiltered.some(
            (cargo) => cargo.branch_id === driver.branch_id
          )
        )
      else return this.allCheckers
    },
    getCompanieBranchesFiltered() {
      // Retorna somente as filiais existente na busca de cargos
      // Para voltar a exibir todas as filiais, apenas substitua esta computed propertie pelo getter getCompanieBranches
      return this.getCompanieBranches.filter((branch) =>
        this.getAllCargosFiltered.some(
          (cargo) => cargo.branch_id === branch.branch_id
        )
      )
    },
  },
  mounted() {
    // Atribuindo na var local o valor do state de filtros
    this.filtersLocal = { ...this.filters }

    // Previne que as ações do assign acima sejam executadas assim que componente é carregado sem disparar os eventos de update
    setTimeout(() => {
      this.firstload = false
    }, 500)
  },
  methods: {
    ...mapMutations('Cargos', [
      'setSearchCargosFilter',
      'resetSearchCargosFilter',
    ]),
    onUpdatedFields() {
      this.$emit('onUpdateStart')

      if (!this.firstload) {
        // Simula o lazy load, para disparar o update a cada 1 segundo após a última alteração
        clearTimeout(this.lazyOnUpdate)
        this.lazyOnUpdate = setTimeout(() => {
          // this.setSearchCargosFilter(this._.toPlainObject(this.filtersLocal))
          this.$emit('onUpdate', this._.toPlainObject(this.filtersLocal))
        }, 1000)
      }
    },
    resetFilter() {
      this.$emit('resetFilterClick', this._.toPlainObject(this.filtersLocal))
      this.resetSearchCargosFilter()
      this.filtersLocal = { ...this.filters }
    },
    applyFilter() {
      this.$emit('applyFilterClick', this._.toPlainObject(this.filtersLocal))
      this.setSearchCargosFilter(this._.toPlainObject(this.filtersLocal))
    },
  },
}
</script>

<style>
.cargos-filter-bar .textoQuantidade {
    font-style: italic;
    font-size: 10px;
    text-align: center;
    margin-top: 4px;
  }
</style>
