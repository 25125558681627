const validatePass = (
  rule,
  value,
  callback,
  passwordConfirm,
  formRefName,
  formFieldName,
  that
) => {
  if (value === '') {
    callback(new Error('Por favor digite uma senha com no mínimo 6 digitos'))
  } else if (value.length < 6) {
    callback(new Error('Por favor digite uma senha com no mínimo 6 digitoss'))
  } else if (passwordConfirm !== '') {
    that.$refs[formRefName].validateField(formFieldName)
    callback()
  } else {
    callback()
  }
}

const confirmPass = (rule, value, callback, originalPassword) => {
  if (value === '') callback(new Error('Por favor tente novamente'))
  else if (value !== originalPassword)
    callback(new Error('Senhas digitadas não são iguais'))
  else callback()
}

export default {
  validatePass,
  confirmPass,
}
