<template>
  <div class="drag-and-drop-vehicles-list">
    <h2>
      <el-row type="flex" justify="space-between">
        <el-col :span="19">
          <span>{{ `${readerMode ? 'Veículo' : 'Veículos'}` }}</span>
        </el-col>
        <el-col :span="5">
          <el-button
            v-if="!editCargoMode"
            type="text"
            @click="showDialog = true"
            >{{ addText }}</el-button
          >
        </el-col>
      </el-row>
    </h2>
    <div class="space" />
    <el-card class="box-card" shadow="never">
      <trucks-invoices
        :Vehicles="Vehicles"
        @setItems="setItems"
        @changeView="changeView"
        :readerMode="readerMode"
        :canRemove="!editCargoMode && !readerMode"
        ref="TrucksInvoices"
      />
    </el-card>

    <add-truck-modal-vue
      v-if="showDialog"
      :show-dialog.sync="showDialog"
      :AllVehicles="freeTrucks()"
      :readerMode="readerMode"
      @changeView="changeView"
    />
  </div>
</template>

<script>
import AddTruckModalVue from './AddTruckModal.vue'
import TrucksInvoices from './TrucksInvoices.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Vehicles',
  components: {
    AddTruckModalVue,
    TrucksInvoices,
  },
  props: {
    AllVehicles: {
      type: Array,
      default: () => [],
    },
    editCargo: {
      type: Object,
      default: () => null,
    },
    readerMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      Vehicles: [],
    }
  },
  watch: {},
  computed: {
    ...mapState('Cargos', ['allCargos']),
    ...mapState('Checkers', ['allCheckers']),
    ...mapState('Invoices', ['filters']),
    editCargoMode() {
      return this.editCargo
    },
    addText() {
      if (this.Vehicles.length > 0 && this.readerMode)
        return 'Substituir veículo'
      else return 'Adicionar veículo'
    },
  },
  created() {
    if (this.editCargoMode) {
      this.getInvoicesInCargo({
        branch: this.editCargo.branch_id,
        cargo: this.editCargo.cargo_id,
      }).then((invoices) => {
        // console.log('Edit cargo Invoices received for this cargo', invoices)
        let temp = this._.cloneDeep(invoices)
        this.editCargoFunc(temp)
      })
    }
  },
  mounted() {
    // Se tiver até dois vehiculos adiciona automaticamente para criar carga
    this.autoFillTrucks()
  },
  methods: {
    ...mapActions('Invoices', ['getInvoicesInCargo']),
    freeTrucks() {
      let trucksCopy = this._.cloneDeep(this.AllVehicles)

      // Se houver somente um conferente, amarra ele em todos os veículos
      if (this.allCheckers.length === 1) {
        trucksCopy.map((truck) => {
          truck['checker'] = this.allCheckers[0].name
          return truck
        })
      }

      return trucksCopy
        .filter((t) => {
          return t.branch_id === this.filters.branch
        })
        .filter((t) => {
          return !this.Vehicles.find((v) => {
            return v.id === t.id
          })
        })
    },
    autoFillTrucks() {
      // Se tiver até dois vehiculos adiciona automaticamente para criar carga
      if (!this.editCargoMode && !this.readerMode) {
        let freeTrucks = this.freeTrucks()
        if (freeTrucks.length <= 2 && freeTrucks.length > 0) {
          for (let i = 0; i < freeTrucks.length; i++) {
            this.changeView(Object.assign({}, freeTrucks[i]), 'ADD')
          }
        }
      }
    },
    editCargoFunc(invoices) {
      let cargoEdit = this.allCargos.find(
        (c) => c.id === `${this.editCargo.branch_id}${this.editCargo.cargo_id}`
      )
      let truckEdit = this._.cloneDeep(this.AllVehicles).find(
        (v) => v.id === cargoEdit.truck_id
      )

      invoices = invoices.map((invoice) => {
        return { data: invoice }
      })

      let weightCargo = 0
      let amountCargo = 0
      let itemsInInvoices = 0
      let specialProducts = 0
      let quantityInvoices = 0
      let quantityCustomers = 0
      if (invoices && invoices.length > 0) {
        weightCargo = invoices.reduce(
          (total, item) => total + item.data.weight,
          0
        )
        amountCargo = invoices.reduce(
          (total, item) => total + item.data.invoice_total,
          0
        )
        invoices.forEach((invoice) => {
          specialProducts += invoice.data.items.reduce(
            (totalItem, item) => totalItem + (item.quantity_special || 0),
            0
          )
        })

        invoices.forEach((invoice) => {
          itemsInInvoices += invoice.data.items.reduce((totalItem, item) => {
            return totalItem + (item.quantity ? 1 : 0)
          }, 0)
        })

        quantityInvoices = invoices.length
        quantityCustomers = invoices.reduce((results, invoice) => {
          if (results.indexOf(invoice.data.customer_id) < 0) {
            results.push(invoice.data.customer_id)
          }
          return results
        }, []).length
        invoices = this.makeSequence(invoices)
      }
      // debugger
      // console.log('weight_cargo 1', truckEdit.weight_cargo, weightCargo)
      truckEdit.weight_cargo = weightCargo || 0
      truckEdit.amount_cargo = amountCargo || 0
      truckEdit.itemsInInvoices = itemsInInvoices || 0
      truckEdit.special_products = specialProducts
      truckEdit.quantity_invoices = quantityInvoices
      truckEdit.quantity_customers = quantityCustomers
      truckEdit.showDetails = true
      truckEdit.checker = cargoEdit.checker

      truckEdit.invoices = []
      truckEdit.invoices = invoices
      this.Vehicles = []
      this.Vehicles.push({})
      this.$set(this.Vehicles, 0, truckEdit)
      this.$emit('setItems', this.Vehicles)

      // console.log('Edit Cargo Truck Loaded', this.Vehicles)
    },
    changeView(truck, oper) {
      if (oper === 'ADD') {
        this.Vehicles.push(truck)
      }
      if (oper === 'DEL') {
        this.Vehicles = this.Vehicles.filter((v) => v.id !== truck.id)
        this.$emit('setItems', this.Vehicles)
      }
      if (oper === 'UPD') {
        let index = this.Vehicles.findIndex((v) => {
          return v.id === truck.id
        })
        if (index >= 0) {
          this.$set(this.Vehicles, index, truck)
        }
        this.$emit('setItems', this.Vehicles)
      }
      if (oper === 'REPLACE') {
        this.clearVehicles()
        this.$set(this.Vehicles, 0, truck)
      }
    },
    makeSequence(invoices) {
      let sequence = 0
      let lastInvoice = null
      invoices.map((i) => {
        if (
          lastInvoice === null ||
          i.data.customer_id !== lastInvoice.data.customer_id ||
          i.data.address1 !== lastInvoice.data.address1
        ) {
          lastInvoice = i
          sequence += 1
        }
        i.data['sequence'] = sequence
        return i
      })
      return invoices
    },
    clearVehicles() {
      this.Vehicles = []
    },
    leaveOnlyOneVehicles() {
      if (this.Vehicles.length > 1) {
        let veiculoManter = this.Vehicles[0]
        this.Vehicles = []
        this.Vehicles.push(veiculoManter)
      }
    },
    setItems(items) {
      this.$emit('setItems', items)
    },
    onConfirm() {
      this.$emit('onConfirm')
    },
  },
}
</script>

<style>
.drag-and-drop-vehicles-list .el-row {
    margin-bottom: 0;
  }
.drag-and-drop-vehicles-list .el-button--text {
    padding: 0;
  }
.drag-and-drop-vehicles-list .el-col {
    margin: 0;
  }
.drag-and-drop-vehicles-list h2 {
    margin: 0;
    padding: 0;
  }
.drag-and-drop-vehicles-list .textoQuantidade {
    font-style: italic;
    font-size: 10px;
    text-align: center;
    margin-top: 4px;
  }
.drag-and-drop-vehicles-list .space {
    min-height: 42px;
  }
</style>
