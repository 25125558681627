<template>
  <div>
    <h1>Lista de eventos</h1>

    <!-- Menu de opções de filtros do Dashboard -->
    <filter-bar @refreshClick="getEvents()" />

    <el-table :data="formatedData" style="width: 100%">
      <el-table-column sortable prop="created_at" width="160" label="Data" />
      <el-table-column sortable prop="type" width="130" label="Tipo" />
      <el-table-column sortable prop="truck" width="100" label="Veículo" />
      <el-table-column sortable prop="desc" label="Observação" />
      <el-table-column sortable prop="value" width="120" label="Valor" />
      <el-table-column sortable prop="km" width="120" label="Km" />

      <el-table-column label="Imagens" width="120">
        <template slot-scope="scope">
          <div v-viewer="{ movable: true, navbar: false, title: false }">
            <img
              v-for="src in [scope.row.image]"
              :src="src"
              :key="`${scope.row.id}${src}`"
              class="img"
              width="30%"
              height="30%"
              style="cursor: pointer"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Detalhes" width="150">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-view"
              @click="handleView(scope.row)"
            />
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import format from '../../../helpers/format'
import filterBar from '@/components/Menu/eventsFilterBar'
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from 'v-viewer'

function formatedType(tipo) {
  if (tipo === 'payfuel') return 'Abastecimento'
  else if (tipo === 'paytax') return 'Pedágio'
  else if (tipo === 'general') return 'Geral'
  else return tipo
}

export default {
  name: 'events',
  components: {
    filterBar
  },
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('Events', ['allEvents']),
    formatedData() {
      let dataToReturn = []
      this.allEvents.forEach((event) => {
        let dataObject = {
          type: formatedType(event.event_type),
          truck: event.truck_id,
          desc: event.event.notice,
          created_at: this.$moment
            .unix(event.created_at.seconds)
            .format('DD/MM/YY HH:mm:ss'),
          value: format(event.event.value, 'C'),
          km: format(event.event.kmatual, 'N'),
          image: null,
          images:[]
        }

        if (Array.isArray(event.images_urls)) {
          dataObject.image = Object.values(event.images_urls)[0].url || null

          Object.values(event.images_urls).forEach((image) =>{
            // console.log(`Url das imgagens: ${image.url}`)
            dataObject.images.push(image.url)
          })
        }
        dataToReturn.push(dataObject)
      })
      return dataToReturn
    },
  },
  created() {
    this.getEvents()
  },
  methods: {
    ...mapActions('Events', ['getEvents']),
    handleView(row) {
      this.$router.push({ name: 'EventDetail', params: { eventDetail: row } })
    },
  },
}
</script>
