export default {
  getTrackingResume(state, getters, rootState, rootGetters) {
    return getters.getAllCargosFiltered
      .filter((cargo) => cargo.cargo_status === 'D')
      .map((cargo) => {
        let cargoPositionStart = null
        if (cargo.hasOwnProperty('started_geo') && cargo.started_geo) {
          cargoPositionStart = {
            lat: cargo.started_geo.latitude,
            lng: cargo.started_geo.longitude,
          }
        }

        const customers = cargo.customers
        const totalInvoices = Object.values(customers)
          .filter((customer) => customer.invoices)
          .reduce(
            (partial, customer) =>
              partial +
              Object.values(customer.invoices).reduce(
                (invoiceAmount, invoice) => invoiceAmount + 1,
                0
              ),
            0
          )

        const totalCustomersDone = Object.values(customers)
          .filter((customer) => customer.invoices)
          .reduce((partial, customer) => {
            return (partial +=
              Object.values(customer.invoices).reduce(
                (invoiceAmount, invoice) =>
                  invoiceAmount +
                  (invoice.status || customer.status === 'canceled' ? 1 : 0),
                0
              ) > 0
                ? 1
                : 0)
          }, 0)

        const totalInvoicesDelivered = Object.values(customers)
          .filter((customer) => customer.invoices)
          .reduce(
            (partial, customer) =>
              partial +
              Object.values(customer.invoices).reduce(
                (invoiceAmount, invoice) =>
                  invoiceAmount + (invoice.status === 'delivered' ? 1 : 0),
                0
              ),
            0
          )

        const totalInvoicesPartial = Object.values(customers)
          .filter((customer) => customer.invoices)
          .reduce(
            (partial, customer) =>
              partial +
              Object.values(customer.invoices).reduce(
                (invoiceAmount, invoice) =>
                  invoiceAmount + (invoice.status === 'with_partial' ? 1 : 0),
                0
              ),
            0
          )

        const totalInvoicesCanceled = Object.values(cargo.customers)
          .filter((customer) => customer.invoices)
          .reduce(
            (partial, customer) =>
              partial +
              Object.values(customer.invoices).reduce(
                (invoiceAmount, invoice) =>
                  invoiceAmount +
                  (invoice.status === 'canceled' ||
                  customer.status === 'canceled'
                    ? 1
                    : 0),
                0
              ),
            0
          )

        const truckBranch = rootGetters['Trucks/getTruckBranch'](cargo.truck_id)

        const branchLocation =
          rootGetters['User/getCompanieBranchLocation'](truckBranch)

        const points = Object.values(customers).map((customer) => {
          return {
            customer,
            name: customer.customer,
            deliveredWho: customer.delivered_who || '',
            address: `${customer.address1}, ${customer.neighborhood} - ${customer.city} - ${customer.state} , Brasil`,
            latlng: customer.delivered_geo || customer.latlng,
            deliveredAt: customer.delivered_at,
            sequenceOfDelivery: customer.sequenceofdelivery,
            hasDelivered:
              Object.values(customer.invoices).reduce(
                (invoiceAmount, invoice) =>
                  invoiceAmount + (invoice.status ? 1 : 0),
                0
              ) > 0,
            hasCalcel:
              Object.values(customer.invoices).reduce(
                (invoiceAmount, invoice) =>
                  invoiceAmount + (invoice.status === 'with_partial' ? 1 : 0),
                0
              ) > 0,
            hasPartial:
              Object.values(customer.invoices).reduce(
                (invoiceAmount, invoice) =>
                  invoiceAmount + (invoice.status === 'canceled' ? 1 : 0),
                0
              ) > 0,
          }
        })

        return {
          branchId: cargo.branch_id,
          cargoId: cargo.cargo_id,
          truckId: cargo.truck_id,
          driverName: rootGetters['User/getDriverName'](cargo.started_by),
          totalCustomers: Object.values(customers).length,
          remaininPoints: Object.values(customers).length - totalCustomersDone,

          totalCustomersDone,
          totalInvoices,
          totalInvoicesDelivered,
          totalInvoicesPartial,
          totalInvoicesCanceled,
          points,
          branchLocation,
          cargoPositionStart,
        }
      })
  },
}
