<template>
  <el-dialog
    class="http-response-dialog"
    :visible.sync="visible"
    width="60%"
    :show-close="false"
  >
    <div display="flex">
      <div class="messages">
        <div class="header" v-if="header">{{ header }}</div>
        <div class="results" v-for="(msg, index) in messages" :key="index">
          <b>{{ msg.subtitle }}</b>
          <br />
          <span>{{ msg.message }}</span>
        </div>
      </div>
    </div>

    <span slot="title" class="title-success" v-if="status === 'success'">
      <i class="el-icon-success" />
      Processamento concluído!
    </span>

    <span slot="title" class="title-error" v-if="status === 'error'">
      <i class="el-icon-error" />
      Falha na montagem de uma ou mais cargas
    </span>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="okButton">
        OK
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
// let successData = {
//     ver: 'v1',
//     error: false,
//     date: '2020-04-20 09:20:27 +00:00',
//     msg: '2 carga(s) processadas com sucesso',
//     data: [
//       {
//         cargo_id: 'C00000275',
//         truck_id: 'QPM7990',
//         branch_id: '04',
//         status: 'success',
//         cargo_status: 'B',
//         msgs:
//           'Carga 04C00000275 gravada como bloqueado, veículo QPM7990 possui carga disponível (04C00000269)',
//       },
//       {
//         cargo_id: 'C00000276',
//         truck_id: 'LRO8714',
//         branch_id: '04',
//         status: 'success',
//         cargo_status: 'B',
//         msgs:
//           'Carga 04C00000276 gravada como bloqueado, veículo LRO8714 possui carga disponível (04C00000268)',
//       },
//     ],
//   }

// let errorResposta = {
//   ver: 'v1',
//   error: true,
//   date: '2020-04-20 10:37:36 +00:00',
//   msg:
//     "'truck_id' length must be less than or equal to 8 characters long | child 'cargos' fails because ['cargos' at position 0 fails because [child 'truck_id' fails because ['truck_id' length must be less than or equal to 8 characters long]]]",
//   data: null,
// }

export default {
  name: 'http-response-dialog',
  props: {},
  data() {
    return {
      status: 'success',
      visible: false,
      header: '',
      messages: [],
      httpresponse: {},
      isEditing: false,
    }
  },
  methods: {
    show(httpresponse, isEditing = false) {
      this.messages = []
      this.visible = true
      this.isEditing = isEditing

      let data = httpresponse.data
      let status = httpresponse.status
      this.header = data.msg

      if (data.data && Array.isArray(data.data)) {
        data.data.map((message) => {
          this.messages.push({
            subtitle: `${message.truck_id}`,
            message: message.msgs,
          })
        })
      } else if (data.data) {
        this.messages = [
          {
            subtitle: 'Data:',
            message: JSON.stringify(data.data),
          },
        ]
      }

      if (data.error || status < 200 || status >= 300) {
        this.status = 'error'
        this.header = 'Não conseguimos processar uma ou mais cargas:'

        if (data.msg) {
          this.messages.push({
            subtitle: httpresponse.responseMessage || 'Detalhes',
            message: data.msg,
          })
        }
      }
    },
    okButton() {
      this.visible = false
      if (this.isEditing) this.$router.push({ name: 'CargosList' })
    },
  },
}
</script>

<style>
.http-response-dialog .messages {
    flex-direction: row;
    width: 100%;
    margin-bottom: 8px;
    font-size: 13px;
}

.http-response-dialog .title-success {
    font-weight: bold;
    color: #67c23a;
}

.http-response-dialog .title-error {
    font-weight: bold;
    color: #f56c6c;
}

.http-response-dialog .el-icon-success, .el-icon-error {
    margin-right: 10px;
  }

.http-response-dialog .header {
    margin-bottom: 20px;
  }

.http-response-dialog .results {
    margin-bottom: 10px;
  }
</style>
