<template>
  <div class="cargo-conference-component">
    <cargos-resume :cargo="cargoDetailsFormated" />

    <div class="box-client-list">
      <div
        v-for="(customer, index) in cargoDetailClone"
        :key="`box-client-line${index}`"
      >
        <div
          class="box-client-line"
          v-if="profile.roles.includes('finance') || customer.needLogisticCheck"
        >
          <div class="box-client-line-1">
            <div>
              <el-checkbox
                @change="checkerChange(customer, 'fin')"
                v-model="customer.checked_fin"
                label=""
                style="margin-right: 10px;"
                :disabled="!profile.roles.includes('finance')"
              >
                Conferido financeiro
              </el-checkbox>
            </div>
            <div class="checkbox-last-line">
              <el-checkbox
                @change="checkerChange(customer, 'log')"
                v-model="customer.checked_log"
                label=""
                style="margin-right: 10px;"
                :disabled="
                  !profile.roles.includes('logistics') ||
                  !customer.needLogisticCheck
                "
              >
                Conferido logistica
              </el-checkbox>
            </div>

            <div class="new-comment-button">
              <el-button type="text" @click="showComment(customer)">
                Novo comentário
              </el-button>
            </div>
          </div>

          <div class="box-client-line-2">
            <div @click="showDrawer(customer)" style="cursor: pointer;">
              <div class="box-client-name">
                <b>{{ customer.customer }}</b>
              </div>
            </div>

            <div class="box-client-line-address">
              <b>Endereço:</b> {{ customer.address1 }},
              {{ customer.neighborhood }}
            </div>

            <!-- Notas Fiscais -->
            <br />
            <div v-for="invoice in customer.invoices" :key="invoice.invoice">
              <p class="titulo"><b>Nota fiscal</b></p>
              <el-table :data="[invoice]" size="mini">
                <el-table-column prop="invoice" label="Documento" />
                <el-table-column width="120" prop="total" label="Valor Total" />
                <el-table-column width="120" prop="volumes" label="Volumes" />
                <el-table-column width="120" prop="weight" label="Peso" />
                <el-table-column width="120" label="Itens">
                  <template slot-scope="scope">
                    {{ scope.row.items.length }}
                  </template>
                </el-table-column>
              </el-table>

              <div class="paymentsDivs">
                <div>
                  <p class="titulo"><b>Pagamento:</b></p>
                  <el-table :data="invoice.payment" size="mini">
                    <el-table-column
                      width="100"
                      prop="ammountFormated"
                      label="Produto"
                    />
                    <el-table-column
                      width="140"
                      prop="nameFormated"
                      label="Tipo"
                    />
                    <el-table-column width="100" prop="nsuNumber" label="NSU" />
                  </el-table>
                </div>
                <div>
                  <div v-if="invoice.paymentEdited">
                    <p class="titulo"><b>Pagamento alterado para:</b></p>
                    <el-table :data="invoice.paymentEdited" size="mini">
                      <el-table-column
                        width="100"
                        property="ammountFormated"
                        label="Produto"
                      />
                      <el-table-column
                        width="140"
                        prop="nameFormated"
                        label="Tipo"
                      />
                      <el-table-column width="100" prop="nsu" label="NSU" />
                    </el-table>
                  </div>
                </div>
              </div>
            </div>

            <!-- Comentários -->
            <br />
            <div v-if="hasComentariosEChecks(customer)">
              <p class="titulo"><b>Comentários e eventos:</b></p>
              <el-table :data="comentariosEChecks(customer)" size="mini">
                <el-table-column width="140" label="Hora">
                  <template slot-scope="scope">
                    {{
                      $moment
                        .unix(scope.row.comment_at.seconds)
                        .format('DD/MM/YYYY HH:mm')
                    }}
                  </template>
                </el-table-column>
                <el-table-column width="100" label="Usuário">
                  <template slot-scope="scope">
                    {{ scope.row.comment_by.split(' ')[0] }}
                  </template>
                </el-table-column>
                <el-table-column prop="comment" label="Tipo" />
              </el-table>
            </div>
          </div>

          <div class="box-client-line-3">
            <cargo-tags :customer="customer" />
          </div>
        </div>
      </div>
    </div>

    <div class="box-events">
      <cargo-conference-events :events="this.events" />
    </div>

    <div class="box-logs">
      <cargo-conference-logs :logs="this.logs" />
    </div>

    <cargo-obs-dialog
      :visible.sync="obsDialog"
      :customer="obsDialogData"
      @onComment="newComment"
    />

    <cargo-drawer
      :visible.sync="drawer"
      :drawerdata="drawerdata"
      @km-edit-click="kmEditClick"
      @km-click="kmClick"
      @show-map="showMapClick"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import detailsTablePrepare from './_customerDetailsTablePrepare'
import cargoConferenceLogs from './cargosConferenceLogs'
import cargoConferenceEvents from './cargosConferenceEvents'
import cargoDrawer from './cargosDrawer'
import cargoTags from './cargosTags'
import cargoObsDialog from './cargosConferenceObsDialog'
import format from '@/helpers/format'
import { logging } from '@/services/logging.js'
import cargosResume from './cargosResume'
import cargosDataTable from './_cargoDataTablePrepare'

export default {
  name: 'cargos-conference',
  components: {
    cargoConferenceLogs,
    cargoConferenceEvents,
    cargoDrawer,
    cargoTags,
    cargoObsDialog,
    cargosResume,
  },
  props: {
    cargoid: {
      type: String,
      required: true,
    },
    branchid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cargoDetailClone: [],
      logs: [],
      events: [],
      drawer: false,
      drawerdata: {},
      obsDialog: false,
      obsDialogData: {},
    }
  },
  computed: {
    ...mapState('Cargos', ['allCargos', 'deliveryLabels']),
    ...mapState('User', ['profile']),
    cargoDetail() {
      return detailsTablePrepare(this.allCargos, this.cargoid, this.branchid)
    },
    uName() {
      return this.profile.name
    },

    cargoDetailsFormated() {
      return cargosDataTable(this.allCargos, this.cargoid)[0]
    },
    hasPendingCustomers() {
      // totalInvoices
      // let ti = this.cargoDetailClone.reduce((qtd, customer) => {
      //   return qtd + customer.invoices.length
      // }, 0)

      let tc = this.cargoDetailClone.length // totalCustomers
      // let cp = tc * 2 // conferenciasPossiveis
      let cp = this.cargoDetailClone.reduce((p, e) => {
        if (e.needLogisticCheck) return p + 1
        else return p
      }, tc)

      // totalConferenciasRealizadas
      let tr = this.cargoDetailClone.reduce((qtd, c) => {
        // c = customer
        let checks = 0
        if (c.checked_fin) checks++
        if (c.checked_log) checks++

        return qtd + checks
      }, 0)

      // console.log('hasPendingCustomers', tc, tr, cp)
      return !(tr === cp)
    },
  },
  watch: {
    allCargos(value) {
      setTimeout(() => {
        // console.log('Cargos atualizado no firestore')
        this.cargoDetailClone = [...this.cargoDetail.details]
      }, 600)
    },
  },
  methods: {
    ...mapMutations('Shared', ['setInfoMessage']),
    ...mapActions('Cargos', [
      'updateCargoConferenceChecker',
      'addCargoComment',
    ]),
    ...mapActions('Logs', ['getLogsOfCargo']),
    ...mapActions('Events', ['getEventsOfCargo']),
    showMapClick(points) {
      this.$emit('show-map', points)
    },
    kmEditClick({ row, field }) {
      this.$emit('km-edit-click', { row, field })
    },
    kmClick({ row, field }) {
      this.$emit('km-click', { row, field })
    },
    formatC(value) {
      return format(value, 'C')
    },
    showDrawer(row) {
      this.drawerdata = row
      this.drawer = true
      // console.log('Show Client Drawer', this.drawerdata)
      // console.log('Profile', this.profile)
    },
    hasComentariosEChecks(customer) {
      return (
        customer.comments ||
        (customer.checked_log && customer.checked_log_at) ||
        (customer.checked_fin && customer.checked_fin_at)
      )
    },
    comentariosEChecks(customer) {
      let d = customer.comments ? [...customer.comments] : []

      if (customer.checked_log && customer.checked_log_at) {
        d.push({
          comment: `Conferido pela logistica`,
          comment_by: customer.checked_log_by,
          comment_at: customer.checked_log_at,
        })
      }

      if (customer.checked_fin && customer.checked_fin_at) {
        d.push({
          comment: `Conferido pela financeiro`,
          comment_by: customer.checked_fin_by,
          comment_at: customer.checked_fin_at,
        })
      }

      return d
    },
    showComment(row) {
      this.obsDialogData = row
      this.obsDialog = true
    },
    newComment({ comment, customer }) {
      // customer deve ser customerDetails
      // console.log(comment, customer)
      const c = customer

      let e = {
        cargo_id: this.cargoid,
        branch_id: this.branchid,
        comment: {
          comment,
          comment_by: `${this.uName} (${this.profile.id})`,
          comment_at: this.getSecondsObj(),
        },
        msg: `Comentário adicionado por ${this.uName} para o cliente ${c.customer}: ${comment}`,
      }

      this.addCargoComment({
        customerDetails: customer,
        extra: e,
      }).then(() => {
        logging(e.msg, 'Cargos', 'Customer Comment', e)
        this.logs.push({ created_at: this.getSecondsObj(), msg: e.msg })
      })
    },
    getSecondsObj() {
      return { seconds: Math.round(new Date().getTime() / 1000) }
    },
    checkerChange(c, type = 'fin') {
      // c deve ser customerDetails
      // console.log(c)
      let e = {
        type: type,
        login: `${this.uName} (${this.profile.id})`,
        status: true,
        text: type === 'fin' ? 'financeiro' : 'logistica',
        cargo_id: this.cargoid,
        branch_id: this.branchid,
        hasPendingCustomers: this.hasPendingCustomers,
      }
      // Update dos logs agora vem do watch
      // c[`checked_${type}_at`] = this.getSecondsObj()
      // c[`checked_${type}_by`] = this.uName

      if (c[`checked_${type}`]) {
        e.msg = `Conferência ${e.text} realizada para o cliente ${c.customer} por ${this.uName}`
      } else {
        e.status = false
        e.msg = `Conferência ${e.text} removida do cliente ${c.customer} por ${this.uName}`
      }

      this.updateCargoConferenceChecker({
        customerDetails: c,
        extra: e,
      }).then(() => {
        logging(e.msg, 'Cargos', 'Customer Conference', e)
        this.logs.push({ created_at: this.getSecondsObj(), msg: e.msg })
      })
    },
  },
  async mounted() {
    // console.log('this.cargoDetail', this.cargoDetail)
    // this.cargoDetailClone = JSON.parse(JSON.stringify(this.cargoDetail))
    this.cargoDetailClone = [...this.cargoDetail.details]

    const dblogs = await this.getLogsOfCargo({
      cargoId: this.cargoid,
      branchId: this.branchid,
    })

    this.events = await this.getEventsOfCargo({
      cargoId: this.cargoid,
      branchId: this.branchid,
    })

    // Cargo Operations
    // 'Open Cargo', 'Set Deliver', 'Set Delivering', 'Checkin', 'Checkout', 'Close Cargo', 'File Upload Service'
    // do admin 'Customer Conference'

    // Cargo Zones
    // 'Services', 'Cargos'

    this.logs = dblogs.filter(
      (log) => log.zone === 'Cargos' && log.operation !== 'Set Deliver'
    )

    // console.log(this.cargoDetailClone, this.profile)
    // console.log(this.logs, this.events)
    // console.log(this.hasPendingCustomers)
  },
}
</script>

<style>
.cargo-conference-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box-client-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 5px 10px;
}

.box-client-line {
  display: flex;
  background: #eaecee;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 130px;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 15px 10px;
}

.box-logs {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 5px 10px;
  /* width: 400px; */
}

.box-events {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 5px 10px;
  /* width: 400px; */
}

.box-client-line-1 {
  display: flex;
  /* flex-direction: column; */
  flex-flow: column nowrap;
  line-height: 14px;
  font-size: 12px;
  justify-content: space-between;
  height: 100%;
}

.box-client-line-2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 10px;
  margin-left: 10px;
  line-height: 14px;
  font-size: 12px;
}

.box-client-line-3 {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.box-client-line-payedited {
  margin-left: 20px;
}

.box-client-line-address {
  margin-left: 20px;
  color: lightslategrey;
  text-transform: capitalize;
}

.new-comment-button {
  /* align-self: flex-end; */
}

.checkbox-last-line {
  flex-grow: 1;
}

.paymentsDivs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.box-client-name {
  font-size: 16px;
  margin-bottom: 5px;
}
</style>
