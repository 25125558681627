// const getDefaultUserProfileState = () => {
//   return {
//     id: '',
//     login: '',
//     name: '',
//     roles: [],
//     branch_id: null,
//     company_id: null
//   }
// }

const getDefaultCompanyState = () => {
  return {
    id: null,
    name: null,
    dnsname: null,
    branchs: [],
  }
}

const getDefaultUserState = () => {
  return {
    profile: null,
    lastloggedin: null,
    token: null,
    branchsLocked: [],
    companie: Object.assign({}, getDefaultCompanyState()),
  }
}

export default getDefaultUserState()
export { getDefaultUserState }
export { getDefaultCompanyState }
// export { getDefaultUserProfileState }
