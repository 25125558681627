import { db, timestamp } from '@/config/firebase'
import dbFilter from '@/helpers/firestoreQueryFilter'

function getGeoLocation(form) {
  return new Promise((resolve) => {
    // campos obrigatórios
    let address = `${form.address1}, ${form.address2}, ${form.neighborhood}, ${form.city}, ${form.state}`

    console.log({ address })

    /* eslint-disable */
    let geocoder = new google.maps.Geocoder()
    geocoder.geocode({ address: address }, async (results, status) => {
      // console.log('status', status)
      // console.log('address', address)
      // console.log('latitude', results[0].geometry.location.lat())
      // console.log('longitude', results[0].geometry.location.lng())
      resolve({
        latitude: results[0].geometry.location.lat(),
        longitude: results[0].geometry.location.lng(),
      })
    })
  })
}

export default {
  async getBranches({ commit, rootState }) {
    let branchRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('branches')
    let branches = await dbFilter.query(branchRef)
    commit('changeBranches', branches)
    return branches
  },

  async addBranch({ commit, rootState }, form) {
    if (form.branch_id) {
      // Recebo todos os campos enviados pelo form
      const newBranch = { ...form }

      // outros campos obrigatórios na criação
      newBranch.created_by = rootState.User.profile.id
      newBranch.created_at = timestamp

      // Removo campos que não devem ser cadastrados
      delete newBranch.id

      let geolocation = await getGeoLocation(form)
      newBranch.latitude = geolocation.latitude
      newBranch.longitude = geolocation.longitude

      return db
        .collection('companies')
        .doc(rootState.User.companie.id)
        .collection('branches')
        .doc(form.branch_id)
        .set(newBranch)
        .then(() => {
          commit('addBranch', { id: newBranch.branch_id, ...newBranch })
          return newBranch
        })
        .catch((err) => {
          commit('Shared/setError', { err, that: this }, { root: true })
        })
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },

  async updateBranch({ commit, rootState }, form) {
    if (form && form.branch_id) {
      commit('Shared/setLoadingMessage', 'Atualizando filial...', {
        root: true,
      })
      // Recebo todos os campos enviados pelo form
      const newBranch = { ...form }

      // outros campos obrigatórios na criação
      newBranch.updated_by = rootState.User.profile.id
      newBranch.updated_at = timestamp

      // Removo campos que não devem ser cadastrados
      delete newBranch.id
      // let geolocation = await getGeoLocation(form)
      // newBranch.latitude = geolocation.latitude
      // newBranch.longitude = geolocation.longitude
      const branchRef = db
        .collection('companies')
        .doc(rootState.User.companie.id)
        .collection('branches')
        .doc(newBranch.branch_id)

      if (branchRef) {
        await branchRef
          .set(newBranch, { merge: true })
          .then(() => console.log('Updated'))
          .catch((e) =>
            commit('Shared/setError', { err: e, that: this }, { root: true })
          )
        commit('updateBranch', newBranch)
        commit('Shared/setLoadingMessage', '', { root: true })

        return newBranch
      }
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },
}
