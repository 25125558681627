<template>
  <el-dialog
    :title="`Adicionar comentário`"
    :visible="visible"
    center
    :before-close="onClose"
  >
    <center>
      <span> Comentário para {{ customer.customer }} </span>
      <br /><br />
    </center>

    <el-form :model="form" ref="formKm">
      <el-form-item prop="comment">
        <el-input
          v-model="form.comment"
          type="textarea"
          placeholder="Insira uma breve descrição da ocorrência que deseja registrar"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onSubmit()">
        Salvar
      </el-button>
      <el-button @click="onClose">Cancelar</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'cargos-conference-obs-dialog',
  props: {
    customer: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      form: {
        comment: '',
      },
    }
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$emit('onComment', {
        comment: this.form.comment,
        customer: this.customer,
      })
      this.onClose()
    },
    onClose() {
      this.$emit('update:visible', false)
    },
  },
  mounted() {},
}
</script>

<style scoped></style>
