import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import env from '@/config/environment'

const firebaseConfig = {
  apiKey: env.firebase.apiKey,
  authDomain: env.firebase.authDomain,
  databaseURL: env.firebase.databaseUrl,
  projectId: env.firebase.projectId,
  storageBucket: env.firebase.storageBucket,
  messagingSenderId: env.messagingSenderId,
}

// Default export
export default firebase

// Export initialized firebase app
export const fbapp = firebase.initializeApp(firebaseConfig)

// Podemos usar import { firestore as db } from '@/config/firebase'
// ou import { db } from '@/config/firebase' com o export abaixo
export const firestore = fbapp.firestore()
const settings = {
  // timestampsInSnapshots: true // removed in 5.8.3
}

firestore.settings(settings)
firestore
  .enablePersistence({ synchronizeTabs: true })
  .then(() => {})
  .catch((err) => console.error('firestore.enablePersistence', err.message))

export { firestore as db }

// Exports extras
export const timestamp = firebase.firestore.FieldValue.serverTimestamp()
export const FieldValue = firebase.firestore.FieldValue
export const geopoint = firebase.firestore.GeoPoint
export const auth = firebase.auth.Auth

// Para criar um timestamp em uma data especifica
// buildTimeStamp.fromMillis(sec * 1000)
export const buildTimeStamp = firebase.firestore.Timestamp

export const getStateTimeStamp = () => {
  return {
    seconds: Math.round(new Date().getTime() / 1000),
  }
}

// Google cloud Storage
export const gcs = fbapp.storage(env.firebase.work_bucket).ref()
