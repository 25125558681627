<template>
  <div class="groups">
    <div class="group" :ref="`truck${truck.id}`">
      <container
        group-name="column"
        :get-child-payload="getChildPayload"
        @drop="onDrop($event)"
      >
        <draggable v-for="(item, index) in invoices" :key="index">
          <div class="draggable-item">
            <invoice-collapse
              :invoice="item.data"
              :index="index"
              :showSequence="true"
              :showRemove="true"
              :enableChangeLatLng="true"
              @changeLatLng="changeInvoceLatLng"
              @remove="remove"
            />
          </div>
        </draggable>
      </container>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag } from '@/helpers/drag.js'
import invoiceCollapse from './invoiceCollapse.vue'
export default {
  name: 'InvoiceGroup',

  components: {
    Container,
    Draggable,
    'invoice-collapse': invoiceCollapse,
  },
  props: {
    truck: Object,
  },
  data() {
    return {
      invoices: [],
      color: '',
    }
  },
  watch: {
    truck(newValue) {
      this.invoices = this.truck.invoices
    },
  },
  created() {
    this.invoices = this.truck.invoices
  },
  methods: {
    updateTruck() {
      this.invoices = this.truck.invoices
    },
    onDrop(dropResult) {
      if (dropResult.addedIndex !== null && dropResult.addedIndex >= 0) {
        if (this.truck.branch_id !== dropResult.payload.data.branch_id) {
          this.$message({
            showClose: true,
            message: 'O Documento dever ser da mesma Filial do Veículo.',
            type: 'error',
          })
          return
        }
      }
      if(this.invoices && !dropResult.removedIndex && dropResult.addedIndex >= 0) {
        const invoiceChanged = dropResult.payload.data
        const customerExists = this.invoices.find(item => {
          const {data,id} = item
          return data.customer_id === invoiceChanged.customer_id && data.address1 === invoiceChanged.address1
        })
        if (customerExists) {
          dropResult.payload.data.latlng = customerExists.data.latlng
        }
      }
      let payload = {}
      this.invoices = applyDrag(this.invoices || [], dropResult)
      this.truck['duration'] = null
      this.truck['distance'] = null
      payload['truck_id'] = this.truck['truck_id']
      payload['invoices'] = this.invoices

      this.$emit('setItems', payload)
    },
    getChildPayload(index) {
      return this.invoices[index]
    },
    changeInvoceLatLng(invoiceChanged) {
      this.invoices = this.invoices.map(item => {
        const {data,id} = item
        if (data.customer_id === invoiceChanged.customer_id && data.address1 === invoiceChanged.address1) {
          data.latlng = invoiceChanged.latlng
        }
        return {data,id}
      })
    },
    remove(invoice) {
      let payload = {}
      this.invoices = this.invoices.filter(
        (i) =>
          i.data.branch + i.data.invoice + i.data.order_number !==
          invoice.branch + invoice.invoice + i.data.order_number
      )
      this.truck['duration'] = null
      this.truck['distance'] = null
      payload['truck_id'] = this.truck['truck_id']
      payload['invoices'] = this.invoices
      this.$emit('setItems', payload)
    },
  },
}
</script>

<style>
.groups .el-col {
  margin-bottom: 15px;
}
 .groups .el-col {
  border-radius: 4px;
}
</style>
