<template>
  <invoice-form />
</template>

<script>
import Form from '@/components/Admin/Invoices/components/form'

export default {
  name: 'invoicesAdd',
  components: {
    InvoiceForm: Form,
  },
}
</script>
