<template>
  <div>
    <ul class="menu-header">
      <li><span class="h2">Parâmetros de funcionamento do app</span></li>
    </ul>

    <el-form
      ref="formSettings"
      status-icon
      label-position="right"
      style="max-width: 70%;"
    >
      <!-- Configurações gerais -->
      <h2 class="subtitle">Configurações gerais</h2>

      <switch-item
        :input.sync="settings.general.maxUniqueKmUpdateDiff"
        label="Diferença máxima de KM permitido"
        forcetype="number"
      />
      <switch-item
        :input.sync="settings.general.minUniqueKmUpdateDiff"
        label="Diferença mínima de KM permitido"
        forcetype="number"
      />
      <switch-item
        :input.sync="settings.general.updateTruckLocationInterval"
        description="Intervalo de tempo em segundos utilizado pelo app para envio automático de sua localização atual"
        label="Auto envio da localização"
        forcetype="number"
      />
      <switch-item
        v-if="false"
        :input.sync="settings.images.compressQuality"
        label="Qualidade de compressão da foto"
        disabled
      />
      <switch-item
        :swinput.sync="settings.images.uploadOnlyWifi"
        description="Informa se envio de fotos deve ocorrer somente quando APP estiver conectado em uma rede Wireless"
        label="Enviar fotos somente via wifi"
      />
      <switch-item
        :swinput.sync="settings.general.mandatoryStockist"
        description="Informa se é obrigatório informar o conferente na montagem de uma carga"
        label="Obrigatório conferente na carga"
      />
      <switch-item
        :swinput.sync="settings.general.includesEventsInTheCargoDetail"
        description="Informa se os eventos devem ser listados junto aos clientes no detalhamento da carga"
        label="Inclui eventos no detalhe da carga"
      />

      <!-- Configurações relacionadas ao processo de entrega -->
      <h2 class="subtitle">Processo de entrega</h2>
      <switch-item
        :swinput.sync="settings.general.odomPhotoOnEachDelivery"
        description="Habilitar cadastro de foto do odômetro na etapa de checkout de cada entrega"
        label="Foto do odômetro no checkout"
      />
      <switch-item
        :swinput.sync="settings.general.odomPhotoOnEachDeliveryForced"
        description="Cadastro de foto do odômetro no checkout se torna obrigatório"
        label="Foto do odômetro no checkout obrigatório"
      />
      <switch-item
        :swinput.sync="settings.general.odomPhotoOnOpenCargo"
        description="Habilitar cadastro de foto do odômetro na etapa de abertura da carga"
        label="Foto do odômetro na abertura da carga"
      />
      <switch-item
        :swinput.sync="settings.general.odomPhotoOnCloseCargo"
        description="Habilitar cadastro de foto do odômetro na etapa de encerramento da carga"
        label="Foto do odômetro no encerramento da carga"
      />
      <switch-item
        :swinput.sync="settings.general.showFantasyName"
        description="Mostra nome fantasia na entrega"
        label="Mostra nome fantasia na entrega"
      />
      <switch-item
        :swinput.sync="settings.general.forceEvtPayFuelBeforeCloseCargo"
        :input.sync="settings.general.forceEvtPayFuelBeforeCloseCargoTime"
        description="Abastecimento obrigatório no encerramento da carga e tempo máximo em minutos entre abastecimento e encerramento da carga"
        label="Abastecimento obrigatório"
        forcetype="number"
      />

      <!-- Cadastro de motivos -->
      <select-reasons
        :input.sync="settings.cancellationReasons"
        label="Motivos do cancelamento"
      />

      <select-reasons
        :input.sync="settings.cancellationReasonsPartial"
        label="Motivos da entrega parcial"
      />

      <!-- Formas de pagamentos aceitas -->
      <!-- https://blog.oobj.com.br/campos-de-pagamento-nfe-4-00/ -->
      <h2 class="subtitle">Formas de Pagamento Aceitas</h2>
      <el-row class="switch-row">
        <el-col :span="11">Descrição</el-col>
        <el-col :span="2">Habilitado</el-col>
        <el-col :span="2">NSU</el-col>
        <el-col :span="2">Obrigatório</el-col>
        <el-col :span="7">Código integração</el-col>
      </el-row>
      <switch-item
        v-for="(payment, id) in settings.payments"
        :key="id"
        :swinput.sync="settings.payments[id].enabled"
        :swinput2.sync="settings.payments[id].nsu"
        :swinput3.sync="settings.payments[id].forcensu"
        :input.sync="settings.payments[id].type"
        :label="`${settings.payments[id].code} - ${settings.payments[id].name}`"
        :description="settings.payments[id].description"
      />
    </el-form>

    <div class="actions-buttons actions-buttons">
      <el-button type="success" @click="saveForm">Salvar</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import switchItem from '../components/formSwitchItem'
import selectReasons from '../components/formSelectReasons'

let cancellationReasonsDefault = [
  { description: 'Material Danificado *', id: 0, photo: true },
  { description: 'Cliente fechado', id: 1, photo: false },
]
let cancellationReasonsPartialDefault = [
  { description: 'Item Divergente *', id: 0, photo: true },
  { description: 'Item danificado', id: 1, photo: true },
]

export default {
  name: 'tabCompanieFormSettings',
  components: { switchItem, selectReasons },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    companie: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        payments: {
          x01: {
            name: 'Dinheiro',
            code: '01',
            type: this._.get(this, 'companie.settings.payments.x01.type', 'R$'),
            nsu: this._.get(this, 'companie.settings.payments.x01.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x01.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x01.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x01.enabled', true),
          },
          x02: {
            name: 'Cheque',
            code: '02',
            type: this._.get(this, 'companie.settings.payments.x02.type', 'CH'),
            nsu: this._.get(this, 'companie.settings.payments.x02.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x02.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x02.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x02.enabled', false),
          },
          x03: {
            name: 'Cartão de crédito',
            code: '03',
            type: this._.get(this, 'companie.settings.payments.x03.type', 'CC'),
            nsu: this._.get(this, 'companie.settings.payments.x03.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x03.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x03.description', ''), //description: 'Obriga cadastro do NSU', // Obrigatoriedade hardcode no app
            enabled: this._.get(this, 'companie.settings.payments.x03.enabled', true),
          },
          x04: {
            name: 'Cartão de débito',
            code: '04',
            type: this._.get(this, 'companie.settings.payments.x04.type', 'CD'),
            nsu: this._.get(this, 'companie.settings.payments.x04.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x04.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x04.description', ''), //description: 'Obriga cadastro do NSU', // Obrigatoriedade hardcode no app
            enabled: this._.get(this, 'companie.settings.payments.x04.enabled', true),
          },
          x05: {
            name: 'Crédito loja',
            code: '05',
            type: this._.get(this, 'companie.settings.payments.x05.type', 'CL'),
            nsu: this._.get(this, 'companie.settings.payments.x05.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x05.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x05.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x05.enabled', false),
          },
          x10: {
            name: 'Vale alimentação',
            code: '10',
            type: this._.get(this, 'companie.settings.payments.x10.type', 'VA'),
            nsu: this._.get(this, 'companie.settings.payments.x10.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x10.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x10.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x10.enabled', false),
          },
          x11: {
            name: 'Vale refeição',
            code: '11',
            type: this._.get(this, 'companie.settings.payments.x11.type', 'VR'),
            nsu: this._.get(this, 'companie.settings.payments.x11.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x11.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x11.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x11.enabled', false),
          },
          x12: {
            name: 'Vale presente',
            code: '12',
            type: this._.get(this, 'companie.settings.payments.x12.type', 'VP'),
            nsu: this._.get(this, 'companie.settings.payments.x12.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x12.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x12.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x12.enabled', false),
          },
          x13: {
            name: 'Vale combustível',
            code: '13',
            type: this._.get(this, 'companie.settings.payments.x13.type', 'VC'),
            nsu: this._.get(this, 'companie.settings.payments.x13.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x13.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x13.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x13.enabled', false),
          },
          x14: {
            name: 'Duplicata Mercantil',
            code: '14',
            type: this._.get(this, 'companie.settings.payments.x14.type', 'DM'),
            nsu: this._.get(this, 'companie.settings.payments.x14.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x14.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x14.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x14.enabled', false),
          },
          x15: {
            name: 'Boleto bancário',
            code: '15',
            type: this._.get(this, 'companie.settings.payments.x15.type', 'BO'),
            nsu: this._.get(this, 'companie.settings.payments.x15.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x15.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x15.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x15.enabled', true),
          },
          x16: {
            name: 'Pix',
            code: '16',
            type: this._.get(this, 'companie.settings.payments.x16.type', 'PX'),
            nsu: this._.get(this, 'companie.settings.payments.x16.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x16.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x16.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x16.enabled', true),
          },
          x17: {
            name: 'Online via link',
            code: '17',
            type: this._.get(this, 'companie.settings.payments.x17.type', 'AP'),
            nsu: this._.get(this, 'companie.settings.payments.x17.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x17.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x17.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x17.enabled', true),
          },
          x50: {
            name: 'Depósito',
            code: '50',
            type: this._.get(this, 'companie.settings.payments.x50.type', 'DE'),
            nsu: this._.get(this, 'companie.settings.payments.x50.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x50.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x50.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x50.enabled', true),
          },
          x51: {
            name: 'Contra Entrega',
            code: '51',
            type: this._.get(this, 'companie.settings.payments.x51.type', 'DH'),
            nsu: this._.get(this, 'companie.settings.payments.x51.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x51.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x51.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x51.enabled', true),
          },
          x90: {
            name: 'Sem pagamento',
            code: '90',
            type: this._.get(this, 'companie.settings.payments.x90.type', 'SP'),
            nsu: this._.get(this, 'companie.settings.payments.x90.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x90.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x90.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x90.enabled', true),
          },
          x99: {
            name: 'Outros',
            code: '99',
            type: this._.get(this, 'companie.settings.payments.x99.type', 'OU'),
            nsu: this._.get(this, 'companie.settings.payments.x99.nsu', false),
            forcensu: this._.get(this, 'companie.settings.payments.x99.forcensu', false),
            description: this._.get(this, 'companie.settings.payments.x99.description', ''),
            enabled: this._.get(this, 'companie.settings.payments.x99.enabled', true),
          },
        },
        cancellationReasons: this._.get(
          this,
          'companie.settings.cancellationReasons',
          cancellationReasonsDefault
        ),
        cancellationReasonsPartial: this._.get(
          this,
          'companie.settings.cancellationReasonsPartial',
          cancellationReasonsPartialDefault
        ),
        general: {
          maxUniqueKmUpdateDiff: this._.get(
            this,
            'companie.settings.general.maxUniqueKmUpdateDiff',
            800
          ),
          minUniqueKmUpdateDiff: this._.get(
            this,
            'companie.settings.general.minUniqueKmUpdateDiff',
            0
          ),
          updateTruckLocationInterval: this._.get(
            this,
            'companie.settings.general.updateTruckLocationInterval',
            60
          ),
          odomPhotoOnEachDelivery: this._.get(
            this,
            'companie.settings.general.odomPhotoOnEachDelivery',
            false
          ),
          odomPhotoOnEachDeliveryForced: this._.get(
            this,
            'companie.settings.general.odomPhotoOnEachDeliveryForced',
            false
          ),
          odomPhotoOnOpenCargo: this._.get(
            this,
            'companie.settings.general.odomPhotoOnOpenCargo',
            false
          ),
          odomPhotoOnCloseCargo: this._.get(
            this,
            'companie.settings.general.odomPhotoOnCloseCargo',
            false
          ),
          mandatoryStockist: this._.get(
            this,
            'companie.settings.general.mandatoryStockist',
            true
          ),
          includesEventsInTheCargoDetail: this._.get(
            this,
            'companie.settings.general.includesEventsInTheCargoDetail',
            false
          ),
          showFantasyName: this._.get(
            this,
            'companie.settings.general.showFantasyName',
            false
          ),
          forceEvtPayFuelBeforeCloseCargo: this._.get(
            this,
            'companie.settings.general.forceEvtPayFuelBeforeCloseCargo',
            false
          ),
          forceEvtPayFuelBeforeCloseCargoTime: this._.get(
            this,
            'companie.settings.general.forceEvtPayFuelBeforeCloseCargoTime',
            60
          ),
        },
        images: {
          compressQuality: this._.get(
            this,
            'companie.settings.images.compressQuality',
            '0'
          ),
          uploadOnlyWifi: this._.get(
            this,
            'companie.settings.images.uploadOnlyWifi',
            true
          ),
        },
      },
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    ...mapActions('User', ['updateCompanieSettings']),
    saveForm() {
      this.updateCompanieSettings({ settings: this.settings })
    },
  },
}
</script>

<style>
.company-settings .subtitle {
    border-bottom: 0px;
    margin: 20px 0px;
  }
</style>
