import { db, timestamp } from '@/config/firebase'
import getLocation from '@/services/getLocation'
import dbFilter from '@/helpers/firestoreQueryFilter'

export default {
  async getTrucks({ commit, rootState }) {
    let truckRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('trucks')
      .orderBy('truck_id')
    let trucks = await dbFilter.query(truckRef)
    commit('changeTrucks', trucks)
    return trucks
  },
  async getTrucksOnSnapshot({ commit, rootState }) {
    let truckRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('trucks')
      .orderBy('truck_id')
    await dbFilter.query(truckRef, false, '', 'Trucks/changeTrucks', true)
  },
  saveAndSetTruckNewKm({ commit, rootState, state }, payload) {
    // Recebe objecto {km: newKm, truck_id: truckId}
    // ou apenas o Km e atualiza o truck que estiver definido em selectedTruck

    const newKm = payload.km || payload
    const truckId = payload.truck_id || state.selectedTruck.truck_id
    const companyId = rootState.User.profile.company_id

    if (truckId && companyId && !isNaN(newKm)) {
      const truckRef = db
        .collection('companies')
        .doc(companyId)
        .collection('trucks')
        .doc(truckId)

      // Commit chages to state
      commit('changeSelectedTruckKm', newKm)

      // Save to database new Km and location
      return getLocation()
        .then((location) => {
          return {
            location,
            km: newKm,
            updated_at: timestamp,
          }
        })
        .then((dataToUpdate) => truckRef.update(dataToUpdate))
        .then(() =>
          console.log(
            `Doc trucks/${truckId}/km successfully updated with ${newKm}`
          )
        )
    } else {
      throw new Error(
        'saveAndSetTruckNewKm: truckID or companyID cant be empty or invalid number'
      )
    }
  },

  setSelectedTruck({ commit }, payload) {
    if (payload) {
      commit('changeSelectedTruck', payload)
    } else {
      commit('changeSelectedTruck', false)
    }
  },

  addTruck({ commit, rootState }, form) {
    if (form.truck_id) {
      // Recebo todos os campos enviados pelo form
      const newTruck = { ...form }

      // outros campos obrigatórios na criação
      newTruck.created_by = rootState.User.profile.id
      newTruck.created_at = timestamp

      // Removo campos que não devem ser cadastrados
      delete newTruck.id

      return db
        .collection('companies')
        .doc(rootState.User.companie.id)
        .collection('trucks')
        .doc(form.truck_id)
        .set(newTruck)
        .then(() => {
          commit('addTruck', { id: newTruck.truck_id, ...newTruck })
        })
        .catch((err) => {
          commit('Shared/setError', { err, that: this }, { root: true })
        })
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },

  removeTruck({ commit, rootState }, truckId) {
    if (truckId) {
      commit('Shared/setLoadingMessage', 'Removendo veículo...', { root: true })
      db.collection('companies')
        .doc(rootState.User.companie.id)
        .collection('trucks')
        .doc(truckId)
        .delete()
        .then(() => {
          commit('removeTruck', truckId)
          commit('Shared/setLoadingMessage', '', { root: true })
        })
        .catch((err) => {
          commit('Shared/setError', { err, that: this }, { root: true })
        })
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },

  async updateTruck({ commit, rootState }, form) {
    if (form && form.truck_id) {
      commit('Shared/setLoadingMessage', 'Atualizando veículo...', {
        root: true,
      })
      const companyId = rootState.User.companie.id
      const truckRef = db
        .collection('companies')
        .doc(companyId)
        .collection('trucks')
        .doc(form.truck_id)

      form.updated_at = timestamp
      form.updated_by = rootState.User.profile.id

      delete form.id

      if (truckRef) {
        await truckRef
          .update(form)
          .catch((e) =>
            commit('Shared/setError', { err: e, that: this }, { root: true })
          )
        commit('updateTruck', form)
        commit('Shared/setLoadingMessage', '', { root: true })
      }
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },

  async enableDisableTruck({ commit, rootState }, truck) {
    if (truck.truck_id) {
      commit('Shared/setLoadingMessage', 'Atualizando veículo...', {
        root: true,
      })
      const companyId = rootState.User.companie.id
      const truckRef = db
        .collection('companies')
        .doc(companyId)
        .collection('trucks')
        .doc(truck.truck_id)
      const updateData = {
        truck_id: truck.truck_id,
        updated_at: timestamp,
        updated_by: rootState.User.profile.id,
        is_disabled: !truck.is_disabled,
      }
      if (truckRef) {
        await truckRef
          .update(updateData)
          .catch((e) =>
            commit('Shared/setError', { err: e, that: this }, { root: true })
          )
        commit('updateTruck', updateData)
        commit('Shared/setLoadingMessage', '', { root: true })
      }
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },
}
