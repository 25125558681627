import moment from 'moment'
import { store } from '@/store'
import format from '@/helpers/format'

export default {
  getDeliveringByHour(state, getters) {
    let calculated = {
      ignorados: 0,
      total: 0,
      title: ['Horario', 'Entrege', 'Em entrega', 'Cancelado', 'Outros'],
      H00: ['00:00', 0, 0, 0, 0],
      H01: ['01:00', 0, 0, 0, 0],
      H02: ['02:00', 0, 0, 0, 0],
      H03: ['03:00', 0, 0, 0, 0],
      H04: ['04:00', 0, 0, 0, 0],
      H05: ['05:00', 0, 0, 0, 0],
      H06: ['06:00', 0, 0, 0, 0],
      H07: ['07:00', 0, 0, 0, 0],
      H08: ['08:00', 0, 0, 0, 0],
      H09: ['09:00', 0, 0, 0, 0],
      H10: ['10:00', 0, 0, 0, 0],
      H11: ['11:00', 0, 0, 0, 0],
      H13: ['12:00', 0, 0, 0, 0],
      H12: ['13:00', 0, 0, 0, 0],
      H14: ['14:00', 0, 0, 0, 0],
      H15: ['15:00', 0, 0, 0, 0],
      H16: ['16:00', 0, 0, 0, 0],
      H17: ['17:00', 0, 0, 0, 0],
      H18: ['18:00', 0, 0, 0, 0],
      H19: ['18:00', 0, 0, 0, 0],
      H20: ['20:00', 0, 0, 0, 0],
      H21: ['21:00', 0, 0, 0, 0],
      H22: ['22:00', 0, 0, 0, 0],
      H23: ['23:00', 0, 0, 0, 0],
    }

    getters.getAllCargosFiltered.map((cargo) => {
      const gmtFix = new Date().getTimezoneOffset() * 60
      let tempCustomers = cargo.customers || cargo.costumers
      Object.values(tempCustomers).map((customer) => {
        let dateObj = new Date(1970, 0, 1)
        calculated.total = calculated.total + 1
        if (
          customer.status === 'delivered' &&
          customer.delivered_at &&
          customer.delivered_at.seconds
        ) {
          dateObj.setSeconds(customer.delivered_at.seconds - gmtFix)
          let hour =
            dateObj.getHours() < 10
              ? '0' + dateObj.getHours()
              : dateObj.getHours()
          calculated[`H${hour}`][1] = calculated[`H${hour}`][1] + 1
        } else if (
          customer.status === 'delivering' &&
          customer.delivering_at &&
          customer.delivering_at.seconds
        ) {
          dateObj.setSeconds(customer.delivering_at.seconds - gmtFix)
          let hour =
            dateObj.getHours() < 10
              ? '0' + dateObj.getHours()
              : dateObj.getHours()
          calculated[`H${hour}`][2] = calculated[`H${hour}`][2] + 1
        } else if (
          customer.status === 'canceled' &&
          customer.canceled_at &&
          customer.canceled_at.seconds
        ) {
          dateObj.setSeconds(customer.canceled_at.seconds - gmtFix)
          let hour =
            dateObj.getHours() < 10
              ? '0' + dateObj.getHours()
              : dateObj.getHours()
          calculated[`H${hour}`][3] = calculated[`H${hour}`][3] + 1
        } else {
          calculated.ignorados = calculated.ignorados + 1
        }
      })
    })

    let chartFormated = Object.values(calculated).filter(
      (row, index) => index !== 0 && index !== 1
    )

    return {
      full: calculated,
      chartFormated,
    }
  },

  //
  // ______ POR VEICULO
  //
  // retorna km percorrido por veiculo
  getKmDeliveredByTruck:
    (state, getters, rootState, rootGetters) =>
    (returnMode = 'cleanData') => {
      // Formated for use with Google Charts
      let trucks = {}

      getters.getAllCargosFiltered.map((cargo) => {
        let tempCustomer = cargo.customers || cargo.costumers

        // Se não existe truck no objeto, cria agora
        if (!trucks[cargo.truck_id]) {
          trucks[cargo.truck_id] = {
            truck_id: cargo.truck_id,
            branch_id: cargo.branch_id,
            kms: [],
          }
        }

        // Pego km inicial e final e cadastro no array
        if (cargo.started_km && !isNaN(cargo.started_km))
          trucks[cargo.truck_id]['kms'].push(cargo.started_km)
        if (cargo.finished_km && !isNaN(cargo.finished_km))
          trucks[cargo.truck_id]['kms'].push(cargo.finished_km)

        // Para cada customer, pego o km de entrega, caso não tenha o finished km, este será considerado
        Object.values(tempCustomer).map((customer) => {
          if (customer.delivered_km && !isNaN(customer.delivered_km))
            trucks[cargo.truck_id]['kms'].push(customer.delivered_km)
        })
      })

      if (returnMode === 'cleanData') {
        let returnObj = {}
        Object.values(trucks).map((truck) => {
          // let maxKm = truck.kms.reduce((a, b) => Math.max(a, b))
          let maxKm = Math.max(...truck.kms)
          let minKm = Math.min(...truck.kms)

          returnObj[truck.truck_id] = {
            truck_id: truck.truck_id,
            branch_id: truck.branch_id,
            branch_name: rootGetters['User/getCompanieBranchName'](
              truck.branch_id
            ),
            maxKm,
            minKm,
            deliveredkm: parseFloat((maxKm - minKm).toFixed(1)),
          }
        })
        return returnObj

        // Cabeçalhos do objeto de retorno para Gchart
      } else if (returnMode === 'gchart') {
        // Cabeçalhos do objeto de retorno
        let returnObj = [['Veículo', 'KM']]
        Object.values(trucks).map((truck) => {
          // let maxKm = truck.kms.reduce((a, b) => Math.max(a, b))
          let maxKm = Math.max(...truck.kms)
          let minKm = Math.min(...truck.kms)
          returnObj.push([truck.truck_id, maxKm - minKm])
        })
        return returnObj

        // Se qualquer outro modo retorna dados cru
      } else {
        return trucks
      }
    },

  // retorna horas trabalhadas por veiculo
  getWorkedHoursByTruck:
    (state, getters, rootState, rootGetters) =>
    (returnMode = 'cleanData') => {
      // Formated for use with Google Charts
      let trucks = {}

      getters.getAllCargosFiltered.map((cargo) => {
        let tempCustomer = cargo.customers || cargo.costumers

        // Se não existe truck no objeto, cria agora
        if (!trucks[cargo.truck_id]) {
          trucks[cargo.truck_id] = {
            truck_id: cargo.truck_id,
            branch_id: cargo.branch_id,
            branch_name: rootGetters['User/getCompanieBranchName'](
              cargo.branch_id
            ),
            seconds_by_day: [],
          }
        }

        let secondsStart =
          cargo.started_at &&
          cargo.started_at.seconds &&
          !isNaN(cargo.started_at.seconds)
            ? cargo.started_at.seconds
            : 0
        let secondsEnd =
          cargo.finished_at &&
          cargo.finished_at.seconds &&
          !isNaN(cargo.finished_at.seconds)
            ? cargo.finished_at.seconds
            : 0
        let secondsCustomer = 0

        // Para cada customer retorno o maior km de entrega possivel
        Object.values(tempCustomer).map((customer) => {
          let deliveredAt =
            customer.delivered_at &&
            customer.delivered_at.seconds &&
            !isNaN(customer.delivered_at.seconds)
              ? customer.delivered_at.seconds
              : 0
          if (secondsCustomer < deliveredAt) secondsCustomer = deliveredAt
        })

        if (secondsStart > 0 && secondsEnd > 0 && secondsStart < secondsEnd)
          trucks[cargo.truck_id]['seconds_by_day'].push(
            secondsEnd - secondsStart
          )
        else if (
          secondsStart > 0 &&
          secondsCustomer > 0 &&
          secondsStart < secondsCustomer
        )
          trucks[cargo.truck_id]['seconds_by_day'].push(
            secondsCustomer - secondsStart
          )
        else trucks[cargo.truck_id]['seconds_by_day'].push(0)
      })

      if (returnMode === 'cleanData') {
        let returnObj = {}
        Object.values(trucks).map((truck) => {
          let totalHours =
            truck.seconds_by_day.reduce((a, b) => a + b, 0) / 60 / 60
          returnObj[truck.truck_id] = {
            truck_id: truck.truck_id,
            branch_id: truck.branch_id,
            hours: Number(totalHours.toFixed('0')),
          }
        })
        return returnObj

        // Cabeçalhos do objeto de retorno para Gchart
      } else if (returnMode === 'gchart') {
        let returnObj = [['Veículo', 'Horas', { role: 'style' }]]
        Object.values(trucks).map((truck) => {
          let totalHours =
            truck.seconds_by_day.reduce((a, b) => a + b, 0) / 60 / 60
          returnObj.push([truck.truck_id, totalHours, 'color: green'])
        })
        return returnObj

        // Se qualquer outro modo retorna dados cru
      } else {
        return trucks
      }
    },

  // Retorna os dois getters unidos getKmDeliveredByTruck e getWorkedHoursByTruck
  getWorkedHoursAndKMByTruck:
    (state, getters, rootState, rootGetters) =>
    (returnMode = 'cleanData') => {
      // Formated for use with Google Charts
      let trucksKms = getters.getKmDeliveredByTruck('cleanData')
      let trucksHours = getters.getWorkedHoursByTruck('cleanData')

      let cleanData = {}
      Object.values(trucksKms).forEach((truck) => {
        cleanData[truck.truck_id] = {
          ...trucksKms[truck.truck_id],
          ...trucksHours[truck.truck_id],
          truckCost: truck.truck_id ? rootGetters['Trucks/getTruckCost'](truck.truck_id) : 0
        }

        cleanData[truck.truck_id]['cost_bykm'] = cleanData[truck.truck_id].truckCost / cleanData[truck.truck_id].deliveredkm

        cleanData[truck.truck_id]['cost_bykm'] = format(cleanData[truck.truck_id]['cost_bykm'], 'C')
        cleanData[truck.truck_id]['truckCost'] = format(cleanData[truck.truck_id]['truckCost'], 'C')

      })

      if (returnMode === 'cleanData') {
        return cleanData

        // Cabeçalhos do objeto de retorno para Gchart
      } else if (returnMode === 'gchart') {
        let returnObj = [['Veículo', 'Horas', 'Km']]
        Object.values(cleanData).forEach((truck) => {
          returnObj.push([truck.truck_id, truck.hours, truck.deliveredkm])
        })
        return returnObj
        // Se qualquer outro modo retorna cleandata
      } else {
        return cleanData
      }
    },

  //
  // ______ POR MOTORISTA
  //
  // retorna horas trabalhadas por motorista
  // retorna total de notas entregues, canceladas, etc... por motorista
  // função getCargosGeneralStatisticsByDriver retorna diversas estatisticas por motoristas
  getByDriverCargosGeneralStatistics:
    (state, getters, rootState, rootGetters) => () => {
      let cleanData = {}
      let _stats = {
        total_cargos: 0,
        total_customer: 0,
        total_invoices: 0,
        failed_cargos: 0,
        failed_cargos_partial: 0,
        failed_customers: 0,
        failed_customers_partial: 0,
      }

      // Calculo em cima de cargas com status válidos
      getters.getAllCargosFiltered
        .filter(
          (cargo) => !state.excludedReportStatus.includes(cargo.cargo_status)
        )
        .map((cargo) => {
          // Casos que a carga não tem o started_by mas esta com status entregue ?????
          // Foram pouquissimos casos
          if (!cargo.started_by && !cargo.finished_by) {
            console.warn('Carga Invalida', cargo)
            _stats.failed_cargos++
            return

            // Casos que a carga não tem o started_by mas esta com status entregue e tem finished_by ?????
          } else if (
            !cargo.started_by &&
            cargo.finished_by &&
            typeof cargo.finished_by === 'string' &&
            cargo.finished_by.length >= 10
          ) {
            _stats.failed_cargos_partial++
            // cargo.started_by = cargo.finished_by
          }

          // Validaçào da troca do nome do costumer
          let tempCustomer = cargo.customers || cargo.costumers

          // Traduz id do motorista em nome
          let drivername = cargo.started_by
            ? rootGetters['User/getDriverName'](cargo.started_by)
            : ''

          // Arrays e objectos de apoio
          if (!cleanData[cargo.started_by]) cleanData[cargo.started_by] = {}
          if (!cleanData[cargo.started_by].seconds_trip_times)
            cleanData[cargo.started_by].seconds_trip_times = [] // tempo de viagem, delivering_checking_at - delivering_at
          if (!cleanData[cargo.started_by].seconds_delivering_times)
            cleanData[cargo.started_by].seconds_delivering_times = [] // tempo de entrega, delivered_at - delivering_checking_at
          if (!cleanData[cargo.started_by].seconds_by_costumer)
            cleanData[cargo.started_by].seconds_by_costumer = [] // tempo total por cliente, viagem + entrega, delivered_at - delivering_at
          if (!cleanData[cargo.started_by].seconds_by_cargo)
            cleanData[cargo.started_by].seconds_by_cargo = [] // tempos totais da carga, finished_at - started_at
          if (!cleanData[cargo.started_by].failed_customers)
            cleanData[cargo.started_by].failed_customers = 0 // clientes com fahas na carga
          if (!cleanData[cargo.started_by].failed_customers_partial)
            cleanData[cargo.started_by].failed_customers_partial = 0 // clientes com falha parcial na carga

          // Stats
          _stats.total_cargos++

          // Horas trabalhadas
          // Para cada carga, pego os valores do started_at e finished_at
          let secondsStart =
            cargo.started_at &&
            cargo.started_at.seconds &&
            Number.isInteger(cargo.started_at.seconds)
              ? cargo.started_at.seconds
              : 0
          let secondsEnd =
            cargo.finished_at &&
            cargo.finished_at.seconds &&
            Number.isInteger(cargo.finished_at.seconds)
              ? cargo.finished_at.seconds
              : 0
          // let secondsCustomer = 0

          // Dados da carga
          cleanData[cargo.started_by]['name'] = drivername
          cleanData[cargo.started_by]['auth_uid'] = cargo.started_by
          cleanData[cargo.started_by]['branch_id'] = cargo['branch_id']
          const getCompanieBranchName =
            rootGetters['User/getCompanieBranchName']
          cleanData[cargo.started_by]['branch_name'] = getCompanieBranchName(
            cargo['branch_id']
          )

          // retorno objecto com id de cada motorista que já evetuou alguma entrega
          // e as quantidades de entregas, cancelamentos, e qualquer outro status do invoice
          Object.values(tempCustomer).map((customer) => {
            // Horas trabalhadas
            // Retorno o maior horario de enterga, para conseguir calcular horas trabalhadas mesmo em uma carga não finalizada.
            // let deliveredAt = (customer.delivered_at && customer.delivered_at.seconds && Number.isInteger(customer.delivered_at.seconds)) ? customer.delivered_at.seconds : 0
            // if (secondsCustomer < deliveredAt) secondsCustomer = deliveredAt

            // Stats
            _stats.total_customer++

            // Estatisticas por invoice
            Object.values(customer.invoices).map((invoice) => {
              _stats.total_invoices++

              // contagem de cada invoice status
              if (invoice.status) {
                cleanData[cargo.started_by][invoice.status] =
                  cleanData[cargo.started_by][invoice.status] + 1 || 1
              }
            })

            // Tempo de entrega
            // Para calcular o tempo médio de viagem e entrega de cada motorista
            // Alguns casos encontratos não possuem o delivering_checkin_at ??????
            if (customer.status === 'delivered') {
              // Calculo tempo total de entrega e de viagem de cada cliente
              if (
                customer.delivering_at &&
                customer.delivering_checkin_at &&
                customer.delivered_at
              ) {
                cleanData[cargo.started_by].seconds_trip_times.push(
                  customer.delivering_checkin_at.seconds -
                    customer.delivering_at.seconds
                )
                cleanData[cargo.started_by].seconds_delivering_times.push(
                  customer.delivered_at.seconds -
                    customer.delivering_checkin_at.seconds
                )
                cleanData[cargo.started_by].seconds_by_costumer.push(
                  customer.delivered_at.seconds - customer.delivering_at.seconds
                )

                // Calculo tempo total de cada cliente
              } else if (customer.delivering_at && customer.delivered_at) {
                _stats.failed_customers_partial++
                cleanData[cargo.started_by].failed_customers_partial++
                cleanData[cargo.started_by].seconds_by_costumer.push(
                  customer.delivered_at.seconds - customer.delivering_at.seconds
                )

                // Apenas para debug
              } else {
                _stats.failed_customers++
                cleanData[cargo.started_by].failed_customers++
                // console.warn('Customer com problema', customer)
              }
            } else {
              cleanData[cargo.started_by].failed_customers++
            }
          })

          // Horas trabalhadas
          // Logica que retorna a maior diferença entre o start e o maior valor disponivel da carga, seja ela finalizada com sucesso ou não
          if (secondsStart > 0 && secondsEnd > 0 && secondsStart < secondsEnd)
            cleanData[cargo.started_by].seconds_by_cargo.push(
              secondsEnd - secondsStart
            )
          // else if (secondsStart > 0 && secondsCustomer > 0 && secondsStart < secondsCustomer) cleanData[cargo.started_by].seconds_by_cargo.push((secondsCustomer - secondsStart))
          else cleanData[cargo.started_by].seconds_by_cargo.push(0)
        })

      // Processar valores
      Object.values(cleanData).map((driver) => {
        // Padroniza todos motoristas com campos de outros status de entrega, mesmo que seja zero
        state.deliveryLabels.forEach((status) => {
          if (!cleanData[driver.auth_uid][status.content])
            cleanData[driver.auth_uid][status.content] =
              cleanData[status.content] || 0
        })

        // Calcula horas de trabalho
        cleanData[driver.auth_uid]['hours'] = Number(
          (
            driver.seconds_by_cargo.reduce((a, b) => a + b, 0) /
            60 /
            60
          ).toFixed('0')
        )
        cleanData[driver.auth_uid]['hours_driving'] = Number(
          (
            driver.seconds_trip_times.reduce((a, b) => a + b, 0) /
            60 /
            60
          ).toFixed('0')
        )
        cleanData[driver.auth_uid]['hours_delivering'] = Number(
          (
            driver.seconds_delivering_times.reduce((a, b) => a + b, 0) /
            60 /
            60
          ).toFixed('0')
        )

        // Médias
        cleanData[driver.auth_uid]['min_delivering_average'] = Number(
          (
            driver.seconds_delivering_times.reduce((a, b) => a + b, 0) /
            60 /
            driver.seconds_delivering_times.length
          ).toFixed('0')
        )
        cleanData[driver.auth_uid]['min_driving_average'] = Number(
          (
            driver.seconds_trip_times.reduce((a, b) => a + b, 0) /
            60 /
            driver.seconds_trip_times.length
          ).toFixed('0')
        )
        cleanData[driver.auth_uid]['min_customer_average'] = Number(
          (
            driver.seconds_by_costumer.reduce((a, b) => a + b, 0) /
            60 /
            driver.seconds_by_costumer.length
          ).toFixed('0')
        )
      })

      return { data: cleanData, _stats }
    },

  // Retorna apenas as horas trabalhadas do motorista, extraido de getByDriverCargosGeneralStatistics
  getByDriverWorkedHours:
    (state, getters, rootState) =>
    (returnMode = 'cleanData') => {
      let driverStatistics = getters.getByDriverCargosGeneralStatistics().data
      let returnObj = {}
      if (returnMode === 'gchart') returnObj = [['Motorista', 'Horas']]

      Object.values(driverStatistics).map((driver) => {
        if (returnMode === 'cleanData') {
          returnObj[driver.auth_uid] = {
            auth_uid: driver.auth_uid,
            branch_id: driver.branch_id,
            branch_name: driver.branch_name,
            name: driver.name,
            hoursWorking: driver.hours,
            hoursDriving: driver.hours_driving,
            hoursDelivering: driver.hours_delivering,
          }

          // Formatação Google Chart
        } else if (returnMode === 'gchart') {
          returnObj.push([driver.name, driver.hours])
        }
      })

      return returnObj
    },

  // retorna apenas total de notas entregues, canceladas, etc... por motorista, extraido de getByDriverCargosGeneralStatistics
  getByDriverInvoiceDeliverStatus:
    (state, getters, rootState, rootGetters) =>
    (returnMode = 'cleanData') => {
      let driverStatistics = getters.getByDriverCargosGeneralStatistics().data
      let returnObj = {}
      if (returnMode === 'gchart')
        returnObj = [['Motorista', 'Entregas', 'Cancelamentos', 'Outros']]

      Object.values(driverStatistics).map((driver) => {
        if (returnMode === 'cleanData') {
          returnObj[driver.auth_uid] = {
            auth_uid: driver.auth_uid,
            branch_id: driver.branch_id,
            branch_name: driver.branch_name,
            name: driver.name,
          }

          // Adiciona somente as quantidades de cada entrega
          state.deliveryLabels.forEach((status) => {
            if (driver[status.content])
              returnObj[driver.auth_uid][status.content] =
                driver[status.content]
          })

          // Formatação Google Chart
        } else if (returnMode === 'gchart') {
          // Soma todos os outros status diferente de entregue e cancelado como outros
          let outros = 0
          state.deliveryLabels.forEach((status) => {
            if (
              status.content !== 'delivered' &&
              status.content !== 'canceled'
            ) {
              if (driver[status.content])
                outros = outros + driver[status.content]
            }
          })
          returnObj.push([
            driver.name,
            driver['delivered'] || 0,
            driver['canceled'] || 0,
            outros,
          ])
        }
      })

      return returnObj
    },

  // Retorna os dois getters unidos getByDriverWorkedHours e getByDriverInvoiceDeliverStatus, extraido de getByDriverCargosGeneralStatistics
  getByDriverWorkedHoursAndDeliveryCount:
    (state, getters) =>
    (returnMode = 'cleanData') => {
      let driverDeliver =
        getters.getByDriverCargosGeneralStatistics('cleanData').data
      let returnObj = {}
      if (returnMode === 'gchart')
        returnObj = [
          ['Motorista', 'Horas', 'Entregas', 'Cancelamentos', 'Outros'],
        ]

      Object.values(driverDeliver).forEach((driver) => {
        if (returnMode === 'cleanData') {
          returnObj[driver.auth_uid] = driver
          delete returnObj[driver.auth_uid].seconds_trip_times
          delete returnObj[driver.auth_uid].seconds_delivering_times
          delete returnObj[driver.auth_uid].seconds_by_costumer
          delete returnObj[driver.auth_uid].seconds_by_cargo

          // Formatação Google Chart
        } else if (returnMode === 'gchart') {
          // Soma todos os outros status diferente de entregue e cancelado como outros
          let outros = 0
          state.deliveryLabels.forEach((status) => {
            if (
              status.content !== 'delivered' &&
              status.content !== 'canceled'
            ) {
              if (driver[status.content])
                outros = outros + driver[status.content]
            }
          })
          returnObj.push([
            driver.name,
            driver.hours,
            driver['delivered'] || 0,
            driver['canceled'] || 0,
            outros,
          ])
        }
      })

      return returnObj
    },

  //
  // ______ CONTAGEM POR CAMPOS do customer
  //
  // retorna contagem de customers/entregas em cada cidade / estado / bairro / zona etc... qualquer campo do customer
  getByPlacesGeneralStatistics:
    (state, getters) =>
    (field = 'state') => {
      let _titleTable = {
        state: 'Estado',
        city: 'Cidadde',
        neighborhood: 'Bairro',
        route: 'Rota',
        sector: 'Setor',
        zone: 'Zona',
        customer: 'Cliente',
      }

      let _stats = {
        total_cargos: 0,
        total_customer: 0,
        total_invoices: 0,
        failed_cargos: 0,
        failed_cargos_partial: 0,
        failed_customers: 0,
        failed_customers_partial: 0,
      }

      let cleanData = {}

      // Calculo em cima de cargas com status válidos
      getters.getAllCargosFiltered
        .filter(
          (cargo) => !state.excludedReportStatus.includes(cargo.cargo_status)
        )
        .map((cargo) => {
          // Validaçào da troca do nome do costumer
          let tempCustomer = cargo.customers || cargo.costumers

          // Stats
          _stats.total_cargos++

          Object.values(tempCustomer).map((customer) => {
            // Stats
            _stats.total_customer++

            // Campo informado deve existir e não ser nulo no ciente
            if (customer[field] && customer[field] !== '') {
              // To Upper para correção de erros no cadastro
              let upperfield = customer[field].toUpperCase()

              // Arrays e objectos de apoio
              if (!cleanData[upperfield]) cleanData[upperfield] = {}
              if (!cleanData[upperfield].arr_weights)
                cleanData[upperfield].arr_weights = [0] // com todos os pesos de cada nota, start com zero para calculo não falhar se vazio na carga
              if (!cleanData[upperfield].arr_volumes)
                cleanData[upperfield].arr_volumes = [0] // com todos os volumes de cada nota, start com zero para calculo não falhar se vazio na carga
              if (!cleanData[upperfield].arr_items)
                cleanData[upperfield].arr_items = [0] // com a quantidade de items de cada nota, start com zero para calculo não falhar se vazio na carga
              if (!cleanData[upperfield].arr_totals)
                cleanData[upperfield].arr_totals = [0] // com todos os valores totais de cada nota, start com zero para calculo não falhar se vazio na carga
              if (!cleanData[upperfield].arr_receivables)
                cleanData[upperfield].arr_receivables = [0] // com todos os valores a receber de cada nota, start com zero para calculo não falhar se vazio na carga
              if (!cleanData[upperfield].quantity_customers)
                cleanData[upperfield].quantity_customers = 0
              if (!cleanData[upperfield].quantity_invoices)
                cleanData[upperfield].quantity_invoices = 0

              // Contagem e campos padroes
              cleanData[upperfield].quantity_customers++
              cleanData[upperfield].state = customer['state'].toUpperCase()

              // Estatisticas por invoice
              Object.values(customer.invoices).map((invoice) => {
                _stats.total_invoices++
                cleanData[upperfield].quantity_invoices++
                if (invoice.weight)
                  cleanData[upperfield].arr_weights.push(invoice.weight)
                if (invoice.volumes)
                  cleanData[upperfield].arr_volumes.push(invoice.volumes)
                if (invoice.total)
                  cleanData[upperfield].arr_totals.push(invoice.total)
                if (invoice.items)
                  cleanData[upperfield].arr_items.push(
                    Object.keys(invoice.items).length
                  )
                if (invoice.amountreceivable)
                  cleanData[upperfield].arr_receivables.push(
                    invoice.amountreceivable
                  )
              })

              // Campos complementares conforme nível de profundidade do dado
              if (field === 'city') {
                cleanData[upperfield].city = customer['city'].toUpperCase()
              } else if (field === 'neighborhood') {
                cleanData[upperfield].city = customer['city']
                cleanData[upperfield].neighborhood = customer['neighborhood']
              } else if (field === 'customer') {
                cleanData[upperfield].city = customer['city']
                cleanData[upperfield].neighborhood = customer['neighborhood']
                cleanData[upperfield].neighborhood = customer['customer']
              }
            }
          })
        })

      // Processar valores
      Object.values(cleanData).map((group) => {
        // Ticket Médio
        group['average_ticket'] = Number(
          (
            group.arr_totals.reduce((a, b) => a + b, 0) /
            group.arr_totals.length
          ).toFixed('2')
        )
        group['average_items'] = Number(
          (
            group.arr_items.reduce((a, b) => a + b, 0) / group.arr_items.length
          ).toFixed('0')
        )
        group['average_volumes'] = Number(
          (
            group.arr_volumes.reduce((a, b) => a + b, 0) /
            group.arr_volumes.length
          ).toFixed('0')
        )
        group['average_weight'] = Number(
          (
            group.arr_weights.reduce((a, b) => a + b, 0) /
            group.arr_weights.length
          ).toFixed('2')
        )
        group['average_receivables'] = Number(
          (
            group.arr_receivables.reduce((a, b) => a + b, 0) /
            group.arr_receivables.length
          ).toFixed('2')
        )

        group['max_ticket'] = Math.max(...group.arr_totals)
        group['max_item'] = Math.max(...group.arr_items)
        group['max_volume'] = Math.max(...group.arr_volumes)
        group['max_weight'] = Math.max(...group.arr_weights)
        group['max_receivable'] = Math.max(...group.arr_receivables)

        group['min_ticket'] = Math.min(...group.arr_totals)
        group['min_item'] = Math.min(...group.arr_items)
        group['min_volume'] = Math.min(...group.arr_volumes)
        group['min_weight'] = Math.min(...group.arr_weights)
        group['min_receivable'] = Math.min(...group.arr_receivables)

        group['total_ticket'] = Number(
          group.arr_totals.reduce((a, b) => a + b, 0).toFixed('2')
        )
        group['total_items'] = Number(
          group.arr_items.reduce((a, b) => a + b, 0).toFixed('0')
        )
        group['total_volumes'] = Number(
          group.arr_volumes.reduce((a, b) => a + b, 0).toFixed('0')
        )
        group['total_weight'] = Number(
          group.arr_weights.reduce((a, b) => a + b, 0).toFixed('2')
        )
        group['total_receivables'] = Number(
          group.arr_receivables.reduce((a, b) => a + b, 0).toFixed('2')
        )
      })

      return { data: cleanData, titles: _titleTable, stats: _stats }
    },

  // Mesmo resultado da getByPlacesGeneralStatistics porém retirando os arrays para melhor leitura do json
  getByPlacesGeneralStatisticsClean:
    (state, getters) =>
    (field = 'state') => {
      let data = getters.getByPlacesGeneralStatistics(field).data

      let returnObj = {}
      Object.values(data).forEach((group) => {
        returnObj[group[field]] = group
        delete returnObj[group[field]].arr_weights
        delete returnObj[group[field]].arr_volumes
        delete returnObj[group[field]].arr_items
        delete returnObj[group[field]].arr_totals
        delete returnObj[group[field]].arr_receivables
      })

      return returnObj
    },

  // Retorna quantidade de notas ou clientes por campo informado com opção de formatacao para google chart
  getByPlaceTotalsByField:
    (state, getters) =>
    (returnMode = 'cleanData', field = 'state', what = 'invoices') => {
      let { data, titles } = getters.getByPlacesGeneralStatistics(field)

      let returnObj = {}
      if (returnMode === 'gchart' && what === 'invoices')
        returnObj = [[titles[field], 'Notas']]
      if (returnMode === 'gchart' && what === 'customers')
        returnObj = [[titles[field], 'Clientes']]

      Object.values(data).forEach((group) => {
        if (returnMode === 'cleanData') {
          returnObj[group[field]] = {
            quantity_invoices: group.quantity_invoices,
            quantity_customers: group.quantity_customers,
          }

          // Cabeçalhos do objeto de retorno para Gchart
        } else if (returnMode === 'gchart') {
          if (what === 'invoices')
            returnObj.push([group[field], group.quantity_invoices])
          if (what === 'customers')
            returnObj.push([group[field], group.quantity_customers])
        }
      })

      return returnObj
    },

  getListErrors:
    (state, getters, rootState, rootGetters) =>
    (returnMode = 'cleanData') => {
      let returnObj = []
      const newDataFiltered = getters.getAllCargosFiltered.filter((item) => {
        if (item.cargo_status === 'U') return true
        else return false
      })

      newDataFiltered.forEach((cargo) => {
        Object.keys(cargo.customers).forEach((indexCustumer) => {
          if (cargo.customers[indexCustumer].checked_log) {
            let statusLabel = store.state.Cargos.statusLabels.find(
              (item) => item.content === cargo.cargo_status
            )
            statusLabel = !statusLabel ? cargo.cargo_status : statusLabel.value

            returnObj.push({
              truck_id: cargo.truck_id,
              branch_name: rootGetters['User/getCompanieBranchName'](
                cargo.branch_id
              ),
              branch_id: cargo.branch_id,
              cargo_status: statusLabel,
              cargo_id: cargo.cargo_id,
              reason: rootGetters['User/cancellationReasonsDesc'](
                cargo.customers[indexCustumer].canceled_reason
              ),
              created_at: moment
                .unix(cargo.created_at.seconds)
                .format('DD/MM/YYYY HH:mm'),
              custumer: cargo.customers[indexCustumer].customer,
              driver_name: rootGetters['User/getDriverName'](cargo.started_by),
              motivo: cargo.customers[indexCustumer].cancel_desc,
              observation: cargo.customers[indexCustumer].canceled_notice
                ? cargo.customers[indexCustumer].canceled_notice
                : cargo.customers[indexCustumer].order_observations,
              observation_driver: cargo.customers[indexCustumer].delivered_info,
              time_cancel: moment
                .unix(cargo.customers[indexCustumer].checked_log_at.seconds)
                .format('DD/MM/YYYY HH:mm'),
            })
          }
        })
      })
      return returnObj
    },
}
