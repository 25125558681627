<template>
  <div class="form-invoice">
    <el-form
      ref="addCustomerInvoice"
      status-icon
      :model="form"
      :rules="rules"
      label-position="top"
      size="mini"
    >
      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item v-if="branchs.length" label="Filial" prop="branch_id">
            <el-select
              v-model="form.branch_id"
              placeholder="Selecione uma filial"
            >
              <el-option
                v-for="item in branchs"
                :key="item.branch_id"
                :label="item.branch_name"
                :value="item.branch_id"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="14">
          <el-form-item label="Cliente" prop="customer">
            <el-input v-model="form.customer" />
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="CNPJ/CPF" prop="customer_document">
            <el-input v-model="form.customer_document" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="CEP" prop="postal_code">
            <el-input v-model="form.postal_code" @input="fetchAddress" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="Endereço" prop="address1">
            <el-input v-model="form.address1" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="Complemento" prop="address2">
            <el-input v-model="form.address2" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="11">
          <el-form-item label="Bairro" prop="neighborhood">
            <el-input v-model="form.neighborhood" />
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="Cidade" prop="city">
            <el-input v-model="form.city" />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item label="UF" prop="state">
            <el-input v-model="form.state" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="7">
          <el-form-item label="Data de Entrega" prop="delivery_date">
            <el-date-picker
              v-model="form.delivery_date"
              type="date"
              size="small"
            />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item label="DDD" prop="phone_code_area">
            <el-input v-model="form.phone_code_area" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="Telefone" prop="phone">
            <el-input v-model="form.phone" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Contato" prop="delivered_who">
            <el-input v-model="form.delivered_who" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="!isInAModal">
        <el-button type="text" size="mini" @click="invoiceAddVisible = true"
          >Adiciona Documento</el-button
        >
        <addInvoiceDetail
          :visible="invoiceAddVisible"
          :edit="invoiceAddEdit"
          @onClose="handleAddInvoice"
          @onConfirm="handleAddInvoice"
      /></el-row>

      <el-table
        v-if="!isInAModal && invoices.length > 0"
        :data="invoices"
        size="mini"
      >
        <el-table-column prop="order_number" label="Numero Pedido">
        </el-table-column>
        <el-table-column prop="invoice" label="Nota Fiscal"> </el-table-column>
        <el-table-column prop="invoice_total" label="Valor"> </el-table-column>

        <el-table-column>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEditInvoice(scope.row)"
              >Editar</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDeleteInvoice(scope.row)"
              >Apagar</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="success" @click="saveForm()">Salvar</el-button>
        <el-button type="info" @click="cancelForm()">Cancelar</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import fetchAddressByPostalCode from '@/services/fetchAddressByPostalCode'
import addInvoiceDetail from '@/components/Admin/Invoices/components/addInvoice'
import { getPaymentTypeLabel } from '@/helpers/paymentTypes'
import format from '@/helpers/format'
import geocodeAddress from '@/services/geocodeAddress'

export default {
  components: { addInvoiceDetail },
  props: {
    isInAModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    branchId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      form: {},
      branchs: [],
      rules: {
        customer: [{ required: true, message: 'Campo obrigatório' }],
        customer_document: [{ required: false }],
        branch_id: [{ required: true, message: 'Campo obrigatório' }],
        postal_code: [{ required: true, message: 'Campo obrigatório' }],
        address1: [{ required: true, message: 'Campo obrigatório' }],
        neighborhood: [{ required: true, message: 'Campo obrigatório' }],
        city: [{ required: true, message: 'Campo obrigatório' }],
        state: [{ required: true, message: 'Campo obrigatório' }],
        delivery_date: [{ required: true, message: 'Campo obrigatório' }],
      },
      invoices: [],
      invoiceAddVisible: false,
      invoiceAddEdit: null,
    }
  },
  computed: {
    ...mapState('User', ['profile']),
    ...mapGetters('User', ['getCompanieBranches']),
  },
  created() {
    this.branchs = this.getCompanieBranches

    // Form object somente com os campos necesários para update
    this.form = {
      customer: '',
      customer_document: '',
      branch_id: this.isInAModal ? this.branchId : '',
      postal_code: '',
      address1: '',
      address2: '',
      neighborhood: '',
      city: '',
      state: '',
      phone_code_area: '',
      phone: '',
      delivered_who: '',
      delivery_date: this.isInAModal ? new Date() : new Date(),
    }
  },
  watch: {
    isInAModal(newValue) {
      // console.log(new Date())
      if (newValue) {
        this.form = {
          customer: '',
          customer_document: '',
          branch_id: this.branchId,
          postal_code: '',
          address1: '',
          address2: '',
          neighborhood: '',
          city: '',
          state: '',
          phone_code_area: '',
          phone: '',
          delivered_who: '',
          delivery_date: new Date(),
        }
      }
    },
  },
  methods: {
    ...mapActions('Invoices', ['getAndMonitorInvoices']),
    ...mapMutations('Shared', [
      'setError',
      'setSuccessMessage',
      'setInfoMessage',
      'setLoadingMessage',
    ]),
    handleAddInvoice(invoiceForm) {
      if (invoiceForm) {
        let index = this.invoices.findIndex((i) => i === invoiceForm)
        if (index >= 0) {
          this.invoices[index] = invoiceForm
        } else {
          this.invoices.push(invoiceForm)
        }
      }
      this.invoiceAddVisible = false
      this.invoiceAddEdit = null
    },
    handleEditInvoice(invoice) {
      this.invoiceAddEdit = invoice
      this.invoiceAddVisible = true
    },
    handleDeleteInvoice(invoice) {
      this.$confirm('Confirma a remoção do documento?', 'Aviso', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        type: 'warning',
      }).then(() => {
        this.invoices = this.invoices.filter((i) => i !== invoice)
      })
    },
    cancelForm() {
      if (this.isInAModal) {
        this.$emit('onFormAction')
      } else {
        this.$router.go(-1)
      }
    },
    async saveForm() {
      return this.$refs['addCustomerInvoice'].validate(async (valid) => {
        if (valid) {
          let deliveryDate = ''
          if (this.form.delivery_date) {
            deliveryDate = this.$moment(this.form.delivery_date).format(
              'YYYYMMDD'
            )
          }
          if (this.isInAModal) {
            this.form.delivery_date = deliveryDate
            this.$emit('onFormAction', this.form)
          } else {
            const position = await geocodeAddress(
              `${this.form.address1}, ${this.form.neighborhood}, ${this.form.city}, ${this.form.state}`,
              this.form.postal_code
            )

            const invoices = this.invoices.map((i) => {
              let invoiceId = this.form.branch_id
              if (i.order_number.length > 0) {
                invoiceId += i.order_number
              } else {
                invoiceId += i.invoice
              }
              return {
                id: invoiceId,
                data: {
                  ...this.form,
                  ...i,
                  customer_id: `manual${Math.floor(100000000 + Math.random() * 900000000)}`,
                  company_id: this.profile.company_id,
                  payment_type: getPaymentTypeLabel(i.payment_code),
                  payment_code: i.payment_code,
                  invoice_total: format(i.invoice_total, 'CR'),
                  payment_ammount: format(i.payment_ammount, 'CR'),
                  volumes: format(i.volumes, 'CR'),
                  weight: format(i.weight, 'CR'),
                  delivery_date: deliveryDate,
                  invoice_status: 'A',
                  latlng: position,
                  invoice_date: this.$moment().format('YYYYMMDD'),
                  created_at: this.$moment().format('YYYYMMDD H:mm:ss'),
                  zone: '',
                  sector: '',
                  route: '',
                  items: [],
                },
              }
            })
            if (invoices && invoices.length <= 0) {
              this.setError('Informe pelo menos um documento.')
              return
            }

            this.setLoadingMessage('Gravando documento(s)')

            try {
              // const retorno =
              await this.$elastic.postInvoices(invoices)
              await this.getAndMonitorInvoices({
                force: true,
                useDateFilters: true,
                showMsg: true,
              })
              // console.log('retorno', retorno)
              this.setLoadingMessage('')
              this.$router.push({ name: 'InvoicesList' })
            } catch (error) {
              this.setLoadingMessage('')
              console.log(error)
              this.setError('Ocorreu um erro na inclusão')
            }
          }
        } else {
          return false
        }
      })
    },
    async fetchAddress(postalCode) {
      if (postalCode.length !== 8) {
        return
      }

      try {
        const address = await fetchAddressByPostalCode(postalCode)
        this.form.address1 = address.address1
        this.form.address2 = address.address2
        this.form.neighborhood = address.neighborhood
        this.form.city = address.city
        this.form.state = address.state
      } catch (error) {
        console.error('fetchAddress ERROR', error)
      }
    },
  },
}
</script>

<style>
.form-invoice .el-form {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
.form-invoice .el-form-item--mini.el-form-item {
    padding: 0;
    margin-bottom: 8px;
  }
</style>
