<template>
  <div class="cargo-list">
    <global-title-with-filter-bar
      :title="$route.meta.title"
      filtersCargos
      @gFilterSearchClick="searchButtonClick"
      :buttons="[
        {
          text: 'Nova Carga',
          click: () => $router.push('mount'),
          type: 'success',
        },
      ]"
    />
    <el-tabs type="card">
      <el-tab-pane label="Listagem">
        <cargos-data-table
          ref="datatable"
          @cargo-edit-click="cargoEdit"
          @km-edit-click="showKmEditDialog"
          @km-click="showPicture"
          @cargo-id-click="showCargoDetail"
          @status-click="showStatusEditDialog"
          @downloadXLS="DownloadXLS()"
        />
      </el-tab-pane>
      <el-tab-pane label="Acompanhamento" lazy><cargoTracking /></el-tab-pane>
    </el-tabs>

    <picture-dialog ref="pictureDialog" />
    <edit-km-dialog ref="kmEditFormDialod" />
    <edit-status-dialog ref="statusEditFormDialod" />
  </div>
</template>

<script>
// import globalFilterBar from '@/components/Menu/globalFilterBar'
import editKmDialog from './components/editCargoKmDialog'
import editStatusDialog from './components/editCargoStatusDialog'
import cargosDataTable from './components/cargosDataTable'
import pictureDialog from './components/pictureDialog'
import globalTitleWithFilterBar from '@/components/Menu/globalTitleWithFilterBar'
import cargoTracking from './cargoTracking'
import { mapActions } from 'vuex'

export default {
  name: 'cargosIndex',
  components: {
    // globalFilterBar,
    editKmDialog,
    cargosDataTable,
    pictureDialog,
    editStatusDialog,
    globalTitleWithFilterBar,
    cargoTracking,
  },
  data() {
    return {}
  },
  computed: {},
  activated() {
    // console.log('Cargo Index Activated')
  },
  mounted() {
    // console.log('mountedgetAndMonitorCargos')
    this.getAndMonitorCargos({ showMsg: false })
  },
  methods: {
    ...mapActions('Cargos', ['getAndMonitorCargos']),

    cargoEdit(cargoId) {
      this.$router.push({ name: 'CargosMount', params: { cargoId } })
    },
    searchButtonClick() {
      this.getAndMonitorCargos({ showMsg: true, force: true })
    },
    showCargoDetail({ cargoId, branchId }) {
      this.$router.push({
        name: 'CargosDetails',
        params: { branchId, cargoId },
      })
    },
    showStatusEditDialog(data) {
      this.$refs.statusEditFormDialod.showDialog(
        data.row._database.cargo_status,
        data.row
      )
    },
    showKmEditDialog(data) {
      let km = data.row._database[data.field]
      let firestoreDocId = `${data.row._database.branch_id}${data.row._database.cargo_id}`
      let field = data.field
      this.$refs.kmEditFormDialod.showDialog(
        km,
        firestoreDocId,
        field,
        data.row
      )
    },
    showPicture(data) {
      this.$refs.pictureDialog.showDialog(data.row._database[data.field])
    },
    DownloadXLS() {
      this.$refs.datatable.DownloadXLS()
    },
    DownloadCSV() {
      this.$refs.datatable.DownloadCSV()
    },
  },
}
</script>
