<template>
  <el-table
    :data="filiais"
    style="width: 100%;"
    size="mini"
    row-class-name="row-class-branches-data-table"
  >
    <el-table-column
      sortable
      resizable
      prop="id"
      label="doc id"
      width="100"
      fixed
    />
    <el-table-column
      sortable
      resizable
      prop="branch_id"
      label="Id Filial"
      width="110"
      fixed
    />
    <el-table-column
      sortable
      resizable
      prop="cnpj"
      label="CNPJ"
      width="150"
      fixed
    />
    <el-table-column
      sortable
      resizable
      prop="branch_name"
      label="Name"
      width="100"
      fixed
    />
    <el-table-column
      sortable
      resizable
      prop="cargo_id_count"
      label="Seq"
      width="80"
      fixed
    />
    <el-table-column
      sortable
      resizable
      prop="address1"
      label="Endereço"
      width="250"
      fixed
    />
    <el-table-column
      sortable
      resizable
      prop="city"
      label="Cidade"
      width="110"
      fixed
    />
    <el-table-column
      sortable
      resizable
      prop="postal_code"
      label="CEP"
      width="80"
      fixed
    />

    <el-table-column width="150">
      <template slot-scope="scope">
        <el-button-group>
          <el-button
            size="small"
            type="text"
            icon="el-icon-edit"
            @click="handleEdit(scope.row)"
          />
          <el-button
            size="small"
            type="text"
            icon="el-icon-delete"
            disabled
            @click="handleDelete(scope.row)"
          />
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'branches-data-table',
  props: {
    filiais: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    handleEdit(row) {
      this.$emit('edit', row)
    },
    handleDelete(row) {
      this.$confirm(
        `Tem certeza que deseja remover a filial ${row.id}?`,
        'Atenção!',
        { confirmButtonText: 'Sim', cancelButtonText: 'Não', type: 'warning' }
      )
        .then(() => this.$emit('delete', row))
        .catch(() => {})
    },
  },
}
</script>

<style scoped>
.row-class-branches-data-table .cell {
  padding-left: 3px !important;
  padding-right: 3px !important;
  visibility: visible !important;
}
 .row-class-branches-data-table .redcolor {
  color: red;
}
 .row-class-branches-data-table .el-button--text {
  font-size: 12px;
}
 .row-class-branches-data-table .el-tag {
  cursor: pointer;
}
 
</style>
