<template>
  <el-dialog
    class="AddTruckModal"
    :visible.sync="show"
    v-on:update:visible="updateShowDialog"
    width="50%"
    center
    title="Veículos disponíveis"
  >
    <el-row :gutter="20">
      <el-col
        :span="12"
        v-for="(item, index) in OnlyActiveVehicles"
        :key="index"
      >
        <el-card>
          <div slot="header" class="clearfix">
            <span style="font-size: small;">{{ item.truck_id }}</span>
            <el-button
              size="mini"
              style="float: right; padding: 3px 0;"
              type="text"
              @click="addTruck(item)"
              >Adicionar</el-button
            >
          </div>
          {{ item.truck_model }}
        </el-card>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'AddTruckModal',

  props: {
    AllVehicles: Array,
    showDialog: Boolean,
    readerMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: this.showDialog,
    }
  },
  computed: {
    OnlyActiveVehicles() {
      return this.AllVehicles.filter((truck) => truck.is_disabled === false)
    },
  },
  watch: {
    AllVehicles: function (newVal) {
      this.AllVehicles = newVal
      if (this.AllVehicles.length === 0) this.$emit('update:showDialog', false)
    },
  },
  methods: {
    addTruck(item) {
      if (!this.readerMode) {
        this.$emit('changeView', Object.assign({}, item), 'ADD')
      } else {
        this.$emit('changeView', Object.assign({}, item), 'REPLACE')
        this.$emit('update:showDialog', false)
      }
    },
    updateShowDialog(isVisible) {
      if (isVisible) return false
      this.$emit('update:showDialog', false)
    },
  },
}
</script>

<style>
.AddTruckModal {
  max-height: 60vh;
  overflow-y: auto;
}
.AddTruckModa .el-card {
  margin-top: 5px;
}
</style>
