import moment from 'moment'
import { store } from '@/store'
import format from '@/helpers/format'

export default {
  getReportsCargosFin:
    (state, getters, rootState, rootGetters) =>
    () => {
      let returnObj = []
      const newDataFiltered = getters.getAllCargosFiltered.filter((item) => {
        const allowedStatus = ['F', 'V', 'U']
        if (allowedStatus.includes(item.cargo_status)) return true
        else return false
      })

      newDataFiltered.forEach((cargo) => {
        Object.keys(cargo.customers).forEach((indexCustumer) => {
          let customer = cargo.customers[indexCustumer]

          Object.keys(customer.invoices).forEach((indexInvoice) => {
            let invoice = customer.invoices[indexInvoice]

            let concatPayments = ''
            let concatNSU = ''
            Object.keys(invoice.payment).forEach((indexPayment) => {
              let payment = invoice.payment[indexPayment]
              let nsu = payment.nsuNumber ? payment.nsuNumber : false

              if (concatNSU) { concatNSU = nsu ? `${nsu} | ${concatNSU}` : `${concatNSU}` }
              else { concatNSU = nsu ? `${nsu}` : `${concatNSU}` }

              if (indexPayment == 0) {
                concatPayments = `${rootGetters['User/getPaymentName'](payment)} (${format(payment.ammount, 'C')})`
              } else {
                concatPayments = `${concatPayments} | ${rootGetters['User/getPaymentName'](payment)} (${format(payment.ammount, 'C')})`
              }
            })

            let concatPaymentsEdited = '---'
            if (invoice.paymentEdited) {
              Object.keys(invoice.paymentEdited).forEach((indexPayment) => {
                let payment = invoice.paymentEdited[indexPayment]
                let nsu = payment.nsu ? payment.nsu : false

                if (concatNSU) { concatNSU = nsu ? `${nsu} | ${concatNSU}` : `${concatNSU}` }
                else { concatNSU = nsu ? `${nsu}` : `${concatNSU}` }

                if (indexPayment == 0) {
                  concatPaymentsEdited = `${rootGetters['User/getPaymentName'](payment)} (${format(payment.ammount, 'C')})`
                } else {
                  concatPaymentsEdited = `${concatPayments} | ${rootGetters['User/getPaymentName'](payment)} (${format(payment.ammount, 'C')})`
                }
              })
            }

            returnObj.push({
              customer: customer.customer,
              order_number: invoice.order_number,
              invoice: invoice.invoice,
              invoice_ammount: format(invoice.total, 'C'),
              invoice_items:  Object.keys(invoice.items).length,
              invoice_status: rootGetters['Cargos/getCargoDeliveryLabels'](invoice.status),
              truck_id: cargo.truck_id,
              driver_name: rootGetters['User/getDriverName'](cargo.started_by),
              checker_name: cargo.checker,
              branch_id: cargo.branch_id,
              branch_name: rootGetters['User/getCompanieBranchName'](cargo.branch_id),
              cargo_id: cargo.cargo_id,
              cargo_status: rootGetters['Cargos/getCargoStatusLabel'](cargo.cargo_status),
              cargo_started_at: cargo.started_at ? moment.unix(cargo.started_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              cargo_finished_at: cargo.finished_at ? moment.unix(cargo.finished_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              checked_log: customer.checked_log ? 'Sim' : 'Não',
              checked_log_by: customer.checked_log_at ? moment.unix(customer.checked_log_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              checked_log_at: customer.checked_log_by ? customer.checked_log_by.split(' ')[0] : '',
              checked_fin: customer.checked_fin ? 'Sim' : 'Não',
              checked_fin_by: customer.checked_fin_at ? moment.unix(customer.checked_fin_at.seconds).format('DD/MM/YYYY HH:mm') : '',
              checked_fin_at: customer.checked_fin_by ? customer.checked_fin_by.split(' ')[0] : '',
              payments: concatPayments,
              paymentsEdited: invoice.paymentEdited ? 'Sim' : 'Não',
              paymentsEditedTo: concatPaymentsEdited,
              nsu: concatNSU
             })

          })
        })
      })
      return returnObj
    },
}
