export default {
  getAllCargosFiltered(state, getters, rootState) {
    let debug = false
    if (debug) console.log(`getAllCargosFiltered started`)
    let allCargosFiltered = state.allCargos
    let filters = state.filters

    if (allCargosFiltered === undefined || allCargosFiltered === null) {
      console.warn(`Invalid state allCargos in getAllCargosFiltered`)
      allCargosFiltered = []
    }

    // Se existir filtro de numero de carga start
    if (filters.cargostart !== '') {
      if (debug)
        console.log('getAllCargosFiltered cargostart:', filters.cargostart)
      allCargosFiltered = allCargosFiltered.filter((cargo) => {
        let digit = cargo.cargo_id.match(/\d/) || { index: 0 } // index of first digit
        return (
          Number(cargo.cargo_id.substring(digit.index, 60)) >=
          Number(filters.cargostart)
        )
      })
    }

    if (filters.cargoend !== '') {
      if (debug) console.log('getAllCargosFiltered cargoend:', filters.cargoend)
      allCargosFiltered = allCargosFiltered.filter((cargo) => {
        let digit = cargo.cargo_id.match(/\d/) || { index: 0 } // index of first digit
        return (
          Number(cargo.cargo_id.substring(digit.index, 60)) <=
          Number(filters.cargoend)
        )
      })
    }

    // Se existir filtro de status
    if (filters.status.length > 0) {
      if (debug) console.log('getAllCargosFiltered states:', filters.status)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        filters.status.includes(cargo.cargo_status)
      )
    }

    // Se existir filtro de data
    if (Array.isArray(filters.datepicker) && filters.datepicker.length === 2) {
      if (debug) console.log('getAllCargosFiltered dates:', filters.datepicker)
      let start = new Date(filters.datepicker[0])
      let end = new Date(filters.datepicker[1])
      start = start.getTime() / 1000
      end = end.getTime() / 1000
      allCargosFiltered = allCargosFiltered.filter(
        (cargo) =>
          cargo.created_at.seconds > start && cargo.created_at.seconds < end
      )
    }

    // Se existir filtro de filial
    if (filters.branches.length > 0) {
      if (debug) console.log('getAllCargosFiltered branch:', filters.branches)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        filters.branches.includes(cargo.branch_id)
      )
    }

    // Se existir filtro de veiculo
    if (filters.trucks.length > 0) {
      if (debug) console.log('getAllCargosFiltered trucks:', filters.trucks)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        filters.trucks.includes(cargo.truck_id)
      )
    }

    // Se existir filtro de motorista
    if (filters.drivers.length > 0) {
      if (debug) console.log('getAllCargosFiltered driver:', filters.drivers)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        filters.drivers.includes(cargo.started_by)
      )
    }

    // Se existir filtro de conferente
    if (filters.checkers.length > 0) {
      if (debug) console.log('getAllCargosFiltered checker:', filters.checkers)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        filters.checkers.includes(cargo.checker)
      )
    }

    // Se existir filtro de estado
    // Retorna toda a carga que contiver pelo menos um costumer do estado selecionado
    if (filters.states.length > 0) {
      if (debug) console.log('getAllCargosFiltered states:', filters.states)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        Object.values(cargo.customers).some((customer) =>
          filters.states.includes(customer.state.toUpperCase())
        )
      )
    }

    // Se existir filtro de cidade
    // Retorna toda a carga que contiver pelo menos um costumer da cidade selecionado
    if (filters.cities.length > 0) {
      if (debug) console.log('getAllCargosFiltered city:', filters.cities)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        Object.values(cargo.customers).some((customer) =>
          filters.cities.includes(customer.city)
        )
      )
    }

    // Se existir filtro de bairro
    // Retorna toda a carga que contiver pelo menos um costumer do bairro selecionado
    if (filters.neighborhoods.length > 0) {
      if (debug)
        console.log(
          'getAllCargosFiltered neighborhoods:',
          filters.neighborhoods
        )
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        Object.values(cargo.customers).some((customer) =>
          filters.neighborhoods.includes(customer.neighborhood)
        )
      )
    }

    // Se existir filtro de rota
    // Retorna toda a carga que contiver pelo menos um costumer com a rota selecionado
    if (filters.routes && filters.routes.length > 0) {
      if (debug) console.log('getAllCargosFiltered routes:', filters.routes)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        Object.values(cargo.customers).some((customer) =>
          filters.routes.includes(customer.route)
        )
      )
    }

    // Se existir filtro de setores
    // Retorna toda a carga que contiver pelo menos um costumer do setor selecionado
    if (filters.sectors && filters.sectors.length > 0) {
      if (debug) console.log('getAllCargosFiltered sectors:', filters.sectors)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        Object.values(cargo.customers).some((customer) =>
          filters.sectors.includes(customer.sector)
        )
      )
    }

    // Se existir filtro de zona
    // Retorna toda a carga que contiver pelo menos um costumer da zona selecionada
    if (filters.zones && filters.zones.length > 0) {
      if (debug) console.log('getAllCargosFiltered zones:', filters.zones)
      allCargosFiltered = allCargosFiltered.filter((cargo) =>
        Object.values(cargo.customers).some((customer) =>
          filters.zones.includes(customer.zone)
        )
      )
    }
    if (debug)
      console.log(
        'getAllCargosFiltered Resultados sem filtro:',
        state.allCargos.length,
        ' após filtro:',
        allCargosFiltered.length
      )

    return allCargosFiltered
  },
}
