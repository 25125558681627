import { getDefaultBranchState } from './state'

export default {
  resetState(state) {
    // console.log(`Branch state reseted`)
    Object.assign(state, getDefaultBranchState())
  },
  changeBranches(state, payload) {
    // console.log(`User state branches changed`)
    if (Array.isArray(payload)) state.allBranches = payload
    this.commit('User/changeBranchesFromBranchMutation', payload)
  },
  addBranch(state, payload) {
    state.allBranches.push(payload)
    this.commit('User/updateBranchFromBranchMutation', payload)
  },
  updateBranch(state, payload) {
    const branchIndex = state.allBranches.findIndex(
      (branch) => branch.branch_id === payload.branch_id
    )
    if (branchIndex < 0) state.allBranches.push(payload)
    else Object.assign(state.allBranches[branchIndex], payload)
    this.commit('User/updateBranchFromBranchMutation', payload)
  },
}
