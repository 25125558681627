<template>
  <el-dialog
    title="Endereço de Entrega"
    :visible.sync="openGetAddress"
    width="80vw"
    top="3vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <Form
      :isInAModal="openGetAddress"
      :branchId="branchId"
      @onFormAction="formAction"
    />
  </el-dialog>
</template>

<script>
import Form from '../../Invoices/components/form'
export default {
  props: {
    openGetAddress: {
      type: Boolean,
      required: false,
    },
    docAddressModal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      branchId: '',
    }
  },
  components: { Form },
  methods: {
    formAction(form) {
      this.$emit('onCloseModal', form)
    },
  },
  watch: {
    openGetAddress(newValue) {
      if (newValue) {
        this.branchId = this.docAddressModal.branch_id
      }
    },
  },
}
</script>

<style></style>
