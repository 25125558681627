<template>
  <el-dialog
    :title="title"
    :visible.sync="showThisDialog"
    :before-close="onClose"
    center
  >
    <div v-if="subtitle">
      <span><strong>{{subtitle}}</strong></span>
    </div>
    <gmap-map
      v-if="showThisMap"
      :center="dialogMapCoordsCenter.coords"
      :zoom="15"
      map-type-id="terrain"
      style="width: 100%; height: 500px; cursor: pointer;"
      @click="mapClick"
      :clickable="enableClick"
    >
      <gmap-marker
        v-for="(point, index) in dialogMapCoords"
        :key="index"
        :position="point.coords"
        :label="point.type"
        @click="center = dialogMapCoords"
      />
    </gmap-map>
  </el-dialog>
</template>

<script>
export default {
  name: 'map-dialog',
  props: {
    title: {
      default: 'Mapa',
      type: String,
    },
    subtitle: {
      type: String,
      required: false
    },
    enableClick: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showThisDialog: false,
      showThisMap: false,
      dialogMapCoords: null,
      dialogMapCoordsCenter: null,
    }
  },
  computed: {},
  mounted() {
    // console.log('Map Dialog Mounted')
  },
  methods: {
    showDialog(points) {
      const parsedPoints = points.map(point => {
        const lat = parseFloat(point.coords.lat)
        const lng = parseFloat(point.coords.lng)
        return {
          coords: {
            lat,
            lng,
          },
          type: point.type,
        }
      })
      this.dialogMapCoordsCenter = parsedPoints[0]
      this.dialogMapCoords = parsedPoints
      this.showThisDialog = true
      this.showThisMap = true
    },
    mapClick(event) {
      if (!this.enableClick) {
        return
      }
      const latLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      this.dialogMapCoordsCenter.coords = latLng
      this.dialogMapCoords.coords = latLng
      this.showThisMap = false
      setTimeout(() => {
        this.showThisMap = true
      }, 200)
      this.$emit('map-click', latLng)
    },
    onClose() {
      // Run when the dialods close
      this.showThisDialog = false
      this.showThisMap = false
    },
  },
}
</script>
