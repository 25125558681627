<template>
  <div>
    <el-menu
      ref="navmenu"
      class="el-menu-vertical"
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
      :collapse="menuCollapse"
      :router="false"
    >
      <el-menu-item index="Home" @click="handleClick">
        <i class="el-icon-s-data"></i>
        <span>Dashboard</span>
      </el-menu-item>

      <el-menu-item
        index="CompanieTabGeneral"
        @click="handleClick"
        v-if="!lockerUser"
      >
        <icon class="icon" name="building"></icon>
        <span>Empresa</span>
      </el-menu-item>

      <el-menu-item index="CargosList" @click="handleClick">
        <icon class="icon" name="route"></icon>
        <span>Cargas</span>
      </el-menu-item>

      <!-- <el-submenu index="CargosTitle">
        <template slot="title" @click="console.log('clicked1')">
          <icon class="icon" name="route"></icon>
          <span>Cargas</span>
        </template>

        <el-menu-item index="1-1">item one</el-menu-item>
        <el-menu-item index="1-2">item one</el-menu-item>
        <el-menu-item index="1-3">item three</el-menu-item>
      </el-submenu> -->

      <el-menu-item index="InvoicesList" @click="handleClick">
        <i class="el-icon-tickets"></i>
        <span>Documentos</span>
      </el-menu-item>

      <el-menu-item index="TrucksIndex" @click="handleClick">
        <icon class="icon" name="truck-moving"></icon>
        <span>Veículos</span>
      </el-menu-item>

      <el-menu-item index="DriversList" @click="handleClick" v-if="!lockerUser">
        <icon class="icon" name="id-card"></icon>
        <span>Usuários</span>
      </el-menu-item>

      <el-menu-item index="DevicesIndex" @click="handleClick">
        <i class="el-icon-mobile-phone"></i>
        <span>Dispositivos</span>
      </el-menu-item>

      <el-menu-item index="EventsIndex" @click="handleClick">
        <i class="el-icon-news"></i>
        <span>Eventos</span>
      </el-menu-item>

      <el-menu-item index="ReportsIndex" @click="handleClick">
        <i class="el-icon-tickets"></i>
        <span>Relatórios</span>
      </el-menu-item>

      <el-menu-item index="LogsIndex" @click="handleClick" v-if="!lockerUser">
        <i class="el-icon-tickets"></i>
        <span>Logs</span>
      </el-menu-item>

      <el-submenu index="10" disabled v-if="!lockerUser">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>Admin</span>
        </template>

        <el-menu-item-group title="Group One">
          <el-menu-item index="1-1">item one</el-menu-item>
          <el-menu-item index="1-2">item one</el-menu-item>
        </el-menu-item-group>

        <el-menu-item-group title="Group Two">
          <el-menu-item index="1-3">item three</el-menu-item>
        </el-menu-item-group>

        <el-submenu index="1-4">
          <template slot="title">item four</template>
          <el-menu-item index="1-4-1">item one</el-menu-item>
        </el-submenu>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'navMenu',
  props: {
    menuCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('User', ['profile', 'branchsLocked']),
    ...mapState('route', ['name']),
    lockerUser() {
      return Array.isArray(this.branchsLocked) && this.branchsLocked.length > 0
    },
  },
  mounted() {
    this.$refs['navmenu'].updateActiveIndex(this.$route.name)
    // this.$refs['navmenu'].open('CargosTitle')
  },
  methods: {
    ...mapMutations('Shared', ['setError']),
    handleOpen(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClick(menuItem) {
      this.$router.push({ name: menuItem.index })
    },
  },
}
</script>

<style scoped>
.el-menu-vertical:not(.el-menu--collapse) {
  min-width: 200px;
}

.el-submenu .el-menu-item {
  height: 30px;
  line-height: 30px;
}

svg {
  margin-right: 5px;
  width: 24px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .el-menu--collapse {
    display: none;
  }
}
</style>
