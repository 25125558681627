<template>
  <div class="home-dashboard">
    <global-title-with-filter-bar
      title="Dashboard"
      filtersCargos
      @gFilterSearchClick="updateDashboard"
      @cFilterOnUpdate="onUpdatedFilter"
      @cFilterOnUpdateStart="onUpdatedFilterStart"
      @cFilterSearchClick="updateDashboard"
    />

    <div>
      <!-- Update message -->
      <el-row type="flex" justify="center">
        <el-col
          :xs="24"
          :sm="24"
          class="dashboard-texto-aviso dashboard-grid-content"
        >
          <span v-if="updateMessageShow">Atualizando visualização</span>
        </el-col>
      </el-row>

      <!-- Pie Charts 1 -->
      <el-row :gutter="20" class="dashboard-grid-content">
        <el-col :xs="24" :sm="12">
          <g-chart
            type="PieChart"
            :data="getCargosStatusCount"
            :options="chartOptions1"
            :events="chartEvents"
            ref="gChart1"
            @ready="onChartReady1"
          />
        </el-col>
        <el-col :xs="24" :sm="12">
          <g-chart
            type="PieChart"
            :settings="{ current: { language: 'pt_BR' } }"
            :data="getDeliveredCustomersCount"
            :options="chartOptions2"
            :events="chartEvents"
            ref="gChart2"
          />
        </el-col>
      </el-row>

      <!-- Pie Charts 2 -->
      <el-row :gutter="20" class="dashboard-grid-content">
        <el-col :xs="24" :sm="12">
          <g-chart
            type="PieChart"
            :data="getByPlaceTotalsByField('gchart', 'city')"
            :options="chartOptionsDeliveryByCity"
            :events="chartEvents"
            ref="gChartDeliveryByCity"
          />
        </el-col>

        <el-col :xs="24" :sm="12">
          <g-chart
            type="PieChart"
            :data="getByPlaceTotalsByField('gchart', 'state')"
            :options="chartOptionsDeliveryByState"
            :events="chartEvents"
            ref="gChartDeliveryByState"
          />
        </el-col>
      </el-row>

      <!-- Pie Charts Km por veiculo -->
      <el-row :gutter="20" class="dashboard-grid-content">
        <el-col :xs="24" :sm="24">
          <g-chart
            v-if="getAllCargosFiltered.length > 0"
            type="ColumnChart"
            :data="getWorkedHoursAndKMByTruck('gchart')"
            :options="chartOptionsTrucksKmAndHours"
            :events="chartEvents"
            ref="gChartKmHourByTruck"
          />
        </el-col>
      </el-row>

      <!-- Pie Charts Horas por Motorista-->
      <el-row :gutter="20" class="dashboard-grid-content">
        <el-col :xs="24" :sm="24">
          <g-chart
            v-if="getAllCargosFiltered.length > 0"
            type="ColumnChart"
            :data="getByDriverWorkedHoursAndDeliveryCount('gchart')"
            :options="chartOptionsDriversHours"
            :events="chartEvents"
            ref="gChartHourByDriver"
          />
        </el-col>
      </el-row>

      <!-- Line Charts -->
      <el-row :gutter="10" class="dashboard-grid-content">
        <el-col :xs="24" :sm="24">
          <g-chart
            v-if="getAllCargosFiltered.length > 0"
            type="LineChart"
            :data="getDeliveringByHour.chartFormated"
            :options="chartOptionsLineChartDeliverybyHour"
            :events="chartEvents"
            ref="gChartDeliveredByHour"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import globalTitleWithFilterBar from '@/components/Menu/globalTitleWithFilterBar'

// https://github.com/devstark-com/vue-google-charts
// https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
import { GChart } from 'vue-google-charts'

export default {
  name: 'Home',
  components: {
    GChart,
    globalTitleWithFilterBar,
  },
  data() {
    return {
      updateMessageShow: false,
      chartOptions1: {
        title: `Cargas: 0`,
        titleTextStyle: { fontSize: 16 },
        is3D: true,
        backgroundColor: { strokeWidth: 1 },
      },
      chartOptions2: {
        title: `Entregas: 0`,
        titleTextStyle: { fontSize: 16 },
        backgroundColor: { strokeWidth: 1 },
      },
      chartOptionsDeliveryByCity: {
        title: `Notas por cidade`,
        titleTextStyle: { fontSize: 16 },
        backgroundColor: { strokeWidth: 1 },
        sliceVisibilityThreshold: 0.02,
      },
      chartOptionsDeliveryByState: {
        title: `Notas por estado`,
        titleTextStyle: { fontSize: 16 },
        backgroundColor: { strokeWidth: 1 },
        sliceVisibilityThreshold: 0.02,
      },
      chartOptionsLineChartDeliverybyHour: {
        title: `Entregas: 0`,
        titleTextStyle: { fontSize: 16 },
        backgroundColor: { strokeWidth: 1 },
        legend: {
          position: 'top',
          textStyle: { color: 'black', fontSize: 12 },
        },
        animation: {
          startup: true,
          duration: 1000,
        },
      },
      chartOptionsTrucksKmAndHours: {
        title: `Km percorrido e horas trabalhadas por veículos`,
        hAxis: { textStyle: { fontSize: 10 } },
        titleTextStyle: { fontSize: 16 },
        backgroundColor: { strokeWidth: 1 },
        legend: {
          position: 'right',
          textStyle: { color: 'black', fontSize: 12 },
        },
        series: {
          0: { targetAxisIndex: 0 },
          1: { targetAxisIndex: 1 },
        },
        vAxes: {
          0: { title: 'horas' },
          1: { title: 'km' },
        },
      },
      chartOptionsDriversHours: {
        title: `Horas trabalhadas e total de entregas ou cancelamentos por motorista`,
        hAxis: { textStyle: { fontSize: 10 } },
        titleTextStyle: { fontSize: 16 },
        backgroundColor: { strokeWidth: 1 },
        legend: {
          position: 'right',
          textStyle: { color: 'black', fontSize: 12 },
        },
      },
      chartGoogleObj: [],
      chartEvents: {
        select: (selectEvt) => {
          // const table = this.$refs.gChart2.chartObject
          // const selection = table.getSelection()
          // const onSelectionMeaasge =
          //   selection.length !== 0 ? 'row was selected' : 'row was diselected'
          // console.log(selectEvt)
          // console.log(onSelectionMeaasge)
          // console.log(selection)
          // console.log(table)
          // console.log(this.$refs.gChart2)
        },
      },
    }
  },
  computed: {
    ...mapGetters('Cargos', [
      'getCargosStatusCount',
      'getDeliveredCustomersCount',
      'getTotalCustomersCount',
      'getTotalCargos',
      'getDeliveringByHour',
      'getAllCargosFiltered',
      'getWorkedHoursByDriver',
      'getWorkedHoursAndKMByTruck',
      'getByPlaceTotalsByField',
      'getByDriverWorkedHoursAndDeliveryCount',
    ]),
  },
  mounted() {
    this.updateDashboard()
  },
  methods: {
    ...mapActions('Cargos', ['getAndMonitorCargos']),
    onChartReady1(chart, google) {
      this.chartGoogleObj[0] = google
    },
    onUpdatedFilter(payload) {
      this.updateDashboard()
      this.updateMessageShow = false
    },
    onUpdatedFilterStart(payload) {
      this.updateMessageShow = true
    },
    async updateDashboard() {
      await this.getAndMonitorCargos({ showMsg: true, force: true })
      this.chartOptions1.title = `Cargas: ${this.getTotalCargos}`
      this.chartOptions2.title = `Entregas: ${this.getTotalCustomersCount}`
      this.chartOptionsLineChartDeliverybyHour.title = `Entregas: ${this.getDeliveringByHour.full.total} / ${this.getDeliveringByHour.full.ignorados}`
    },
  },
}
</script>

<style>
.dashboard-grid-content {
  min-height: 16px;
  background: transparent;
  margin-bottom: 16px;
}

.dashboard-texto-aviso {
  font-style: italic;
  font-size: 12px;
  text-align: center;
  color: red;
}
</style>
