<template>
  <user-form :user="user" />
</template>

<script>
import Form from '@/components/Admin/Users/components/form'

export default {
  name: 'UsersEdit',
  components: {
    UserForm: Form,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  created() {
    if (!this.user) this.$router.push({ name: 'DriversList' })
  },
}
</script>
