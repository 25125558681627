<template>
  <el-row class="switch-row">
    <el-col :span="11">
      <el-form-item
        :label="label"
        class="switch-item-input"
        v-if="description !== ''"
      >
        <el-tooltip :content="description" placement="top">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item v-else :label="label" class="switch-item-input" />
    </el-col>

    <el-col :span="2">
      <el-form-item class="switch-item-input">
        <el-switch
          v-if="swinput !== undefined"
          v-model="internalSw"
          @change="$emit('update:swinput', internalSw)"
        />
        <span v-else>&nbsp;&nbsp;</span>
      </el-form-item>
    </el-col>

    <el-col :span="2">
      <el-form-item class="switch-item-input">
        <el-switch
          v-if="swinput2 !== undefined"
          v-model="internalSw2"
          @change="$emit('update:swinput2', internalSw2)"
        />
        <span v-else>&nbsp;&nbsp;</span>
      </el-form-item>
    </el-col>

    <el-col :span="2">
      <el-form-item class="switch-item-input">
        <el-switch
          v-if="swinput3 !== undefined"
          v-model="internalSw3"
          @change="$emit('update:swinput3', internalSw3)"
        />
        <span v-else>&nbsp;&nbsp;</span>
      </el-form-item>
    </el-col>

    <el-col :span="7">
      <el-form-item class="switch-item-input">
        <el-input
          v-if="forcetype === 'string' || typeof input === 'string'"
          v-model="internalIn"
          @input="$emit('update:input', internalIn)"
          :disabled="disabled"
        ></el-input>
        <el-input
          v-else-if="forcetype === 'number' || typeof input === 'number'"
          v-model.number="internalIn"
          @input="$emit('update:input', internalIn)"
          :disabled="disabled"
        ></el-input>

        <el-select
          v-else-if="Array.isArray(input)"
          v-model="internalArray"
          multiple
          @change="onSelectUpdate"
          filterable
          allow-create
          default-first-option
          placeholder="Escolha ou digite as opções"
          :disabled="disabled"
        >
          <el-option
            v-for="item in internalArrayOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <span v-else>&nbsp;&nbsp;</span>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'form-switch-item',
  props: {
    swinput: {
      type: Boolean,
      default: undefined,
    },
    swinput2: {
      type: Boolean,
      default: undefined,
    },
    swinput3: {
      type: Boolean,
      default: undefined,
    },
    input: {
      default: undefined,
    },
    label: {
      type: String,
      default: 'Label do item',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forcetype: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      internalSw: false,
      internalSw2: false,
      internalIn: '',
      internalArray: [],
      internalArrayOptions: [],
    }
  },
  computed: {},
  watch: {
    // swinput () { this.internalSw = this.swinput },
    // input () { this.updateInternalInput(this.input) }
  },
  created() {
    this.internalSw = this.swinput
    this.internalSw2 = this.swinput2
    this.internalSw3 = this.swinput3
    this.updateInternalInput(this.input)
  },
  methods: {
    updateInternalInput(input) {
      if (Array.isArray(this.input)) {
        this.input.forEach((element) => {
          if (typeof element === 'string') {
            this.internalArray.push(element)
            this.internalArrayOptions.push({ value: element, label: element })
          }
        })
      } else {
        this.internalIn = this.input
      }
    },
    onSelectUpdate() {
      this.$emit('update:input', this.internalArray)
      this.internalArrayOptions = []
      this.internalArray.forEach((element) => {
        if (typeof element === 'string') {
          this.internalArrayOptions.push({ value: element, label: element })
        }
      })
    },
  },
}
</script>

<style scoped>
.switch-item-input {
  margin-bottom: 0px;
}

.switch-row:hover {
  background-color: #f5f7fa;
}
</style>
