<template>
  <div>
    <el-dialog
      title="Dados documento"
      width="60%"
      :visible.sync="visible"
      :before-close="() => $emit('onClose')"
    >
      <el-form
        ref="addInvoice"
        :model="form"
        size="mini"
        :rules="rules"
        status-icon
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Numero Pedido" prop="order_number">
              <el-input
                v-model="form.order_number"
                :disabled="edit !== null"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Nota Fiscal" prop="invoice">
              <el-input
                v-model="form.invoice"
                autocomplete="off"
                :disabled="edit !== null"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Chave NF-e" prop="order_observanfe_keytions">
              <el-input v-model="form.nfe_key" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Valor do documento" prop="invoice_total">
              <el-input
                v-model.lazy="form.invoice_total"
                v-money="moneyFormat"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Valor a receber" prop="payment_ammount">
              <el-input
                v-model.lazy="form.payment_ammount"
                v-money="moneyFormat"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Forma pagamento" prop="payment_code">
              <el-select v-model="form.payment_code">
                <el-option
                  v-for="item in paymenTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Volumes" prop="volumes">
              <el-input
                v-model.lazy="form.volumes"
                v-money="numberFormat"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Peso" prop="weight">
              <el-input
                v-model.lazy="form.weight"
                v-money="numberFormat"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col>
            <el-form-item label="Observações" prop="order_observations">
              <el-input type="textarea" v-model="form.order_observations" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="close">Cancelar</el-button>
        <el-button type="success" @click="confirm">Confirma</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { paymenTypes as paymenTypesBase } from '@/helpers/paymentTypes'
import { VMoney } from 'v-money'
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Object,
      required: false,
    },
  },
  directives: { money: VMoney },
  data() {
    return {
      dialogFormVisible: true,
      form: null,
      paymenTypes: paymenTypesBase,
      rules: {
        order_number: [{ validator: this.validateKeys, trigger: 'blur' }],
        invoice: [{ validator: this.validateKeys, trigger: 'blur' }],
      },
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      numberFormat: {
        decimal: ',',
        thousands: '.',
        precision: 2,
      },
    }
  },
  methods: {
    validateKeys(rule, value, callback) {
      if (!this.form.order_number.trim() && !this.form.invoice.trim()) {
        callback(new Error('Pedido e Nota Fiscal em branco'))
      } else {
        callback()
      }
    },
    confirm() {
      this.$refs['addInvoice'].validate(async (valid) => {
        if (valid) {
          this.$emit('onConfirm', this.form)
        }
      })
    },
    close() {
      this.$emit('onClose')
    },
    initForm() {
      if (this.edit) {
        this.form = this.edit
      } else {
        this.form = {
          invoice: '',
          order_number: '',
          nfe_key: '',
          invoice_total: 0,
          payment_ammount: 0,
          payment_code: '',
          volumes: 0,
          weight: 0,
          order_observations: '',
        }
      }
      if (this.$refs['addInvoice']) {
        this.$refs['addInvoice'].clearValidate()
      }
    },
  },
  beforeMount() {
    this.initForm()
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.initForm()
      }
    },
  },
}
</script>

<style></style>
