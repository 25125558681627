<template>
  <!-- Coluna de opções do Dashboard -->
  <el-row :gutter="5">
    <el-col :xs="24" :sm="7">
      <el-date-picker
        size="small"
        disabled
        v-model="dateRangeInternal"
        type="daterange"
        align="left"
        :unlink-panels="false"
        range-separator="-"
        start-placeholder="Data inicial"
        end-placeholder="Data final"
        format="dd/MM/yyyy"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
        :picker-options="pickerDateOptions"
      >
      </el-date-picker>
    </el-col>

    <el-col :xs="24" :sm="3" style="padding-left: 20px;">
      <el-select
        v-model="usersSelectedInternal"
        size="small"
        multiple
        filterable
        disabled
        collapse-tags
        placeholder="Usuário"
      >
        <el-option
          v-for="item in allDrivers"
          :key="item.login"
          :label="item.name"
          :value="item.auth_uid"
        >
        </el-option>
      </el-select>
    </el-col>

    <el-col :xs="24" :sm="3">
      <el-select
        v-model="usersSelectedInternal"
        size="small"
        multiple
        filterable
        disabled
        collapse-tags
        placeholder="Veículo"
      >
        <el-option
          v-for="item in allDrivers"
          :key="item.login"
          :label="item.name"
          :value="item.auth_uid"
        >
        </el-option>
      </el-select>
    </el-col>

    <el-col :xs="24" :sm="3">
      <el-select
        v-model="usersSelectedInternal"
        size="small"
        multiple
        filterable
        disabled
        collapse-tags
        placeholder="Filial"
      >
        <el-option
          v-for="item in allDrivers"
          :key="item.login"
          :label="item.name"
          :value="item.auth_uid"
        >
        </el-option>
      </el-select>
    </el-col>

    <el-col :xs="24" :sm="3">
      <el-select
        v-model="usersSelectedInternal"
        size="small"
        multiple
        filterable
        disabled
        collapse-tags
        placeholder="Tipo de evento"
      >
        <el-option
          v-for="item in allDrivers"
          :key="item.login"
          :label="item.name"
          :value="item.auth_uid"
        >
        </el-option>
      </el-select>
    </el-col>

    <el-col :xs="24" :sm="4">
      <el-button-group>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          plain
          @click="searchClick"
        ></el-button>
        <el-button
          size="small"
          v-if="!isListening"
          type="primary"
          icon="el-icon-refresh"
          plain
          @click="refreshClick"
        ></el-button>
        <el-button
          size="small"
          v-if="isListening"
          type="primary"
          icon="el-icon-loading"
          plain
          @click="stopClick"
        ></el-button>
      </el-button-group>
    </el-col>
  </el-row>
</template>

<script>
import { mapState } from 'vuex'
import dates from '@/helpers/getDateRange'

export default {
  name: 'filterBar',
  props: {},
  data() {
    return {
      pickerDateOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '1 dia',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '7 dias',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '30 dias',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      usersSelectedInternal: [],
      dateRangeInternal: [dates.d.startDateTodayFull, dates.d.endDateFull],
      isListening: false,
    }
  },
  computed: {
    ...mapState('Drivers', ['allDrivers']),
    formatedUserSelect() {
      return this.usersSelectedInternal.length > 0
        ? this.usersSelectedInternal
        : ['*']
    },
  },
  methods: {
    async searchClick() {
      this.$emit('searchClick', {
        usersFilter: this.formatedUserSelect,
        startDateFilter: this.dateRangeInternal[0],
        endDateFilter: this.dateRangeInternal[1],
      })
    },
    async refreshClick() {
      this.isListening = true
      this.$emit('refreshClick', {
        usersFilter: this.formatedUserSelect,
        startDateFilter: this.dateRangeInternal[0],
        endDateFilter: this.dateRangeInternal[1],
      })
    },
    async stopClick() {
      this.isListening = false
      this.$emit('stopClick')
    },
  },
}
</script>
