import Vue from 'vue'
import Router from 'vue-router'
import t from 'typy'
import { fbapp } from '@/config/firebase'
import { store } from '@/store'

import Home from '@/components/Home'
import Login from '@/components/Login'
import Logout from '@/components/Logout'
import Install from '@/components/General/Install'
import Reload from '@/components/General/Reload'
import About from '@/components/General/About'

import Cargos from '@/components/Admin/Cargos/index.vue'
import CargosDetails from '@/components/Admin/Cargos/details.vue'
import CargosDetailsConferente from '@/components/Admin/Cargos/conferencia.vue'
import CargosList from '@/components/Admin/Cargos/list.vue'
import CargosMount from '@/components/Admin/Cargos/mount/mount.vue'

import Users from '@/components/Admin/Users/index.vue'
import Drivers from '@/components/Admin/Users/drivers.vue'
import Admins from '@/components/Admin/Users/admins.vue'
import Checkers from '@/components/Admin/Users/checkers.vue'
import UsersAdd from '@/components/Admin/Users/add.vue'
import UsersEdit from '@/components/Admin/Users/edit.vue'

import Events from '@/components/Admin/Events/index.vue'
import EventDetail from '@/components/Admin/Events/detail.vue'
import Logs from '@/components/Admin/Logs/index.vue'
// import Reports from '@/components/Admin/Reports/index.vue'

import Devices from '@/components/Admin/Devices/index.vue'
import DevicesList from '@/components/Admin/Devices/list.vue'

import Invoices from '@/components/Admin/Invoices/index.vue'
import InvoicesAdd from '@/components/Admin/Invoices/add.vue'
import InvoicesList from '@/components/Admin/Invoices/list.vue'

import Trucks from '@/components/Admin/Trucks/index.vue'
import TrucksAdd from '@/components/Admin/Trucks/add.vue'
import TrucksEdit from '@/components/Admin/Trucks/edit.vue'
import TrucksList from '@/components/Admin/Trucks/list.vue'

import Companie from '@/components/Admin/Companie/index.vue'

import openStore from '@/services/localForageService'
const storeParams = openStore('params')

Vue.use(Router)

function lazyLoadComponent(view) {
  return () => import(`@/components/Admin/${view}.vue`)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { requiresAuth: true },
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: { requiresAuth: false },
    },
    {
      path: '/login/:comp?',
      name: 'Login',
      component: Login,
      meta: { requiresAuth: false },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
      meta: { requiresAuth: false },
    },
    {
      path: '/install',
      name: 'Install',
      component: Install,
      meta: { requiresAuth: false },
    },
    {
      path: '/reload',
      name: 'Reload',
      component: Reload,
      meta: { requiresAuth: false },
    },
    {
      path: '/cargos',
      component: Cargos,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/' },
      children: [
        {
          path: '',
          name: 'CargosList',
          component: CargosList,
          meta: { title: 'Cargas' },
        },
        {
          path: 'mount',
          name: 'CargosMount',
          component: CargosMount,
          meta: { title: 'Montar Carga' },
        },
        {
          path: 'mount/reader',
          name: 'CargosMountWithReader',
          component: CargosMount,
          meta: { title: 'Montar carga' },
        },
        {
          path: 'edit/:branchId/:cargoId',
          name: 'CargosEdit',
          component: CargosMount,
          meta: { title: 'Editar Carga' },
        },
        {
          path: ':branchId/:cargoId',
          name: 'CargosDetails',
          component: CargosDetails,
          meta: { title: 'Detalhes da carga' },
        },
        {
          path: 'conference/:branchId/:cargoId',
          name: 'CargosDetailsConferente',
          component: CargosDetailsConferente,
          meta: { title: 'Conferência da Carga' },
        },
      ],
    },
    {
      path: '/users',
      component: Users,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/' },
      children: [
        {
          path: 'drivers',
          name: 'DriversList',
          component: Drivers,
          meta: { title: 'Motoristas' },
        },
        {
          path: 'admins',
          name: 'AdminsList',
          component: Admins,
          meta: { title: 'Administradores' },
        },
        {
          path: 'checkers',
          name: 'CheckersList',
          component: Checkers,
          meta: { title: 'Conferentes' },
        },
        {
          path: 'add',
          name: 'UsersAdd',
          component: UsersAdd,
          meta: { title: 'Adicionar novo Usuário' },
        },
        {
          path: 'edit',
          props: true,
          name: 'UsersEdit',
          component: UsersEdit,
          meta: { title: 'Atualizar Usuário' },
        },
      ],
    },
    {
      path: '/trucks',
      component: Trucks,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/' },
      children: [
        {
          path: '',
          name: 'TrucksIndex',
          component: TrucksList,
          meta: { title: 'Veículos' },
        },
        {
          path: 'add',
          name: 'TrucksAdd',
          component: TrucksAdd,
          meta: { title: 'Adicionar novo Veículo' },
        },
        {
          path: 'edit',
          props: true,
          name: 'TrucksEdit',
          component: TrucksEdit,
          meta: { title: 'Atualizar Veículo' },
        },
      ],
    },
    {
      path: '/events',
      name: 'EventsIndex',
      component: Events,
      meta: {
        requiresAuth: true,
        onErrorValidateRouteTo: '/',
        title: 'Eventos',
      },
    },
    {
      path: '/events/detail',
      name: 'EventDetail',
      component: EventDetail,
      props: true,
      meta: {
        requiresAuth: true,
        onErrorValidateRouteTo: '/',
        title: 'Detalhes do Evento',
      },
    },
    {
      path: '/logs',
      name: 'LogsIndex',
      component: Logs,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/', title: 'Logs' },
    },
    {
      path: '/reports',
      name: 'ReportsIndex',
      // component: Reports,
      component: lazyLoadComponent('Reports/index'),
      meta: {
        requiresAuth: true,
        onErrorValidateRouteTo: '/',
        title: 'Reatórios',
      },
    },
    {
      path: '/devices',
      component: Devices,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/' },
      children: [
        {
          path: '',
          name: 'DevicesIndex',
          component: DevicesList,
          meta: { title: 'Dispositivos' },
        },
      ],
    },
    {
      path: '/invoices',
      component: Invoices,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/' },
      children: [
        {
          path: '',
          name: 'InvoicesList',
          component: InvoicesList,
          meta: { title: 'Documentos' },
        },
        {
          path: 'add',
          name: 'InvoicesAdd',
          component: InvoicesAdd,
          meta: { title: 'Adicionar Documento(s)' },
        },
      ],
    },
    {
      path: '/companie',
      component: Companie,
      children: [
        {
          path: '',
          name: 'CompanieTabGeneral',
          meta: {
            requiresAuth: true,
            onErrorValidateRouteTo: '/',
            title: 'Configurações gerais da Empresa',
            tabName: 'tabGeneral',
          },
        },
        {
          path: 'branches',
          name: 'CompanieTabBranches',
          meta: {
            requiresAuth: true,
            onErrorValidateRouteTo: '/',
            title: 'Empresa',
            tabName: 'tabBranches',
            showAddBranchForm: false,
          },
        },
        {
          path: 'branches/add',
          name: 'CompanieTabBranchesAdd',
          props: true,
          meta: {
            requiresAuth: true,
            onErrorValidateRouteTo: '/',
            title: 'Empresa',
            tabName: 'tabBranches',
            showAddBranchForm: true,
          },
        },
        {
          path: 'branches/edit/:id',
          name: 'CompanieTabBranchesEdit',
          props: true,
          meta: {
            requiresAuth: true,
            onErrorValidateRouteTo: '/companie/branches',
            title: 'Empresa',
            tabName: 'tabBranches',
            showAddBranchForm: true,
          },
        },
        {
          path: 'config/notifications',
          name: 'CompanieTabNotificationsSettings',
          meta: {
            requiresAuth: true,
            onErrorValidateRouteTo: '/',
            title: 'Empresa',
            tabName: 'tabNotifications',
          },
        },
        {
          path: 'config/general',
          name: 'CompanieTabGeneralSettings',
          meta: {
            requiresAuth: true,
            onErrorValidateRouteTo: '/',
            title: 'Empresa',
            tabName: 'tabConfigurations',
          },
        },
        {
          path: 'config/events',
          name: 'CompanieTabEventsSettings',
          meta: {
            requiresAuth: true,
            onErrorValidateRouteTo: '/',
            title: 'Empresa',
            tabName: 'tabEvents',
          },
        },
      ],
    },
  ],
})

// Função para recarregar os dados do usuários e empresa quando navegador é encerrado e os dados do state perdidos
function reloadProfiles(user) {
  return new Promise((resolve, reject) => {
    store.commit('Shared/setLoadingMessage', 'Carregando...', { root: true })
    storeParams
      .getItem('companieDnsName')
      .then((companiename) =>
        store.dispatch('User/getCompanieByDnsName', {
          dnsname: companiename,
          bypassRemoveMsg: true,
          bypassAddMsg: true,
        })
      )
      .then((companie) => store.dispatch('User/getUserProfile', user))
      .then((profile) => {
        store.commit('Shared/setLoadingMessage', '', { root: true })
        resolve(profile)
      })
  })
}

// Auth
router.beforeEach((to, from, next) => {
  // Verifica se rota necessita de autenticação
  if (to.matched.some((rec) => rec.meta.requiresAuth)) {
    // Verifica se profile do usuário esta carregado no state e na lib do firebase, se sim... continua
    // Veja fbapp.auth().onAuthStateChanged carregado no main.js para garantir a inicialização do auth antes do app
    if (
      t(store, 'state.User.profile.login').isDefined &&
      fbapp.auth().currentUser
    ) {
      next()
      // Se não, verifica se apenas o auth().currentUser esta carregado, se sim, recarrega o profile do usuário do firestore
    } else if (fbapp.auth().currentUser) {
      // console.log(`Reloading user from router with auth().currentUser`)
      reloadProfiles(fbapp.auth().currentUser).then((profile) =>
        next({ name: 'Home' })
      )
    } else {
      // Force logout and route to login page
      store.dispatch('User/userLogout', {
        bypassRouter: false,
        bypassLog: true,
      })
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  const user = store.state.User.profile || false
  if (user && to.name === 'Login') {
    router.forward()
  } else {
    next()
  }
})

export default router
