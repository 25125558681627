<template>
  <div class="cargo-conference">
    <global-title-with-filter-bar
      :title="title"
      hidedate
      :buttons="[{ text: 'Voltar', click: () => $router.go(-1) }]"
    />

    <cargos-conference
      :cargoid="$route.params.cargoId"
      :branchid="$route.params.branchId"
      @km-edit-click="showKmEditDialog"
      @km-click="showPicture"
      @show-map="showMap"
    />
    <picture-dialog ref="pictureDialog" />
    <map-dialog ref="mapDialog" />
    <edit-km-dialog ref="kmEditFormDialod" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import editKmDialog from './components/editCargoKmDialog'
import mapDialog from './components/mapDialog'
import pictureDialog from './components/pictureDialog'
import cargosConference from './components/cargosConference'
import globalTitleWithFilterBar from '@/components/Menu/globalTitleWithFilterBar'

export default {
  name: 'cargosIndex',
  components: {
    editKmDialog,
    mapDialog,
    pictureDialog,
    cargosConference,
    globalTitleWithFilterBar,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('Cargos', ['allCargos']),
    title() {
      return `Conferencia da Carga ${this.$route.params.cargoId}`
    },
    cargo() {
      return this.allCargos.find(
        (cargo) => cargo.cargo_id === this.$route.params.cargoId
      )
    },
  },
  methods: {
    showKmEditDialog(data) {
      let km = data.row._database[data.field]
      let cargoid = `${data.row._database.branch_id}${data.row._database.cargo_id}`
      let field = data.field
      this.$refs.kmEditFormDialod.showDialog(km, cargoid, field, data.row)
    },
    showMap(points) {
      this.$refs.mapDialog.showDialog(points)
    },
    showPicture(data) {
      this.$refs.pictureDialog.showDialog(data.row[data.field])
    },
  },
}
</script>
