<template>
  <div>
    <cargos-resume :cargo="cargoDetailsFormated" />

    <el-table :data="cargoDetail" size="mini" :row-class-name="rowClass">
      <el-table-column
        property="sequenceofdelivery"
        label="#"
        width="30"
      ></el-table-column>

      <el-table-column label="Cliente/Evento" width="340">
        <template slot-scope="scope">
          <el-button type="text" @click="showDrawer(scope.row)">
            {{ scope.row.customer }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column prop="status" label="Status" width="128" align="center">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.statusColor"
            disable-transitions
            size="mini"
            style="width: 125px;"
            >{{ scope.row.statusLabel }}</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        property="invoicesQuantity"
        label="Notas"
        width="60"
        align="right"
      ></el-table-column>
      <el-table-column
        property="invoicesTotal"
        label="Valor Nota/Evento"
        width="120"
        align="right"
      ></el-table-column>

      <el-table-column label="Valor Recebido" width="130" align="right">
        <template slot-scope="scope">
          {{ scope.row.amountreceived }}
          <i
            v-if="scope.row.status !== 'event' && scope.row.amountreceived < scope.row.invoicesTotal"
            class="el-icon-warning-outline redcolor"
          />
          <i
            v-if="scope.row.status !== 'event' && scope.row.amountreceived > scope.row.invoicesTotal"
            class="el-icon-warning-outline bluecolor"
          />
        </template>
      </el-table-column>

      <el-table-column
        property="delivered_who"
        label="Recebedor"
        width="130"
        align="center"
      ></el-table-column>
      <el-table-column
        property="weight"
        label="Peso"
        width="80"
        align="right"
      ></el-table-column>
      <el-table-column label="KM" width="100" align="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="kmClick({ row: scope.row, field: 'delivered_km_photo_url' })"
          >
            {{ scope.row.delivered_km }}
          </el-button>
          <i
            v-if="scope.row.status !== 'event'"
            class="el-icon-edit"
            @click="kmEditClick(scope.row, 'delivered_km')"
            :class="{ redcolor: scope.row.startedKm_why }"
          />
        </template>
      </el-table-column>
      <el-table-column
        property="traveledKm"
        label="KM Percorrido"
        width="110"
        align="right"
      ></el-table-column>
      <el-table-column
        property="deliveringAt"
        label="Hora Checkin"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column
        property="deliveredAt"
        label="Hora Checkout"
        width="112"
        align="center"
      ></el-table-column>
      <el-table-column
        property="deliveryDuration"
        label="Duração"
        width="100"
        align="center"
      ></el-table-column>

      <el-table-column label="Mapa" width="120" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="
              (scope.row.hasOwnProperty('delivering_geo') &&
              scope.row.delivering_geo) ||
                            (scope.row.hasOwnProperty('deliverGeo') &&
              scope.row.deliverGeo)
            "
            circle
            icon="el-icon-location"
            size="mini"
            @click="showMapClick(scope.row.deliverGeo)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <cargo-drawer
      :visible.sync="drawer"
      :drawerdata="drawerdata"
      @km-edit-click="kmEditClick"
      @km-click="kmClick"
      @show-map="showMapClick"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import detailsTablePrepare from './_customerDetailsTablePrepare'
import cargoDrawer from './cargosDrawer'
import cargosDataTable from './_cargoDataTablePrepare'
import cargosResume from './cargosResume'

export default {
  name: 'cargos-details',
  components: {
    cargoDrawer,
    cargosResume,
  },
  props: {
    cargoid: {
      type: String,
      required: true,
    },
    branchid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      drawer: false,
      drawerdata: {},
      events: []
    }
  },
  computed: {
    ...mapState('Cargos', ['allCargos']),
    cargoData() {
      return this.allCargos.find((cargo) => cargo.cargo_id === this.cargoid)
    },
    cargoDetail() {
      return detailsTablePrepare(this.allCargos, this.cargoid, this.branchid, this.events)
        .details
    },
    cargoDetailsFormated() {
      return cargosDataTable(this.allCargos, this.cargoid)[0]
    },
    includesEventsInTheCargoDetail() {
      return this.$store.state.User.companie.settings.general.includesEventsInTheCargoDetail
    }
  },
  methods: {
    ...mapActions("Events", ["getEventsOfCargo"]),
    showMapClick(points) {
      this.$emit('show-map', points)
    },
    kmEditClick(row, field) {
      this.$emit('km-edit-click', {
        row,
        field,
        fieldArray: "customers",
        fieldCompare: "sequenceofdelivery",
        valueCompare: row.sequenceofdelivery
      })
    },
    kmClick(d) {
      this.$emit('km-click', d)
    },
    showDrawer(row) {
      if(row.status === 'event') {
        this.$router.push({ name: 'EventDetail', params: { eventDetail: row.event } })
      } else {
        this.drawerdata = row
        this.drawer = true
      }
    },
    rowClass({ row, rowIndex }) {
      if (
        row.status === 'canceled' ||
        row.invoicesPartial >= 1 ||
        row.invoicesCanceled >= 1
      ) {
        return 'mr-data-table-row warning-row'
      } else {
        return 'mr-data-table-row'
      }
    },
  },
  async mounted() {
    const { cargo_id: cargoId, branch_id: branchId } = this.cargoData
    if(this.includesEventsInTheCargoDetail) {
      this.events = await this.getEventsOfCargo({ cargoId, branchId })
    } else {
      this.events = []
    }
  },
}
</script>

<style>
.cargo-details-box-card p,
.lineDetails {
  line-height: 14px;
  font-size: 12px;
}

.cargo-details-box-card p.titulo {
  margin-top: 30px;
}

.cargo-details-box-card th,
.cargo-details-box-card td {
  padding: 0 !important;
}

.cargo-details-box-card .el-card__header {
  padding: 10px 12px;
  font-weight: bold;
}

.cargo-details-box-card .mini-text {
  font-size: 10px;
  font-weight: normal;
  padding: 0;
  line-height: 15px;
}

.cargo-details-box-card .el-card__body {
  padding: 12px;
}

.cargo-details-box-card .status-details {
  margin-left: 10px;
}

.cargo-details-box-card .status-details-red {
  margin-left: 10px;
  color: red;
}

.cargo-details-box-card .clearfix:before,
.cargo-details-box-card .clearfix:after {
  display: table;
  content: '';
}

.cargo-details-box-card .clearfix:after {
  clear: both;
}

.cargo-details-box-card {
  margin-bottom: 8px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
