<template>
  <div>
    <div class="header">
      <h1>{{ $route.meta.title }}</h1>
      <div class="menu">
        <el-button-group>
          <el-button
            size="small"
            type="primary"
            @click="$router.push({ name: 'DriversList' })"
            icon="el-icon-tickets"
            >Motoristas</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="$router.push({ name: 'AdminsList' })"
            icon="el-icon-star-off"
            >Administradores</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="$router.push({ name: 'CheckersList' })"
            icon="el-icon-star-off"
            >Conferentes</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="$router.push({ name: 'UsersAdd' })"
            icon="el-icon-plus"
            >Adicionar</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="updateDriverList()"
            icon="el-icon-refresh"
            >Atualizar</el-button
          >
        </el-button-group>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'DriversList',
  methods: {
    ...mapActions('Drivers', ['getDrivers']),
    ...mapActions('Admins', ['getAdmins']),
    ...mapActions('Checkers', ['getCheckers']),
    ...mapMutations('Shared', ['setLoadingMessage']),
    async updateDriverList() {
      if (this.$route.name === 'AdminsList') {
        this.setLoadingMessage('Buscando usuários admins...')
        await this.getAdmins()
      } else if (this.$route.name === 'CheckersList') {
        this.setLoadingMessage('Buscando usuários conferentes...')
        await this.getCheckers()
      } else {
        this.setLoadingMessage('Buscando motoristas...')
        await this.getDrivers()
      }
      this.setLoadingMessage('')
    },
  },
}
</script>

<style scoped>
.el-main > div {
    width: 100%;
}

.el-main > div .header {
      width: 100%;
      display: flex;
      position: relative;
      padding-top: 10px;
}

.el-main > div .header h1 {
  width: 100%;
  padding-top: 5px;
}

.el-main > div .header .menu {
  position: absolute;
  right: 0;
}
</style>
