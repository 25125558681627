import { store } from '@/store'
import lodash from 'lodash'

const unsubscribeOnSnapshot = []
const debug = false

function queryUnsubscribe(commitOnSnapshot) {
  if (unsubscribeOnSnapshot[commitOnSnapshot]) {
    unsubscribeOnSnapshot[commitOnSnapshot]()
    if (debug) console.log('dbQuery Limpando onSnapShot', commitOnSnapshot)
  }
}

function pushDocs(querySnapshot, commit = false, resolver = null) {
  let docs = []
  querySnapshot.forEach((doc) => {
    if (doc.id !== '--stat--') {
      docs.push(Object.assign({ id: doc.id.trim() }, doc.data()))
    }
  })

  // Se commit contem valor, executa o commit do state, usado no onSnapshot
  if (commit) {
    if (debug) console.log(`dbQuery Commiting to ${commit}`)
    store.commit(commit, docs)
  }

  // Se enviado um call back resolve de uma promise, executa agora, usado no onSnapshot
  if (typeof resolver === 'function' && resolver !== null) {
    resolver(docs)
    resolver = null
  }

  if (debug)
    console.log(
      `dbQuery search to`,
      `/${querySnapshot.query._query.path.segments
        .slice(2)
        .join('/')} finished `,
      `with ${docs.length} docs in results`
    )
  return docs
}

async function query(
  queryRef,
  applyDateFilter = false,
  datefield = 'created_at',
  commitOnSnapshot = false,
  force = false
) {
  // Se já existir um listner cadastrado, não cria um novo
  // Para forçar um novo, envie true no utimo parâmetro
  if (unsubscribeOnSnapshot[commitOnSnapshot] && !force) {
    if (debug)
      console.log(
        `dbQuery snapshot to ${commitOnSnapshot} already running... canceling search again`
      )
    return new Promise((resolve) =>
      resolve({ promise: new Promise((resolve) => resolve([])) })
    )
  }

  const branchsLocked = store.state.User.branchsLocked
  const branchFilter = store.state.Shared.searchBranchsFilter

  let finalFilterApplyed = []
  let collectionName = '?'
  let companyIdPathQuery = '?'
  let firebaseProjetctQuery = lodash.get(
    queryRef,
    'firestore._databaseId.projectId',
    undefined
  )
  let startDate = '*'
  let endDate = '*'

  // Se necessário aplicar filtro de datas
  if (applyDateFilter) {
    if (debug)
      console.log(
        'dbQuery Filtrando desde ',
        store.state.Shared.searchStartDateFilter,
        'dbQuery Filtrando até ',
        store.state.Shared.searchEndDateFilter
      )

    let testeStart = store.state.Shared.searchStartDateFilter
    let testeEnd = store.state.Shared.searchEndDateFilter

    if (
      !testeStart ||
      testeStart === null ||
      testeStart === undefined ||
      testeStart === 'NaN-NaN-NaN NaN:NaN:NaN'
    ) {
      startDate = new Date()
      startDate.setHours(0, 0, 0, 1)
    } else {
      startDate = new Date(store.state.Shared.searchStartDateFilter)
    }

    if (
      !testeEnd ||
      testeEnd === null ||
      testeEnd === undefined ||
      testeEnd === 'NaN-NaN-NaN NaN:NaN:NaN'
    ) {
      endDate = new Date()
      endDate.setHours(23, 59, 59, 999)
    } else {
      endDate = new Date(store.state.Shared.searchEndDateFilter)
    }

    if (startDate) queryRef = queryRef.where(datefield, '>=', startDate)
    if (startDate) queryRef = queryRef.where(datefield, '<=', endDate)
  }

  // Recupera nome da collection para debug
  if (Array.isArray(lodash.get(queryRef, '_query.path.segments', undefined))) {
    collectionName = queryRef._query.path.segments.slice(2).join('/')
    companyIdPathQuery = queryRef._query.path.segments[1]
  }

  // Se existe lock do usuário com filiais e se filtro de consulta aplicado
  // Retorna array com resultado do filtro aplicado somente as filiais permitidas
  if (branchsLocked.length > 0 && branchFilter[0] !== '*') {
    finalFilterApplyed = branchFilter.filter((branch) =>
      branchsLocked.includes(branch)
    )

    // Se existe lock do usuário com filiais e nenhum filtro de consulta foi aplicado
    // Retorna array somente com as branchs bloqueadas para filtro
  } else if (branchsLocked.length > 0 && branchFilter[0] === '*') {
    finalFilterApplyed = [...branchsLocked]

    // Se não... não existe lock no usuário e todo filtro pode ser aplicado
  } else {
    finalFilterApplyed = [...branchFilter]
  }

  // Antes de gravar novo snapshot, remove o anterior
  if (commitOnSnapshot) {
    if (unsubscribeOnSnapshot[commitOnSnapshot]) {
      // console.log('dbQuery Limpando onSnapShot', commitOnSnapshot)
      unsubscribeOnSnapshot[commitOnSnapshot]()
    }
  }

  // Se a busca for por filiais específicas
  if (finalFilterApplyed[0] !== '*') {
    // console.log(`dbQuery Query looking for branches `,`${JSON.stringify(finalFilterApplyed)}}`)
    queryRef = queryRef.where('branch_id', 'in', finalFilterApplyed)
  }

  if (debug)
    console.log(
      `-> dbQuery Start query at ${firebaseProjetctQuery} id`,
      ` ${companyIdPathQuery.substr(0, 6)} \n`,
      `-> dbQuery Colllection ${collectionName} with branchFilter`,
      ` (${JSON.stringify(branchFilter)})\n`,
      `-> dbQuery start (${JSON.stringify(startDate)}) |`,
      ` end (${JSON.stringify(endDate)})\n`,
      `-> dbQuery branchsLocked (${JSON.stringify(branchsLocked)}) \n`,
      `-> dbQuery finalFilterApplyed ${finalFilterApplyed}\n`,
      `-> dbQuery commitOnSnapShot ${commitOnSnapshot || 'none\n'}`
    )

  // Se deve monitorar com onSnapshot ou apenas retornar os dados
  // Com onSnapshot, retorna a function de unsubscribe e a promise enquanto executa os dados
  // Quando a promise for resolvida, os dados coletados serão envidos a ela apenas na primeira execução
  if (commitOnSnapshot) {
    // console.log(`dbQuery Running query with onSnapshot`)
    let unsubscribeTemp = null
    let promise = new Promise((resolve, reject) => {
      unsubscribeTemp = queryRef.onSnapshot((qs) =>
        pushDocs(qs, commitOnSnapshot, resolve)
      )
      unsubscribeOnSnapshot[commitOnSnapshot] = unsubscribeTemp
    })

    // Indica para quem chamou esta função quando o onsnapshot acabou
    return {
      unsubscribe: unsubscribeTemp,
      promise,
    }

    // Sem snapshot
    // retorna o doc direto
  } else {
    // console.log(`dbQuery Running query looking for all branchs * without onSnapshot`)
    let docs = await queryRef.get().then((qs) => pushDocs(qs))
    return docs
  }
}

export default { query, queryUnsubscribe }
