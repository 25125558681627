<template>
  <div>
    <h1>Install</h1>
    <br />
    <hr />
    <p>{{ teste }}</p>
    <br />
    <hr />
    <p>{{ installPromptEvent }}</p>

    <button @click="callInstallPrompt">Install1</button>
    <button @click="callInstallPrompt2">Install2</button>
  </div>
</template>

<script>
export default {
  name: 'Install',
  data() {
    return {
      teste: undefined,
      installPromptEvent: undefined,
    }
  },
  mounted() {
    console.log('Mounted')
    window.addEventListener('beforeinstallprompt', (event) => {
      // console.log('beforeinstallprompt')
      event.preventDefault()
      this.installPromptEvent = event
      this.teste = 'Atribuido'
    })
  },
  methods: {
    callInstallPrompt2() {
      navigator.install()
    },
    callInstallPrompt() {
      // We can't fire the dialog before preventing default browser dialog
      // console.log('Clicked to install', this.installPromptEvent)
      alert('Clicked to install', this.installPromptEvent)
      if (this.installPromptEvent !== undefined) {
        // console.log('Clicked to install -> Not undefined')
        this.installPromptEvent.prompt()
      }
    },
  },
}
</script>
