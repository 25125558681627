import gettersCargosFiltered from './getters/gettersCargosFiltered'
import gettersLists from './getters/gettersLists'
import gettersReports from './getters/gettersReports'
import gettersReportsAllCargos from './getters/gettersReportsAllCargos'
import getAllCargosForReportExtended from './getters/gettersReportsAllCargosExtended'
import gettersReportsCargosFin from './getters/gettersReportsCargosFin'
import gettersTotals from './getters/gettersTotals'
import gettersTrackingResume from './getters/gettersTrackingResume'
import gettersGeneral from './getters/gettersGeneral'

let allgetters = Object.assign(
  {},
  gettersGeneral,
  gettersCargosFiltered,
  gettersLists,
  gettersReports,
  gettersReportsAllCargos,
  getAllCargosForReportExtended,
  gettersReportsCargosFin,
  gettersTotals,
  gettersTrackingResume
)
export default allgetters
