<template>
  <div>
    <el-header>
      <div class="onlineState">
        <icon
          class="icon"
          name="exclamation-triangle"
          scale="1.0"
          v-if="!onlineState && !isConnectionFast"
        ></icon>
      </div>

      <div class="isConnectionFast" :class="{ warning: connectionTypeBypass }">
        <icon
          class="icon"
          name="wifi"
          scale="1.0"
          v-if="isConnectionFast"
        ></icon>
      </div>

      <div class="isSyncing">
        <i class="el-icon-loading" v-if="isSyncing"></i>
      </div>

      <div class="isSyncingPending">
        <span v-if="isLoggedIn && isSyncingPending">
          {{ isSyncingPending }}</span
        >
      </div>

      <div class="isGPSError">
        <i class="el-icon-location" v-if="isLoggedIn && isGPSError"></i>
      </div>

      <div
        class="show-menu-button"
        @click="setShowMenuCollapse()"
        v-show="showNavMenuBar"
      >
        <i class="el-icon-menu"></i>
      </div>

      <div
        class="left-arrow"
        @click="$router.go(-1)"
        v-show="showHeaderLeftArrow"
      >
        <i class="el-icon-arrow-left"></i>
      </div>

      <el-dropdown
        class="dots"
        :hide-on-click="true"
        trigger="click"
        @command="goTo"
        v-show="showHeaderDots"
      >
        <i class="el-icon-more"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/logout"> Sair </el-dropdown-item>
          <!-- <el-dropdown-item class="dropdown-title"> Eventos </el-dropdown-item>
            <el-dropdown-item command='/events/paytax'> Registrar pedágio</el-dropdown-item>
            <el-dropdown-item command='/events/payfuel'> Registrar abastecimento </el-dropdown-item>
            <el-dropdown-item command='/events/general'> Outros eventos </el-dropdown-item> -->
          <el-dropdown-item class="dropdown-title">
            Configurações
          </el-dropdown-item>
          <el-dropdown-item command="/about"> Sobre </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <div class="el-header-separador"></div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'headerBar',
  data() {
    return {}
  },
  computed: {
    ...mapState('Shared', [
      'showHeaderLeftArrow',
      'showHeaderDots',
      'onlineState',
      'showNavMenuBar',
      'connectionType',
      'isSyncing',
      'isSyncingPending',
      'connectionTypeBypass',
      'isGPSError',
    ]),
    ...mapGetters('Shared', ['isConnectionFast']),
    ...mapGetters('User', ['isLoggedIn']),
  },
  methods: {
    ...mapMutations('Shared', ['setShowMenuCollapse']),
    goTo(destination) {
      if (destination) this.$router.push(destination)
    },
  },
}
</script>

<style scoped>
.el-header {
  background: url('../../../src/assets/logo_50px.png') no-repeat center;
  position: relative;
  background-color: black;
  color: white;
  text-align: center;
  line-height: 60px;
  padding: 0px;
  font-size: 12px;
  width: 100%;
}

.el-header-separador {
  position: relative;
  z-index: 1;
  background-color: #409eff;
  padding: 4px;
}

.dots {
  top: 5px;
  display: inline-block;
  position: absolute;
  right: 5px;
  line-height: 60px;
  width: 50px;
  cursor: pointer;
}

.dots i {
  color: white;
  font-size: 24px;
}

.el-dropdown-menu {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.left-arrow {
  float: left;
  line-height: 60px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  font-size: 24px;
  width: 50px;
  cursor: pointer;
}

.show-menu-button {
  float: left;
  line-height: 60px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  font-size: 24px;
  cursor: pointer;
  width: 50px;
  margin-left: 5px;
}

.onlineState {
  color: red;
  font-size: 10px;
  position: absolute;
  top: 1px;
  left: 10px;
  line-height: 10px;
}

.isConnectionFast {
  font-size: 10px;
  position: absolute;
  top: 1px;
  left: 10px;
  line-height: 10px;
}

.isSyncingPending {
  font-size: 12px;
  position: absolute;
  top: 3px;
  left: 32px;
  line-height: 10px;
}

.isSyncing {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 43px;
  line-height: 10px;
}

.isGPSError {
  color: red;
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 60px;
  line-height: 10px;
}

.dropdown-title {
  font-weight: bold;
  text-align: center;
  background-color: rgb(196, 195, 195);
}

.warning {
  color: #d8d400;
}
</style>
