import { getDefaultTruckState } from './state'

export default {
  resetState(state) {
    // console.log(`Truck state reseted`)
    Object.assign(state, getDefaultTruckState())
  },
  changeTrucks(state, payload) {
    state.allTrucks = payload
  },
  changeSelectedTruck(state, payload) {
    state.selectedTruck = payload
  },
  addTruck(state, payload) {
    state.allTrucks.push(payload)
  },
  removeTruck(state, payload) {
    state.allTrucks = state.allTrucks.filter(
      (truck) => truck.truck_id !== payload
    )
  },
  updateTruck(state, payload) {
    const truckIndex = state.allTrucks.findIndex(
      (truck) => truck.truck_id === payload.truck_id
    )
    Object.assign(state.allTrucks[truckIndex], payload)
  },
  changeSelectedTruckKm(state, payload) {
    state.selectedTruck.km = payload
    const truckIndex = state.allTrucks.findIndex(
      (truck) => truck.truck_id === state.selectedTruck.truck_id
    )
    state.allTrucks[truckIndex]['km'] = payload
  },
}
