import t from 'typy'
import { fbapp, db, auth, FieldValue, timestamp } from '@/config/firebase'
import router from '@/router'
import { logging } from '@/services/logging.js'
import { getDefaultCompanyState } from '@/store/modules/user/state.js'
import createIds from '@/helpers/createIds'

export default {
  userLogin({ commit, dispatch, rootState }, payload) {
    // console.log('Start userLogin')
    // Validações
    let firstTests = 1
    if (
      !t(payload, 'form.password').isDefined &&
      !t(payload, 'form.username').isDefined
    )
      firstTests = -1
    if (
      typeof payload.form.username !== 'string' &&
      typeof payload.form.password !== 'string'
    )
      firstTests = -2

    // Preparando variaveis e removendo whitespaces
    payload.form.username = payload.form.username
      .replace(/\s+/g, '')
      .toLowerCase() // \s+ matches any whitespace character (equal to [\r\n\t\f\v ])
    payload.form.password = payload.form.password.replace(/^\s+|\s+$/g, '') // matches any whitespace no ínicio e no final apenas

    let form = {
      username: `${payload.form.username}@${rootState.User.companie.id}.app.minharota.com.br`,
      password: payload.form.password,
    }

    const extralog = {
      form_companie: payload.form.companie,
      form_username: payload.form.username,
      company_id: t(rootState, 'User.companie.id').safeObject || null,
      login: form.username,
    }

    // Regex Testes
    let formUsernameRegex = /^[a-z0-9._]+$/i
    let fullloginRegex = /^([a-z0-9._]+)@([a-z0-9]+).app.minharota.com.br$/i

    if (!formUsernameRegex.test(payload.form.username)) firstTests = -3
    if (!fullloginRegex.test(form.username)) firstTests = -4

    if (firstTests <= 0) {
      logging(
        `Usuário ou senha inválido enviados para login ${firstTests}`,
        'Users',
        'Login error',
        extralog
      )
      commit(
        'Shared/setError',
        `Usuário ou senha inválido enviados para login (${firstTests})`,
        { root: true }
      )
      return false
    }

    commit('Shared/setLoadingMessage', 'Autenticando usuário...', {
      root: true,
    })

    return (
      fbapp
        .auth()
        .setPersistence(auth.Persistence.LOCAL)
        .then(() => {
          // Try authenticate with login and password
          return fbapp
            .auth()
            .signInWithEmailAndPassword(form.username, form.password)
        })
        .then(async (userSignOp) => {
          let customClaims = await fbapp
            .auth()
            .currentUser.getIdTokenResult()
            .then((idTokenResult) => idTokenResult.claims)

          if (customClaims.roles && customClaims.roles.includes('admin')) {
            return userSignOp
          } else {
            logging(
              `User ${form.username} without permissions to login`,
              'Users',
              'Login error'
            )
            throw new Error('Usuário sem permissão de acesso')
          }
        })
        .then(async (userSignOp) => {
          // Get User profile from Firestore Database
          // console.log(`User auhenticated successfuly`)
          return dispatch('getUserProfile', userSignOp.user)
        })
        // Bloco comentado pois validação de login alterado para custom claims no auth
        // .then(async userData => {
        //   console.log(`User Auhenticated and profile loaded`)
        //   if (userData.profile.roles.includes('admin')) {
        //     logging(`User logged in`, 'Users', 'Login', { fullDeviceReport: true })
        //     userDataReturn = userData
        //     return userData
        //   } else {
        //     logging(`User without permissions to login`, 'Users', 'Login error')
        //     await dispatch('userLogout', { bypassRouter: true, bypassLog: true })
        //     throw new Error('Usuário sem permissão de acesso')
        //   }
        // })
        .then((userData) => {
          commit('Shared/setLoadingMessage', 'Buscando veículos...', {
            root: true,
          })
          return dispatch('Trucks/getTrucks', null, { root: true })
        })
        .then((trucks) => {
          commit('Shared/setLoadingMessage', 'Buscando motoristas...', {
            root: true,
          })
          return dispatch('Drivers/getDrivers', null, { root: true })
        })
        .then((drivers) => {
          commit('Shared/setLoadingMessage', 'Buscando usuários....', {
            root: true,
          })
          return dispatch('Admins/getAdmins', null, { root: true })
        })
        // .then((admins) => {
        //   commit('Shared/setLoadingMessage', 'Buscando cargas...', {
        //     root: true,
        //   })
        //   return dispatch('Cargos/getAndMonitorCargos', { force: true }, { root: true })
        // })
        // .then((cargosPromise) => {
        //   // Quando utilizado getAndMonitorCargos
        //   // Ele retorna uma promise com um objeto e uma chave promise
        //   // Essa chave promise que retornará o resultado da carga e deixará registrado um onsnapshot
        //   return cargosPromise.promise
        // })
        .then((cargos) => {
          // Obrigatório buscar filiais somente após função getUserProfile
          // pois getBranches atualiza o state companie com a listagem de filiais
          commit('Shared/setLoadingMessage', 'Buscando Filiais...', {
            root: true,
          })
          return dispatch('Branches/getBranches', null, { root: true })
        })
        .then((branches) => {
          logging(`User logged in`, 'Users', 'Login', {
            fullDeviceReport: true,
          })
          return commit('Shared/setLoadingMessage', '', { root: true })
        })
        .then(() => {
          return true
        })
        .catch(async (err) => {
          dispatch('userLogout', { bypassRouter: true, bypassLog: true })

          if (
            err.code === 'auth/user-not-found' ||
            err.code === 'auth/wrong-password'
          ) {
            logging(
              `Invalid user or password`,
              'Users',
              'Login error',
              extralog
            )
            commit('Shared/setError', 'Usuário ou senha inválido', {
              root: true,
            })
          } else {
            extralog.error_code = err.code
            extralog.error_message = err.message
            logging(`Generic Error`, 'Users', 'Login error', extralog)
            commit('Shared/setError', { err, that: this }, { root: true })
          }

          return false
        })
    )
  },

  async userLogout({ rootState, commit }, payload = {}) {
    // console.log('Start userLogout and force clear all states')
    let bypassRouter = payload.bypassRouter || false
    let bypassLog = payload.bypassLog || false
    const redirectTo = rootState.User.companie.dnsname
      ? `/${rootState.User.companie.dnsname}`
      : ''

    if (!bypassLog) {
      const extralog = {
        login: t(rootState, 'User.profile.login').safeObject || null,
        company_id: t(rootState, 'User.companie.id').safeObject || null,
      }
      logging(`User logged out`, 'Users', 'Logout', extralog)
    }

    // Reset all states
    Object.keys(rootState).forEach((stateModulesNames) => {
      if (stateModulesNames !== 'route')
        commit(`${stateModulesNames}/resetState`, null, { root: true })
    })

    await fbapp
      .auth()
      .signOut()
      .then(() => {})
    if (!bypassRouter) router.push(`/login${redirectTo}`)
  },

  getCompanieByDnsName({ commit }, payload) {
    if (payload) {
      let dnsname = payload.dnsname || payload
      let bypassRemoveMsg = payload.bypassRemoveMsg || false
      let bypassAddMsg = payload.bypassAddMsg || false

      if (!bypassAddMsg)
        commit('Shared/setLoadingMessage', 'Autenticando empresa...', {
          root: true,
        })

      // Prepare dnsName string
      dnsname =
        typeof dnsname === 'string'
          ? dnsname.toLowerCase().trim().replace(/ /g, '')
          : false

      if (dnsname) {
        return db
          .collection('companies_public')
          .where('dns_name', '==', dnsname)
          .limit(1)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              return getDefaultCompanyState()
            } else {
              // Get returned value without foreach method
              const position = querySnapshot.docs.length - 1
              const companie = querySnapshot.docs[position].data()
              const companieId =
                querySnapshot.docs[querySnapshot.docs.length - 1].id

              // Only basic data before auth
              let companieData = getDefaultCompanyState()
              return Object.assign(companieData, {
                id: companieId,
                name: companie.public_name,
                disabled: !!companie.disabled,
                dnsname: dnsname,
              })
            }
          })
          .then((companieResult) => {
            // console.log(
            //   'getCompanieByDnsName result: ',
            //   JSON.stringify(companieResult)
            // )
            if (!companieResult.id) return false
            commit('changeCompanie', companieResult)
            if (!bypassRemoveMsg)
              commit('Shared/setLoadingMessage', '', { root: true })
            return companieResult
          })
      }
    }
  },

  // Get user and companie data from firestore
  // Receive a firebase user auth object or just uid as string
  async getUserProfile({ rootState, commit, dispatch }, payload = false) {
    // console.log('getUserProfile')
    let uid = false
    let token = false
    let lastloggedin = false

    // É um objeto recebido como retorno do fbapp.auth().signInWithEmailAndPassword(username, password)
    // ou retorno do fbapp.auth().currentUser()
    if (typeof payload === 'object' && payload.uid) {
      uid = payload.uid || null
      token = await payload.getIdToken()
      lastloggedin = t(payload, 'metadata.lastSignInTime').safeObject || null

      // Se payload vazio foi enviado, tento recuperar do state do usuário
      // utilizado para recarregar/atualizar o profile do usuário/compania do banco
    } else if (payload === false) {
      uid = t(rootState, 'User.profile.auth_uid').safeObject || null
      token = t(rootState, 'User.token').safeObject || null
      lastloggedin = t(rootState, 'User.lastloggedin').safeObject || null

      // considero que foi enviado um uid especifico para a consulta
    } else if (typeof payload === 'string') {
      uid = payload
    }

    if (uid && t(rootState, 'User.companie.id').isDefined) {
      let userProfile = null
      let companieProfile = getDefaultCompanyState()
      let myPromises = []

      // get user profile data
      await db
        .collection('companies')
        .doc(rootState.User.companie.id)
        .collection('users')
        .where('auth_uid', '==', uid)
        .limit(1)
        .get()
        .then(async (querySnapshot) => {
          if (!querySnapshot.empty) {
            // console.log('Firestore User profile loaded')
            const position = querySnapshot.docs.length - 1
            userProfile = querySnapshot.docs[position].data()
            userProfile.id =
              querySnapshot.docs[querySnapshot.docs.length - 1].id
            userProfile.uid =
              querySnapshot.docs[querySnapshot.docs.length - 1].id
            if (typeof payload === 'object' && payload.uid) {
              userProfile.claims = await payload.getIdTokenResult().claims
              // console.log('Firestore User profile claims loaded')
            }
          }

          // console.log('user actions getUserProfile 1')
        })
        .catch((err) =>
          console.error(`Fail to load user profile: ${err.message}`)
        )

      // get companie profile data
      await db
        .collection('companies')
        .doc(rootState.User.companie.id)
        .get()
        .then((doc) => {
          // console.log('Firestore Companie profile loaded')
          companieProfile = Object.assign(companieProfile, doc.data())
          companieProfile.id = doc.id
          // console.log('user actions getUserProfile 2')
        })
        .catch((err) =>
          console.error(`Fail to load companie profile: ${err.message}`)
        )

      // get companie branches data
      await dispatch('Branches/getBranches', null, { root: true })
        .then((branches) => {
          // console.log('user actions getUserProfile 3', branches)
          companieProfile.branchs = [...branches]
        })
        .catch((err) =>
          console.error(`Fail to load companie branches: ${err.message}`)
        )

      let userData = {
        profile: userProfile,
        companie: companieProfile,
        token,
        lastloggedin,
      }

      if (userProfile.is_branch_locked) {
        // console.log(`User is branch locked to ${userProfile.branch_id}`)
        userData.branchsLocked = [userProfile.branch_id]
      }

      // console.log('user actions getUserProfile 4', userData)
      await commit('changeUser', userData)

      return userData
    } else {
      throw new Error('Invalid UID')
    }
  },

  addCompanieBranch({ commit, rootState }, form) {
    if (form) {
      return db
        .collection('companies')
        .doc(rootState.User.companie.id)
        .update({
          branchs: FieldValue.arrayUnion({
            branch_id: form.branch_id,
            branch_name: form.branch_name,
          }),
        })
        .catch((err) => {
          commit('Shared/setError', { err, that: this }, { root: true })
        })
    }
  },

  async checkLoginExists({ rootState }, login) {
    const companyId = rootState.User.profile.company_id
    try {
      const querySnapshot = await db
        .collection('companies')
        .doc(companyId)
        .collection('users')
        .where('driver_login', '==', login)
        .limit(1)
        .get()
      if (querySnapshot.docs.length > 0) return true
      return false
    } catch (err) {
      throw new Error(err)
    }
  },

  addUser({ commit, rootState }, form) {
    // console.log('Form:', form)
    if (form.driver_login) {
      const companyId = rootState.User.companie.id
      const uniqueId = createIds.smallId()
      commit('Shared/setLoadingMessage', 'Criando usuário...', { root: true })

      // Recebo todos os campos enviados pelo form
      const newUser = { ...form }

      // Crio campos obrigatórios dependentes do form
      newUser.roles = form.roles || ['invalid']
      newUser.login = `${form.driver_login}@${companyId}.app.minharota.com.br`
      newUser.password = form.password.length > 0 ? form.password : 'autoset'

      // id para controle e relacionamento com id do sistema do cliente, quando enviado por api
      newUser.driver_id = uniqueId

      // outros campos obrigatórios na criação
      newUser.company_id = companyId
      newUser.is_disabled = false
      newUser.created_by = rootState.User.profile.id
      newUser.created_at = timestamp

      // Removo campos que não devem ser cadastrados
      delete newUser.is_admin
      delete newUser.id
      delete newUser.passwordconfirm

      return db
        .collection('companies')
        .doc(companyId)
        .collection('users_new')
        .doc()
        .set(newUser)
        .then((doc) => {
          // console.log('new user......', doc)
          if (form.roles.includes('admin'))
            commit('Admins/addAdmin', { id: null, ...newUser }, { root: true })
          if (form.roles.includes('driver'))
            commit(
              'Drivers/addDriver',
              { id: null, ...newUser },
              { root: true }
            )
          if (form.roles.includes('checker'))
            commit(
              'Checkers/addChecker',
              { id: null, ...newUser },
              { root: true }
            )
          commit('Shared/setLoadingMessage', '', { root: true })
        })
        .catch((err) => {
          commit('Shared/setLoadingMessage', '', { root: true })
          commit('Shared/setError', { err, that: this }, { root: true })
        })
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },

  removeUser({ commit, rootState }, user) {
    // console.log('User:', user)
    if (user && user.id && user.driver_login) {
      commit('Shared/setLoadingMessage', 'Excluindo usuário...', { root: true })
      db.collection('companies')
        .doc(rootState.User.companie.id)
        .collection('users')
        .doc(user.id)
        .delete()
        .then(() => {
          if (!user.roles.includes('admin'))
            commit('Admins/removeAdmin', user.driver_login, { root: true })
          if (!user.roles.includes('driver'))
            commit('Drivers/removeDriver', user.driver_login, { root: true })
          if (!user.roles.includes('checker'))
            commit('Checkers/removeChecker', user.driver_login, { root: true })
          commit('Shared/setLoadingMessage', '', { root: true })
        })
        .catch((err) => {
          commit('Shared/setError', { err, that: this }, { root: true })
        })
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },

  async updateUser({ commit, rootState }, form) {
    // console.log('updateUser Form:', form)
    if (form.id) {
      commit('Shared/setLoadingMessage', 'Atualizando usuário...', {
        root: true,
      })
      const companyId = rootState.User.companie.id
      const userRef = db
        .collection('companies')
        .doc(companyId)
        .collection('users')
        .doc(form.id)

      form.updated_at = timestamp
      form.updated_by = rootState.User.profile.id
      form.roles = form.roles || ['invalid']

      delete form.id
      delete form.password
      delete form.is_admin
      delete form.passwordconfirm

      if (userRef) {
        await userRef
          .update(form)
          .catch((e) =>
            commit('Shared/setError', { err: e, that: this }, { root: true })
          )

        if (form.roles.includes('admin'))
          commit('Admins/updateAdmin', form, { root: true })
        if (form.roles.includes('driver'))
          commit('Drivers/updateDriver', form, { root: true })
        if (form.roles.includes('checker'))
          commit('Checkers/updateChecker', form, { root: true })

        if (!form.roles.includes('admin'))
          commit('Admins/removeAdmin', form.driver_login, { root: true })
        if (!form.roles.includes('driver'))
          commit('Drivers/removeDriver', form.driver_login, { root: true })
        if (!form.roles.includes('checker'))
          commit('Checkers/removeChecker', form.driver_login, { root: true })

        commit('Shared/setLoadingMessage', '', { root: true })
      }
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },

  async updateUserPassword({ commit, rootState }, form) {
    if (form.uid && form.login && form.password) {
      commit('Shared/setLoadingMessage', 'Atualizando usuário...', {
        root: true,
      })
      const companyId = rootState.User.companie.id

      delete form.passwordconfirm

      const userRef = db
        .collection('companies')
        .doc(companyId)
        .collection('spool')
        .doc('jobs')
        .collection('password_reset')

      await userRef
        .add(form)
        .catch((e) =>
          commit('Shared/setError', { err: e, that: this }, { root: true })
        )
      commit('Shared/setLoadingMessage', '', { root: true })
    }
  },

  async enableDisableUser({ commit, rootState }, user) {
    if (user.login && user.id && user.driver_login) {
      commit('Shared/setLoadingMessage', 'Atualizando usuário...', {
        root: true,
      })
      const companyId = rootState.User.companie.id
      const userRef = db
        .collection('companies')
        .doc(companyId)
        .collection('users')
        .doc(user.id)
      const updateData = {
        driver_login: user.driver_login,
        updated_at: timestamp,
        updated_by: rootState.User.profile.id,
        is_disabled: !user.is_disabled,
      }
      if (userRef) {
        await userRef
          .update(updateData)
          .catch((e) =>
            commit('Shared/setError', { err: e, that: this }, { root: true })
          )

        if (user.roles.includes('admin'))
          commit('Admins/updateAdmin', updateData, { root: true })
        else commit('Drivers/updateDriver', updateData, { root: true })

        commit('Shared/setLoadingMessage', '', { root: true })
      }
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },

  async updateCompanieSettings({ commit, rootState, vm }, form) {
    const companyId = rootState.User.companie.id
    form = JSON.parse(JSON.stringify(form))
    // console.log('updateCompanieSetting Form:', form, companyId)

    if (form.settings && companyId) {
      commit('Shared/setLoadingMessage', 'Atualizando configurações...', {
        root: true,
      })

      let newSettings = {
        ...rootState.User.companie.settings,
        ...form.settings,
      }

      await db
        .collection('companies')
        .doc(companyId)
        .set(form, { merge: true })
        .then(() => {
          commit('User/changeCompanieSettings', newSettings, { root: true })
        })
        .catch((e) =>
          commit('Shared/setError', { err: e, that: this }, { root: true })
        )

      commit('Shared/setLoadingMessage', '', { root: true })
    } else {
      commit('Shared/setError', 'Dados inválidos para continuar', {
        root: true,
      })
    }
  },
}
