import { getDefaultUserState } from './state'

export default {
  resetState(state) {
    // console.log(`User/Company state reseted`)
    Object.assign(state, getDefaultUserState())
  },
  changeUser(state, payload) {
    // console.log(`User state changed`)
    if (payload.profile) state.profile = payload.profile
    if (payload.token) state.token = payload.token
    if (payload.lastloggedin) state.lastloggedin = payload.lastloggedin
    if (payload.branchsLocked && Array.isArray(payload.branchsLocked))
      state.branchsLocked = payload.branchsLocked
    if (payload.companie && payload.companie.id) {
      this.commit('User/changeCompanie', payload.companie)
    }
  },
  changeCompanie(state, payload) {
    // console.log(`User state companie changed`)
    if (payload.id) {
      state.companie = payload
    }
  },
  changeCompanieSettings(state, p) {
    // console.log(`User state companie settings changed`)
    if (
      p.cancellationReasons &&
      p.cancellationReasonsPartial &&
      p.events &&
      p.general &&
      p.images &&
      p.notifications
    ) {
      state.companie.settings = p
    }
  },
  changeBranchesFromBranchMutation(state, payload) {
    // Mutation to update array branch inside companie data, called only from mutation on branches store
    // console.log(`User state companie branches changed`)
    if (Array.isArray(payload)) state.companie.branchs = payload
  },
  updateBranchFromBranchMutation(state, payload) {
    // Mutation to update array branch inside companie data, called only from mutation on branches store
    const branchIndex = state.companie.branchs.findIndex(
      (branch) => branch.branch_id === payload.branch_id
    )
    if (branchIndex < 0) state.companie.branchs.push(payload)
    else Object.assign(state.companie.branchs[branchIndex], payload)
  },
  changeToken(state, payload) {
    state.token = payload || null
  },
}
