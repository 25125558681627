import { db, timestamp, getStateTimeStamp, FieldValue } from '@/config/firebase'
import dbFilter from '@/helpers/firestoreQueryFilter'
import http from '@/services/axiosHttp'

export default {
  async addCargoComment({ rootState }, { customerDetails, extra }) {
    if (!customerDetails._cargo) return false

    const c = customerDetails
    const cargo = customerDetails._cargo

    const customerIndex = Object.values(cargo.customers).findIndex(
      (customer) =>
        customer.customer + customer.address1 + customer.sequenceofdelivery ===
        c.customer + c.address1 + c.sequenceofdelivery
    )

    // Estrutura do objeto extra.comment experado
    //  {
    //   comment: 'String',
    //   comment_by: `${this.uName} (${this.profile.id})`,
    //   comment_at: this.getSecondsObj(),
    //  }

    let customerUpdate = {}
    const value = FieldValue.arrayUnion(extra.comment)
    customerUpdate[`customers.${customerIndex}.has_comments`] = true
    customerUpdate[`customers.${customerIndex}.comments`] = value

    let cargoRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('cargos')
      .doc(c._cargo.id)

    return cargoRef
      .update(customerUpdate)
      .then(() =>
        console.log(
          `Doc cargos/${c._cargo.id}/customers/${customerIndex} successfully received new comment`
        )
      )
  },

  async updateCargoConferenceChecker(
    { rootState },
    { customerDetails, extra }
  ) {
    if (!customerDetails._cargo) return false

    const c = customerDetails
    const cargo = customerDetails._cargo

    const customerIndex = Object.values(cargo.customers).findIndex(
      (customer) =>
        customer.customer + customer.address1 + customer.sequenceofdelivery ===
        c.customer + c.address1 + c.sequenceofdelivery
    )
    let prefix = 'checked_fin'
    if (extra.type === 'log') prefix = 'checked_log'
    let status = 'U'
    if (!extra.hasPendingCustomers) status = 'V'
    let customerUpdate = {}
    customerUpdate[`cargo_status`] = status
    customerUpdate[`customers.${customerIndex}.${prefix}`] = extra.status
    customerUpdate[`customers.${customerIndex}.${prefix}_at`] = timestamp
    customerUpdate[`customers.${customerIndex}.${prefix}_by`] = extra.login
    let cargoRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('cargos')
      .doc(c._cargo.id)

    return cargoRef
      .update(customerUpdate)
      .then(() =>
        console.log(
          `Doc cargos/${c._cargo.id}/customers/${customerIndex} successfully checker updated`
        )
      )
  },

  async getMaplinkRetorno({ commit, rootState }, params = {}) {
    const activeLoading = params.hasOwnProperty('activeLoading') ? params.activeLoading : false
    const cargo_id = params.hasOwnProperty('cargo_id') ? params.cargo_id : ''
    const branch_id = params.hasOwnProperty('branch_id') ? params.branch_id : ''

    if (activeLoading)
      commit('Shared/setLoadingMessage', 'Buscando carga automática...', {
        root: true,
      })

    console.log('getMaplinkRetorno', rootState.User.profile.company_id, cargo_id, branch_id)

    let dbref = db
      .collection('maplink')
      .doc('invoices')
      .collection('retornos')
      .where('companie_id', '==', rootState.User.profile.company_id)
      .where('cargo_id', '==', cargo_id)
      .where('branch_id', '==', branch_id)
      .limit(1)
      .orderBy('created_at')

    let maplinkdetails = await dbFilter.query(dbref, true)

    commit('Shared/setLoadingMessage', '', { root: true })
    return maplinkdetails
  },

  async getCargos({ commit, rootState }, activeLoading = true) {
    if (activeLoading)
      commit('Shared/setLoadingMessage', 'Atualizando Cargas...', {
        root: true,
      })

    let cargoRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('cargos')
      .orderBy('created_at')
    let cargos = await dbFilter.query(cargoRef, true)

    commit('changeAllCargos', cargos)
    commit('Shared/setLoadingMessage', '', { root: true })
    return cargos
  },

  async getAndMonitorCargos({ commit, rootState }, params = {}) {
    const activeLoading = params.hasOwnProperty('showMsg')
      ? params.showMsg
      : true
    const force = params.hasOwnProperty('force') ? params.force : false
    if (activeLoading)
      commit('Shared/setLoadingMessage', 'Atualizando Cargas...', {
        root: true,
      })

    // Quando onSnapshot esta ativado, o commit é realizado por dentro do methodo
    let cargoRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('cargos')
      .orderBy('created_at')
    let snapshot = await dbFilter.query(
      cargoRef,
      true,
      'created_at',
      'Cargos/changeAllCargos',
      force
    )
    const docs = await snapshot.promise
    if (docs.length !== 0) {
      commit('changeAllCargos', docs)
    }
    commit('Shared/setLoadingMessage', '', { root: true })
    return snapshot
  },

  async getOpenCargoByTruckID({ commit, rootState }, truckid) {
    let cargoRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('cargos')
      .where('truck_id', '==', truckid)
      .where('cargo_status', '==', 'A')
      .limit(1)

    let cargo = await dbFilter.query(cargoRef)
    return cargo
  },

  async updateCargoKm({ commit, rootState }, payload) {
    const {
      newKm,
      why,
      cargoid,
      field,
      fieldArray,
      fieldCompare,
      valueCompare,
    } = payload
    const companyId = rootState.User.profile.company_id
    const userId = rootState.User.profile.id
    if (cargoid && companyId && field && !isNaN(newKm)) {
      const cargoRef = db
        .collection('companies')
        .doc(companyId)
        .collection('cargos')
        .doc(cargoid)

      if (fieldArray && fieldCompare) {
        const cargo = await cargoRef.get()
        const doc = cargo.data()
        if (fieldArray in doc) {
          Object.values(doc[fieldArray]).forEach((item, index) => {
            if (item[fieldCompare] === valueCompare) {
              doc[fieldArray][index][field] = newKm
              doc[fieldArray][index][`${field}_whyUpdate`] = why
              doc[fieldArray][index]['updated_at'] = timestamp
              doc[fieldArray][index]['updated_by'] = userId
              return cargoRef.update(doc).then(() => {})
            }
          })
        }
      } else {
        commit('updateCargoKm', payload)
        let dataToUpdate = {
          updated_at: timestamp,
          updated_by: userId,
        }
        dataToUpdate[`${field}_whyUpdate`] = why
        dataToUpdate[field] = newKm
        return cargoRef.update(dataToUpdate).then(() => {})
      }
    } else {
      throw new Error('updateCargoKm: invalid data')
    }
  },

  async updateStatus({ commit, rootState }, payload) {
    const oldStatus = payload.oldStatus || null
    const newStatus = payload.newStatus || null
    const cargoId = payload.cargo_id || null
    const branchId = payload.branch_id || null
    const firebaseDocId = `${branchId}${cargoId}`
    const updateMessage = payload.updateMessage || ''
    const enableInvoicesAgain = payload.hasOwnProperty('enableInvoicesAgain')
      ? payload.enableInvoicesAgain
      : false // libera cargas novamente, true or false

    const companyId = rootState.User.profile.company_id || null
    const userId = rootState.User.profile.id || null

    if (cargoId && branchId && companyId && newStatus) {
      const cargoRef = db
        .collection('companies')
        .doc(companyId)
        .collection('cargos')
        .doc(firebaseDocId)

      let updateFirestoreSchema = {
        updated_at: timestamp,
        updated_by: userId,
        cargo_status: newStatus,
      }

      let cancelCargoPostSchema = {
        cargo_id: cargoId,
        branch_id: branchId,
        update_message: updateMessage,
        updated_at: getStateTimeStamp(), // não utilizado pela api /cargos/cancel
        updated_by: userId, // não utilizado pela api /cargos/cancel
        enable_invoices_again: enableInvoicesAgain,
        new_status: newStatus,
        old_status: oldStatus,
        firebase_doc_id: firebaseDocId,
      }

      // Se for um cancelamento de uma carga, enviar para reabilitação também das notas associadas
      if (
        (oldStatus === 'A' || oldStatus === 'B') &&
        (newStatus === 'C' || newStatus === 'E')
      ) {
        let httpresponse = await http.post(
          `/cargos/cancel/${branchId}/${cargoId}`,
          cancelCargoPostSchema
        )

        if (httpresponse.status === 200 && !httpresponse.data.error) {
          commit('Shared/setInfoMessage', httpresponse.data.msg, { root: true })
          commit('updateStatus', cancelCargoPostSchema) // Commit chages to state
          return true
        } else {
          commit('Shared/setError', httpresponse.response.data.msg, {
            root: true,
          })
          return false
        }

        // Açao padrao, apenas mudar o status da carga
        // Save to database the new status
      } else {
        return cargoRef.update(updateFirestoreSchema).then(() => {
          commit('updateStatus', cancelCargoPostSchema) // Commit chages to state
          return true
        })
      }
    } else {
      throw new Error('updateStatus: invalid data')
    }
  },
}
