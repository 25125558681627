<template>
  <div class="cargo-details">
    <global-title-with-filter-bar
      :title="title"
      hidedate
      :buttons="[
        { text: 'Voltar', click: () => $router.go(-1) },
        {
          text: 'Editar',
          click: () =>
            $router.push({
              name: 'CargosEdit',
              params: { branchId: cargo.branch_id, cargoId: cargo.cargo_id },
            }),
        },
        {
          text: 'Conferência',
          click: () =>
            $router.push({
              name: 'CargosDetailsConferente',
              params: { branchId: cargo.branch_id, cargoId: cargo.cargo_id },
            }),
        },
      ]"
    />

    <cargos-details
      :cargoid="$route.params.cargoId"
      :branchid="$route.params.branchId"
      @km-edit-click="showKmEditDialog"
      @km-click="showPicture"
      @show-map="showMap"
    />
    <picture-dialog ref="pictureDialog" />
    <map-dialog ref="mapDialog" />
    <edit-km-dialog ref="kmEditFormDialod" />


    <div v-if="maplinkdetails.length > 0">
    <el-col class="title">Montagem automática</el-col>

    <div class="lineDetails">
      <b>Rota:</b>
      <div class="status-details">
        <table>
        <tr><td><b>vehicle</b></td><td>{{maplinkdetails.mapLinkBody.vehicleRoutes["0"].vehicle}}</td></tr>
        <tr><td><b>arrivalSite</b></td><td>{{maplinkdetails.mapLinkBody.vehicleRoutes["0"].period.arrivalSite}}</td></tr>
        <tr><td><b>departureSite</b></td><td>{{maplinkdetails.mapLinkBody.vehicleRoutes["0"].period.departureSite}}</td></tr>
        <tr><td><b>timeWindow.start</b></td><td>{{toDateFromTS(maplinkdetails.mapLinkBody.vehicleRoutes["0"].period.timeWindow.start)}}</td></tr>
        <tr><td><b>timeWindow.end</b></td><td>{{toDateFromTS(maplinkdetails.mapLinkBody.vehicleRoutes["0"].period.timeWindow.end)}}</td></tr>
        </table>
      </div>
    </div>


    <div class="lineDetails">
      <b>Estatistica:</b>
      <div class="status-details">
        <table>
        <tr><td><b>averageOccupancyRateVolume</b></td><td>{{maplinkdetails.mapLinkBody.indicators.averageOccupancyRateVolume}}</td></tr>
        <tr><td><b>averageOccupancyRateWeight</b></td><td>{{maplinkdetails.mapLinkBody.indicators.averageOccupancyRateWeight}}</td></tr>
        <tr><td><b>dayWorkingTotalTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.dayWorkingTotalTime)}}</td></tr>
        <tr><td><b>nightWorkingTotalTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.nightWorkingTotalTime)}}</td></tr>
        <tr><td><b>rejectOperationsNumber</b></td><td>{{maplinkdetails.mapLinkBody.indicators.rejectOperationsNumber}}</td></tr>
        <tr><td><b>routesNumber</b></td><td>{{maplinkdetails.mapLinkBody.indicators.routesNumber}}</td></tr>
        <tr><td><b>timeWindowNumber</b></td><td>{{maplinkdetails.mapLinkBody.indicators.timeWindowNumber}}</td></tr>
        <tr><td><b>totalCollectingTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalCollectingTime)}}</td></tr>
        <tr><td><b>totalDeliveringTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalDeliveringTime)}}</td></tr>
        <tr><td><b>totalDistance</b></td><td>{{maplinkdetails.mapLinkBody.indicators.totalDistance}}</td></tr>
        <tr><td><b>totalDrivingTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalDrivingTime)}}</td></tr>
        <tr><td><b>totalLoadingTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalLoadingTime)}}</td></tr>
        <tr><td><b>totalRestTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalRestTime)}}</td></tr>
        <tr><td><b>totalServiceTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalServiceTime)}}</td></tr>
        <tr><td><b>totalTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalTime)}}</td></tr>
        <tr><td><b>totalUnloadingTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalUnloadingTime)}}</td></tr>
        <tr><td><b>totalWaitingTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalWaitingTime)}}</td></tr>
        <tr><td><b>totalWorkingTime</b></td><td>{{toHHMMSS(maplinkdetails.mapLinkBody.indicators.totalWorkingTime)}}</td></tr>
        </table>
      </div>
    </div>

    <div class="lineDetails">
      <b>Atividades:</b>
      <div class="status-details">
        <table class="stattable">
        <tr>
          <th>Atividade</th>
          <th>#</th>
          <th>Tipo</th>
          <th>Start</th>
          <th>End</th>
          <th>Distancia</th>
          <th>F Dur</th>
          <th>N Dur</th>
          <th>Destino</th>
          <th>Origem</th>
        </tr>
        <tr v-for="(op, index) in maplinkdetails.mapLinkBody.vehicleRoutes['0'].routes['0'].activities" :key="index">
          <td style="width: 150px;"><b>{{op.activity}}</b></td>
          <td style="width: 25px;">{{op.operations.length}}</td>
          <td style="width: 50px;">{{op.type}}</td>
          <td style="width: 150px;">{{toDateFromTS(op.timeWindow.start)}}</td>
          <td style="width: 150px;">{{toDateFromTS(op.timeWindow.end)}}</td>
          <td style="width: 50px;">{{op.distance}}</td>
          <td style="width: 50px;">{{op.fixedTimeSite ? toHHMMSS(op.fixedTimeSite) : ''}}</td>
          <td style="width: 50px;">{{op.nominalDuration ? toHHMMSS(op.nominalDuration) : ''}}</td>
          <td>{{op.site || op.arrivalSite}}</td>
          <td>{{op.departureSite}}</td>
        </tr>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<style>
  .stattable tr:nth-child(even) {
    background-color: #D6EEEE;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'
import editKmDialog from './components/editCargoKmDialog'
import mapDialog from './components/mapDialog'
import pictureDialog from './components/pictureDialog'
import cargosDetails from './components/cargosDetails'
import globalTitleWithFilterBar from '@/components/Menu/globalTitleWithFilterBar'

export default {
  name: 'cargosIndex',
  components: {
    editKmDialog,
    mapDialog,
    pictureDialog,
    cargosDetails,
    globalTitleWithFilterBar,
  },
  data() {
    return {
      maplinkdetails: []
    }
  },
  computed: {
    ...mapState('Cargos', ['allCargos']),
    title() {
      return `Detalhe Carga ${this.$route.params.cargoId}`
    },
    cargo() {
      return this.allCargos.find(
        (cargo) => cargo.cargo_id === this.$route.params.cargoId
      )
    },
  },
  methods: {
    ...mapActions('Cargos', ['getMaplinkRetorno']),
    showKmEditDialog(data) {
      const {fieldArray, fieldCompare, valueCompare} = data
      let km = data.row[data.field]
      let cargoid = `${this.$route.params.branchId}${this.$route.params.cargoId}`
      let field = data.field
      this.$refs.kmEditFormDialod.showDialog(km, cargoid, field, data.row, fieldArray, fieldCompare, valueCompare)
    },
    showMap(points) {
      this.$refs.mapDialog.showDialog(points)
    },
    showPicture(d) {
      this.$refs.pictureDialog.showDialog(d.row[d.field])
    },
    toHHMMSS(secs) {
      const sec_num = parseInt(secs, 10)
      const hours   = Math.floor(sec_num / 3600)
      const minutes = Math.floor(sec_num / 60) % 60
      const seconds = sec_num % 60

      return [hours,minutes,seconds]
          .map(v => v < 10 ? "0" + v : v)
          .filter((v,i) => v !== "00" || i > 0)
          .join(":")
    },
    checkZero(data) {
      if(data.length == 1){
        data = "0" + data;
      }
      return data;
    },
    toDateFromTS(secs) {
      let d = new Date(secs)
      let day = d.getDate() + "";
      let month = (d.getMonth() + 1) + "";
      let year = d.getFullYear() + "";
      let hour = d.getHours() + "";
      let minutes = d.getMinutes() + "";
      let seconds = d.getSeconds() + "";

      day = this.checkZero(day);
      month = this.checkZero(month);
      year = this.checkZero(year);
      hour = this.checkZero(hour);
      minutes = this.checkZero(minutes);
      seconds = this.checkZero(seconds);

      return day + "/" + month + "/" + year + " " + hour + ":" + minutes + ":" + seconds
    }
  },
  mounted() {
    console.log('getMaplinkRetorno')
    const getMaplinkData = this.getMaplinkRetorno({cargo_id: `${this.$route.params.branchId}${this.$route.params.cargoId}`, branch_id: this.$route.params.branchId})
    
    getMaplinkData.then(details => {
      this.maplinkdetails = details[0] || []
      console.log(this.maplinkdetails)
    })
  },
}
</script>
