<template>
  <div class="cargos-resume-box">
    <div class="boxes-line">
      <div class="box-double">
        <div class="title">Concluído</div>
        <el-progress
          type="dashboard"
          :percentage="cargo.porcentFinished"
          :color="colors"
          :width="100"
        ></el-progress>
      </div>

      <div class="boxes">
        <div class="hdouble">
          <div class="line">
            <div class="title2">Criado em</div>
            <div>{{ getDate(db.created_at) }}</div>
          </div>
          <div class="line">
            <div class="title2">Iniciado em</div>
            <div>{{ getDate(db.started_at) }}</div>
          </div>
          <div class="line">
            <div class="title2">Concluido em</div>
            <div>{{ getDate(db.finished_at) }}</div>
          </div>
          <div class="line linedouble">
            <div class="title2">Conferente</div>
            <div>{{ db.checker }}</div>
          </div>
          <div class="line">
            <div class="title2">Status</div>
            <div>{{ cargo.status }}</div>
          </div>
          <div class="line">
            <div class="title2">Veículo</div>
            <div>{{ cargo.vehicle }}</div>
          </div>
          <div class="line">
            <div class="title2">Motorista</div>
            <div>{{ cargo.driver }}</div>
          </div>
          <div class="line linedouble">
            <div class="title2">Filial</div>
            <div>{{ cargo.branch }}</div>
          </div>
          <div class="line">
            <div class="title2">Carga</div>
            <div>{{ cargo.cargo_id }}</div>
          </div>
          <div class="line">
            <div class="title2">Criado por</div>
            <div>{{ db.created_by.split(' ')[0] }}</div>
          </div>
        </div>
      </div>

      <div class="boxes">
        <div class="box">
          <div class="title">Valor total da carga</div>
          <div class="value">{{ cargo.amount }}</div>
        </div>

        <div class="box">
          <div class="title">Ticket médio</div>
          <div class="value">{{ cargo.averageTicket }}</div>
        </div>

        <div class="box">
          <div class="title">Entregas com sucesso</div>
          <div class="value">
            {{
              cargo.invoices -
              (cargo.invoicesCanceled +
                cargo.invoicesPartial +
                cargo.totalReavailable)
            }}
          </div>
        </div>

        <div class="box">
          <div class="title">Entregas com eventos</div>
          <div class="value">
            {{
              cargo.invoicesCanceled +
              cargo.invoicesPartial +
              cargo.totalReavailable
            }}
          </div>
        </div>

        <div class="box">
          <div class="title">Duração total</div>
          <div class="value">{{ cargo.duration }}</div>
        </div>

        <div class="box">
          <div class="title">Distância percorrida</div>
          <div class="value">{{ cargo.traveledKm }} KM</div>
        </div>

        <div class="box">
          <div class="title">Peso da carga</div>
          <div class="value">{{ cargo.weight }} KG</div>
        </div>

        <div class="box">
          <div class="title">Volumes</div>
          <div class="value">{{ cargo.totalVolumes }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cargos-resume-box',
  props: {
    cargo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      colors: [
        { color: '#1989fa', percentage: 20 },
        { color: '#1989fa', percentage: 40 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 90 },
        { color: '#5cb87a', percentage: 100 },
      ],
    }
  },
  computed: {
    db() {
      return this.cargo._database
    },
  },
  methods: {
    getDate(d) {
      if (d && d.seconds) {
        return this.$moment.unix(d.seconds).format('DD/MM/YYYY HH:mm')
      } else {
        return '---'
      }
    },
    getTime(seconds) {
      return this.$moment.unix(seconds).format('HH:mm')
    },
  },
  mounted() {
    // console.log(this.cargo)
  },
}
</script>

<style>
.cargos-resume-box {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  flex-wrap: nowrap;
}
 .cargos-resume-box .timeline {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  justify-items: center;
}
 .cargos-resume-box .timeline .step-resume {
  width: 600px;
}
 .cargos-resume-box .title {
  font-style: italic;
  color: #444;
  font-size: 11px;
  justify-self: flex-start;
  font-weight: bold;
}
 .cargos-resume-box .boxes-line {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
 .cargos-resume-box .boxes-line .box-double {
  height: 200px;
  width: 150px;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  justify-content: space-between;
  align-content: center;
  display: flex;
  flex-direction: column;
}
 .cargos-resume-box .boxes-line .box-double .el-progress {
  align-self: center;
  margin-bottom: auto;
  margin-top: auto;
}
 .cargos-resume-box .boxes-line .boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
 .cargos-resume-box .boxes-line .boxes .box {
  height: 90px;
  width: 150px;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  justify-content: space-between;
  align-content: center;
  display: flex;
  flex-direction: column;
}
 .cargos-resume-box .boxes-line .boxes .box .value {
  display: flex;
  font-size: 17px;
  color: #000;
  align-self: center;
  margin-bottom: auto;
  margin-top: auto;
}
 .cargos-resume-box .boxes-line .boxes .hdouble {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  display: flex;
  width: 300px;
  height: 200px;
  align-items: flex-start;
  font-size: 13px;
  flex-direction: column;
}
 .cargos-resume-box .boxes-line .boxes .hdouble .line {
  display: flex;
  flex-direction: row;
  line-height: 15px;
}
 .cargos-resume-box .boxes-line .boxes .hdouble .linedouble {
  margin-top: 10px;
}
 .cargos-resume-box .boxes-line .boxes .hdouble .title2 {
  font-size: 11px;
  width: 100px;
  font-weight: bold;
}
 
</style>
