import axios from 'axios'
export default async (postalCode) => {
  const data = await axios.get(`https://viacep.com.br/ws/${postalCode}/json/`)
  let address = data.data
  address = {
    address1: address.logradouro
      .toUpperCase()
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
    address2: address.complemento
      .toUpperCase()
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
    neighborhood: address.bairro
      .toUpperCase()
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
    city: address.localidade
      .toUpperCase()
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
    state: address.uf
      .toUpperCase()
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
  }

  return address
}
