const defaultInvoicesFiltersState = () => {
  return {
    branch: '',
    states: [],
    cities: [],
    neighborhoods: [],
    routes: [],
    zones: [],
    sectors: [],
    dates: null,
    searchString: '',
  }
}

const getDefaultInvoiceState = () => {
  return {
    allInvoices: [],
    statusLabels: [
      {
        value: 'Disponível',
        content: 'A',
        showOnChange: true,
        description: 'Nota disponível para ser montada',
        color: 'success',
      },
      {
        value: 'Montada',
        content: 'M',
        showOnChange: false,
        description: 'Nota montada em carga',
        color: 'primary',
      },
      {
        value: 'Bloqueada',
        content: 'B',
        showOnChange: true,
        description: 'Nota bloqueada',
        color: 'warning',
      },
      {
        value: 'Erro',
        content: 'E',
        showOnChange: true,
        description: 'Nota com erro de processamento',
        color: 'danger',
      },
      {
        value: 'Cancelada',
        content: 'C',
        showOnChange: true,
        description: 'Nota cancelada',
        color: 'info',
      },
      {
        value: 'Parcial',
        content: 'P',
        showOnChange: true,
        description: 'Entregue Parcial',
        color: 'info',
      },
    ],
    filters: Object.assign({}, defaultInvoicesFiltersState()), // filtros para carga
  }
}

export default getDefaultInvoiceState()
export { getDefaultInvoiceState }
export { defaultInvoicesFiltersState }
