import gettersLists from './getters/gettersLists'
import gettersTotals from './getters/gettersTotals'
import gettersInvoicesFiltered from './getters/gettersInvoicesFiltered'

let allgetters = Object.assign(
  {},
  gettersInvoicesFiltered,
  gettersLists,
  gettersTotals
)
export default allgetters
