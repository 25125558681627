<template>
  <div id="app">
    <el-container>
      <header-bar />

      <el-container>
        <nav-menu :menuCollapse="showNavMenuCollapse" v-if="showNavMenuBar" />

        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>

      <el-footer height="30px">
        <file-upload-message-simple
          :uploadingStatus="uploadingStatus"
          :timeout="3000"
          class="uploadMessage"
        />
        <div class="versao" @click="hackWifi">
          {{ versao }} | {{ build_date }}{{ nodeEndPrint }}
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import headerBar from '@/components/Header/headerbar.vue'
import navMenu from '@/components/Menu/navmenu.vue'
import FileUploadMessageSimple from '@/components/Shared/FileUploadMessageSimple'
import fileUpload from '@/services/fileUploadService'
import env from '@/config/environment'

export default {
  name: 'app',
  components: {
    headerBar,
    navMenu,
    FileUploadMessageSimple,
  },
  data() {
    return {
      versao: env.build.version,
      build_date: env.build.buildDate,
      node_env: env.build.nodeEnv,
      loading: null,
      installPromptEvent: undefined,
      queueImageUploadJob: [],
      messagesQueue: [],
    }
  },
  computed: {
    ...mapState('Shared', [
      'loadingMessage',
      'infoMessage',
      'successMessage',
      'errorMessage',
      'uploadingStatus',
      'showNavMenuCollapse',
      'showNavMenuBar',
      'connectionTypeBypass',
    ]),
    ...mapState('route', ['name']),
    ...mapGetters('Shared', ['swNeedUpdate']),
    nodeEndPrint() {
      return this.node_env !== 'production' ? ` | ${this.node_env}` : ''
    },
  },
  watch: {
    loadingMessage(value) {
      if (value && !this.loading) {
        this.loading = this.$loading({
          lock: true,
          text: value,
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
      } else if (this.loading && this.loading.visible && value) {
        this.loading.text = value
      } else {
        if (this.loading) {
          this.loading.close()
          this.loading = null
        }
      }
    },
    errorMessage(value) {
      this.addMessagesToQueue(value, 'error')
    },
    infoMessage(value) {
      this.addMessagesToQueue(value, 'info')
    },
    successMessage(value) {
      this.addMessagesToQueue(value, 'success')
    },
    swNeedUpdate(value) {
      // Notifica usuário que existem atualizações do app
      if (value) {
        // Se existir atualização, force reload
        if (this.$route.name === 'Home' && this.swNeedUpdate) {
          // this.setSwNeedUpdate(false)
          // setTimeout(() => { window.location.reload(true) }, 800)
          setTimeout(() => {
            this.$router.push('/reload')
          }, 800)
        }
      }
    },
    name(value) {
      // Menu Bar && Menu dots
      if (value !== 'Login') {
        this.setShowItems({ stateName: 'showNavMenuBar', value: true })
        this.setShowItems({ stateName: 'showHeaderDots', value: true })
      } else {
        this.setShowItems({ stateName: 'showNavMenuBar', value: false })
        this.setShowItems({ stateName: 'showHeaderDots', value: false })
      }

      // Left Back Arrow
      // if (value !== 'Login' && value !== 'Home' && value !== 'Cargo') {
      //   this.setShowItems({stateName: 'showHeaderLeftArrow', value: true})
      // } else {
      //   this.setShowItems({stateName: 'showHeaderLeftArrow', value: false})
      // }
    },
  },
  created() {
    // Clear any messages from cache
    this.clearError()
    this.clearInfoMessage()

    // Upload files/images/audios queue
    this.queueImageUploadJob.forEach((intID) => clearInterval(intID))
    this.queueImageUploadJob.push(
      setInterval(() => {
        fileUpload.queueImageUpload()
      }, env.config.wifiInterval)
    )
    this.queueImageUploadJob.push(
      setInterval(() => {
        this.setConnectionTypeBypass(false)
      }, env.config.wifiBypassInterval)
    )

    // Init title from env
    document.title = env.config.title

    // Verifica versão local com versão declarada no banco
    // if (navigator.onLine) {
    //   this.getAppVersions()
    //     .then(versions => {
    //       const bdAr = versions.app_build.split('.')
    //       const dbNumberBuild = new Date(`20${bdAr[2]}-${bdAr[1]}-${bdAr[0]}T${bdAr[3]}:00:00.000-03:00`)
    //       const locAr = this.build_date.split('.')
    //       const envNumberBuild = new Date(`20${locAr[2]}-${locAr[1]}-${locAr[0]}T${locAr[3]}:00:00.000-03:00`)

    //       const bdVer = versions.app_version.split('.')
    //       const locVer = this.versao.split('.')
    //       const dbNumberVer = parseInt(`1${bdVer[0].replace(/v/g, '')}` + bdVer[1] + bdVer[2])
    //       const envNumberVer = parseInt(`1${locVer[0].replace(/v/g, '')}` + locVer[1] + locVer[2])

    //       setTimeout(() => {
    //         if ((dbNumberBuild > envNumberBuild) || (dbNumberVer > envNumberVer)) {
    //           this.$confirm('Atualizações encontradas, deseja atualizar agora?', 'Novas atualizações', {
    //             confirmButtonText: 'Sim, faça agora!',
    //             cancelButtonText: 'Não, vou fazer depois',
    //             type: 'warning'
    //           })
    //             .then(() => {
    //               this.setSwNeedUpdate(true)
    //               setTimeout(() => { this.$router.push('/reload') }, 500)
    //             })
    //         }
    //       }, 1000)
    //     })
    // }
  },
  mounted() {
    this.setOnlineState()
    window.addEventListener('online', this.setOnlineState)
    window.addEventListener('offline', this.setOnlineState)
    navigator.connection.addEventListener('change', this.setConnectionType)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.setOnlineState)
    window.removeEventListener('offline', this.setOnlineState)
    navigator.connection.removeEventListener('change', this.setConnectionType)
  },
  methods: {
    ...mapActions('Shared', ['setOnlineState', 'getAppVersions']),
    ...mapMutations('Shared', [
      'clearError',
      'clearInfoMessage',
      'clearSuccessMessage',
      'setSwNeedUpdate',
      'setShowItems',
      'setConnectionType',
      'setConnectionTypeBypass',
    ]),
    hackWifi() {
      this.setConnectionTypeBypass(!this.connectionTypeBypass)
    },

    // Esse metodo recebe as mesagens enviadas para as mutations setError, setInfoMessage, setSuccessMessage
    // E organiza para que o método alertMessage paça o disparo sequencialmente
    addMessagesToQueue(messages, type, duration = 10000) {
      // Message sempre será um array, que pode ser um array de arrays ou um array de strings
      if (messages.length === 0) return

      let clearAction = () => {}
      if (type === 'error') clearAction = this.clearError
      if (type === 'info') clearAction = this.clearInfoMessage
      if (type === 'success') clearAction = this.clearSuccessMessage

      // console.log(`addMessagesToQueue ${type}Message`, messages)

      messages.map((submmsg1, idx1) => {
        // Se cada submensagemm for um array de mensagens
        if (Array.isArray(submmsg1) && submmsg1.length > 0) {
          submmsg1.map((submmsg2, idx2) => {
            this.messagesQueue.push({ msg: submmsg2, type, duration })
          })

          // Se cada submensagemm for uma string simples
        } else if (typeof submmsg1 === 'string' && submmsg1 !== '') {
          this.messagesQueue.push({ msg: submmsg1, type, duration })

          // Se cada submensagemm for uma mensagem Vnode
          // Espera um objeto com essaformação
          // {
          //   messages: ['array_de_mensagens_de_texto'],
          //   vnode: true,
          // }
        } else if (typeof submmsg1 === 'object' && submmsg1.vnode) {
          this.messagesQueue.push({
            msg: submmsg1.messages,
            type,
            duration,
            vnode: true,
          })
        }
      })

      // Chamo a próxima função e limpo o state para novas mensagens
      this.alertMessage()
      clearAction()
    },

    // Se objeto for uma construção do tipo vNode
    // Construi objeto vNode
    buildvNodeMessage(array) {
      const h = this.$createElement
      const style = {
        style: 'font-size: 14px; margin-bottom: 5px; color:#3d3d3d;',
      }
      const vNodeMessages = []
      array.map((msg) => {
        vNodeMessages.push(h('div', style, msg))
        // vNodeMessages.push(h('br', null, null))
      })

      const styleParent = 'dispay: flex; flex-direction: row;'
      const vNodeHeader = h('div', { style: styleParent }, vNodeMessages)
      return vNodeHeader
    },

    // Dispara os alertas recebitos pelas mutations setError, setInfoMessage, setSuccessMessage
    // Após processamento de addMessagesToQueue
    alertMessage() {
      let messages = this.messagesQueue
      this.messagesQueue = []
      // console.log(`alertMessage`, messages, this.messagesQueue)

      if (messages.length > 0) {
        messages.map((msgObject, idx) => {
          if (msgObject.vnode) {
            msgObject.msg = this.buildvNodeMessage(msgObject.msg)
          }

          setTimeout(() => {
            this.$message({
              type: msgObject.type,
              message: msgObject.msg,
              showClose: true,
              duration: msgObject.duration,
            })
          }, 250 * idx)
        })
      }
    },
  },
}
</script>

<style>
html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

h1 {
  border-bottom: 1px #ccc solid;
  text-transform: uppercase;
  font-size: 15px;
  padding-bottom: 5px;
}

h2 {
  border-bottom: 1px #ccc solid;
  text-transform: uppercase;
  font-size: 13px;
  padding-bottom: 5px;
  margin-bottom: 4px;
}

.versao {
  font-size: 10px;
  position: absolute;
  bottom: 6px;
  right: 2px;
  line-height: 10px;
}

.uploadMessage {
  font-size: 10px;
  position: absolute;
  line-height: 10px;
  color: white;
  bottom: 6px;
  left: 2px;
}

.app-main-div {
  z-index: 1;
  height: 100%;
}

.el-container main {
  padding: 5px 18px;
  min-height: 100%;
}

.el-main {
  position: relative;
  margin: auto;
  width: 100%;
  margin-top: 0px;
  padding: 0px !important;
}

.el-main > div {
  overflow: auto;
  margin: auto;
  position: relative;
  margin-bottom: 50px;
}

.el-main > div > div {
  padding: 5px 18px;
  margin-top: 7px;
}

.el-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to bottom, white, black 30%);
  color: #ffffff;
  font-size: 10px;
  text-align: center;
}

.h2-descricao {
  font-size: 14px;
  margin-bottom: 10px;
  color: #808080;
  line-height: 1.5;
  margin-top: 5px;
}
.h2-descricao-destaque {
  font-weight: bold;
  color: black;
}

.redcolor {
  color: red;
}

.bluecolor {
  color: blue;
}

/* Titulos de página e Botões do topo */
ul.menu-header {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border-bottom: 1px #ccc solid;
  font-size: 12px;
  margin-bottom: 7px;
}

ul.menu-header + * {
  margin-top: 0;
}

ul.menu-header + form {
  margin-top: 14px;
}

ul.menu-header li {
  float: left;
}

ul.menu-header li.r {
  float: right;
}

ul.menu-header li span {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px;
}

ul.menu-header li span.h1 {
  font-size: 14px;
}

ul.menu-header li span.h2 {
  font-size: 12px;
}

ul.menu-header li a {
  min-width: 100px;
  cursor: pointer;
  display: block;
  color: white;
  text-align: center;
  padding: 8px 10px;
  text-decoration: none;
  border-left: 0.5px #ccc solid;
}

ul.menu-header li a {
  background-color: #bebebe;
}

ul.menu-header li a:hover:not(.active) {
  background-color: #9b9b9b;
}

ul.menu-header .active {
  background-color: #bebebe;
}

.padding-after-10px {
  padding-bottom: 10px;
}

.padding-after-20px {
  padding-bottom: 20px;
}

.padding-after-30px {
  padding-bottom: 30px;
}

/* Fix el-drawer - add scroll bar */
.el-drawer__wrapper {
  overflow: auto !important;
}

.el-drawer__container {
  height: 240% !important;
}

/* el-paginator */
.el-pagination {
  display: flex;
  justify-content: center;
}

/* tabelas */
.el-table--mini td,
.el-table--mini th {
  padding: 0 !important;
}

.el-table__header th {
  background-color: #f5f7fa !important;
  border-top: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
}

.el-table .warning-row {
  background: oldlace;
  color: red;
}

.el-table .el-button--text {
  font-size: 12px;
  padding: 2px 2px;
}

.el-table .cell {
  padding-left: 3px !important;
  padding-right: 3px !important;
  word-break: unset !important;
  line-height: 16px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.el-table--mini .mr-data-table-row td,
.el-table--mini .mr-data-table-row th {
  padding: 4px 0 !important;
}

/* Padronização de formuários */
.el-form {
  width: 90%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.el-form-fullwidth {
  max-width: 100%;
}

.el-form.form-no-margin {
  margin-left: 0;
  margin-right: 0;
}

.el-form label.el-checkbox {
  width: 100%;
}

.el-form-item + h2 {
  margin-top: 25px;
}

.el-select {
  width: 100%;
}

.el-form--label-right .el-select {
  width: 60%;
}

.el-form--inline .el-select {
  min-width: 400px;
}

.el-form--inline .el-form-item__label {
  min-width: 100px;
  margin-right: 10px;
}

.el-select__tags-text {
  font-size: 10px;
}

.el-input-number {
  width: 70%;
  margin-left: 14%;
  margin-right: 14%;
}

.el-input-number + .el-form-item__error,
.input-file-container + .el-form-item__error,
.el-form-item__content > .el-form-item__error {
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.el-form-item > label.el-form-item__label {
  padding: 0 8px 0 0;
}

.el-form-item.el-form-item-no-botton-margin {
  margin-bottom: 0px !important;
}

.el-link {
  font-size: inherit !important;
  font-weight: inherit !important;
}

/* classes para os buttons de ação no final dos formulários */
.actions-buttons {
  width: 100%;
  text-align: center;
  align-content: center;
  margin-top: 10px;
  border-top: 1px #ccc solid;
  padding-top: 10px;
}

.actions-buttons button {
  margin-bottom: 10px;
  margin-left: 0px;
  width: 160px;
}

.actions-buttons-fullWidth button {
  width: 90%;
}

.el-button + .el-button,
.actions-buttons > .el-button:not(:first-child) {
  margin-left: 0;
}

.actions-buttons a:link {
  text-decoration: none;
}

.el-button--primary {
  background-color: #00afef;
}

/* classes para adicionar opções extras relacionadas com campos de formuários */
.form-input-extra-option-link {
  color: #ccc;
  font-size: 12px;
}

.form-input-extra-option-link-red {
  color: #f56c6c;
  font-size: 12px;
}

.form-input-extra-option {
  text-align: right;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.form-input-extra-option-left {
  text-align: left;
  color: #ccc;
  font-size: 12px;
}

.form-input-extra-info {
  color: #ccc;
  font-size: 12px;
  text-align: right;
  line-height: 20px;
  font-style: italic;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .el-message {
    min-width: 95%;
    font-size: 11px;
    font-weight: normal;
    min-width: 280px;
  }

  .el-message-box {
    width: 95% !important;
  }
}

/* não esta funcionando ainda, pesquisar */
/* @media only screen and (max-width: 360px) {
    .mobile { min-width: 360px; }
}

@media only screen and (min-width: 361px) {
    .mobile { min-width: 400px; }
}

@media only screen and (min-width: 400px) {
    .mobile { min-width: 500px; }
}

@media only screen and (min-width: 500px) {
    .mobile { min-width: 600px; }

@media only screen and (min-width: 600px) {
    .mobile { min-width: 700px; }
} */

/* para usar na route transition */
/* .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
} */
</style>
