<template>
  <div class="cargo-conference-component-logs">
    <el-card class="box-card-conference-history" shadow="never">
      <div slot="header" class="box-card-conference-header">
        Históricos
      </div>

      <div
        class="historyline"
        v-for="(log, index) in logsSorted"
        :key="`logs_${index}`"
      >
        <b>{{
          $moment.unix(log.created_at.seconds).format('DD/MM/YYYY HH:mm')
        }}</b
        ><br />
        {{ log.msg }}
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'cargos-conference-logs',
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    logsSorted() {
      let sorted = this.logs
      return sorted.sort((a, b) => {
        if (a.created_at.seconds > b.created_at.seconds) return -1
        if (a.created_at.seconds < b.created_at.seconds) return 1
        return 0
      })
    },
  },
  methods: {},
  mounted() {},
}
</script>

<style scoped>
.historyline {
  line-height: 14px;
  font-size: 12px;
  margin-bottom: 10px;
}
.box-card-conference-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 60px;
}
</style>
