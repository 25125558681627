<template>
  <truck-form :truck="truck" />
</template>

<script>
import Form from '@/components/Admin/Trucks/components/form'

export default {
  name: 'trucksEdit',
  components: {
    TruckForm: Form,
  },
  props: {
    truck: {
      type: Object,
      default: null,
    },
  },
}
</script>
