import env from '@/config/environment'
import { store } from '@/store'
import axios from 'axios'

const callAxios = ({
  type,
  data,
  method,
  useIndex = true,
  command,
  params,
}) => {
  if (!env.elastic.url) {
    throw new Error(`Elastic error, invalid URL`)
  }

  if (!env.elastic.token) {
    throw new Error(`Elastic error, invalid Token`)
  }

  const token = env.elastic.token
  const elasticURL = env.elastic.url
  const elasticIndex = store.state.User.companie.id.toLowerCase()
  let url = elasticURL

  if (useIndex) {
    url = `${url}/${elasticIndex}`
  }

  if (type) {
    url = `${url}/${type}`
  }

  if (command) {
    url = `${url}/${command}`
  }

  let axiosObject = {
    url,
    method: method,
    headers: {
      Authorization: `Basic ${token}`,
      'Content-type': 'application/json',
    },
  }

  if (method.toUpperCase() === 'POST' && data) {
    axiosObject.data = data
  }

  if (method.toUpperCase() === 'GET' && data) {
    axiosObject.params = {
      source: JSON.stringify(data),
      source_content_type: 'application/json',
    }
  }
  return axios(axiosObject)
    .then((response) => response)
    .catch((error) => {
      console.warn(`HTTP error on request to ${url} : `, error.message)
      throw error
    })
}

const updateInvoiceStatus = (invoiceId, newStatus) => {
  const invoiceData = { doc: { invoice_status: newStatus } }
  if (newStatus === 'A') {
    invoiceData.doc['status'] = 'not_started'
    invoiceData.doc['cargo_id'] = ''
    invoiceData.doc['cargo_doc_id'] = ''
    invoiceData.doc['cargo_branch_id'] = ''
  }
  return callAxios({
    type: 'invoices',
    data: invoiceData,
    method: 'POST',
    command: `${invoiceId}/_update`,
  })
}

const getInvoices = (query) => {
  return callAxios({
    type: 'invoices',
    data: query,
    method: 'GET',
    command: '_search?size=10000',
  })
}

const deleteInvoice = (id) => {
  return callAxios({
    type: 'invoices',
    method: 'DELETE',
    command: encodeURI(id),
  })
}

const getInvoicesSQL = async (query) => {
  if (typeof query !== 'string') {
    query = mountSQLQuery(query)
  }
  const sqlQuery = {
    query,
  }
  const returnQuery = await translateSqlToElasticQuery(sqlQuery)
  const elasticQuery = { size: 10000, query: returnQuery.data.query }
  if (returnQuery.data.hasOwnProperty('sort')) {
    elasticQuery.sort = returnQuery.data.sort
  }
  return callAxios({
    type: 'invoices',
    data: elasticQuery,
    method: 'POST',
    command: '_search',
  })
}

const mountSQLQuery = (query) => {
  const companyID = store.state.User.companie.id
  const elasticIndex = companyID.toLowerCase()
  let querySql = `SELECT * FROM ${elasticIndex}`
  querySql += ` where company_id= '${companyID}'`
  query.forEach((q) => {
    if (q.operation === 'EQUAL') {
      if (typeof q.value === 'string') {
        querySql += ` AND ${q.field} = '${q.value}'`
      } else {
        querySql += ` AND ${q.field} = ${q.value}`
      }
    } else if (q.operation === 'NOEQUAL') {
      if (typeof q.value === 'string') {
        querySql += ` AND ${q.field} <> '${q.value}'`
      } else {
        querySql += ` AND ${q.field} <> ${q.value}`
      }
    } else if (q.operation === 'IN') {
      querySql += ` AND ${q.field} IN ('${q.value.join("','")}')`
    } else if (q.operation === 'BETWEEN') {
      if (typeof q.value.start === 'string') {
        querySql += ` AND ${q.field} BETWEEN '${q.value.start}' AND '${q.value.end}'`
      } else {
        querySql += ` AND ${q.field} BETWEEN ${q.value.start} AND ${q.value.end}`
      }
    }
  })
  const orderBy = query.filter((q) => q.operation === 'ORDERBY')
  if (orderBy.length > 0) {
    querySql += ` ORDER BY ${orderBy[0].value.join(', ')}`
  }
  return querySql
}
const postInvoices = (invoices) => {
  let invoiceData = ''

  invoices.forEach((i) => {
    invoiceData += `{"index":{"_id" : "${i.id}"}}\n`
    invoiceData += JSON.stringify(i.data) + '\n'
  })

  return callAxios({
    type: 'invoices',
    data: invoiceData,
    method: 'POST',
    command: '_bulk',
  })
}

const translateSqlToElasticQuery = (query) => {
  return callAxios({
    command: '_sql/translate',
    data: query,
    method: 'POST',
    useIndex: false,
  })
}

const vueElasticInstall = {
  install(Vue, options) {
    Vue.prototype.$elastic = {
      getInvoices,
      getInvoicesSQL,
      postInvoices,
      deleteInvoice,
      updateInvoiceStatus,
    }
  },
}

export { vueElasticInstall as elasticInstall }
export { getInvoices, getInvoicesSQL, postInvoices, deleteInvoice }
