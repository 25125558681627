import localforage from 'localforage'
import env from '@/config/environment'

function openStore(storeName) {
  return localforage.createInstance({
    driver: localforage.INDEXEDDB,
    name: env.config.index_db,
    version: 1.0,
    storeName: storeName,
    description: '',
  })
}

export default openStore
