<template>
  <div>
    <ul class="menu-header">
      <li><span class="h2">Configurações de notificações</span></li>
    </ul>
    <el-form
      ref="formNotifications"
      status-icon
      label-position="right"
      style="max-width: 70%;"
    >
      <switch-item
        :swinput.sync="settings.notifications.enabled.onConstumerCanceled"
        :input.sync="settings.notifications.addresses.onConstumerCanceled"
        label="Notificar quando cliente é cancelado"
      />
      <switch-item
        :swinput.sync="settings.notifications.enabled.onInvoiceCanceled"
        :input.sync="settings.notifications.addresses.onInvoiceCanceled"
        label="Notificar quando documento é cancelado"
      />
      <switch-item
        :swinput.sync="settings.notifications.enabled.onInvoicePartial"
        :input.sync="settings.notifications.addresses.onInvoicePartial"
        label="Notificar quando entrega parcial é realizada"
      />
    </el-form>

    <div class="actions-buttons actions-buttons">
      <el-button type="success" @click="saveForm">Salvar</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import switchItem from '../components/formSwitchItem'

export default {
  name: 'tabCompanieFormNotification',
  components: { switchItem },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    companie: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        notifications: {
          addresses: {
            onConstumerCanceled: this._.get(
              this,
              'companie.settings.notifications.addresses.onConstumerCanceled',
              ''
            ),
            onInvoiceCanceled: this._.get(
              this,
              'companie.settings.notifications.addresses.onInvoiceCanceled',
              ''
            ),
            onInvoicePartial: this._.get(
              this,
              'companie.settings.notifications.addresses.onInvoicePartial',
              ''
            ),
          },
          enabled: {
            onConstumerCanceled: this._.get(
              this,
              'companie.settings.notifications.enabled.onConstumerCanceled',
              false
            ),
            onInvoiceCanceled: this._.get(
              this,
              'companie.settings.notifications.enabled.onInvoiceCanceled',
              false
            ),
            onInvoicePartial: this._.get(
              this,
              'companie.settings.notifications.enabled.onInvoicePartial',
              false
            ),
          },
        },
      },
    }
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions('User', ['updateCompanieSettings']),
    saveForm() {
      this.updateCompanieSettings({ settings: this.settings })
    },
  },
}
</script>
