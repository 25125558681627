export default {
  getAllInvoicesFilteredOnyByBranch(state) {
    // console.log(`getAllInvoicesFilteredOnyByBranch started`)

    let allInvoicesFiltered = []
    let filters = state.filters

    // Se existir filtro de FILIAL, aceitando array de filiais ou apenas um astring de filial
    if (filters.branch && filters.branch.length > 0) {
      if (Array.isArray(filters.branch)) {
        allInvoicesFiltered = state.allInvoices.filter((invoice) =>
          filters.branch.includes(invoice.branch_id)
        )
      } else if (
        typeof filters.branch === 'string' &&
        filters.branch.trim() !== ''
      ) {
        allInvoicesFiltered = state.allInvoices.filter(
          (invoice) => invoice.branch_id === filters.branch
        )
      }
    }

    // Retorna resultados
    return allInvoicesFiltered
  },

  getAllInvoicesFiltered(state) {
    if (debug) console.log(`getAllInvoicesFiltered started`, state.allInvoices)

    let allInvoicesFiltered = state.allInvoices
    let debug = false
    let filters = state.filters

    if (allInvoicesFiltered === undefined || allInvoicesFiltered === null) {
      console.warn(`Invalid state allInvoices in getAllInvoicesFiltered`)
      allInvoicesFiltered = []
    }

    // Se existir filtro de FILIAL, aceitando array de filiais ou apenas um astring de filial
    if (filters.branch && filters.branch.length > 0) {
      if (debug) console.log('getAllInvoicesFiltered branch:', filters.branch)
      if (Array.isArray(filters.branch)) {
        allInvoicesFiltered = allInvoicesFiltered.filter((invoice) =>
          filters.branch.includes(invoice.branch_id) || !invoice.branch_id
        )
      } else if (
        typeof filters.branch === 'string' &&
        filters.branch.trim() !== ''
      ) {
        allInvoicesFiltered = allInvoicesFiltered.filter(
          (invoice) => invoice.branch_id === filters.branch || !invoice.branch_id
        )
      }
    }

    // Se existir filtro de BAIRRO, aceitando array de filiais ou apenas um astring de filial
    if (filters.neighborhoods && filters.neighborhoods.length > 0) {
      if (debug)
        console.log(
          'getAllInvoicesFilteredneighborhood:',
          filters.neighborhoods
        )
      if (Array.isArray(filters.neighborhoods)) {
        allInvoicesFiltered = allInvoicesFiltered.filter((invoice) =>
          filters.neighborhoods.includes(invoice.neighborhood)
        )
      } else if (
        typeof filters.neighborhood === 'string' &&
        filters.branch.trim() !== ''
      ) {
        allInvoicesFiltered = allInvoicesFiltered.filter(
          (invoice) => invoice.neighborhood === filters.neighborhoods
        )
      }
    }

    // Se existir filtro de CIDADE, aceitando array de filiais ou apenas um astring de filial
    if (filters.cities && filters.cities.length > 0) {
      if (debug) console.log('getAllInvoicesFiltered cities:', filters.cities)

      if (Array.isArray(filters.cities)) {
        allInvoicesFiltered = allInvoicesFiltered.filter((invoice) =>
          filters.cities.includes(invoice.city)
        )
      } else if (
        typeof filters.neighborhood === 'string' &&
        filters.branch.trim() !== ''
      ) {
        allInvoicesFiltered = allInvoicesFiltered.filter(
          (invoice) => invoice.city === filters.cities
        )
      }
    }

    // Se existir filtro de ESTADOS, aceitando array de filiais ou apenas um astring de filial
    if (filters.states && filters.states.length > 0) {
      if (debug) console.log('getAllInvoicesFiltered states:', filters.states)

      if (Array.isArray(filters.states)) {
        allInvoicesFiltered = allInvoicesFiltered.filter((invoice) =>
          filters.states.includes(invoice.state)
        )
      } else if (
        typeof filters.neighborhood === 'string' &&
        filters.branch.trim() !== ''
      ) {
        allInvoicesFiltered = allInvoicesFiltered.filter(
          (invoice) => invoice.state === filters.states
        )
      }
    }

    // Se existir filtro de SETOR, aceitando array de filiais ou apenas um astring de filial
    if (filters.sectors && filters.sectors.length > 0) {
      if (debug) console.log('getAllInvoicesFiltered sectors:', filters.sectors)

      if (Array.isArray(filters.sectors)) {
        allInvoicesFiltered = allInvoicesFiltered.filter((invoice) =>
          filters.sectors.includes(invoice.sector)
        )
      } else if (
        typeof filters.neighborhood === 'string' &&
        filters.branch.trim() !== ''
      ) {
        allInvoicesFiltered = allInvoicesFiltered.filter(
          (invoice) => invoice.sector === filters.sectors
        )
      }
    }

    // Se existir filtro de ZONAS, aceitando array de filiais ou apenas um astring de filial
    if (filters.zones && filters.zones.length > 0) {
      if (debug) console.log('getAllInvoicesFiltered zones:', filters.zones)

      if (Array.isArray(filters.zones)) {
        allInvoicesFiltered = allInvoicesFiltered.filter((invoice) =>
          filters.zones.includes(invoice.zone)
        )
      } else if (
        typeof filters.neighborhood === 'string' &&
        filters.branch.trim() !== ''
      ) {
        allInvoicesFiltered = allInvoicesFiltered.filter(
          (invoice) => invoice.zone === filters.zones
        )
      }
    }

    // Se existir filtro de SETOR, aceitando array de filiais ou apenas um astring de filial
    if (filters.routes && filters.routes.length > 0) {
      if (debug) console.log('getAllInvoicesFiltered routes:', filters.routes)

      if (Array.isArray(filters.routes)) {
        allInvoicesFiltered = allInvoicesFiltered.filter((invoice) =>
          filters.routes.includes(invoice.route)
        )
      } else if (
        typeof filters.neighborhood === 'string' &&
        filters.branch.trim() !== ''
      ) {
        allInvoicesFiltered = allInvoicesFiltered.filter(
          (invoice) => invoice.route === filters.routes
        )
      }
    }

    // Se existir filtro de searchString busca livre
    if (filters.searchString && filters.searchString.length >= 2) {
      if (debug)
        console.log(
          'getAllInvoicesFiltered searchString:',
          filters.searchString
        )

      allInvoicesFiltered = allInvoicesFiltered.filter((d) => {
        // Separo o campo busca em array, e aplico a funcão every que verificar se todos os valores satifaz a função
        // Na função procuro pela palavra dentro do grande campo concatenado
        return filters.searchString
          .toLowerCase()
          .split(' ')
          .every((v) =>
            `${d.invoice}${d.order_number}${d.customer}${d.neighborhood}${d.nfe_key}`
              .toLowerCase()
              .includes(v)
          )
      })
    }

    // Debug resultados
    if (debug)
      console.log(
        'getAllInvoicesFiltered Resultados sem filtro:',
        state.allInvoices.length,
        ' após filtro:',
        allInvoicesFiltered.length
      )
    if (debug) console.log()

    // Retorna resultados
    return allInvoicesFiltered
  },
}
