import format from '@/helpers/format'
import moment from 'moment'
import _ from 'lodash'
import { store } from '@/store'

// function getDate(seconds) {
//   return moment.unix(seconds).format('DD/MM/YYYY HH:mm')
// }

function getTime(seconds) {
  return moment.unix(seconds).format('HH:mm')
}

// function getActive(d) {
//   if (_.get(d, '_cargo.finished_at.seconds', false)) return 5
//   if (_.get(d, 'delivered_at.seconds', false)) return 4
//   if (_.get(d, 'delivering_checkin_at.seconds', false)) return 3
//   if (_.get(d, 'delivering_at.seconds', false)) return 2
//   if (_.get(d, '_cargo.started_at.seconds', false)) return 1
//   return 0
// }

function paymentEdited(invoice) {
  if (invoice.paymentEdited) {
    return Object.values(invoice.paymentEdited).map((payment) => {
      return {
        ...payment,
        ammountFormated: format(payment.ammount, 'C'),
        nameFormated: store.getters['User/getPaymentName'](payment),
      }
    })
  } else {
    return false
  }
}

function formatedType(tipo) {
  if (tipo === 'payfuel') return 'Abastecimento'
  else if (tipo === 'paytax') return 'Pedágio'
  else if (tipo === 'general') return 'Geral'
  else return tipo
}
const cargoDetail = (allCargos = [], cargoid = '', branchid = '', events) => {
  let cargoDetail = allCargos.find(
    (cargo) => cargo.cargo_id === cargoid && cargo.branch_id === branchid
  )
  const startKm = cargoDetail.started_km ? cargoDetail.started_km : 0
  if (cargoDetail) {
    let lastKm = startKm
    let customers = cargoDetail['customers'] || cargoDetail['costumers']
    cargoDetail = Object.values(customers)
      .sort((a, b) => {
        let keyA =
          a.hasOwnProperty('delivering_at') && a.delivering_at.seconds
            ? a.delivering_at.seconds
            : '23:59'
        let keyB =
          b.hasOwnProperty('delivering_at') && b.delivering_at.seconds
            ? b.delivering_at.seconds
            : '23:59'
        if (keyA < keyB) {
          return -1
        }
        if (keyA > keyB) {
          return 1
        }
        return 0
      })
      .map((customer) => {
        let deliveredAt = ''
        if (customer.hasOwnProperty('costumer')) {
          customer.customer = customer.costumer
        }
        if (customer.hasOwnProperty('delivered_at')) {
          deliveredAt = moment
            .unix(customer.delivered_at.seconds)
            .format('HH:mm')
        }
        let deliveringAt = ''
        if (customer.hasOwnProperty('delivering_at')) {
          deliveringAt = moment
            .unix(customer.delivering_at.seconds)
            .format('HH:mm')
        }
        let deliveringCheckinAt = ''
        if (customer.hasOwnProperty('delivering_checkin_at')) {
          deliveringCheckinAt = moment
            .unix(customer.delivering_checkin_at.seconds)
            .format('HH:mm')
        }
        let deliveryDuration = ''
        if (
          customer.hasOwnProperty('delivering_at') &&
          customer.hasOwnProperty('delivered_at')
        ) {
          let startedAt = moment.unix(customer.delivering_at.seconds)
          let finishedAt = moment.unix(customer.delivered_at.seconds)
          let totalSeconds = moment
            .duration(finishedAt.diff(startedAt))
            .as('seconds')
          let hours = Math.floor(totalSeconds / 3600)
          totalSeconds %= 3600
          let minutes = Math.floor(totalSeconds / 60)
          let seconds = totalSeconds % 60
          minutes = String(minutes).padStart(2, '0')
          hours = String(hours).padStart(2, '0')
          seconds = String(seconds).padStart(2, '0')
          deliveryDuration = hours + ':' + minutes + ':' + seconds
        }
        let deliverGeo = []
        if (
          customer.hasOwnProperty('delivering_geo') &&
          customer.delivering_geo
        ) {
          deliverGeo.push({
            type: 'Checkin',
            coords: {
              lat: customer.delivering_geo.latitude,
              lng: customer.delivering_geo.longitude,
            },
          })
        }
        if (
          customer.hasOwnProperty('delivered_geo') &&
          customer.delivered_geo
        ) {
          deliverGeo.push({
            type: 'Checkout',
            coords: {
              lat: customer.delivered_geo.latitude,
              lng: customer.delivered_geo.longitude,
            },
          })
        }
        let customerStatuslabel = {}
        if (!customer.status) {
          customerStatuslabel =
            store.state.Cargos.deliveryLabels.find(
              (item) => item.content === 'not_started'
            ) || {}
        } else {
          customerStatuslabel =
            store.state.Cargos.deliveryLabels.find(
              (item) => item.content === customer.status
            ) || {}
        }
        if (customer.has_invoice_partial) {
          customerStatuslabel =
            store.state.Cargos.deliveryLabels.find(
              (item) => item.content === 'with_partial'
            ) || {}
        }
        if (customer.has_invoice_canceled) {
          customerStatuslabel =
            store.state.Cargos.deliveryLabels.find(
              (item) => item.content === 'with_one_invoice_canceled'
            ) || {}
        }
        let temp = {
          _database: { ...customer },
          _cargo: { ...cargoDetail },
          ...customer,
          cargoStartedAt: _.get(cargoDetail, 'started_at.seconds', false)
            ? getTime(cargoDetail.started_at.seconds)
            : 'Aguardando',
          cargoFinishedAt: _.get(cargoDetail, 'finished_at.seconds', false)
            ? getTime(cargoDetail.finished_at.seconds)
            : 'Aguardando',
          deliverGeo: deliverGeo,
          invoicesQuantity: Object.values(customer.invoices).length,
          invoicesTotal: format(
            Object.values(customer.invoices).reduce(
              (invoiceAmount, invoice) => invoiceAmount + invoice.total,
              0
            ),
            'C'
          ),
          invoicesPartial: Object.values(customer.invoices).reduce(
            (invoiceAmount, invoice) =>
              invoiceAmount + (invoice.status === 'with_partial' ? 1 : 0),
            0
          ),
          invoicesCanceled: Object.values(customer.invoices).reduce(
            (invoiceAmount, invoice) =>
              invoiceAmount + (invoice.status === 'canceled' ? 1 : 0),
            0
          ),
          amountreceivable: format(
            Object.values(customer.invoices).reduce(
              (invoiceAmount, invoice) =>
                invoiceAmount +
                (invoice.amountreceivable ? invoice.amountreceivable : 0),
              0
            ),
            'C'
          ),
          amountreceived: format(
            Object.values(customer.invoices).reduce(
              (invA, invoice) =>
                invA +
                Object.values(invoice.payment).reduce(
                  (pA, payment) => pA + (payment.ammount ? payment.ammount : 0),
                  0
                ),
              0
            ),
            'C'
          ),
          weight: format(
            Object.values(customer.invoices).reduce(
              (invoiceAmount, invoice) => invoiceAmount + invoice.weight,
              0
            ),
            'N'
          ),
          traveledKm:
            customer.hasOwnProperty('delivered_km') && customer.delivered_km
              ? format(customer.delivered_km - lastKm, 'N')
              : '',
          deliveredAt: deliveredAt,
          deliveringAt: deliveringAt,
          deliveringCheckinAt: deliveringCheckinAt,
          deliveryDuration: deliveryDuration,
          invoices: Object.values(customer.invoices).map((invoice) => {
            let InvoiceStatuslabel =
              store.state.Cargos.deliveryLabels.find(
                (item) => item.content === invoice.status
              ) || {}
            return {
              ...invoice,
              items: Object.values(invoice.items),
              total: format(invoice.total, 'C'),
              amountreceivable: format(invoice.amountreceivable, 'C'),
              payment: Object.values(invoice.payment).map((payment) => {
                return {
                  ...payment,
                  ammountFormated: format(payment.ammount, 'C'),
                  nameFormated: store.getters['User/getPaymentName'](payment),
                }
              }),
              paymentEdited: paymentEdited(invoice),
              status: invoice.status || 'Não iniciado',
              statusLabel: InvoiceStatuslabel.value || 'Não iniciado',
            }
          }),
          invoicesHasPaymentEdited: Object.values(customer.invoices).find(
            (invoice) => invoice.paymentEdited
          ),
          needLogisticCheck: customerStatuslabel.logisticCheck,
          statusLabel: customerStatuslabel.value || 'ND',
          statusColor: customerStatuslabel.color || 'info',
          checked: false,
        }
        lastKm = customer.delivered_km
        return temp
      })
  }
  let eventsDetails = []
  if (events) {
    eventsDetails = events.map((event) => {
      return {
        event: {
          ...event,
          type: formatedType(event.event_type),
          truck: event.truck_id,
          desc: event.event.notice,
          created_at: moment
            .unix(event.created_at.seconds)
            .format('DD/MM/YY HH:mm:ss'),
          value: format(event.event.value, 'C'),
          km: format(event.event.kmatual, 'N'),
          image: null,
          images:
            'images_urls' in event
              ? event.images_urls.map((image) => image.url)
              : [],
        },
        customer: (
          event.event.notice || formatedType(event.event_type)
        ).toUpperCase(),
        status: 'event',
        statusColor: 'warning',
        statusLabel: 'Evento',
        invoicesQuantity: 0,
        invoicesTotal: format(event.event.value, 'C'),
        amountreceived: 0,
        delivered_who: '',
        weight: 0,
        delivered_km: event.event.kmatual,
        deliveringAt: moment.unix(event.created_at.seconds).format('HH:mm'),
        deliveredAt: '',
        deliverGeo: [
          {
            type: 'Evento',
            coords: {
              lat: event.location.latitude,
              lng: event.location.longitude,
            },
          },
        ],
      }
    })
  }
  let lastKm = startKm
  const details = [...cargoDetail, ...eventsDetails]
    .sort((a, b) => {
      let keyA =
        a.hasOwnProperty('delivered_km') && a.delivered_km ? a.delivered_km : 0
      let keyB =
        b.hasOwnProperty('delivered_km') && b.delivered_km ? b.delivered_km : 0
      if (keyA < keyB) {
        return -1
      }
      if (keyA > keyB) {
        return 1
      }
      return 0
    })
    .map((item) => {
      const traveledKm =
        item.hasOwnProperty('delivered_km') && item.delivered_km
          ? format(item.delivered_km - lastKm, 'N')
          : ''
      if (traveledKm) {
        lastKm = item.delivered_km
      }
      return {
        ...item,
        traveledKm,
      }
    })
  return {
    cargo_id: cargoid,
    branch_id: branchid,
    details,
  }
  // return cargoDetail
}

export default cargoDetail
