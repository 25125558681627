<template>
  <el-dialog
    :title="title"
    :visible.sync="showThisDialog"
    center
    :before-close="onClose"
  >
    <el-form :model="form" :rules="formRules" ref="formStatus">
      <el-form-item label="Novo status" prop="newStatus">
        <el-select
          v-model="form.newStatus"
          placeholder="Selecione o novo status para a carga"
        >
          <el-option
            v-for="status in statusFiltered"
            :key="status.content"
            :label="`${status.value} (${status.description})`"
            :value="status.content"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="Motivo da alteração"
        prop="updateMessage"
        v-if="showExtraFileds1"
      >
        <el-input
          v-model="form.updateMessage"
          placeholder="Informe o motivo da alteração de status da carga"
        />
      </el-form-item>

      <el-form-item
        label="Disponibilizar notas novamente?"
        prop="enableInvoicesAgain"
        v-if="showExtraFileds1"
      >
        <el-switch
          v-model="form.enableInvoicesAgain"
          style="margin-left: 20px;"
        ></el-switch>
        <div
          class="form-input-extra-option form-input-extra-option-left"
          v-if="form.enableInvoicesAgain"
        >
          Notas desta carga serão disponibilizadas para montagem de uma nova
          carga.
        </div>
        <div
          class="form-input-extra-option form-input-extra-option-left"
          v-else
        >
          Notas desta carga não serão alteradas, impossibilitando seu uso em uma
          nova carga.
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="submitForm()"
        :disabled="submitDisabled"
        :loading="isLoading"
        style="width: 140px;"
        >{{ submitText }}</el-button
      >
      <el-button @click="onClose" :disabled="isLoading">Cancelar</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'edit-status-dialog',
  props: {},
  data() {
    return {
      showThisDialog: false,
      title: 'Alterar status da carga',
      cargo_id: null,
      branch_id: null,
      statusFiltered: [],
      row: {},
      submitDisabled: true,
      isLoading: false,
      form: {},
      formRules: {
        newStatus: [
          { required: true, validator: this.validateStatus, trigger: 'change' },
        ],
      },
    }
  },
  computed: {
    ...mapState('Cargos', ['statusLabels']),
    submitText() {
      if (this.isLoading) {
        return 'Verificando'
      } else {
        return 'Alterar'
      }
    },
    showExtraFileds1() {
      return this.form.newStatus === 'C' || this.form.newStatus === 'E'
    },
  },
  methods: {
    ...mapActions('Cargos', ['updateStatus', 'getOpenCargoByTruckID']),
    ...mapMutations('Shared', ['setError', 'setSuccessMessage']),
    submitForm() {
      this.$refs['formStatus'].validate(async (valid) => {
        if (valid) {
          if (this.form.newStatus === this.form.oldStatus) {
            // console.log('Status iguais... ignorando submit')
            this.showThisDialog = false
          } else {
            let payload = {
              oldStatus: this.form.oldStatus,
              newStatus: this.form.newStatus,
              updateMessage: this.form.updateMessage,
              enableInvoicesAgain: this.form.enableInvoicesAgain, // libera cargas novamente, true or false
              cargo_id: this.cargo_id,
              branch_id: this.branch_id,
            }
            // console.log(
            //   `Status update de ${this.form.oldStatus} para ${payload.newStatus} na carga ${payload.cargo_id}`
            // )
            this.isLoading = true
            try {
              let success = await this.updateStatus(payload)
              if (success) {
                this.resetForm()
                this.showThisDialog = false
                this.setSuccessMessage('Status alterado com sucesso.')
              }
            } catch (error) {
              this.setError('Ocorreu alguma falha na alteração do status.')
            }

            this.isLoading = false
          }
        }
      })
    },
    onClose() {
      // Run when the dialods close
      this.resetForm()
      this.showThisDialog = false
    },
    resetForm() {
      this.form = {
        newStatus: '',
        oldStatus: '',
        enableInvoicesAgain: true,
        updateMessage: '',
      }
    },
    showDialog(oldstatus, row = {}) {
      // console.log(
      //   'Open edit status dialog',
      //   `${row.branch_id}${row.cargo_id}`,
      //   oldstatus,
      //   row
      // )
      if (oldstatus === 'F' || oldstatus === 'C' || oldstatus === 'E') {
        this.setError('Status da carga não pode ser alterada')
        return
      }

      this.resetForm()
      this.statusFiltered = this.statusLabels
        .filter((statusItem) => statusItem.showOnChange === true)
        .filter((statusItem) => statusItem.content !== oldstatus)
      this.form.oldStatus = row._database.cargo_status
      this.cargo_id = row.cargo_id
      this.branch_id = row.branch_id
      this.row = row
      this.title = `Alterar status da carga ${row.cargo_id}`
      this.submitDisabled = true
      this.isLoading = false
      this.showThisDialog = true
    },
    async validateStatus(rule, value, callback) {
      // console.log(`Validando novo status ${value}`)
      this.submitDisabled = true

      // Se troca é de F para qualquer outra
      if (this.form.oldStatus === 'F') {
        callback(new Error('Carga com status finalizada não pode ser alterada'))

        // Se troca é de D para A
      } else if (this.form.oldStatus === 'D' && value === 'A') {
        callback(
          new Error(
            'Carga aberta em entrega não pode ser disponibilizada novamente'
          )
        )

        // Se troca é de C para A
      } else if (this.form.oldStatus === 'C' && value === 'A') {
        callback(
          new Error(
            'Carga cancelada não pode ser disponibilizada novamente, crie uma nova carga'
          )
        )

        // Se troca é de A para B
      } else if (this.form.oldStatus === 'A' && value === 'B') {
        this.submitDisabled = false
        callback()

        // Se troca é de B para A
      } else if (this.form.oldStatus === 'B' && value === 'A') {
        if (this._.get(this, 'row.vehicle', false)) {
          this.isLoading = true
          let cargoAvaliable = await this.getOpenCargoByTruckID(
            this.row.vehicle
          )

          this.isLoading = false
          if (cargoAvaliable.length > 0) {
            callback(
              new Error(
                `Conflito encontrado: Carga ${cargoAvaliable[0].cargo_id} está com status disponível para o veículo ${cargoAvaliable[0].truck_id}`
              )
            )
          } else {
            this.submitDisabled = false
            callback()
          }
        } else {
          callback(
            new Error('Impossível verificar se status pode ser alterado...')
          )
        }

        // Nenhuma das condições acima foram verdadeiras
      } else {
        this.submitDisabled = false
        callback()
      }
    },
  },
}
</script>
