<!--
    <global-filter-bar
      :branchs="getCompanieBranches"
      :branchsSelected.sync="pickerBranchsValue"
      :dateRange.sync="pickerDateValue"
      insideTitle                                - Definições se a barra aparecerá como titulo da página
      mountCargoBt                               - Habilita botão nova carga
      @searchClick="updateDashboard"             - Evento de clique do botão de busca, busca realiza a busca de cargas e dispara evento para componente pai
      @refreshClick="updateDashboard"            - Evento que tem mesma ação do botão search por enquanto
    />
-->

<template>
  <div class="global-filter-bar">
    <el-row :gutter="5" :justify="positionJustify" :type="positionType">
      <el-col :xs="24" :sm="size1InsideTitle">
        <date-picker stateValuesUpdate denyEmpty />
      </el-col>
      <el-col :xs="24" :sm="size1InsideTitle">
        <el-select
          v-model="branchsSelectedInternal"
          multiple
          filterable
          size="mini"
          collapse-tags
          placeholder="Selecione a unidade para filtro"
          @change="$emit('update:branchsSelected', branchsSelectedInternal)"
        >
          <el-option
            v-for="item in branchsInternal"
            :key="item.branch_id"
            :label="item.branch_name"
            :value="item.branch_id"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :xs="24" :sm="size2InsideTitle">
        <el-tooltip placement="top-start">
          <div slot="content">
            Procurar lista de cargas no banco
            <br />
            <span class="textoQuantidade-globalfilter-bar">
              {{
                `Exibindo ${getTotalCargos} de ${allCargos.length} cargas na memória`
              }}
            </span>
            <br />
            <br />
            <span class="textoQuantidade-globalfilter-bar">
              {{ `${allCargos.length} cargas carregadas` }}
              <br />
              {{ `${allInvoices.length} notas carregados` }}
            </span>
          </div>
          <el-badge
            :value="`${allCargos.length} / ${allInvoices.length}`"
            class="customBadge-globalfilter-bar"
          >
            <el-button-group v-if="$listeners.refreshClick">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-search"
                plain
                @click="searchClick"
              ></el-button>
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-refresh"
                plain
                @click="refreshClick"
              ></el-button>
            </el-button-group>
            <el-button
              v-else
              size="mini"
              type="primary"
              icon="el-icon-search"
              plain
              @click="searchClick"
            ></el-button>
          </el-badge>
        </el-tooltip>
      </el-col>

      <el-col :xs="24" :sm="2" v-if="mountCargoBt">
        <el-button
          size="mini"
          type="success"
          icon="el-icon-circle-plus-outline"
          @click="$router.push('mount')"
          >Nova Carga</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import datePicker from '@/components/Shared/DatePicker'

export default {
  name: 'globalFilterBar',
  components: {
    datePicker,
  },
  props: {
    branchs: {
      type: Array,
      default: () => {
        return []
      },
      // required: true
    },
    dateRange: {
      type: Array,
      default: () => {
        return []
      },
      // required: true
    },
    branchsSelected: {
      type: Array,
      default: () => {
        return []
      },
      // required: true
    },
    insideTitle: {
      type: Boolean,
      default: false,
    },
    mountCargoBt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      branchsSelectedInternal: [],
    }
  },
  computed: {
    ...mapState('Shared', [
      'searchStartDateFilter',
      'searchEndDateFilter',
      'searchBranchsFilter',
    ]),
    ...mapState('Cargos', ['allCargos']),
    ...mapState('Invoices', ['allInvoices']),
    ...mapState('User', ['companie']),
    ...mapGetters('User', ['getCompanieBranches']),
    ...mapGetters('Cargos', ['getTotalCargos']),
    positionJustify() {
      if (!this.insideTitle) return 'center'
      else return 'start'
    },
    positionType() {
      if (!this.insideTitle) return 'flex'
      else return ''
    },
    size1InsideTitle() {
      if (!this.insideTitle) return 5
      else return 10
    },
    size2InsideTitle() {
      if (!this.insideTitle) return 2
      else return 4
    },
    branchsInternal() {
      return this.companie.branchs.map((branch) => branch)
    },
  },
  mounted() {
    if (this.searchBranchsFilter[0] === '*')
      this.branchsSelectedInternal = this.getCompanieBranches.map(
        (branch) => branch.branch_id
      )
    else this.branchsSelectedInternal = this.searchBranchsFilter
  },
  methods: {
    ...mapActions('Cargos', ['getCargos']),
    ...mapMutations('Shared', ['setSearchDateFilter', 'setSearchBranchFilter']),
    async commomMethod() {
      // Update branch filter
      if (
        this.branchsSelectedInternal.length === this.getCompanieBranches.length
      ) {
        this.setSearchBranchFilter(['*'])
      } else {
        this.setSearchBranchFilter(this.branchsSelectedInternal)
      }
    },
    async searchClick() {
      await this.commomMethod()
      this.$emit('searchClick', {
        datestart: this.searchStartDateFilter,
        dateend: this.searchEndDateFilter,
        branchs: this.searchBranchsFilter,
      })
    },
    async refreshClick() {
      await this.commomMethod()
      this.$emit('refreshClick', {
        datestart: this.searchStartDateFilter,
        dateend: this.searchEndDateFilter,
        branchs: this.searchBranchsFilter,
      })
    },
  },
}
</script>

<style>
.textoQuantidade-globalfilter-bar {
  font-style: italic;
  font-size: 10px;
}

.customBadge-globalfilter-bar .el-badge__content {
  top: 5px !important;
}

.customBadge-globalfilter-bar .el-badge__content {
  font-size: 10px;
}
</style>
