<template>
  <div class="cargoTracking">
    <el-row>
      <el-col :span="20">
        <el-button-group>
          <el-button
            v-if="active === 'map'"
            type="primary"
            size="mini"
            icon="el-icon-arrow-left"
            @click="active = 'table'"
            >Voltar</el-button
          >
        </el-button-group>
      </el-col>
    </el-row>

    <el-row v-if="active === 'table'">
      <el-col>
        <el-table
          :data="getTrackingResume"
          stripe
          size="mini"
          highlight-current-row
          height="65vh"
        >
          <el-table-column width="40" align="center">
            <template slot-scope="scope">
              <i
                class="el-icon-map-location"
                @click="goMap(scope.row)"
                style="cursor: pointer;"
              ></i>
            </template>
          </el-table-column>

          <el-table-column width="100" label="Carga">
            <template slot-scope="scope">
              <el-button type="text" @click="cargoIdClick(scope.row)">
                {{ scope.row.cargoId }}
              </el-button>
            </template>
          </el-table-column>

          <el-table-column
            v-for="column in columns"
            :key="column.field"
            :label="column.label"
            :prop="column.field"
            :width="column.width"
            :align="column.align || 'align'"
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row v-if="active === 'map'">
      <el-col>
        <div id="map"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import dbFilter from '@/helpers/firestoreQueryFilter'

export default {
  data() {
    return {
      cargoSelected: null,
      interval: null,
      lastMapUpdate: null,
      active: 'table',
      bounds: null,
      columns: [
        { label: 'Filial', field: 'branchId', width: '40' },
        { label: 'Veículo', field: 'truckId', width: '80' },
        { label: 'Motorista', field: 'driverName', width: '120' },
        {
          label: 'Total Entregas',
          field: 'totalCustomers',
          align: 'center',
        },
        {
          label: 'Restantes',
          field: 'remaininPoints',
          align: 'center',
        },
        {
          label: 'Total Doctos',
          field: 'totalInvoices',
          align: 'center',
        },
        {
          label: 'Entregues',
          field: 'totalInvoicesDelivered',
          align: 'center',
        },
        {
          label: 'Parciais',
          field: 'totalInvoicesPartial',
          align: 'center',
        },
        {
          label: 'Cancelados',
          field: 'totalInvoicesCanceled',
          align: 'center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('Cargos', ['getTrackingResume']),
    ...mapState('Trucks', ['allTrucks']),
    ...mapState('User', ['companie']),
    branchAddress() {
      let branchId = this.cargoSelected.branchId
      let branch = this.companie.branchs.filter(
        (b) => b.branch_id === branchId
      )[0]
      let address = `${branch.address1} - ${branch.neighborhood}, ${branch.city} - ${branch.state}, ${branch.postal_code}`
      let latlng = { lat: branch.latitude, lng: branch.longitude }
      return {
        address,
        latlng,
      }
    },
  },
  methods: {
    ...mapActions('Trucks', ['getTrucksOnSnapshot']),
    getTruckLastLocation(id) {
      const truck = this.allTrucks.find((truck) => truck.id === id)
      let location = truck.location || {}
      if (truck && truck.location) {
        // console.log('****getTruckLastLocation', truck)
        location = { lat: location.latitude, lng: location.longitude }
      }

      return location
    },
    cargoIdClick(row) {
      this.$router.push({
        name: 'CargosDetails',
        params: { branchId: row.branchId, cargoId: row.cargoId },
      })
    },
    goMap(row) {
      this.cargoSelected = row
      this.active = 'map'
    },
    makeMap() {
      if (!this.cargoSelected || this.active === 'table') {
        return
      }

      const truckLocation = this.getTruckLastLocation(
        this.cargoSelected.truckId
      )

      // eslint-disable-next-line no-undef
      this.bounds = new google.maps.LatLngBounds()

      this.lastMapUpdate = this.$moment().format('DD/MM/YYYY - HH:mm')

      const branchLocation = this.cargoSelected.branchLocation

      /* eslint-disable no-new */
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(document.getElementById('map'), {
        center: branchLocation,
        zoom: 10,
        optimized: false,
      })

      let icon = 'https://img.icons8.com/officel/40/000000/garage-closed.png'
      this.createMaker(
        this.branchAddress.latlng,
        '!',
        map,
        null,
        'Origem Entrega',
        null,
        icon
      )
      if (truckLocation && truckLocation.hasOwnProperty('lat')) {
        icon = 'https://img.icons8.com/cute-clipart/40/000000/truck.png'
        this.createMaker(truckLocation, '!', map, null, 'Veículo', null, icon)
      }

      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder()
      // console.log('this.cargoSelected', this.cargoSelected)

      this.cargoSelected.points.forEach((point) => {
        let location = null
        // console.log('point', point)

        let statusDeliver = 'Aguardando Entrega'
        if (point.hasDelivered) {
          if (point.hasCalcel && point.hasPartial) {
            statusDeliver = 'Entrega com cancelamento e parcial'
          } else if (point.hasCalcel) {
            statusDeliver = 'Entrega com cancelamento'
          } else if (point.hasPartial) {
            statusDeliver = 'Entregue parcialmente'
          } else {
            statusDeliver = 'Entrega realizada'
          }
        }

        let windowText = `
        <b>Status:</b> ${statusDeliver}
        <br/>
        <b>Cliente:</b> ${point.name}
        `
        if (point.hasDelivered) {
          windowText += `
          <br/>
          <b>Data e Horario entrega:</b> ${this.$moment
            .unix(point.deliveredAt.seconds)
            .format('DD/MM/YYYY  hh:mm:ss')}
          <br/>
          <b>Recebido por:</b> ${point.deliveredWho}
          `
        }

        if (point.latlng) {
          location = point.latlng
          if (
            !location.hasOwnProperty('lat') &&
            location.hasOwnProperty('latitude')
          ) {
            location = { lat: location.latitude, lng: location.longitude }
          }
          location = {
            ...location,
            hasDelivered: point.hasDelivered,
            hasCalcel: point.hasCalcel,
            hasPartial: point.hasPartial,
          }
          // console.log('sem  geocode', point)

          this.createMaker(
            location,
            point.sequenceOfDelivery,
            map,
            null,
            point.name,
            windowText
          )
        } else {
          // console.log('com geocode', point)
          const self = this
          geocoder.geocode({ address: point.address }, function (
            results,
            status
          ) {
            if (status === 'OK') {
              location = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
                hasDelivered: point.hasDelivered,
                hasCalcel: point.hasCalcel,
                hasPartial: point.hasPartial,
              }

              self.createMaker(
                location,
                point.sequenceOfDelivery,
                map,
                null,
                point.name,
                windowText
              )
            } else {
              alert(
                'Geocode was not successful for the following reason: ' + status
              )
            }
          })
        }
      })
      map.fitBounds(this.bounds)
    },
    createMaker(
      point,
      sequenceOfDelivery,
      map,
      color,
      title = '',
      windowText,
      icon
    ) {
      const baseImage = `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/`
      // console.log('track createMaker', point, sequenceOfDelivery)
      let colorMarker = 'yellow'
      if (color) {
        colorMarker = color
      } else {
        if (point.hasDelivered) {
          colorMarker = 'green'
          if (point.hasCalcel || point.hasPartial) {
            colorMarker = 'red'
          }
        }
      }

      let image =
        baseImage + 'marker_' + colorMarker + sequenceOfDelivery + '.png'

      if (icon) {
        image = icon
      }

      // eslint-disable-next-line no-undef
      const maker = new google.maps.Marker({
        position: point,
        map: map,
        icon: image,
        title,
      })

      if (windowText) {
        // eslint-disable-next-line no-undef
        let infoWindow = new google.maps.InfoWindow({
          content: windowText,
        })

        // eslint-disable-next-line no-undef
        google.maps.event.addListener(maker, 'click', () => {
          infoWindow.open(this.map, maker)
        })
      }

      this.bounds.extend(point)
    },
    /* eslint-enable no-new */
  },
  mounted() {
    this.makeMap()
    this.interval = setInterval(this.makeMap, 60000)
    this.getTrucksOnSnapshot()
  },
  beforeDestroy() {
    clearInterval(this.interval)
    dbFilter.queryUnsubscribe('Trucks/changeTrucks')
  },
  watch: {
    active(newValue) {
      if (newValue === 'map') {
        this.$nextTick(() => {
          this.makeMap()
        })
      }
    },
  },
}
</script>

<style>
.el-tabs__header {
  margin: 0 !important;
}

.cargoTracking td {
    margin: 0 !important;
    padding: 0 !important;
  }
.cargoTracking p {
    margin-top: 16px !important;
    margin-bottom: 0 !important;
    font-size: 10px;
  }
.cargoTracking  #map {
    background-color: lightcyan;
    height: 65vh;
  }

</style>
