<template>
  <div>
    <div class="header">
      <h1>{{ $route.meta.title }}</h1>
      <div class="menu">
        <el-button-group>
          <el-button
            size="small"
            type="primary"
            @click="$router.push({ name: 'TrucksIndex' })"
            icon="el-icon-tickets"
            >Listar</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="$router.push({ name: 'TrucksAdd' })"
            icon="el-icon-plus"
            >Adicionar</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="updateTrucksList()"
            icon="el-icon-refresh"
            >Atualizar</el-button
          >
        </el-button-group>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'trucksIndex',
  methods: {
    ...mapActions('Trucks', ['getTrucks']),
    ...mapMutations('Shared', ['setLoadingMessage']),
    async updateTrucksList() {
      this.setLoadingMessage('Buscando veículos...')
      await this.getTrucks()
      this.setLoadingMessage('')
    },
  },
}
</script>

<style scoped>
.el-main > div {
    width: 100%;
}

.el-main > div .header {
      width: 100%;
      display: flex;
      position: relative;
      padding-top: 10px;
}

.el-main > div .header h1 {
  width: 100%;
  padding-top: 5px;
}

.el-main > div .header .menu {
  position: absolute;
  right: 0;
}
</style>
