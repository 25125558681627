import { db } from '@/config/firebase'
import dbFilter from '@/helpers/firestoreQueryFilter'

export default {
  async getDevices({ commit, rootState }, limit = 20) {
    let deviceRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('devices')
      .orderBy('created_at')
      .limit(limit)

    let devices = await dbFilter.query(deviceRef)

    commit('changeDevices', devices)
    return devices
  },
}
