<template>
  <div>
    <pre>
      {{ profile }}
      {{ companie }}
    </pre>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'companieList',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapState('User', ['profile', 'companie']),
  },
}
</script>
