import { getDefaultInvoiceState, defaultInvoicesFiltersState } from './state'

export default {
  resetState(state) {
    // console.log(`Invoices state reseted`)
    Object.assign(state, getDefaultInvoiceState())
  },
  changeAllInvoices(state, payload) {
    state.allInvoices = payload
  },
  updateStatus(state, payload) {
    let index = state.allInvoices.findIndex(
      (invoice) => invoice.id === payload.invoice_id
    )
    if (index >= 0) {
      state.allInvoices[index]['invocie_status'] = payload.new_status
    }
  },
  setSearchInvoiceFilter(state, payload) {
    if (payload) state.filters = payload
    else state.filters = defaultInvoicesFiltersState()
  },

  resetSearchInvoiceFilter(state) {
    state.filters = defaultInvoicesFiltersState()
  },
}
