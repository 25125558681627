import format from '@/helpers/format'
import moment from 'moment'
import { store } from '@/store'

// const cargoDetail = (getAllCargosFiltered = []) => {
// return this.getAllCargosFiltered.map((cargo) => {

const cargoDetail = (allCargos = [], cargoidfilter = 'all') => {
  return allCargos
    .filter(
      (cargo) => cargo.cargo_id === cargoidfilter || cargoidfilter === 'all'
    )
    .map((cargo) => {
      let customers = cargo['customers'] || cargo['costumers']
      // Total de notas
      let invoices = Object.values(customers)
        .filter((customer) => customer.invoices)
        .reduce(
          (totalInvoices, customer) =>
            totalInvoices + Object.values(customer.invoices).length,
          0
        )

      // Valor total da Nota
      let amount = Object.values(customers)
        .filter((customer) => customer.invoices)
        .reduce(
          (cargoAmount, customer) =>
            cargoAmount +
            Object.values(customer.invoices).reduce(
              (invoiceAmount, invoice) => invoiceAmount + invoice.total,
              0
            ),
          0
        )

      // Peso total da nota
      let weight = Object.values(customers)
        .filter((customer) => customer.invoices)
        .reduce(
          (cargoAmount, customer) =>
            cargoAmount +
            Object.values(customer.invoices).reduce(
              (invoiceAmount, invoice) => invoiceAmount + invoice.weight,
              0
            ),
          0
        )

      // Total de notas com entrega parcial
      let totalInvoicesPartial = Object.values(customers)
        .filter((customer) => customer.invoices)
        .reduce(
          (partialAmount, customer) =>
            partialAmount +
            Object.values(customer.invoices).reduce(
              (invoiceAmount, invoice) =>
                invoiceAmount + (invoice.status === 'with_partial' ? 1 : 0),
              0
            ),
          0
        )

      // Total de notas canceladas
      let totalInvoicesCanceled = Object.values(customers)
        .filter((customer) => customer.invoices)
        .reduce(
          (partialAmount, customer) =>
            partialAmount +
            Object.values(customer.invoices).reduce(
              (invoiceAmount, invoice) =>
                invoiceAmount + (invoice.status === 'canceled' ? 1 : 0),
              0
            ),
          0
        )

      // Total de notas canceladas
      let totalInvoicesPaymentEdited = Object.values(customers)
        .filter((customer) => customer.invoices)
        .reduce((total1, customer) => total1 +
            Object.values(customer.invoices).reduce((total2, invoice) =>
              total2 + (invoice.paymentEdited === 'canceled' ? 1 : 0),
              0
            ),
          0
        )

      // Total de notas conferido pela logistica
      let totalCheckedByLog = Object.values(customers).reduce(
        (total, customer) => {
          return total + (customer.checked_log ? 1 : 0)
        },
        0
      )

      // Total de notas conferido pelo financeiro
      let totalCheckedByFin = Object.values(customers).reduce(
        (total, customer) => {
          return total + (customer.checked_fin ? 1 : 0)
        },
        0
      )

      // Total de clientes reiniciados
      let totalReavailable = Object.values(customers).reduce(
        (total, customer) => {
          return total + (customer.reavailable_at ? 1 : 0)
        },
        0
      )

      // Total de volumes
      let totalVolumes = Object.values(customers).reduce((p, e) => {
        return (
          p +
          Object.values(e.invoices).reduce((p, e) => {
            return p + e.volumes
          }, 0)
        )
      }, 0)

      let startDate = ''
      let startTime = ''
      if (cargo.hasOwnProperty('started_at')) {
        startDate = moment.unix(cargo.started_at.seconds).format('YYYY-MM-DD')
        startTime = moment.unix(cargo.started_at.seconds).format('HH:mm')
      }

      let finishedTime = ''
      let duration = ''
      if (cargo.hasOwnProperty('finished_at')) {
        finishedTime = moment.unix(cargo.finished_at.seconds).format('HH:mm')

        // Validade if has fields before add it to fix admin crash
        let startedAt =
          cargo.started_at && cargo.started_at.seconds
            ? moment.unix(cargo.started_at.seconds)
            : null

        let finishedAt = moment.unix(cargo.finished_at.seconds)
        let totalSeconds = moment
          .duration(finishedAt.diff(startedAt))
          .as('seconds')
        let hours = Math.floor(totalSeconds / 3600)
        totalSeconds %= 3600
        let minutes = Math.floor(totalSeconds / 60)
        let seconds = totalSeconds % 60
        minutes = String(minutes).padStart(2, '0')
        hours = String(hours).padStart(2, '0')
        seconds = String(seconds).padStart(2, '0')
        duration = hours + ':' + minutes + ':' + seconds
      }

      let driver = ''
      if (cargo.started_by) {
        driver = cargo.started_by
      }

      if (cargo.finished_by && cargo.finished_by !== driver) {
        driver = cargo.finished_by
      }

      if (driver) {
        driver = Object.values(store.state.Drivers.allDrivers).find(
          (item) => item.id === driver
        ) // .name
        if (driver) {
          driver = driver.name
        } else {
          driver = ''
        }
      }
      let branchName = store.getters['User/getCompanieBranchName'](
        cargo.branch_id
      )

      let statusLabel = store.state.Cargos.statusLabels.find(
        (item) => item.content === cargo.cargo_status
      )

      statusLabel = !statusLabel ? cargo.cargo_status : statusLabel.value

      let result = {
        _database: { ...cargo },
        date: moment.unix(cargo.created_at.seconds).format('DD/MM/YYYY'),
        status: statusLabel,
        cargo_status: cargo.cargo_status,
        branch_id: cargo.branch_id,
        branch: branchName,
        cargo_id: cargo.cargo_id,
        id: cargo.cargo_id,
        customers_list: Object.values(customers),
        customers: Object.values(customers).length,
        deliveredCustomers: Object.values(customers).filter((customer) =>
          customer.hasOwnProperty('delivered_at')
        ).length,
        canceledCustomers: Object.values(customers).filter((customer) =>
          customer.hasOwnProperty('canceled_at')
        ).length,
        invoices: invoices,
        invoicesPartial: totalInvoicesPartial,
        invoicesCanceled: totalInvoicesCanceled,
        totalReavailable: totalReavailable,
        totalVolumes: totalVolumes,
        amount: format(amount, 'C'),
        averageTicket: format(amount / invoices, 'C'),
        weight: format(weight, 'N'),
        vehicle: cargo.truck_id,
        startedKm_why: cargo.started_km_whyUpdate || null,
        finishedKm_why: cargo.finished_km_whyUpdate || null,
        startedKm:
          cargo.started_km || cargo.started_km > 0
            ? format(cargo.started_km, 'N')
            : '0',
        finishedKm:
          cargo.finished_km || cargo.finished_km > 0
            ? format(cargo.finished_km, 'N')
            : '0',
        traveledKm:
          cargo.finished_km || cargo.finished_km > 0
            ? format(cargo.finished_km - cargo.started_km, 'N')
            : '0',
        startDate: startDate,
        startTime: startTime,
        finishedTime: finishedTime,
        duration: duration,
        driver: driver,
        totalCheckedByFin: totalCheckedByFin,
        totalCheckedByLog: totalCheckedByLog,
        totalInvoicesPaymentEdited: totalInvoicesPaymentEdited
      }
      result.porcentFinished = parseInt(
        parseFloat(
          (result.deliveredCustomers / result.customers) * 100
        ).toFixed(0)
      )

      return result
    })
}

export default cargoDetail
