<template>
  <truck-form />
</template>

<script>
import Form from '@/components/Admin/Trucks/components/form'

export default {
  name: 'trucksAdd',
  components: {
    TruckForm: Form,
  },
}
</script>
