import { db } from '@/config/firebase'

export default {
  // Função clone da getEventsOfCargo dentro da action para ser executada de dentro de outro getter
  getEventsOfCargoGetter: (commit, getters, rootState) => async ({cargoId, branchId}) => {
  // async getEventsOfCargoGetter({ commit, rootState }, ) {
    let logsRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('events')
    let events = []

    await logsRef
      .where('cargo_id', '==', cargoId)
      .where('branch_id', '==', branchId)
      .limit(100)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) =>
          events.push(Object.assign({ id: doc.id }, doc.data()))
        )
      })
      .catch((err) => {
        throw new Error(err)
      })

    events.total_value = events.reduce((total, curr) => {
      if (curr.event && curr.event.value) {
        return (total + curr.event.value)
      }
    }, 0 )

    return events
  },
}
