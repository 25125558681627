<template>
  <div class="drag-and-drop-document-list">
    <h2>
      <el-row>DOCUMENTOS</el-row>
    </h2>

    <el-row type="flex" justify="space-between">
      <div class="textoQuantidade">
        {{ `Documentos: ${customersOnInvoices}` }}
      </div>
      <div class="textoQuantidade">
        {{ `Clientes: ${customersOnInvoices}` }}
      </div>
      <div class="textoQuantidade">
        Peso:
        {{
          new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 1,
          }).format(weightOfInvoices)
        }}
      </div>
      <div class="textoQuantidade">
        Valor:
        {{
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(totalValueOfInvoices)
        }}
      </div>
    </el-row>

    <el-pagination
      @current-change="changeDocsPage"
      :current-page="page"
      :pager-count="9"
      :page-size="limit"
      layout="prev, pager, next"
      :total="quantityOfInvoices"
      small
    />

    <el-card
      class="box-card"
      shadow="never"
      :body-style="{ 'background-color': 'rgba(131, 127, 127, 0.692)' }"
    >
      <div v-if="customersOnInvoices > 0" class="columns">
        <container
          v-for="(items, idx) in groups"
          :key="idx"
          class="column"
          group-name="column"
          :data-index="idx"
          drag-class="opacity-ghost"
          drop-class="opacity-ghost-drop"
          :get-child-payload="(itIndex) => getChildPayload(idx, itIndex)"
          :should-accept-drop="(src, pld) => getShldAcptDrop(idx, src, pld)"
          :should-animate-drop="(src, pld) => getShldAnimDrop(idx, src, pld)"
          @drop="onDrop(idx, $event)"
        >
          <draggable v-for="(item, index) in items" :key="index">
            <div class="draggable-item">
              <invoice-collapse :invoice="item.data" :index="index" />
            </div>
          </draggable>
        </container>
      </div>

      <div v-else class="columns">
        <container
          class="column"
          group-name="column"
          @drop="onDrop(0, $event)"
        ></container>
      </div>
      <!-- <span  :index="index + 1">
                Nenhum documento para exibir
      </span>-->
    </el-card>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag, generateItems } from '@/helpers/drag.js'
import invoiceCollapse from './invoiceCollapse.vue'

let iii = 0

export default {
  name: 'Documents',
  components: {
    Container,
    Draggable,
    'invoice-collapse': invoiceCollapse,
  },
  props: {
    invoices: {
      type: Array,
      default: () => {
        return []
      },
    },
    editCargo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      page: 1,
      limit: 30,

      quantityOfInvoices: 0,
      totalValueOfInvoices: 0,
      weightOfInvoices: 0,
      customersOnInvoices: 0,

      groups: [],
      flags: [],
      logPayload: true,
      returnedInvoices: [], // Usado nas edições de carga
    }
  },
  watch: {
    // Disparado quando mount.vue termina de carregar os documentos
    // Ou quando é adicionado ou removido um elemento no drag-drop
    // Ou quando filtro é ativado
    invoices: function (val) {
      iii = 0
      this.changeDocsPage(1)
      this.calculateDocs(this.concatenedWithAllDocs)
    },
  },

  mounted() {},

  computed: {
    pagedDocuments() {
      return this.invoices.slice(
        (this.page - 1) * this.limit,
        this.page * this.limit
      )
    },
    concatenedDocs() {
      return [].concat(this.pagedDocuments, this.returnedInvoices)
    },
    concatenedWithAllDocs() {
      return [].concat(this.invoices, this.returnedInvoices)
    },
  },

  methods: {
    changeDocsPage(page = 1) {
      this.page = page
      this.setInvoices(this.concatenedDocs)
    },
    calculateDocs(inv) {
      this.quantityOfInvoices = inv.length
      this.totalValueOfInvoices = inv.reduce((t, i) => t + i.invoice_total, 0)
      this.weightOfInvoices = inv.reduce((t, i) => t + i.weight, 0)
      this.customersOnInvoices = inv.reduce((results, invoice) => {
        if (results.indexOf(invoice.customer) < 0) {
          results.push(invoice.customer)
        }
        return results
      }, []).length
    },
    id() {
      return `item-${++iii}`
    },
    setInvoices(invoices) {
      iii = 0
      let documents = []
      let documentsFlag = []
      for (let i in invoices) {
        let data = invoices[i]
        if (iii < 2) {
          documents.push(
            generateItems(1, (iii) => ({
              id: this.id(),
              data: data,
            }))
          )
          documentsFlag.push({ drop: true, animate: true })
        } else {
          let index = iii % 2
          let obj = {}
          obj.id = this.id()
          obj.data = data
          documents[index].push(obj)
        }
      }
      this.groups = documents
      this.flags = documentsFlag
    },
    getChildPayload(groupIndex, itemIndex) {
      return this.groups[groupIndex][itemIndex]
    },
    getShldAcptDrop(index, sourceContainerOptions, payload) {
      return this.flags[index].drop
    },
    getShldAnimDrop(index, sourceContainerOptions, payload) {
      return this.flags[index].animate
    },
    onDrop(groupIndex, dropResult) {
      // Invoice adicionado durante a edição da carga
      if (dropResult.addedIndex && this.editCargo) {
        this.returnedInvoices.push(dropResult.payload.data)
      }

      // Invoice removido durante a edição da carga
      if (dropResult.removedIndex && this.editCargo) {
        let index = this.returnedInvoices.findIndex(
          (inv) => inv.id === dropResult.payload.data.id
        )
        this.returnedInvoices.splice(index, 1)
      }

      let result = applyDrag(this.groups[groupIndex], dropResult)
      this.$set(this.groups, groupIndex, result)
    },
  },
}
</script>
<style>
.drag-and-drop-document-list .controls {
    margin-top: 1em;
  }

.drag-and-drop-document-list .controls > div {
    padding-top: 1em;
  }

.drag-and-drop-document-list label {
    display: block;
    line-height: 1.6em;
  }

.drag-and-drop-document-list .columns {
    display: flex;
    justify-content: stretch;
    overflow-y: auto;
  }

.drag-and-drop-document-list .column {
    margin-right: 20px;
    flex: 1;
    font-size: x-small;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

.drag-and-drop-document-list .column .smooth-dnd-container.vertical {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

.drag-and-drop-document-list .textoQuantidade {
    font-style: italic;
    font-size: 10px;
    text-align: center;
    margin-top: 4px;
  }

.drag-and-drop-document-list h2 {
    margin: 0;
    padding: 0;
  }
</style>
