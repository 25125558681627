<!--
      Barra de filtors global, embutida no titulo da pagina

      <global-title-with-filter-bar
      title="Dashboard"                             - Titulo
      filtersCargos                                 - Habilita botão para exibir barra de filtros relacionado ao state de cargos
      filtersInvoices                               - TODO: Habilita botão para exibir barra de filtros relacionado ao state de invoices
      hidedate                                      - Remove seleção de datas e filiais
      :buttons="[{                                  - Array com botões extras par barra
        text:'Nova Carga',
        click: () => $router.push('mount'),
        type: 'success'}]"
      @gFilterSearchClick="updateDashboard"         - Evento do click no botão de busca do componente global-filter-bar, ele que realiza as buscas pela carga
      @gFilterRefreshClick="updateDashboard"        - Evento do click no botão de refresh do componente global-filter-bar
      @cFilterOnUpdate="onUpdatedFilter"            - Evento disparado na alteração das opções do filtro, 1s depois, do componente cargos-filter-bar
      @cFilterOnUpdateStart="initUpdateFunctions"   - Evento disparado imediatamente no inicio das alterações dos filtros, do componente cargos-filter-bar
      @cFilterApplyClick="updateDashboard"          - Evento de disparo quando clicado no botão apply das barras dos filtros, do componente cargos-filter-bar
      @cFilterResetClick="updateDashboard"          - Evento de disparo quando clicado no botão reset das barras dos filtros, do componente cargos-filter-bar

      Aceita o uso do slot <slot>
      />
 -->

<template>
  <div class="global-title-with-filter-bar">
    <el-row class="global-title-with-filter-bar-row">
      <!-- Titulo -->
      <el-col :xs="colSize.xs1" :sm="colSize.sm1" class="title">{{
        title
      }}</el-col>

      <!-- Coluna central / datepicker +  branch select -->
      <el-col
        :xs="colSize.xs2"
        :sm="colSize.sm2"
        class="global-filters"
        v-if="!hidedate"
      >
        <global-filter-bar @searchClick="gFilterSearchClick" insideTitle />
      </el-col>

      <!-- Botão para exibir / ocultar barra de filtros -->
      <el-col :xs="colSize.xs1" :sm="colSize.sm1">
        <ul>
          <li v-if="filtersCargos || filtersInvoices" class="right">
            <a @click="showFilter" class="filterbutton">
              <icon class="icon" name="filter" scale="0.6" />
              <icon
                class="icon"
                name="exclamation-triangle"
                scale="0.6"
                v-if="hasFilter"
                style="color: red"
              ></icon>
              <!-- <i :class="filterShow ? 'el-icon-remove-outline' : 'el-icon-circle-plus-outline'" />{{ (filterShow) ? ' Ocultar Filtros' : ' Exibir Filtros' }} -->
            </a>
          </li>

          <!-- Botões extras enviados para o componente -->
          <li v-for="(button, index) in buttons" :key="index" class="right">
            <a @click="button.click" :class="button.type || ''">
              {{ button.text }}
            </a>
          </li>
        </ul>
      </el-col>
    </el-row>

    <!-- Barra de filtro de cargas -->
    <transition-group
      v-if="filtersCargos"
      name="slide"
      tag="div"
      :class="['block-slider', lineShow ? 'active' : '']"
    >
      <cargos-filter-bar
        v-if="filtersCargos"
        :key="1"
        v-show="filterShow"
        @onUpdate="cFilterOnUpdate"
        @onUpdateStart="cFilterOnUpdateStart"
        @applyFilterClick="cFilterApplyClick"
        @resetFilterClick="cFilterResetClick"
      />
    </transition-group>

    <!-- Slot para rendereizar componentes informados dentro da tag global-filter-bar -->
    <slot></slot>

    <!-- Barra de filtro de invoices -->
    <!--
    <transition-group>
      <invoices-filter-bar v-if="filtersInvoices" />
    </transition-group>
    -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import globalFilterBar from '@/components/Menu/globalFilterBar'
import cargosFilterBar from '@/components/Menu/cargosFilterBar'
// import invoicesFilterBar from '@/components/Menu/invoicesFilterBar'

export default {
  name: 'globalPlusCargosFilterBar',
  components: {
    globalFilterBar,
    cargosFilterBar,
  },
  props: {
    title: {
      type: String,
      default: 'Titulo',
    },
    filtersCargos: {
      type: Boolean,
      default: false,
    },
    filtersInvoices: {
      type: Boolean,
      default: false,
    },
    filtersStartVisible: {
      type: Boolean,
      default: false,
    },
    hidedate: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      filterShow: false,
      lineShow: false,
    }
  },
  computed: {
    ...mapState('Cargos', ['filters']),
    hasFilter() {
      return Object.keys(this.filters).some(
        (filterKey) => this.filters[filterKey].length >= 1
      )
    },
    colSize() {
      if (this.hidedate) return { xs1: 24, xs2: 0, sm1: 12, sm2: 0 }
      else return { xs1: 24, xs2: 24, sm1: 5, sm2: 14 }
    },
  },
  mounted() {
    // Verifica se existem filtros aplicados e abre a guia de filtros automaticamente.
    Object.keys(this.filters).forEach((filterKey) => {
      if (this.filters[filterKey].length >= 1) {
        this.filterShow = true
        this.lineShow = true
      }
    })

    if (this.filtersStartVisible) {
      this.showFilter()
    }
  },
  methods: {
    async gFilterSearchClick(filters) {
      // console.log('gFilterSearchClick', filters)
      this.$emit('gFilterSearchClick', filters)
    },
    async gFilterRefreshClick(filters) {
      // console.log('gFilterRefreshClick', filters)
      this.$emit('gFilterRefreshClick', filters)
    },
    async cFilterOnUpdate(filters) {
      // console.log('cFilterOnUpdate', filters)
      this.$emit('cFilterOnUpdate', filters)
    },
    async cFilterOnUpdateStart() {
      // console.log('cFilterOnUpdateStart')
      this.$emit('cFilterOnUpdateStart')
    },
    async cFilterApplyClick(filters) {
      // console.log('cFilterApplyClick', filters)
      this.$emit('cFilterApplyClick', filters)
    },
    async cFilterResetClick(filters) {
      // console.log('cFilterResetClick', filters)
      this.$emit('cFilterResetClick', filters)
    },
    showFilter() {
      // console.log('showFilter', this.filterShow)
      this.filterShow = !this.filterShow
      if (!this.filterShow) {
        setTimeout(() => {
          this.lineShow = !this.lineShow
        }, 800)
      } else {
        this.lineShow = !this.lineShow
      }
    },
  },
}
</script>

<style scoped>
.block-slider {
  overflow: hidden;
  /* position: relative; */
}

.block-slider.active div {
  border-bottom: 2px #ccc solid;
  padding-bottom: 10px;
  margin: 10px;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.8s;
}
.slide-enter {
  transform: translate(0, -100%);
}
.slide-leave-to {
  transform: translate(0, -100%);
}

/* Titulos de página e Botões do topo */
.global-title-with-filter-bar {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.global-title-with-filter-bar-row {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  border-bottom: 2px #ccc solid;
  line-height: 20px;
}

.global-title-with-filter-bar-row > .title {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 8px;
}

.global-title-with-filter-bar-row > .global-filters .el-row {
  padding: 0;
  margin: 0;
}

.global-title-with-filter-bar-row > .global-filters {
  padding: 5px;
}

.global-title-with-filter-bar-row ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
}

.global-title-with-filter-bar-row li {
  float: left;
}

.global-title-with-filter-bar-row li.right {
  float: right;
}

.global-title-with-filter-bar-row li a {
  min-width: 100px;
  cursor: pointer;
  display: block;
  color: white;
  text-align: center;
  padding: 8px 10px;
  text-decoration: none;
  border-left: 0.5px #ccc solid;
}

.filterbutton {
  min-width: 50px !important;
}

.global-title-with-filter-bar-row li a {
  background-color: #bebebe;
}

.global-title-with-filter-bar-row li a:hover:not(.active) {
  background-color: #9b9b9b;
}

.global-title-with-filter-bar-row .active {
  background-color: #bebebe;
}

.success {
  background-color: #67c23a !important;
}

.success:hover:not(.active) {
  background-color: rgb(85, 167, 44) !important;
}
</style>
