<!--
    <datepicker-select :dateRange.sync="pickerDateValue" />

    <date-picker
      :dateRange.sync="dateSelected"    - Model no pai que receberá os valores da data
      @change="onUpdatedFields"         - Evento chamada em cada update
      stateValuesUpdate                 - Se campo de datas deve receber valores do state global de filtro start e filtro end
      cargosValuesUpdate                - Atualiza valores do datepicker com os valores do state da tabela de filtro de cargos
      debug                             - Exibe algumas mensagens de console extras
      denyEmpty                         - Impede que datepicker fique vazio
      size                              - tamanho do dapicker conforme elemente ui sizes
      disabled                          - Componente desativado
    />

-->

<template>
  <div class="datepicker-select">
    <el-date-picker
      :size="size"
      style="width: 100%;"
      v-model="dateRangeInternal"
      type="daterange"
      align="left"
      :unlink-panels="false"
      @change="commomMethod"
      range-separator="-"
      start-placeholder="Data inicial"
      end-placeholder="Data final"
      format="dd/MM/yyyy"
      value-format="yyyy-MM-dd HH:mm:ss"
      :default-time="['00:00:00', '23:59:59']"
      :picker-options="pickerDateOptions"
      :clearable="false"
      :disabled="disabled"
    ></el-date-picker>
  </div>
</template>

<script>
import format from '@/helpers/format'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'globalFilterBar',
  props: {
    size: {
      type: String,
      default: 'mini',
    },
    dateRange: {
      type: Array,
      default: () => {
        return []
      },
    },
    stateValuesUpdate: {
      type: Boolean,
      default: false,
    },
    cargosValuesUpdate: {
      type: Boolean,
      default: false,
    },
    debug: {
      type: Boolean,
      default: false,
    },
    denyEmpty: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickerDateOptions: {
        disabledDate(time) {
          // let now = new Date()
          // now.setHours(23, 59, 59, 999)
          // now.setTime(now.getTime() - 3600 * 1000 * 3)
          // if (time.getTime() > now.getTime()) console.log('disabledDate Trus', (time.getTime() > now.getTime()), now.toISOString(), time.toISOString())
          // return time.getTime() > now.getTime()
          time.setHours(0, 0, 0, 0) // corrige bug que ao clicar no dia atual para inicio e fim o componente não aplicava a data
          return time.getTime() > Date.now()
        },
        onPick(dates) {
          // Evento disparado em cada clique de data
          // console.log('onPick', dates.minDate, dates.maxDate)
        },
        shortcuts: [
          {
            text: 'Hoje',
            onClick(picker) {
              const today = new Date() // YYYY-MM-DD 17:11:00
              picker.$emit('pick', [today, today])
            },
          },
          {
            text: '1 dia',
            onClick(picker) {
              const today = new Date() // YYYY-MM-DD 17:11:00
              const start = new Date() // YYYY-MM-DD 17:11:00
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1) // menos 1 dia
              picker.$emit('pick', [start, today])
            },
          },
          {
            text: '7 dias',
            onClick(picker) {
              const today = new Date() // YYYY-MM-DD 17:11:00
              const start = new Date() // YYYY-MM-DD 17:11:00
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7) // menos 7 dias
              picker.$emit('pick', [start, today])
            },
          },
          {
            text: '30 dias',
            onClick(picker) {
              const today = new Date() // YYYY-MM-DD 17:11:00
              const start = new Date() // YYYY-MM-DD 17:11:00
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30) // menos 30 dias
              picker.$emit('pick', [start, today])
            },
          },
          {
            text: 'Último mês',
            onClick(picker) {
              let now = new Date()
              let firstDayPrevMonth = new Date(
                now.getFullYear(),
                now.getMonth() - 1,
                1
              )
              let lastDayPrevMonth = new Date()
              lastDayPrevMonth.setDate(1)
              lastDayPrevMonth.setHours(-1)
              picker.$emit('pick', [firstDayPrevMonth, lastDayPrevMonth])
            },
          },
        ],
      },
      dateRangeInternal: [],
    }
  },
  computed: {
    ...mapState('Shared', ['searchStartDateFilter', 'searchEndDateFilter']),
    ...mapState('Cargos', ['filters']),
  },
  mounted() {
    // Se campo data vazio não for permitido valores nulos, oculta atalho para limpar campos de datas
    // Adiciona linhas em branco para deixar o apagar no final
    if (!this.denyEmpty) {
      let quantidadeAtual = this.pickerDateOptions.shortcuts.length
      let maxItens = 9
      for (let i = quantidadeAtual; i < maxItens; i++) {
        this.pickerDateOptions.shortcuts.push({ text: '.', onClick(picker) {} })
      }
      this.pickerDateOptions.shortcuts.push({
        text: 'Limpar',
        onClick(picker) {
          picker.$emit('pick', null)
        },
      })
    }

    // Prepao o array do picker de datas com os filtros de datas
    if (this.stateValuesUpdate)
      this.dateRangeInternal = [
        this.searchStartDateFilter,
        this.searchEndDateFilter,
      ]

    // Atualiza valores do datepicker com os valores do state da tabela de filtro de cargos
    if (
      this.cargosValuesUpdate &&
      Array.isArray(this.filters.datepicker) &&
      this.filters.datepicker.length > 1
    )
      this.dateRangeInternal = [
        this.filters.datepicker[0],
        this.filters.datepicker[1],
      ]

    // Ganha 10px no campo de datas e ajusda em monitores pequenos
    document.querySelector('.el-range__close-icon').style.display = 'none'
  },
  methods: {
    ...mapMutations('Shared', ['setSearchDateFilter']),
    async commomMethod() {
      if (Array.isArray(this.dateRangeInternal)) {
        let start = new Date(this.dateRangeInternal[0])
        let end = new Date(this.dateRangeInternal[1])

        start.setHours(0, 0, 0, 1)
        end.setHours(23, 59, 59, 999)
        // start.setTime(start.getTime() - 3600 * 1000 * 3) // ajusta para -3 horas, porque setHours converte para UTC
        // end.setTime(end.getTime() - 3600 * 1000 * 3) // ajusta para -3 horas, porque setHours converte para UTC

        this.dateRangeInternal[0] = format(start, 'DTSQL')
        this.dateRangeInternal[1] = format(end, 'DTSQL')

        // Update date range on global filter
        if (this.stateValuesUpdate)
          this.setSearchDateFilter({
            start: this.dateRangeInternal[0],
            end: this.dateRangeInternal[1],
          })

        // Se valor do campo for nulo e este estiver configurado para proibir nulo
      } else {
        if (this.denyEmpty) {
          console.warn(
            `dateRangeInternal não é array em um campo não nulo, redefinindo variaveis agora para as gravadas em memória`,
            this.dateRangeInternal
          )
          setTimeout(() => {
            this.dateRangeInternal = [
              this.searchStartDateFilter,
              this.searchEndDateFilter,
            ]
          }, 800)
        }
      }

      if (this.debug) {
        console.log('Update date range from component', this.dateRangeInternal)
      }

      this.$emit('update:dateRange', this.dateRangeInternal)
      this.$emit('change', this.dateRangeInternal)
    },
  },
}
</script>
