<template>
  <div class="form-branch">
    <el-form
      ref="addBranch"
      status-icon
      :model="form"
      :rules="rules"
      label-position="top"
      class="el-form-fullwidth"
    >
      <el-row>
        <el-col :span="11">
          <el-form-item label="Id da Filial" prop="branch_id">
            <el-input v-model="form.branch_id"></el-input>
          </el-form-item>

          <el-form-item label="CNPJ da Filial" prop="cnpj">
            <el-input v-model="form.cnpj"></el-input>
          </el-form-item>

          <el-form-item label="Nome da Filial" prop="branch_name">
            <el-input v-model="form.branch_name"></el-input>
          </el-form-item>

          <el-form-item label="Endereço" prop="address1">
            <el-input v-model="form.address1"></el-input>
            <div class="form-input-extra-info" v-if="branch">
              Localização {{ branch.latitude }}, {{ branch.longitude }}
            </div>
          </el-form-item>

          <el-form-item label="Complemento" prop="address2">
            <el-input v-model="form.address2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="11">
          <el-form-item label="Bairro" prop="neighborhood">
            <el-input v-model="form.neighborhood"></el-input>
          </el-form-item>

          <el-form-item label="Cidade" prop="city">
            <el-input v-model="form.city"></el-input>
          </el-form-item>

          <el-form-item label="Estado" prop="state">
            <el-input v-model="form.state"></el-input>
          </el-form-item>

          <el-form-item label="CEP" prop="postal_code">
            <el-input v-model="form.postal_code"></el-input>
          </el-form-item>

          <el-form-item label="País" prop="country" disabled>
            <el-input v-model="form.country"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="actions-buttons actions-buttons">
      <el-button type="success" @click="saveForm">{{ submitText }}</el-button
      ><br />
      <el-button type="info" @click="$router.go(-1)">Cancelar</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'form-branch',
  props: {
    branch: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        branch_id: '',
        cnpj: '',
        branch_name: '',
        address1: '',
        address2: '',
        neighborhood: '',
        city: '',
        state: '',
        postal_code: '',
        country: 'Brasil',
      },
      rules: {
        branch_id: [
          { required: true, message: 'Campo obrigatório' },
          { validator: this.validateBranchId, trigger: 'blur' },
        ],
        branch_name: [{ required: true, message: 'Campo obrigatório' }],
        address1: [{ required: true, message: 'Campo obrigatório' }],
      },
    }
  },
  computed: {
    ...mapState('Branches', ['allBranches']),
    submitText() {
      if (this.branch) return 'Atualizar'
      else return 'Adicionar'
    },
  },
  mounted() {
    // Se recebe dados da branch, preenche formulário para edição
    if (this.branch) {
      // Não uso object.assgn por que que quero apenas os campos do form sejam enviados para update/add
      // com o comando assing todos os dados existentes no banco serão enviados para atualização e pode gerar inconsistências
      // Object.assign(this.form, this.branch)

      Object.keys(this.form).forEach((key) => {
        this.form[key] = this._.get(this, `branch.${key}`, '')
      })
    }
  },
  beforeDestroy() {
    this.$emit('onDestroy')
  },
  methods: {
    ...mapActions('Branches', ['addBranch', 'updateBranch']),
    async saveForm() {
      if (this.branch) await this.updateBranch(this.form)
      else await this.addBranch(this.form)

      this.$router.push({ name: 'CompanieTabBranches' })
    },
    validateBranchId(rule, value, callback) {
      if (this.allBranches.some((branch) => branch.branch_id === value))
        callback(new Error('ID em uso'))
      else callback()
    },
  },
}
</script>
