<template>
  <div>
    <h2 style="border-bottom: 0px; margin: 20px 0px;">
      {{ label }}
      (
      <el-button type="text" @click="add">
        Adicionar Novo
      </el-button>
      )
    </h2>
    <el-row class="switch-row" v-for="item in internalArray" :key="item.id">
      <el-col :span="4">
        <el-form-item
          label="Descrição"
          class="switch-item-input"
          :disabled="disabled"
        />
      </el-col>

      <el-col :span="11">
        <el-form-item class="switch-item-input">
          <el-input
            v-model="item.description"
            :disabled="disabled"
            @input="updated"
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="1">
        <span>&nbsp;&nbsp;</span>
      </el-col>

      <el-col :span="4">
        <el-form-item
          label="Habilitar foto"
          class="switch-item-input"
          :disabled="disabled"
        />
      </el-col>

      <el-col :span="2">
        <el-form-item class="switch-item-input">
          <el-switch
            v-model="item.photo"
            :disabled="disabled"
            @change="updated"
          />
        </el-form-item>
      </el-col>

      <el-col :span="1">
        <el-button
          type="info"
          icon="el-icon-delete"
          circle
          plain
          @click="remove(item.id)"
        ></el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'form-switch-item',
  props: {
    input: {
      default: undefined,
    },
    label: {
      type: String,
      default: 'Label do item',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalArray: [],
    }
  },
  computed: {
    getMinId() {
      return Math.min(...this.getIDs())
    },
    getMaxId() {
      return Math.max(...this.getIDs())
    },
  },
  created() {
    this.internalArray = [...this.input]
  },
  methods: {
    add() {
      this.internalArray.push({
        description: '',
        photo: false,
        id: this.getMaxId + 1,
      })
      this.updated()
    },
    remove(id) {
      this.internalArray = this.internalArray.filter((item) => item.id !== id)
      this.updated()
    },
    updated() {
      this.$emit('update:input', this.internalArray)
    },
    getIDs() {
      return this.internalArray.map((d) => d.id)
    },
  },
}
</script>

<style scoped>
.switch-item-input {
  margin-bottom: 0px;
}

.switch-row:hover {
  background-color: #f5f7fa;
}
</style>
