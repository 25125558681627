<template>
  <div class="tab-branches">
    <ul class="menu-header">
      <li><span>Endereços e filiais</span></li>
      <li class="r">
        <a
          v-if="showAddBranchForm"
          @click="$router.push({ name: 'CompanieTabBranches' })"
          >Listar</a
        >
      </li>
      <li class="r">
        <a
          v-if="!showAddBranchForm"
          @click="$router.push({ name: 'CompanieTabBranchesAdd' })"
          >Adicionar Filial</a
        >
      </li>
      <li class="r">
        <a v-if="!showAddBranchForm" @click="updateBranches()">Atualizar</a>
      </li>
    </ul>

    <branches-data-table
      v-if="!showAddBranchForm"
      :filiais="allBranches"
      @edit="handleEdit"
      @delete="handleDelete"
    />
    <form-branch
      v-if="showAddBranchForm"
      :branch="branch"
      @onDestroy="clearBranch"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import formBranch from '../forms/formBranch'
import branchesDataTable from '../components/branchesDataTable'

export default {
  name: 'tabCompanieBranches',
  components: {
    branchesDataTable,
    formBranch,
  },
  data() {
    return {
      showAddBranchForm: false,
      branch: null,
    }
  },
  computed: {
    ...mapState('Branches', ['allBranches']),
  },
  watch: {
    '$route.name'() {
      this.handleMounted()
    },
  },
  mounted() {
    this.handleMounted()
  },
  methods: {
    ...mapActions('Branches', ['getBranches']),
    ...mapMutations('Shared', ['setError']),
    async updateBranches() {
      await this.getBranches()
    },
    handleEdit(row) {
      this.$router.push({
        name: 'CompanieTabBranchesEdit',
        params: { id: row.id },
      })
    },
    handleDelete(row) {
      // console.log('delete', row)
    },
    clearBranch() {
      this.branch = null
    },
    handleMounted() {
      // Captura dos dados da url para form
      if (
        this.$route.name === 'CompanieTabBranchesEdit' &&
        this.$route.params.id
      ) {
        this.branch =
          this.allBranches.find(
            (branch) => branch.id === this.$route.params.id
          ) || {}

        if (!this.branch.id) {
          this.setError('Filial inválida')
          this.$router.push({ name: 'CompanieTabBranches' })
        }
      }

      // Se deve ou não exibir formulário para adicionar nova filial
      this.showAddBranchForm =
        this.$route.meta.tabName === 'tabBranches' &&
        this.$route.meta.showAddBranchForm
    },
  },
}
</script>
