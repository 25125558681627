<template>
  <div class="list-devices">
    <el-table :data="formatedData" class="table" @cell-click="cellClicked">
      <el-table-column sortable prop="login" label="Login" />
      <el-table-column sortable prop="branch" label="Filial" width="70" />
      <el-table-column sortable label="Android" align="center"
        ><template slot-scope="scope"
          >{{ scope.row.osName }} {{ scope.row.osVersion }}</template
        ></el-table-column
      >
      <el-table-column sortable label="Browser" align="center"
        ><template slot-scope="scope"
          >{{ scope.row.browserName }} {{ scope.row.browserVersion }}</template
        ></el-table-column
      >
      <el-table-column sortable label="Tela" width="100" align="center"
        ><template slot-scope="scope"
          >{{ scope.row.screenH }}x{{ scope.row.screenW }}</template
        ></el-table-column
      >
      <el-table-column sortable width="100" prop="version" label="Versão" />
      <el-table-column sortable width="120" prop="build" label="Build" />
      <el-table-column sortable width="70" prop="queue" label="Fila" />
      <el-table-column sortable width="160" prop="updated" label="Data" />
    </el-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import t from 'typy'

export default {
  name: 'devicesList',
  computed: {
    ...mapState('Devices', ['allDevices']),
    formatedData() {
      let dataToReturn = []
      this.allDevices.forEach((device) => {
        let dataObject = {
          login: device.login.split('@')[0],
          branch: device.branch_id || '-',
          version: device.version,
          build: device.build_date,
          updated: this.$moment(device.device.timestamp).format(
            'DD/MM/YY HH:MM'
          ),
          queue: t(device, 'extraData.queue_size').safeObject || '--',
          browserName:
            t(device, 'device.browser.name').isDefined &&
            typeof device.device.browser.name === 'string'
              ? device.device.browser.name.split(' ')[0]
              : '--',
          browserVersion:
            t(device, 'device.browser.version').isDefined &&
            typeof device.device.browser.version === 'string'
              ? device.device.browser.version.split('.')[0]
              : '--',
          osName: t(device, 'device.os.name').safeObject || '--',
          osVersion: t(device, 'device.os.version').safeObject || '--',
          screenH: t(device, 'device.screen.height').safeObject || '--',
          screenW: t(device, 'device.screen.width').safeObject || '--',
        }
        dataToReturn.push(dataObject)
      })
      return dataToReturn
    },
  },
  methods: {
    cellClicked(row, column) {
      if (column.property) {
        // console.log(row)
      }
    },
  },
}
</script>

<style>
.list-devices .table {
  width: 100%;
}

.list-devices tr {
  cursor: pointer;
}
</style>
