<template>
  <div class="list-trucks">
    <el-table :data="allTrucks" class="table" @cell-click="cellClicked">
      <el-table-column width="30">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.is_disabled === true ? 'Desativado' : 'Ativado'"
            placement="top-start"
          >
            <el-button
              size="mini"
              circle
              :type="scope.row.is_disabled === true ? 'danger' : 'success'"
              @click="handleEnable(scope.row)"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="truck_id" label="Placa" width="120" />
      <el-table-column prop="truck_model" label="Modelo" />
      <el-table-column prop="km" label="KM" width="100" />
      <el-table-column label="Última localização">
        <template slot-scope="scope">
          {{
            scope.row.location && scope.row.location.latitude
              ? scope.row.location.latitude
              : ''
          }}
          <br />
          {{
            scope.row.location && scope.row.location.longitude
              ? scope.row.location.longitude
              : ''
          }}
        </template>
      </el-table-column>
      <el-table-column prop="branch_id" label="Filial">
        <template slot-scope="scope">
          {{ getBranchName(scope.row.branch_id) }}
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)"
            />
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              disabled
              @click="handleDelete(scope.row.id, scope.row.truck_id)"
            />
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'trucksList',
  data() {
    return {}
  },
  computed: {
    ...mapState('Trucks', ['allTrucks']),
    ...mapState('User', ['profile']),
    ...mapGetters('User', ['getCompanieBranches']),
  },
  methods: {
    ...mapActions('Trucks', ['removeTruck', 'enableDisableTruck']),
    getBranchName(id) {
      const branch = this.getCompanieBranches.find((b) => b.branch_id === id)
      if (branch) return branch.branch_name
      return ''
    },
    handleEdit(row) {
      this.$router.push({ name: 'TrucksEdit', params: { truck: row } })
    },
    cellClicked(row, column) {
      if (column.property) {
        this.$router.push({ name: 'TrucksEdit', params: { truck: row } })
      }
    },
    handleEnable(row) {
      const question = `Tem certeza que deseja ${
        row.is_disabled ? 'ativar' : 'desativar'
      } o veículo de placa ${row.truck_id}?`

      this.$confirm(question, 'Atenção!', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning',
      })
        .then(() => this.enableDisableTruck(row))
        .then(() =>
          this.$message({ type: 'success', message: 'Veículo atualizado.' })
        )
        .catch(() => {})
    },
    handleDelete(id, truckId) {
      this.$confirm(
        `Tem certeza que deseja remover o veículo de placa ${truckId}?`,
        'Atenção!',
        { confirmButtonText: 'Sim', cancelButtonText: 'Não', type: 'warning' }
      )
        .then(() => this.removeTruck(id))
        .then(() =>
          this.$message({ type: 'success', message: 'Veículo removido.' })
        )
        .catch(() => {})
    },
  },
}
</script>

<style>
.list-trucks .table {
  width: 100%;
}
.list-trucks .table tr {
  cursor: pointer;
}
</style>
