import { getDefaultEventsState } from './state'

export default {
  resetState(state) {
    // console.log(`Event state reseted`)
    Object.assign(state, getDefaultEventsState())
  },
  changeEvents(state, payload) {
    state.allEvents = payload
  },
}
