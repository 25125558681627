import { getDefaultDevicesState } from './state'

export default {
  resetState(state) {
    // console.log(`Device state reseted`)
    Object.assign(state, getDefaultDevicesState())
  },
  changeDevices(state, payload) {
    state.allDevices = payload
  },
}
