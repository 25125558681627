import { getDefaultDriverState } from './state'

export default {
  resetState(state) {
    // console.log(`Driver state reseted`)
    Object.assign(state, getDefaultDriverState())
  },
  changeDrivers(state, payload) {
    state.allDrivers = payload
  },
  addDriver(state, payload) {
    state.allDrivers.push(payload)
  },
  removeDriver(state, payload) {
    state.allDrivers = state.allDrivers.filter(
      (driver) => driver.driver_login !== payload
    )
  },
  updateDriver(state, payload) {
    const driverIndex = state.allDrivers.findIndex(
      (driver) => driver.driver_login === payload.driver_login
    )
    if (driverIndex >= 0) {
      Object.assign(state.allDrivers[driverIndex], payload)
    } else {
      state.allDrivers.push(payload)
    }
  },
}
