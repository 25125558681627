<template>
  <div class="form-truck">
    <el-form
      ref="addTruck"
      status-icon
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item label="Placa" prop="truck_id">
        <el-input :disabled="isEdition" v-model="form.truck_id" />
      </el-form-item>

      <el-form-item label="Modelo" prop="truck_model">
        <el-input v-model="form.truck_model" />
      </el-form-item>

      <el-form-item v-if="branchs.length" label="Filial" prop="branch_id">
        <el-select v-model="form.branch_id" placeholder="Selecione uma filial">
          <el-option
            v-for="item in branchs"
            :key="item.branch_id"
            :label="item.branch_name"
            :value="item.branch_id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="KM" prop="km">
        <el-input-number v-model.number="form.km" :step="0.1" />
      </el-form-item>

      <el-form-item prop="is_disabled" label="Opções">
        <el-checkbox
          v-model="form.is_disabled"
          label="Veículo desativado"
          border
        />
      </el-form-item>


      <br><br>
      <el-divider content-position="left">Capacidade</el-divider>
      <el-form-item label="Peso" prop="cap_weight">
        <el-input-number v-model.number="form.cap_weight" :precision="0" controls-position="right" />
      </el-form-item>

      <el-form-item label="Volumes" prop="cap_volumes">
        <el-input-number v-model.number="form.cap_volumes" :precision="0" controls-position="right" />
      </el-form-item>

      <el-form-item prop="has_special_compartment">
        <el-checkbox
          v-model="form.has_special_compartment"
          label="Possúi compartimento especial?"
          border
        />
      </el-form-item>

      <el-form-item label="Tipo compartimento especial" prop="truck_model">
        <el-input v-model="form.special_compartment" />
      </el-form-item>


      <br><br>
      <el-divider content-position="left">Custos fixos do veículo por ano</el-divider>
      <el-form-item label="Seguro" prop="cost_security">
        <el-input-number v-model.number="form.cost_security" :precision="2" controls-position="right" />
      </el-form-item>

      <el-form-item label="Ipva" prop="cost_ipva">
        <el-input-number v-model.number="form.cost_ipva" :precision="2" controls-position="right" />
      </el-form-item>

      <el-form-item label="Rastreador" prop="cost_tracker">
        <el-input-number v-model.number="form.cost_tracker" :precision="2" controls-position="right" />
      </el-form-item>

      <el-form-item label="Depreciação" prop="cost_depreciation">
        <el-input-number v-model.number="form.cost_depreciation" :precision="2" controls-position="right" />
      </el-form-item>

      <el-form-item label="Outras despesas" prop="cost_others">
        <el-input-number v-model.number="form.cost_others" :precision="2" controls-position="right" />
      </el-form-item>

      <el-form-item label="Outras despesas descrição" prop="cost_othersdescriptions">
        <el-input v-model="form.cost_othersdescriptions" />
      </el-form-item>

      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="success" @click="saveForm(isEdition)">{{
          saveButtonText
        }}</el-button>
        <el-button type="info" @click="$router.go(-1)">Cancelar</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    truck: {
      type: Object,
      default: null,
    },
  },
  data() {
    const validateTruckId = (rule, value, callback) => {
      const truckIdRegex = new RegExp(
        '([A-Z]{3}-?[0-9]{4})|([A-Z]{3}-?[0-9][A-Z][0-9]{2})'
      )
      if (!truckIdRegex.test(value)) {
        callback(new Error('Placa inválida!'))
      } else {
        callback()
      }
    }

    return {
      form: {},
      branchs: [],
      rules: {
        truck_id: [
          { required: !this.truck, message: 'Campo obrigatório' },
          { validator: validateTruckId, trigger: 'blur' },
        ],
        truck_model: [{ required: true, message: 'Campo obrigatório' }],
        branch_id: [{ required: true, message: 'Campo obrigatório' }],
        km: [{ required: true, message: 'Campo obrigatório' }],
      },
      saveButtonText: this.truck ? 'Atualizar' : 'Adicionar',
      isEdition: Boolean(this.truck),
    }
  },
  computed: {
    ...mapGetters('User', ['getCompanieBranches']),
  },
  created() {
    this.branchs = this.getCompanieBranches

    // Form object somente com os campos necesários para update
    this.form = {
      truck_id: this._.get(this, 'truck.truck_id', ''),
      truck_model: this._.get(this, 'truck.truck_model', ''),
      branch_id: this._.get(this, 'truck.branch_id', ''),
      km: this._.get(this, 'truck.km', 0),
      is_disabled: this._.get(this, 'truck.is_disabled', false),
      cost_security: this._.get(this, 'truck.cost_security', 0),
      cost_ipva: this._.get(this, 'truck.cost_ipva', 0),
      cost_tracker: this._.get(this, 'truck.cost_tracker', 0),
      cost_depreciation: this._.get(this, 'truck.cost_depreciation', 0),
      cost_others: this._.get(this, 'truck.cost_others', 0),
      cost_othersdescriptions: this._.get(this, 'truck.cost_othersdescriptions', ''),

      cap_weight: this._.get(this, 'truck.cap_weight', 0),
      cap_volumes: this._.get(this, 'truck.cap_volumes', 0),
      has_special_compartment: this._.get(this, 'truck.has_special_compartment', false),
      special_compartment: this._.get(this, 'truck.special_compartment', ''),
    }
  },
  methods: {
    ...mapActions('Trucks', ['addTruck', 'updateTruck']),
    async saveForm(isEdition) {
      this.$refs['addTruck'].validate(async (valid) => {
        if (valid) {
          if (!isEdition) this.addTruck(this.form)
          else await this.updateTruck(this.form)
          this.$router.push({ name: 'TrucksIndex' })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.form-truck  .el-select {
    width: 50%;
  }

.form-truck .el-input-number {
    margin-left: 0;
  }
</style>
