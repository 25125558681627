import { db } from '@/config/firebase'

export default {
  setOnlineState({ commit }) {
    if (!navigator.onLine) {
      db.disableNetwork().then(
        () => {}
        // console.log('Get Offiline! Firestore network disabled')
      )
    } else {
      db.enableNetwork().then(
        () => {}
        // console.log('Get Online! Firestore network Enabled')
      )
    }
    commit('setOnlineState')
  },

  getAppVersions({ commit }) {
    return db
      .collection('settings')
      .doc('versions')
      .get()
      .then((doc) => {
        // console.log('MR Versions loaded')
        commit('setAppVersions', doc.data())
        return doc.data()
      })
      .catch((err) =>
        console.error(`Fail to load MR Versions: ${err.code}/${err.message}`)
      )
  },
}
